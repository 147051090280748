import React from 'react';
import VarRevInsDetailPresentational from './VarRevInsDetailPresentational';

const VarRevInsDetail = (props) => {
  const {
    profile = {},
    reports = {},
    query = {},
    onFilter = () => {},
  } = props;

  return (
    <VarRevInsDetailPresentational
      data={reports}
      onFilter={onFilter}
      query={query}
      profileType={profile.type}
      {...props}
    />
  );
};

export default VarRevInsDetail;
