//Ref. https://reacttraining.com/react-router/web/example/auth-workflow

import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Proptypes from 'prop-types';

function AdminRoute(props) {
  const {
    component: Component,
    appLoaded,
    isAdmin = false,
    isAuthenticated = false,
    privateRedirect = '/',
    publicRedirect = '/',
    ...rest
  } = props;

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated && isAdmin ? (
          appLoaded ? (
            <Component {...props} />
          ) : (
            ''
          )
        ) : (
          <Redirect
            push={false}
            to={{
              pathname: isAuthenticated ? privateRedirect : publicRedirect,
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
}

AdminRoute.propTypes = {
  component: Proptypes.func.isRequired,
  isAdmin: Proptypes.bool,
  isAuthenticated: Proptypes.bool,
};

export default AdminRoute;
