export const getSection = (data = {}) => {
  const { iafs = [] } = data;
  if (iafs.length === 0) {
    return null;
  }
  const section = iafs[0].hasOwnProperty('suppliers')
    ? 'suppliers'
    : iafs[0].hasOwnProperty('gnet_admin')
    ? 'gnet_admin'
    : iafs[0].hasOwnProperty('invoices')
    ? 'invoices'
    : iafs[0].hasOwnProperty('insurances')
    ? 'insurances'
    : iafs[0].hasOwnProperty('insurancesStudio')
    ? 'insurancesStudio'
    : '';

  return section;
};

export const getBonusCreationSection = (data = {}) => {
  const section = data.hasOwnProperty('suppliers')
    ? 'suppliers'
    : data.hasOwnProperty('gnet_admin')
    ? 'gnet_admin'
    : data.hasOwnProperty('invoices')
    ? 'invoices'
    : data.hasOwnProperty('insurances')
    ? 'insurances'
    : data.hasOwnProperty('insurancesStudio')
    ? 'insurancesStudio'
    : '';

  return section;
};
