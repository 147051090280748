import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../commons/Auth';
import { useLoading } from '../../commons/Loading';
import { useAlert } from 'react-alert';
import {
  useQueryParams,
  NumberParam,
  withDefault,
  StringParam,
} from 'use-query-params';
import Invoices10Presentational from './Invoices10Presentational';

const { REACT_APP_DEFAULT_PAGINATION_LIMIT = 50 } = process.env;

function Invoices10({ profile }) {
  const [{ token } = {}] = useAuth();
  const alert = useAlert();
  const [t] = useTranslation();
  const { showLoading, hideLoading } = useLoading();
  const [invoices10, setInvoices10] = useState([]);
  const [total, setTotal] = useState(0);

  const [query, setQuery] = useQueryParams({
    offset: NumberParam,
    limit: withDefault(NumberParam, +REACT_APP_DEFAULT_PAGINATION_LIMIT),
    admin: withDefault(StringParam, ''),
    supplier: withDefault(StringParam, ''),
    number: withDefault(StringParam, ''),
    date: withDefault(StringParam, ''),
    notPaid: withDefault(StringParam, ''),
  });

  const { limit, offset, admin, supplier, number, date, notPaid } = query;

  useEffect(() => {
    fetchInvoices10();
  }, [limit, offset, admin, supplier, number, date, notPaid]);

  function onFilter(queryDiff) {
    if (
      (queryDiff.admin && queryDiff.admin !== admin) ||
      (admin && queryDiff.admin === '') ||
      (queryDiff.supplier && queryDiff.supplier !== supplier) ||
      (supplier && queryDiff.supplier === '') ||
      (queryDiff.number && queryDiff.number !== number) ||
      (number && queryDiff.number === '') ||
      (queryDiff.date && queryDiff.date !== date) ||
      (date && queryDiff.date === '') ||
      (queryDiff.notPaid && queryDiff.notPaid !== notPaid) ||
      (notPaid && queryDiff.notPaid === '') ||
      (queryDiff.limit && queryDiff.limit !== limit)
    ) {
      queryDiff.offset = 0; // return to first page
    }
    setQuery(queryDiff);
  }

  const fetchInvoices10 = async () => {
    showLoading();

    const response = await axios.get(
      profile && profile._id
        ? `${process.env.REACT_APP_API_URL}/invoices10/${profile._id}`
        : `${process.env.REACT_APP_API_URL}/invoices10`,
      {
        headers: { Authorization: token },
        params: {
          limit,
          offset,
          admin,
          supplier,
          number,
          date,
          notPaid,
        },
      }
    );

    hideLoading();

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    const { data = [], headers = {} } = response;
    console.log(data);
    setInvoices10(data);
    setTotal(+headers['x-total-count']);
  };

  const changePaidStatus = async (id, paid) => {
    showLoading();

    const response = await axios.patch(
      `${process.env.REACT_APP_API_URL}/invoices10/${id}`,
      {
        paid: paid,
      },
      {
        headers: { Authorization: token },
      }
    );

    hideLoading();

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    fetchInvoices10();
    return alert.success(t('COMMON:SUCCESS_UPDATE'));
  };

  return (
    <Invoices10Presentational
      profile={profile}
      data={invoices10}
      total={total}
      query={query}
      onFilter={onFilter}
      onPaidChange={changePaidStatus}
    />
  );
}

export default Invoices10;
