import moment from 'moment';

export const isAfter = (dateToCheck, comparisonDate) => {
  return (
    dateToCheck && moment(dateToCheck).isSameOrAfter(moment(comparisonDate))
  );
};

export const getPastYears = (numberOfYears) => {
  const years = [];
  for (let i = 0; i <= numberOfYears; i++) {
    years.push(moment().subtract(i, 'years').year());
  }
  return years;
};
