import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import generatePaginationOptions from '../../lib/helpers/generatePaginationOptions';
import BurgerMenu from '../../commons/BurgerMenu';
import Header from '../../commons/Header';
import { ReactComponent as IconInvoices } from '../../images/icn_fatture.svg';
import {
  ContFilterCheckbox,
  ContPaginationPage,
} from '../../styled-components';
import { FormGroup, CustomInput, Row, Col } from 'reactstrap';
import SearchInput from '../../commons/SearchInput';
import DateFilter from '../../commons/DateFilter';
import { BtnOutlineOrange, ContFlexRight } from '../../styled-components';
import CSVClientsExport from './CSVClientsExport';
import CondAdminDataTable from './CondAdminDataTable';
import AdminDataTable from './AdminDataTable';

function Invoices10Presentational({
  profile,
  data,
  query,
  total,
  onFilter,
  onPaidChange,
}) {
  const [t] = useTranslation('IAF_INVOICES');
  const { clientType } = useParams();
  const { offset, limit } = query;

  const onFilterNotPaid = (e) => {
    onFilter({
      notPaid: e.target.checked,
    });
  };

  return (
    <div className="App">
      <BurgerMenu />

      <div className="corpo">
        <section>
          <Header
            title={t('PAGES:INVOICES10')}
            icon={<IconInvoices fill="#ffffff" style={{ height: '25px' }} />}
          />
          <Row>
            <ContFlexRight>
              <CSVClientsExport
                data={data}
                clientType={clientType}
                year={query.year}
                isAdmin={!(profile && profile._id)}
              >
                <BtnOutlineOrange type="button" className="uppercase">
                  {t('COMMON:CSV_LIST_EXPORT')}
                </BtnOutlineOrange>
              </CSVClientsExport>
            </ContFlexRight>
          </Row>

          <Row className="search-add-cont m-t-20">
            {!(profile && profile._id) && (
              <Col sm="6" md="6" lg="3" xl="3">
                <SearchInput
                  query={{ search: query && query.admin }}
                  onFilter={({ search }) => onFilter({ admin: search })}
                  label={t('SEARCH:ADMINISTRATOR')}
                />
              </Col>
            )}
            <Col sm="6" md="6" lg="3" xl="3">
              <SearchInput
                query={{ search: query && query.supplier }}
                onFilter={({ search }) => onFilter({ supplier: search })}
                label={t('SEARCH:BY_SUPPLIER')}
              />
            </Col>
            <Col sm="6" md="6" lg="3" xl="3">
              <SearchInput
                query={{ search: query && query.number }}
                onFilter={({ search }) => onFilter({ number: search })}
                label={t('SEARCH_BY_NUMBER')}
              />
            </Col>
            <Col sm="6" md="6" lg="3" xl="3">
              <DateFilter
                query={{ search: query && query.date }}
                onFilter={({ search }) => onFilter({ date: search })}
                label={t('SEARCH_BY_DATE')}
              />
            </Col>
          </Row>
          <Row className="search-add-cont m-t-20">
            <Col sm="6" md="6" lg="3" xl="3">
              <ContFilterCheckbox>
                <CustomInput
                  type="checkbox"
                  id="notApproved"
                  label="Fatture 10% non pagate"
                  inline
                  checked={query && query.notPaid === 'true'}
                  onChange={onFilterNotPaid}
                />
              </ContFilterCheckbox>
            </Col>
          </Row>
        </section>

        <section className="m-t-20">
          {profile && profile._id ? (
            <CondAdminDataTable
              data={data}
              total={total}
              offset={offset}
              limit={limit}
              onFilter={onFilter}
            />
          ) : (
            <AdminDataTable
              data={data}
              total={total}
              offset={offset}
              limit={limit}
              onFilter={onFilter}
              onPaidChange={onPaidChange}
            />
          )}

          <ContPaginationPage>
            <p>{t('COMMON:PAGINATION_PER_PAGE')}</p>

            <FormGroup>
              <CustomInput
                type="select"
                className="pagination-select"
                id="perPage"
                name="perPage"
                value={query.limit}
                onChange={({ target: { value: limit } }) => {
                  onFilter({ limit });
                }}
              >
                {generatePaginationOptions()}
              </CustomInput>
            </FormGroup>
          </ContPaginationPage>
        </section>
      </div>
    </div>
  );
}

export default Invoices10Presentational;
