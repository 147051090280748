export const isCheckable = (data = {}) => {
  const { affiliationPeriod, iafs = [], revenue } = data;
  let { billableMasterShare, earnedMasterShare } = data;

  billableMasterShare = billableMasterShare || 0;
  earnedMasterShare = earnedMasterShare || 0;

  if (!iafs?.length) {
    // Nessuno IAF già emesso per la riga.
    return affiliationPeriod
      ? !!revenue // Affiliazione fornitore o ReteG: riga selezionabile solo se revenue è > 0.
      : !!billableMasterShare; // Compenso variabile su fattura o assicurazione: riga selezionabile solo se billableMasterShare è > 0.
  } else {
    // Sono già stati emessi uno o più IAF per la riga.

    // Verifica qual è la sezione in cui ci si trova.
    const section = iafs[0].hasOwnProperty('suppliers')
      ? 'suppliers'
      : iafs[0].hasOwnProperty('gnet_admin')
      ? 'gnet_admin'
      : iafs[0].hasOwnProperty('invoices')
      ? 'invoices'
      : iafs[0].hasOwnProperty('insurances')
      ? 'insurances'
      : iafs[0].hasOwnProperty('insurancesStudio')
      ? 'insurancesStudio'
      : '';

    if (section === 'suppliers' || section === 'gnet_admin') {
      const { revenue } = data;

      const totalPaid =
        +iafs
          .reduce(
            (acc, iaf = {}) => acc + (iaf[section][0]?.iafRevenue || 0),
            0
          )
          .toFixed(2) || 0;

      if (totalPaid >= revenue) {
        // Se sono già stati emessi IAF per l'intero importo, la riga non è selezionabile.
        return false;
      }
    } else if (
      section === 'invoices' ||
      section === 'insurances' ||
      section === 'insurancesStudio'
    ) {
      const totalPaid = iafs.reduce(
        (acc, iaf = {}) =>
          acc +
          (iaf[section][0]?.billableMasterShare
            ? iaf[section][0]?.billableMasterShare
            : iaf[section][0]?.earnedMasterShare || 0),
        0
      );

      // Considera billableMasterShare oppure earnedMasterShare per mantenere
      // la retrocompatibilità con gli IAF già emessi (il calcolo è stato modificato più volte).
      if (totalPaid >= (billableMasterShare || earnedMasterShare)) {
        // Se sono già stati emessi IAF per l'intero importo, la riga non è selezionabile.
        return false;
      }
    }

    // Se non si verifica nessuno dei casi precedenti, allora la riga è selezionabile.
    return true;
  }
};
