import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useForm from 'react-hook-form';
import {
  TabContent,
  TabPane,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  CustomInput,
} from 'reactstrap';
import {
  ContFlexRight,
  ButtonSave,
  ButtonDelete,
  H5Styled,
  BtnRadiusRed,
  InputLink,
} from '../../../styled-components';
import ModalConfirm from '../../../commons/ModalConfirm';
import regions from '../../../lib/helpers/regions';
import FilesList from './FilesList';
import OpenIcon from '../../../images/icn-open.svg';
import { useAlert } from 'react-alert';
import { URL_REGEX } from '../../../lib/helpers/url';
import { ProfileTypes } from '../../../lib/constants';
import { useAuth } from '../../../commons/Auth';
import FormErrorMessage from '../../../commons/FormErrorMessage';
import { AGENT_TYPES } from '../../../App';

const ProfileInfoPresentational = ({
  data = {},
  isUpdating = false,
  updateProfile,
  createProfile,
  deleteProfile,
  uploadFile,
  handleDelete,
}) => {
  const [t] = useTranslation('CONTACTS');
  const alert = useAlert();
  const [{ isAdmin = false }] = useAuth();

  const [isAgent, setIsAgent] = useState(false);
  const [isMaster, setIsMaster] = useState(false);
  const { register, errors, handleSubmit, watch, setValue } = useForm({
    mode: 'onChange',
    defaultValues: data,
  });

  const region = watch('region');
  const UserType = watch('type');

  useEffect(() => {
    setIsAgent(data.agent);
    setIsMaster(data.master);
  }, [data]);

  function onSubmit(values) {
    const data = {
      ...values,
      agent: isAgent,
      master: isMaster,
    };

    isUpdating ? updateProfile(data) : createProfile(data);
  }

  function getDistricts() {
    const currentRegion = isUpdating && !region ? data.region : region;
    const { capoluoghi = [] } =
      regions.find((r) => r.nome === currentRegion) || {};
    return capoluoghi;
  }

  function handleFileChange({ target: { files = [] } = {} }) {
    const file = files[0];

    const data = new FormData();
    data.append('file', file);

    uploadFile(data);
  }

  function openUrl(url) {
    if (url.match(URL_REGEX)) return window.open(url, '_blank');

    alert.info(t('INVALID_URL'));
  }

  return (
    <Form>
      <TabContent activeTab={'1'}>
        <TabPane tabId="1">
          <Row>
            <Col sm="12" md="6" lg="2">
              <FormGroup>
                <Label>{t('IS_A_MASTER')}</Label>
                <div
                // onChange={({ target }) => {
                //   setIsMaster(target.value === '1');
                // }}
                >
                  <CustomInput
                    type="radio"
                    id="masterRadio1"
                    label="SI"
                    name="master"
                    value="1"
                    inline
                    checked={isMaster}
                    disabled
                  />
                  <CustomInput
                    type="radio"
                    id="masterRadio2"
                    label="NO"
                    name="master"
                    value="0"
                    inline
                    checked={!isMaster}
                    disabled
                  />
                </div>
              </FormGroup>
            </Col>
            <Col sm="12" md="6" lg="2">
              {AGENT_TYPES.includes(UserType) && (
                <FormGroup>
                  <Label>{t('IS_AN_AGENT')}</Label>
                  <div
                    onChange={({ target }) => {
                      setIsAgent(target.value === '1');
                    }}
                  >
                    <CustomInput
                      type="radio"
                      id="agentRadio1"
                      label="SI"
                      name="agent"
                      value="1"
                      inline
                      checked={isAgent}
                    />
                    <CustomInput
                      type="radio"
                      id="agentRadio2"
                      label="NO"
                      name="agent"
                      value="0"
                      inline
                      checked={!isAgent}
                    />
                  </div>
                </FormGroup>
              )}
            </Col>
            <Col sm="12" md="6" lg="3">
              <FormGroup>
                <Label>{t('CUSTOMER_TYPE')}</Label>
                <CustomInput
                  type="select"
                  id="exampleCustomSelect"
                  name="type"
                  innerRef={register({ required: t('COMMON:REQUIRED') })}
                  defaultValue={data.type}
                >
                  {ProfileTypes.map((type) => (
                    <option value={type} key={type}>
                      {t(type)}
                    </option>
                  ))}
                </CustomInput>
                <FormErrorMessage errors={errors} name="type" />
              </FormGroup>
            </Col>
            {UserType === 'GNET_ADMIN' && (
              <Col sm="12" md="6" lg="3">
                <FormGroup>
                  <Label>Ecobonus</Label>
                  <CustomInput
                    type="select"
                    id="exampleCustomSelect"
                    name="ecobonus"
                    innerRef={register}
                    defaultValue={data.ecobonus}
                  >
                    <option value="">-</option>
                    <option value="ECOBONUS">Ecobonus</option>
                    <option value="ECOBONUS_SERVICES">
                      Ecobonus e Servizi
                    </option>
                  </CustomInput>
                  <FormErrorMessage errors={errors} name="type" />
                </FormGroup>
              </Col>
            )}
          </Row>
          <br />
          <Row>
            <Col sm="12" md="6" lg="6">
              <FormGroup>
                <Label>{t('BUSINESS_NAME')}</Label>
                <Input
                  type="text"
                  name="businessName"
                  defaultValue={data.businessName}
                  innerRef={register({
                    required: t('COMMON:REQUIRED'),
                    maxLength: 500,
                  })}
                />
                <FormErrorMessage errors={errors} name="businessName" />
              </FormGroup>
            </Col>
            <Col sm="12" md="6" lg="6">
              <FormGroup>
                <Label>{t('NAME')}</Label>
                <Input
                  type="text"
                  name="name"
                  maxLength="50"
                  defaultValue={data.name}
                  innerRef={register({
                    required: t('COMMON:REQUIRED'),
                  })}
                />
                <FormErrorMessage errors={errors} name="name" />
              </FormGroup>
            </Col>
            <Col sm="12" md="6" lg="4">
              <FormGroup>
                <Label>{t('ADDRESS')}</Label>
                <Input
                  type="text"
                  name="address"
                  maxLength="100"
                  innerRef={register}
                  defaultValue={data.address}
                />
              </FormGroup>
            </Col>
            <Col sm="12" md="6" lg="2">
              <FormGroup>
                <Label>{t('CITY')}</Label>
                <Input
                  type="text"
                  name="city"
                  innerRef={register}
                  defaultValue={data.city}
                />
              </FormGroup>
            </Col>
            <Col sm="12" md="6" lg="2">
              <FormGroup>
                <Label>{t('REGION')}</Label>
                <CustomInput
                  id="regionSelect"
                  type="select"
                  name="region"
                  innerRef={register}
                  defaultValue={data.region}
                  onChange={() => setValue('district', '')}
                >
                  <option value="">-</option>
                  {regions.map((region, i) => (
                    <option key={i} value={region.nome}>
                      {region.nome}
                    </option>
                  ))}
                </CustomInput>
              </FormGroup>
            </Col>
            <Col sm="12" md="6" lg="2">
              <FormGroup>
                <Label>{t('PROVINCE')}</Label>
                <CustomInput
                  id="districtSelect"
                  type="select"
                  name="district"
                  innerRef={register}
                  defaultValue={data.district}
                >
                  <option value="">-</option>
                  {getDistricts().map((district, i) => (
                    <option key={i} value={district}>
                      {district}
                    </option>
                  ))}
                </CustomInput>
              </FormGroup>
            </Col>
            <Col sm="12" md="6" lg="2">
              <FormGroup>
                <Label>{t('CAP')}</Label>
                <Input
                  type="number"
                  name="cap"
                  innerRef={register}
                  defaultValue={data.cap}
                />
              </FormGroup>
            </Col>
            <Col sm="12" md="6" lg="4">
              <FormGroup>
                <Label>{t('VAT')}</Label>
                <Input
                  type="text"
                  name="taxCode"
                  maxLength="20"
                  innerRef={register}
                  defaultValue={data.taxCode}
                />
              </FormGroup>
            </Col>
            <Col sm="12" md="6" lg="4">
              <FormGroup>
                <Label>{t('FISCAL_CODE')}</Label>
                <Input
                  type="text"
                  name="fiscalCode"
                  maxLength="20"
                  innerRef={register}
                  defaultValue={data.fiscalCode}
                />
              </FormGroup>
            </Col>
            <Col sm="12" md="6" lg="4">
              <FormGroup>
                <Label>{t('CELL')}</Label>
                <Input
                  type="text"
                  name="cell"
                  maxLength="20"
                  innerRef={register}
                  defaultValue={data.cell}
                />
              </FormGroup>
            </Col>
            <Col sm="12" md="6" lg="4">
              <FormGroup>
                <Label>{t('PHONE')}</Label>
                <Input
                  type="text"
                  name="phone"
                  maxLength="20"
                  innerRef={register}
                  defaultValue={data.phone}
                />
              </FormGroup>
            </Col>
            <Col sm="12" md="6" lg="4">
              <FormGroup>
                <Label>{t('EMAIL')}</Label>
                <Input
                  type="email"
                  name="emailReference"
                  maxLength="320"
                  innerRef={register({
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: t('EMAIL_INVALID'),
                    },
                  })}
                  defaultValue={data.emailReference}
                />
                <FormErrorMessage errors={errors} name="emailReference" />
              </FormGroup>
            </Col>
            <Col sm="12" md="6" lg="4">
              <FormGroup>
                <Label>{t('EMAIL_COMUNICATION')}</Label>
                <Input
                  type="email"
                  name="emailCommunication"
                  maxLength="320"
                  innerRef={register({
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: t('EMAIL_INVALID'),
                    },
                  })}
                  defaultValue={data.emailCommunication}
                />
                <FormErrorMessage errors={errors} name="emailCommunication" />
              </FormGroup>
            </Col>
            <Col sm="12" md="6" lg="4">
              <FormGroup>
                <Label>{t('EMAIL_PEC')}</Label>
                <Input
                  type="email"
                  name="emailPec"
                  maxLength="320"
                  innerRef={register({
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: t('EMAIL_INVALID'),
                    },
                  })}
                  defaultValue={data.emailPec}
                />
                <FormErrorMessage errors={errors} name="emailPec" />
              </FormGroup>
            </Col>
            <Col sm="12" md="6" lg="4">
              <FormGroup>
                <Label>{t('SDI_CODE')}</Label>
                <Input
                  type="text"
                  name="sdiCode"
                  className="uppercase"
                  innerRef={register({
                    minLength: {
                      value: 6,
                      message: t('SDI_MIN_ERROR'),
                    },
                    maxLength: {
                      value: 7,
                      message: t('SDI_MAX_ERROR'),
                    },
                  })}
                  defaultValue={data.sdiCode}
                />
                <FormErrorMessage errors={errors} name="sdiCode" />
              </FormGroup>
            </Col>
            <Col sm="12" md="6" lg="4">
              <FormGroup>
                <Label>{t('ID_GABETTI')}</Label>
                <Input
                  type="text"
                  name="gabettiId"
                  maxLength="50"
                  innerRef={register}
                  defaultValue={data.gabettiId}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col sm="12" md="6" lg="4">
              <FormGroup>
                <Label>{t('ID_AFFILIATE')}</Label>
                <Input
                  type="text"
                  name="affiliationId"
                  maxLength="50"
                  innerRef={register}
                  defaultValue={data.affiliationId}
                />
              </FormGroup>
            </Col>
          </Row>
          {isAdmin && (
            <Row>
              <Col sm="12">
                <FormGroup>
                  <Label>{t('OPERATIVE_NOTES')}</Label>
                  <Input
                    rows={6}
                    type="textarea"
                    name="notes"
                    innerRef={register({
                      maxLength: {
                        value: 2000,
                        message: t('OPERATIVE_NOTES_MAX_ERROR'),
                      },
                    })}
                    defaultValue={data.notes}
                  />
                  <FormErrorMessage errors={errors} name="notes" />
                </FormGroup>
              </Col>
            </Row>
          )}
          <Row className="link-cont">
            <Col sm="12" md="12" lg="6">
              <H5Styled>{t('AREA_INTERVENTION')}</H5Styled>
              <InputLink>
                <FormGroup>
                  <Label>{t('LINK')}</Label>
                  <Input
                    type="url"
                    name="interventionArea"
                    innerRef={register}
                    defaultValue={data.interventionArea}
                  />
                </FormGroup>
                <BtnRadiusRed
                  type="button"
                  title="Apri"
                  onClick={() => openUrl(data.interventionArea)}
                >
                  <img src={OpenIcon} alt="Apri" />
                </BtnRadiusRed>
              </InputLink>
            </Col>
            <Col sm="12" md="12" lg="6">
              <H5Styled>{t('TYPE_INTERVENTION')}</H5Styled>
              <InputLink>
                <FormGroup>
                  <Label>{t('LINK')}</Label>
                  <Input
                    type="url"
                    name="interventionType"
                    innerRef={register}
                    defaultValue={data.interventionType}
                  />
                </FormGroup>
                <BtnRadiusRed
                  type="button"
                  title="Apri"
                  onClick={() => openUrl(data.interventionType)}
                >
                  <img src={OpenIcon} alt="Apri" />
                </BtnRadiusRed>
              </InputLink>
            </Col>
          </Row>

          {isUpdating && (
            <Row>
              <Col sm="12" md="6" lg="6">
                <FormGroup>
                  <Label for="selectFile">{t('CHOOSE_FILE')}</Label>
                  <CustomInput
                    type="file"
                    id="selectFile"
                    name="customFile"
                    onChange={handleFileChange}
                  />
                </FormGroup>
              </Col>
              <Col sm="12" md="6" lg="6">
                <FilesList files={data.files} handleDelete={handleDelete} />
              </Col>
            </Row>
          )}
        </TabPane>
      </TabContent>

      <br />

      <ContFlexRight>
        {isUpdating ? (
          <React.Fragment>
            <ModalConfirm onConfirm={deleteProfile} text={t('DELETE_REQUEST')}>
              <ButtonDelete type="button">{t('BTN_DELETE')}</ButtonDelete>
            </ModalConfirm>
            <ButtonSave
              type="button"
              onClick={(e) => {
                if (Object.keys(errors).length > 0) window.scrollTo({ top: 0 });
                handleSubmit(onSubmit)(e);
              }}
            >
              {t('BTN_SAVE')}
            </ButtonSave>
          </React.Fragment>
        ) : (
          <ButtonSave
            type="button"
            onClick={(e) => {
              if (Object.keys(errors).length > 0) window.scrollTo({ top: 0 });
              handleSubmit(onSubmit)(e);
            }}
          >
            {t('BTN_CREATE')}
          </ButtonSave>
        )}
      </ContFlexRight>
    </Form>
  );
};

export default ProfileInfoPresentational;
