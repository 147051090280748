import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Row,
  Col,
  FormGroup,
  Label,
  InputGroup,
  Input,
  InputGroupAddon,
  Collapse,
  Badge,
} from 'reactstrap';
import {
  ContAffiliation,
  H4Styled,
  H5Styled,
  ContTitleAction,
  ContFlexRight,
  SummaryAmountCont,
  HeaderCollapse,
  BtnOutlineRed,
} from '../../../styled-components';
import DateInput from '../../../commons/DateInput';
import ModalReceipt from './ModalReceipt';
import ReceiptsTable from './ReceiptsTable';
import moment from 'moment';
import ModalConfirm from '../../../commons/ModalConfirm';

const { REACT_APP_TAX_PERCENTAGE = '22' } = process.env;

const Affiliation = ({
  affiliation = {},
  updateAffiliation,
  deleteAffiliation,
  deletePayment,
}) => {
  const [t] = useTranslation('CONTACTS');
  const [isOpen, setIsOpen] = useState(false);

  const {
    startDate = '',
    endDate = '',
    totalGross = 0,
    totalNet = 0,
    totalTax = Number(REACT_APP_TAX_PERCENTAGE),
    masters = [],
    payments = [],
  } = affiliation;

  const toggle = () => setIsOpen(!isOpen);

  function calculateToReceive() {
    const receivedPaymentsTotal = payments.reduce(
      (acc, val) => acc + val.amountGross,
      0
    );

    return (totalGross - receivedPaymentsTotal).toFixed(2);
  }

  function calculatePercentage(masterIndex) {
    return masters[masterIndex]
      ? Number(
          (Number(totalNet) * masters[masterIndex].percentage) / 100
        ).toFixed(2)
      : 0;
  }

  const onAddPayment = async (payment) => {
    const amountNet = Number(
      ((Number(payment.amountGross) * 100) / (100 + totalTax)).toFixed(2)
    );
    const newPayments = [...payments, { ...payment, amountNet }];

    updateAffiliation({
      id: affiliation._id,
      data: { ...affiliation, payments: newPayments },
    });
  };

  const handleDeleteAffiliation = () => {
    deleteAffiliation(affiliation._id);
  };

  function handleDeletePayment(paymentIndex) {
    deletePayment(affiliation._id, paymentIndex);
  }

  const isAffiliationPaid =
    payments.reduce((acc, { amountGross = 0 } = {}) => acc + amountGross, 0) >=
    totalGross;

  const affiliationHasPayments =
    payments.reduce((acc, { amountGross = 0 } = {}) => acc + amountGross, 0) >
    0;

  return (
    <React.Fragment>
      <HeaderCollapse onClick={toggle}>
        <Row>
          <Col xs="8">
            <h6>
              {t('PAST_AFFILIATION_TITLE')}{' '}
              <i>
                da {moment(startDate).format('DD/MM/YYYY')} a{' '}
                {moment(endDate).format('DD/MM/YYYY')}
              </i>
            </h6>
          </Col>
          <Col xs="4" className="text-right">
            {isAffiliationPaid ? (
              <Badge className="pay">PAGATA</Badge>
            ) : affiliationHasPayments ? (
              <Badge className="partial-paid">PARZIALMENTE PAGATA</Badge>
            ) : (
              <Badge className="not-pay">NON PAGATA</Badge>
            )}

            <i className="fas fa-chevron-down"></i>
          </Col>
        </Row>
      </HeaderCollapse>
      <Collapse isOpen={isOpen}>
        <ContAffiliation>
          <Row>
            <Col sm="12" md="5">
              <H5Styled>{t('DATA_AFFILIATION_TITLE')}</H5Styled>
            </Col>
            <Col sm="12" md="7" className="text-right">
              <ModalConfirm
                onConfirm={handleDeleteAffiliation}
                text={t('CONFIRM_DELETE')}
              >
                <BtnOutlineRed type="button" className="uppercase">
                  {t('DELETE_AFFILIATION')}
                </BtnOutlineRed>
              </ModalConfirm>
            </Col>
          </Row>
          <br />
          <Row>
            <Col sm="12" md="6" lg="3">
              <FormGroup>
                <Label>{t('AFFILIATION_START')}</Label>
                <DateInput value={startDate} readOnly />
              </FormGroup>
            </Col>
            <Col sm="12" md="6" lg="3">
              <FormGroup>
                <Label>{t('AFFILIATION_END')}</Label>
                <DateInput value={endDate} readOnly />
              </FormGroup>
            </Col>
            <Col sm="12" md="6" lg="2">
              <FormGroup>
                <Label>{t('IMPORT_NET')}</Label>
                <InputGroup>
                  <Input
                    type="number"
                    name="totalNet"
                    value={totalNet}
                    readOnly
                  />
                  <InputGroupAddon addonType="prepend">€</InputGroupAddon>
                </InputGroup>
              </FormGroup>
            </Col>
            <Col sm="12" md="6" lg="2">
              <FormGroup>
                <Label>{t('TAX_PERCENTAGE')}</Label>
                <InputGroup>
                  <Input
                    type="number"
                    name="totalTax"
                    value={totalTax}
                    readOnly
                  />
                  <InputGroupAddon addonType="prepend">%</InputGroupAddon>
                </InputGroup>
              </FormGroup>
            </Col>
            <Col sm="12" md="6" lg="2">
              <FormGroup>
                <Label>{t('IMPORT_GROSS')}</Label>
                <InputGroup>
                  <Input
                    type="number"
                    name="totalGross"
                    value={totalGross}
                    readOnly
                  />
                  <InputGroupAddon addonType="prepend">€</InputGroupAddon>
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>

          <Row className="master-row">
            <Col sm="12" md="6" lg="4" className="master-col">
              <H4Styled>1</H4Styled>
              <FormGroup>
                <Label>{t('MASTER')}</Label>
                <Input
                  type="text"
                  name="masterOneName"
                  value={masters[0] ? masters[0].name : ''}
                  readOnly
                />
              </FormGroup>
            </Col>
            <Col sm="12" md="6" lg="4">
              <FormGroup>
                <Label>{t('MASTER_TYPE')}</Label>
                <Input
                  type="text"
                  name="masterOneType"
                  value={masters[0] ? t(masters[0].type) : ''}
                  readOnly
                />
              </FormGroup>
            </Col>
            <Col sm="12" md="6" lg="2">
              <FormGroup>
                <Label>{t('PERCENTAGE')}</Label>
                <InputGroup>
                  <Input
                    type="number"
                    name="masterOnePercentage"
                    value={masters[0] ? t(masters[0].percentage) : ''}
                    readOnly
                  />
                  <InputGroupAddon addonType="prepend">%</InputGroupAddon>
                </InputGroup>
              </FormGroup>
            </Col>
            <Col sm="12" md="6" lg="2">
              <FormGroup>
                <Label>{t('MONEY_CORRESPONDING')}</Label>
                <InputGroup>
                  <Input
                    type="number"
                    name="masterOneCommission"
                    value={calculatePercentage(0)}
                    readOnly
                  />
                  <InputGroupAddon addonType="prepend">€</InputGroupAddon>
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>

          <Row className="master-row">
            <Col sm="12" md="6" lg="4" className="master-col">
              <H4Styled>2</H4Styled>
              <FormGroup>
                <Label>{t('MASTER')}</Label>
                <Input
                  type="text"
                  name="masterTwoName"
                  value={masters[1] ? masters[1].name : ''}
                  readOnly
                />
              </FormGroup>
            </Col>
            <Col sm="12" md="6" lg="4">
              <FormGroup>
                <Label>{t('MASTER_TYPE')}</Label>
                <Input
                  type="text"
                  name="masterTwoType"
                  value={masters[1] ? t(masters[1].type) : ''}
                  readOnly
                />
              </FormGroup>
            </Col>
            <Col sm="12" md="6" lg="2">
              <FormGroup>
                <Label>{t('PERCENTAGE')}</Label>
                <InputGroup>
                  <Input
                    type="number"
                    name="masterTwoPercentage"
                    value={masters[1] ? masters[1].percentage : ''}
                    readOnly
                  />
                  <InputGroupAddon addonType="prepend">%</InputGroupAddon>
                </InputGroup>
              </FormGroup>
            </Col>
            <Col sm="12" md="6" lg="2">
              <FormGroup>
                <Label>{t('MONEY_CORRESPONDING')}</Label>
                <InputGroup>
                  <Input
                    type="number"
                    name="masterTwoCommission"
                    value={calculatePercentage(1)}
                    readOnly
                  />
                  <InputGroupAddon addonType="prepend">€</InputGroupAddon>
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>

          <ContTitleAction>
            <H5Styled>{t('RECEIPT_LIST_TITLE')}</H5Styled>
            <ModalReceipt onAddPayment={onAddPayment} />
          </ContTitleAction>
          <ReceiptsTable
            data={payments}
            handleDeletePayment={handleDeletePayment}
          />
          <ContFlexRight>
            <SummaryAmountCont>
              <Row>
                <Col xs="6">
                  <p>{t('TOTAL_TO_COLLECT')}</p>
                </Col>
                <Col xs="6" className="text-right">
                  <p>{(Number(totalGross) || 0).toFixed(2)} €</p>
                </Col>
              </Row>
              <hr />
              <Row>
                <Col xs="6">
                  <p>{t('TOTAL_TO_RECEIVED')}</p>
                </Col>
                <Col xs="6" className="text-right">
                  <p>{calculateToReceive()} €</p>
                </Col>
              </Row>
            </SummaryAmountCont>
          </ContFlexRight>
        </ContAffiliation>
      </Collapse>
    </React.Fragment>
  );
};

export default Affiliation;
