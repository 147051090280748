import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  FormGroup,
  Label,
  InputGroup,
  Input,
  InputGroupAddon,
  Form,
  FormText,
  CustomInput,
} from 'reactstrap';
import {
  BtnOutlineRed,
  BtnOutlineGreen,
  BtnModalTable,
} from '../../../styled-components';
import '../../MyInvoices/Invoices.scss';
import DateInput from '../../../commons/DateInput';
import AutoSuggest from '../../../commons/Autosuggest';

const ModalEditInvoice = ({
  searchAdministrators,
  onConfirm,
  data: invoice = {},
}) => {
  const [t] = useTranslation('ROYALTY');
  const [modal, setModal] = useState(false);
  const getInitialFormState = () => ({
    customer: invoice.customer,
    admin: invoice.admin,
    number: invoice.number,
    date: invoice.date,
    supposedPaymentDate: invoice.supposedPaymentDate,
    gross: invoice.gross,
    net: invoice.net,
    vatCode: invoice.vatCode,
    accountability: invoice.accountability,
    validated: !!invoice.validated,
  });
  const [formValues, setFormValues] = useState(getInitialFormState());
  const [formErrors, setFormErrors] = useState({
    customer: false,
    admin: false,
    number: false,
    date: false,
    supposedPaymentDate: false,
    gross: false,
    net: false,
    vatCode: false,
    accountability: false,
  });

  useEffect(() => {
    if (moment(formValues.date).isValid()) {
      setFormValues((state) => ({
        ...state,
        supposedPaymentDate: moment(formValues.date).add(60, 'days').toDate(),
      }));
    }
  }, [formValues.date]);

  const toggle = () => {
    setModal(!modal);
    setFormValues(getInitialFormState());
  };

  function onInputChange({ target }) {
    setFormValues((state) => ({ ...state, [target.name]: target.value }));
  }

  function onNumericInputChange({ target }) {
    if (/^[\d]*\.?[\d]{0,2}$/.test(target.value)) {
      setFormValues((state) => ({
        ...state,
        [target.name]: target.value,
      }));
    }
  }

  const renderSuggestion = ({ businessName = '' }) => businessName;
  const getSuggestionValue = ({ businessName = '' }) => businessName;
  const onSuggestionSelect = ({ _id, businessName }) => {
    setFormValues((state) => ({
      ...state,
      admin: { _id, businessName },
    }));
  };

  const onEditInvoiceConfirm = () => {
    const {
      customer,
      admin,
      number,
      date,
      supposedPaymentDate,
      gross,
      net,
    } = formValues;

    const errors = {};

    if (!customer) errors.customer = true;
    if (!admin._id) errors.admin = true;
    if (!number) errors.number = true;
    if (!date) errors.date = true;
    if (!supposedPaymentDate) errors.supposedPaymentDate = true;
    if (!gross) errors.gross = true;
    if (!net) errors.net = true;

    if (Object.keys(errors).length > 0) {
      return setFormErrors(errors);
    }

    onConfirm({
      ...formValues,
      _id: invoice._id,
      admin: formValues.admin._id,
      net: parseFloat(formValues.net),
      gross: parseFloat(formValues.gross),
    });

    toggle();
  };

  function listenForEscapeEvent() {
    document.addEventListener('keydown', ({ key }) => {
      if (key === 'Escape') toggle();
    });
  }

  const {
    customer,
    admin,
    number,
    date,
    supposedPaymentDate,
    gross,
    net,
    vatCode,
    accountability,
    validated,
  } = formValues;

  return (
    <React.Fragment>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div style={{ marginLeft: '10px' }}>
          <BtnModalTable type="button" onClick={toggle}>
            MODIFICA
          </BtnModalTable>
        </div>
      </div>

      <Modal
        isOpen={modal}
        className="new-invoice-modal"
        onOpened={listenForEscapeEvent}
      >
        <ModalHeader toggle={toggle}>{t('EDIT_INVOICE')}</ModalHeader>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            onEditInvoiceConfirm(formValues);
          }}
        >
          <ModalBody>
            <Row>
              <Col sm="12">
                <p>{t('ADD_MANUAL')}</p>
                <Row>
                  <Col xs="12" md="6" lg="4">
                    <FormGroup>
                      <Label>{t('CUSTOMER_REFERENCE')} *</Label>
                      <Input
                        type="text"
                        name="customer"
                        value={customer}
                        onChange={onInputChange}
                      />
                      {formErrors.customer && (
                        <FormText className="error-message">
                          {t('COMMON:REQUIRED')}
                        </FormText>
                      )}
                    </FormGroup>
                  </Col>
                  <Col xs="12" md="6" lg="4">
                    <FormGroup>
                      <Label>{t('AMMINISTRATOR')} *</Label>
                      <AutoSuggest
                        placeholder="Cerca un amministratore..."
                        onFetch={searchAdministrators}
                        onSelect={onSuggestionSelect}
                        renderSuggestion={renderSuggestion}
                        getSuggestionValue={getSuggestionValue}
                        value={admin.businessName}
                        onChange={(businessName) =>
                          setFormValues((state) => ({
                            ...state,
                            admin: { ...admin, businessName },
                          }))
                        }
                      />
                      {formErrors.admin && (
                        <FormText className="error-message">
                          {t('COMMON:REQUIRED')}
                        </FormText>
                      )}
                    </FormGroup>
                  </Col>
                  <Col xs="12" md="6" lg="4">
                    <FormGroup>
                      <Label>{t('INVOICE_NUMBER')} *</Label>
                      <Input
                        type="text"
                        name="number"
                        value={number}
                        onChange={onInputChange}
                      />
                      {formErrors.number && (
                        <FormText className="error-message">
                          {t('COMMON:REQUIRED')}
                        </FormText>
                      )}
                    </FormGroup>
                  </Col>
                  <Col xs="12" md="6" lg="4">
                    <FormGroup>
                      <Label>{t('CONTACTS:VAT_FISCAL')}</Label>
                      <Input
                        type="text"
                        name="vatCode"
                        value={vatCode}
                        onChange={onInputChange}
                      />
                      {formErrors.vatCode && (
                        <FormText className="error-message">
                          {t('COMMON:REQUIRED')}
                        </FormText>
                      )}
                    </FormGroup>
                  </Col>
                  <Col xs="12" md="6" lg="4">
                    <FormGroup>
                      <Label>{t('INVOICE_DATE')} *</Label>
                      <DateInput
                        name="date"
                        value={date}
                        onChange={(date) =>
                          setFormValues((state) => ({ ...state, date }))
                        }
                      />
                      {formErrors.date && (
                        <FormText className="error-message">
                          {t('COMMON:REQUIRED')}
                        </FormText>
                      )}
                    </FormGroup>
                  </Col>
                  <Col xs="12" md="6" lg="4">
                    <FormGroup>
                      <Label>{t('EXPIRY_DATE')} *</Label>
                      <DateInput
                        name="supposedPaymentDate"
                        value={supposedPaymentDate}
                        onChange={(supposedPaymentDate) =>
                          setFormValues((state) => ({
                            ...state,
                            supposedPaymentDate,
                          }))
                        }
                      />
                      {formErrors.supposedPaymentDate && (
                        <FormText className="error-message">
                          {t('COMMON:REQUIRED')}
                        </FormText>
                      )}
                    </FormGroup>
                  </Col>
                  <Col xs="12" md="6" lg="4">
                    <FormGroup>
                      <Label>{t('CONTACTS:IMPORT_NET')} *</Label>
                      <InputGroup>
                        <Input
                          type="number"
                          step="0.01"
                          placeholder="0.00"
                          name="net"
                          value={net}
                          onChange={onNumericInputChange}
                        />
                        <InputGroupAddon addonType="prepend">€</InputGroupAddon>
                      </InputGroup>
                      {formErrors.net && (
                        <FormText className="error-message">
                          {t('COMMON:REQUIRED')}
                        </FormText>
                      )}
                    </FormGroup>
                  </Col>
                  <Col xs="12" md="6" lg="4">
                    <FormGroup>
                      <Label>{t('CONTACTS:IMPORT_TO_RECEIVE')} *</Label>
                      <InputGroup>
                        <Input
                          type="number"
                          step="0.01"
                          placeholder="0.00"
                          name="gross"
                          value={gross}
                          onChange={onNumericInputChange}
                        />
                        <InputGroupAddon addonType="prepend">€</InputGroupAddon>
                      </InputGroup>
                      {formErrors.gross && (
                        <FormText className="error-message">
                          {t('COMMON:REQUIRED')}
                        </FormText>
                      )}
                    </FormGroup>
                  </Col>

                  <Col xs="12" md="6" lg="4">
                    <FormGroup>
                      <Label>{t('ACC_DATE')}</Label>
                      <DateInput
                        name="accountability"
                        value={accountability}
                        showOnlyMonth
                        setMidday
                        onChange={(accountability) => {
                          setFormValues((state) => ({
                            ...state,
                            accountability,
                          }));
                        }}
                      />
                      {formErrors.accountability && (
                        <FormText className="error-message">
                          {t('COMMON:REQUIRED')}
                        </FormText>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col sm="12" md="6" lg="3">
                <FormGroup>
                  <Label style={{ paddingRight: '20px' }}>
                    {t('INVOICES:VALIDATED')}
                  </Label>
                  <CustomInput
                    type="radio"
                    id="validatedRadio"
                    label="SI"
                    name="master"
                    value="1"
                    inline
                    checked={validated}
                    onChange={({ target: { value } }) => {
                      setFormValues((state) => ({
                        ...state,
                        validated: value === '1',
                      }));
                    }}
                  />
                  <CustomInput
                    type="radio"
                    id="notValidatedRadio"
                    label="NO"
                    name="master"
                    value="0"
                    inline
                    checked={!validated}
                    onChange={({ target: { value } }) => {
                      setFormValues((state) => ({
                        ...state,
                        validated: value === '1',
                      }));
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <BtnOutlineRed type="button" className="uppercase" onClick={toggle}>
              {t('COMMON:CANCEL')}
            </BtnOutlineRed>
            <BtnOutlineGreen type="submit" className="uppercase">
              {t('COMMON:CONFIRM')}
            </BtnOutlineGreen>
          </ModalFooter>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default ModalEditInvoice;
