import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { Link, Route, Switch, Redirect } from 'react-router-dom';
import { Nav, NavItem, NavLink, Row, Col, Badge } from 'reactstrap';
import classnames from 'classnames';
import BurgerMenu from '../../commons/BurgerMenu';
import Header from '../../commons/Header';
import Footer from '../../commons/Footer';
import { ReactComponent as IconRegistries } from '../../images/icn_anagrafiche.svg';
import {
  H4Styled,
  BtnRadiusWhite,
  ContFlexLeft,
  ContFlexRight,
} from '../../styled-components';
import ProfileInfo from './ProfileInfo';
import Affiliations from './Affiliations';
import { ReactComponent as IconBack } from '../../images/icn-back.svg';
import './ProfileDetail.scss';
import './TabsProfile.scss';
import { get } from '../../lib/fetch/profiles';
import FinancialReports from './FinancialReports';
import { useAlert } from 'react-alert';
import Energy from './Energy';
import { useLoading } from '../../commons/Loading';
import User from './User';
import Royalties from './Royalties';
import { useAuth } from '../../commons/Auth';
import Insurances from './Insurances';
import { ROYALTIES_ALLOWED_TYPES } from '../../App';

function ProfileDetail(props) {
  const [{ token } = {}] = useAuth();
  const [t] = useTranslation('CONTACTS');
  const [profile, setProfile] = useState({});
  const [pageBack, setPageBack] = useState('');
  const alert = useAlert();
  const { showLoading, hideLoading } = useLoading();
  const { history, location, match = {} } = props;
  const { params: { id } = {}, url = '' } = match;
  const { pathname = '', state = {} } = location;
  const tab = pathname.split('/').pop();

  useEffect(() => {
    if (state.pageBack) setPageBack(state.pageBack);
  }, [state]);

  useEffect(() => {
    if (id !== 'create') {
      fetchProfile(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const fetchProfile = async (id) => {
    showLoading();

    const { error, data } = await get({ id, token });

    if (error) {
      hideLoading();

      alert.error(t('COMMON:GENERIC_ERROR'));
      return;
    }

    setProfile(data);
    hideLoading();
  };

  if (pathname === url) {
    return <Redirect to={`${url}/info`} />;
  }

  const isUpdating = id !== 'create';

  const { activeAffiliation = false, type = '', businessName = '' } = profile;

  return (
    <div className="App">
      <BurgerMenu />

      <div className="corpo">
        <section>
          <Header
            title={t('PAGES:REGISTRIES')}
            icon={<IconRegistries fill="#ffffff" />}
          />
        </section>
        <section>
          <Row>
            <Col sm="12" md="6">
              <ContFlexLeft>
                <BtnRadiusWhite onClick={() => history.replace(`/${pageBack}`)}>
                  <IconBack />
                </BtnRadiusWhite>
                <H4Styled>{t('TITLE_DETAIL')}</H4Styled>
              </ContFlexLeft>
            </Col>
            {isUpdating && (
              <Col sm="12" md="6">
                <ContFlexRight>
                  <H4Styled className="registry-name">
                    <span>
                      {businessName} - <small>{t(type)}</small>
                    </span>
                  </H4Styled>
                  <Badge
                    className={`affiliation${
                      activeAffiliation ? ' active' : ' not-active'
                    }`}
                  >
                    {t('AFFILIATION')}
                    <br />
                    {t(`${activeAffiliation ? 'ACTIVE' : 'INACTIVE'}`)}
                  </Badge>
                </ContFlexRight>
              </Col>
            )}
          </Row>

          <div className="m-tb-20">
            <Nav tabs>
              <NavItem>
                <NavLink
                  tag={Link}
                  to={`${match.url}/info`}
                  className={classnames({ active: tab === 'info' })}
                >
                  {t('CUSTOMER_DATA')}
                </NavLink>
              </NavItem>
              {isUpdating && (
                <>
                  <NavItem>
                    <NavLink
                      tag={Link}
                      to={`${match.url}/affiliations`}
                      className={classnames({
                        active: tab === 'affiliations',
                      })}
                    >
                      {t('AFFILIATIONS')}
                    </NavLink>
                  </NavItem>
                  {ROYALTIES_ALLOWED_TYPES.includes(profile.type) && (
                    <NavItem>
                      <NavLink
                        tag={Link}
                        to={`${match.url}/financial-reports`}
                        className={classnames({
                          active:
                            tab === 'financial-reports' ||
                            tab === 'providers' ||
                            tab === 'gnet-admins' ||
                            tab === 'variable-revenues' ||
                            tab === 'variable-revenues-insurances' ||
                            tab === 'insurance-revenues-studio',
                        })}
                      >
                        {t('PAGES:FINANCIAL_REPORTS')}
                      </NavLink>
                    </NavItem>
                  )}
                  {profile.type !== 'SUPPLIER_INS' && (
                    <NavItem>
                      <NavLink
                        tag={Link}
                        to={`${match.url}/invoices`}
                        className={classnames({
                          active: tab === 'invoices',
                        })}
                      >
                        {t('INVOICES:TITLE')}
                      </NavLink>
                    </NavItem>
                  )}
                  {profile.type !== 'SUPPLIER_ALBO' &&
                    profile.type !== 'SUPPLIER_TEC' && (
                      <NavItem>
                        <NavLink
                          tag={Link}
                          to={`${match.url}/insurances`}
                          className={classnames({
                            active: tab === 'insurances',
                          })}
                        >
                          {t('INSURANCES:TITLE')}
                        </NavLink>
                      </NavItem>
                    )}

                  <NavItem>
                    <NavLink
                      to={`${match.url}/user`}
                      tag={Link}
                      className={classnames({
                        active: tab === 'user',
                      })}
                    >
                      {t('USERS:TITLE')}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      tag={Link}
                      to={`${match.url}/energy`}
                      className={classnames({
                        active: tab === 'energy',
                      })}
                    >
                      {t('ENERGY:TITLE')}
                    </NavLink>
                  </NavItem>
                </>
              )}
            </Nav>

            <Switch>
              <Route
                path={`${match.url}/info`}
                component={(props) => (
                  <ProfileInfo
                    id={id}
                    profile={profile}
                    setProfile={setProfile}
                    {...props}
                  />
                )}
              />
              <Route
                path={`${match.url}/affiliations`}
                component={(props) => (
                  <Affiliations id={id} profile={profile} {...props} />
                )}
              />
              <Route
                path={`${match.url}/financial-reports`}
                component={(props) => (
                  <FinancialReports profile={profile} {...props} />
                )}
              />
              <Route
                path={`${match.url}/invoices`}
                component={(props) => (
                  <Royalties id={id} profile={profile} {...props} />
                )}
              />
              <Route
                path={`${match.url}/insurances`}
                component={(props) => (
                  <Insurances id={id} profile={profile} {...props} />
                )}
              />
              <Route
                path={`${match.url}/user`}
                component={(props) => (
                  <User
                    id={id}
                    profile={profile}
                    setProfile={setProfile}
                    {...props}
                  />
                )}
              />
              <Route
                path={`${match.url}/energy`}
                component={(props) => (
                  <Energy id={id} profile={profile} {...props} />
                )}
              />
            </Switch>
          </div>
        </section>
        <Footer />
      </div>
    </div>
  );
}

export default withRouter(ProfileDetail);
