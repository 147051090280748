import React from 'react';
import { TabContent, TabPane, Form } from 'reactstrap';
import { H5Styled } from '../../../styled-components';

const EnergyPresentational = ({}) => {
  return (
    <Form>
      <TabContent activeTab={'1'}>
        <TabPane tabId="1">
          <H5Styled>Presto disponibile</H5Styled>
        </TabPane>
      </TabContent>
    </Form>
  );
};

export default EnergyPresentational;
