import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAlert } from 'react-alert';
import {
  useQueryParams,
  NumberParam,
  StringParam,
  withDefault,
} from 'use-query-params';
import { list } from '../../lib/fetch/profiles';
import ProfilesPresentational from './ProfilesPresentational';
import { useLoading } from '../../commons/Loading';
import { useAuth } from '../../commons/Auth';
const { REACT_APP_DEFAULT_PAGINATION_LIMIT = 50 } = process.env;

function Profiles(props) {
  const [{ token } = {}] = useAuth();
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [t] = useTranslation('CONTACTS');
  const alert = useAlert();
  const { showLoading, hideLoading } = useLoading();

  const [query, setQuery] = useQueryParams({
    type: withDefault(StringParam, localStorage.getItem('profileType')),
    profileStatus: withDefault(
      StringParam,
      localStorage.getItem('profileStatus')
    ),
    search: withDefault(StringParam, localStorage.getItem('profileSearch')),
    offset: NumberParam,
    limit: withDefault(NumberParam, +REACT_APP_DEFAULT_PAGINATION_LIMIT),
  });

  const { search, offset, limit, type, profileStatus } = query;

  useEffect(() => {
    const fetchProfiles = async () => {
      showLoading();

      const { error, data, headers } = await list({
        token,
        search,
        offset,
        limit,
        type,
        profileStatus,
      });

      if (error) {
        hideLoading();
        setData([]);
        setTotal(0);
        alert.error(t('COMMON:GENERIC_ERROR'));
        return;
      }

      setData(data);
      setTotal(Number(headers['x-total-count']));
      hideLoading();
    };

    fetchProfiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, offset, limit, type, profileStatus]);

  function onFilter(query) {
    if (query && typeof query.search === 'string') {
      localStorage.setItem('profileSearch', query.search);
    }
    if (query && typeof query.type === 'string') {
      localStorage.setItem('profileType', query.type);
    }
    if (query && typeof query.profileStatus === 'string') {
      localStorage.setItem('profileStatus', query.profileStatus);
    }
    if (
      (query.search && query.search !== search) || // first search
      (search && query.search === '') || // search deleted
      (query.type && query.type !== type) ||
      (query.profileStatus && query.type !== profileStatus) ||
      (query.limit && query.limit !== limit)
    ) {
      query.offset = 0; // return to first page
    }
    setQuery(query);
  }

  return (
    <ProfilesPresentational
      data={data}
      total={total}
      query={query}
      onFilter={onFilter}
      {...props}
    />
  );
}

export default Profiles;
