import React from 'react';

import { useAuth } from '../../commons/Auth';

import { ReactComponent as IconLogout } from '../../images/icn_logout.svg';
import './Header.scss';

function Header({ title, icon }) {
  const [, dispatch] = useAuth();

  const logout = () => dispatch({ type: 'logout' });

  return (
    <div className="header-crm">
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-8">
          <h2>
            {icon}
            {title}
          </h2>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-4">
          <div className="cont-header-element">
            {/* <Lang /> */}
            <button onClick={() => logout()} className="btn btn-logout">
              <IconLogout />
            </button>
            {/* {first_name && last_name && <p>{t('COMMON:HELLO')}, <span>{first_name} {last_name}</span></p>} */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
