import React, { useState, useEffect } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAlert } from 'react-alert';
import { useDebouncedCallback } from 'use-debounce';
import FinancialReportsPresentational from './FinancialReportsPresentational';
import ProvidersDetail from '../ProvidersDetail';
import GnetAdminDetail from '../GnetAdminDetail';
import VariableRevenuesDetail from '../VariableRevenuesDetail';
import VarRevInsDetail from '../VarRevInsDetail';
import VarRevInsStudioDetail from '../VarRevInsStudioDetail';
import {
  get,
  createBonus,
  deleteBonus,
  updateBonus,
} from '../../../lib/fetch/reports';
import { useAuth } from '../../../commons/Auth';
import { useLoading } from '../../../commons/Loading';
import {
  useQueryParams,
  withDefault,
  StringParam,
  NumberParam,
} from 'use-query-params';
import { LAST_MASTER_YEAR } from '../../../lib/constants';

const FinancialReports = (props) => {
  const { profile = {}, match = {} } = props;
  const { _id: profileId, master, type: profileType = '' } = profile;
  const [{ token } = {}] = useAuth();
  const [reports, setReports] = useState({
    categories: { gnet_admin: {}, supplier: {}, bonuses: { data: [] } },
    totals: {},
  });
  const alert = useAlert();
  const [t] = useTranslation('CONTACTS');
  const { showLoading, hideLoading } = useLoading();

  const [query, setQuery] = useQueryParams({
    year: withDefault(NumberParam, new Date().getFullYear()),
    accountabilityYear: NumberParam,
    accountabilityMonth: NumberParam,
    supplier: withDefault(StringParam, ''),
    admin: withDefault(StringParam, ''),
    number: withDefault(StringParam, ''),
    insuranceNumber: withDefault(StringParam, ''),
    insuranceAdmin: withDefault(StringParam, ''),
    insuranceSupplier: withDefault(StringParam, ''),
    studioInsuranceNumber: withDefault(StringParam, ''),
    insuranceAccountabilityYear: NumberParam,
    insuranceAccountabilityMonth: NumberParam,
    studioInsuranceAdmin: withDefault(StringParam, ''),
    studioInsuranceSupplier: withDefault(StringParam, ''),
    studioInsuranceAccountabilityYear: NumberParam,
    studioInsuranceAccountabilityMonth: NumberParam,
  });

  const {
    search,
    supplier,
    admin,
    number,
    year,
    accountabilityYear,
    accountabilityMonth,
    insuranceNumber,
    insuranceAdmin,
    insuranceSupplier,
    insuranceAccountabilityYear,
    insuranceAccountabilityMonth,
    studioInsuranceAdmin,
    studioInsuranceNumber,
    studioInsuranceSupplier,
    studioInsuranceAccountabilityYear,
    studioInsuranceAccountabilityMonth,
  } = query;

  const isProfileMaster = master && !(year > LAST_MASTER_YEAR);

  useEffect(() => {
    fetchReports();
  }, [
    year,
    accountabilityYear,
    accountabilityMonth,
    search,
    supplier,
    admin,
    number,
    insuranceNumber,
    insuranceAdmin,
    insuranceSupplier,
    studioInsuranceAdmin,
    studioInsuranceNumber,
    studioInsuranceSupplier,
    insuranceAccountabilityYear,
    insuranceAccountabilityMonth,
    studioInsuranceAccountabilityYear,
    studioInsuranceAccountabilityMonth,
  ]);

  const [fetchReports] = useDebouncedCallback(async () => {
    showLoading();
    const { error, data } = await get({
      profileId,
      year,
      accountabilityYear,
      accountabilityMonth,
      token,
      supplier,
      admin,
      number,
      insuranceNumber,
      insuranceAdmin,
      insuranceSupplier,
      insuranceAccountabilityYear,
      insuranceAccountabilityMonth,
      studioInsuranceAdmin,
      studioInsuranceNumber,
      studioInsuranceSupplier,
      studioInsuranceAccountabilityYear,
      studioInsuranceAccountabilityMonth,
    });

    if (error) {
      hideLoading();
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    setReports(data);
    hideLoading();
  }, 1000);

  function onFilter(queryDiff) {
    if (
      (queryDiff.accountabilityYear &&
        queryDiff.accountabilityYear !== accountabilityYear) ||
      queryDiff.accountabilityYear === ''
    ) {
      queryDiff.accountabilityMonth = '';
    }
    if (
      (queryDiff.insuranceAccountabilityYear &&
        queryDiff.insuranceAccountabilityYear !==
          insuranceAccountabilityYear) ||
      queryDiff.insuranceAccountabilityYear === ''
    ) {
      queryDiff.insuranceAccountabilityMonth = '';
    }
    if (
      (queryDiff.studioInsuranceAccountabilityYear &&
        queryDiff.studioInsuranceAccountabilityYear !==
          studioInsuranceAccountabilityYear) ||
      queryDiff.studioInsuranceAccountabilityYear === ''
    ) {
      queryDiff.studioInsuranceAccountabilityMonth = '';
    }
    setQuery(queryDiff);
  }

  async function onAddBonus(bonus) {
    const { error } = await createBonus({
      token,
      bonus: { ...bonus, profileId },
    });

    if (error) {
      alert.error(t('COMMON:GENERIC_ERROR'));
      return;
    }

    fetchReports();
    alert.success(t('COMMON:SUCCESS_CREATE'));
  }

  async function onBonusDelete(_id) {
    const { error } = await deleteBonus({ token, _id });

    if (error) {
      alert.error(t('COMMON:GENERIC_ERROR'));
      return;
    }

    fetchReports();
    alert.success(t('COMMON:SUCCESS_DELETE'));
  }

  async function onBonusUpdate(data) {
    const { error } = await updateBonus({ token, data });

    if (error) {
      alert.error(t('COMMON:GENERIC_ERROR'));
      return;
    }

    fetchReports();
    alert.success(t('COMMON:SUCCESS_UPDATE'));
  }

  return (
    <Switch>
      <Route
        exact
        path={match.url}
        component={(props) => (
          <FinancialReportsPresentational
            data={reports}
            onAddBonus={onAddBonus}
            onBonusDelete={onBonusDelete}
            onBonusUpdate={onBonusUpdate}
            onFilter={onFilter}
            query={query}
            profileType={profileType}
            isProfileMaster={isProfileMaster}
            {...props}
          />
        )}
      />
      <Route
        path={`${match.url}/suppliers`}
        component={(props) => (
          <ProvidersDetail
            id={profileId}
            profile={profile}
            reports={reports}
            onFilter={onFilter}
            query={query}
            onAddBonus={onAddBonus}
            {...props}
          />
        )}
      />
      <Route
        path={`${match.url}/gnet-admins`}
        component={(props) => (
          <GnetAdminDetail
            id={profileId}
            profile={profile}
            reports={reports}
            onFilter={onFilter}
            query={query}
            onAddBonus={onAddBonus}
            {...props}
          />
        )}
      />
      <Route
        path={`${match.url}/variable-revenues`}
        component={(props) => (
          <VariableRevenuesDetail
            id={profileId}
            profile={profile}
            reports={reports}
            onFilter={onFilter}
            query={query}
            {...props}
            onAddBonus={onAddBonus}
          />
        )}
      />
      <Route
        path={`${match.url}/variable-revenues-insurances`}
        component={(props) => (
          <VarRevInsDetail
            id={profileId}
            profile={profile}
            reports={reports}
            onFilter={onFilter}
            query={query}
            onAddBonus={onAddBonus}
            {...props}
          />
        )}
      />
      <Route
        path={`${match.url}/insurance-revenues-studio`}
        component={(props) => (
          <VarRevInsStudioDetail
            id={profileId}
            profile={profile}
            reports={reports}
            onFilter={onFilter}
            query={query}
            onAddBonus={onAddBonus}
            {...props}
          />
        )}
      />
    </Switch>
  );
};

export default withRouter(FinancialReports);
