import React, { useState } from 'react';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import { Modal, ModalHeader, ModalFooter, Form } from 'reactstrap';
import { BtnOutlineRed, BtnOutlineGreen } from '../../styled-components';

function ModalRegularizeAll({ onConfirm, areSeletedInvoicesValid }) {
  const [t] = useTranslation();
  const alert = useAlert();
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  function onRegularConfirm() {
    onConfirm();
    toggle();
  }

  function validate() {
    if (!areSeletedInvoicesValid()) {
      alert.error(t('ROYALTY:ACCOUNTABILITY_ERROR'));
      return;
    }
    toggle();
  }

  return (
    <React.Fragment>
      <BtnOutlineGreen type="button" onClick={validate}>
        {t('ROYALTY:REGULARIZE_ALL_TITLE')}
      </BtnOutlineGreen>

      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>{t('ROYALTY:REGULARIZE_ALL')}</ModalHeader>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            onRegularConfirm();
          }}
        >
          <ModalFooter>
            <BtnOutlineRed type="button" className="uppercase" onClick={toggle}>
              {t('COMMON:CANCEL')}
            </BtnOutlineRed>
            <BtnOutlineGreen type="submit" className="uppercase">
              {t('COMMON:CONFIRM')}
            </BtnOutlineGreen>
          </ModalFooter>
        </Form>
      </Modal>
    </React.Fragment>
  );
}

export default ModalRegularizeAll;
