import React from 'react';
import { useTranslation } from 'react-i18next';
import generatePaginationOptions from '../../lib/helpers/generatePaginationOptions';
import BurgerMenu from '../../commons/BurgerMenu';
import Header from '../../commons/Header';
import { ReactComponent as IconInvoices } from '../../images/icn_fatture.svg';
import { DataTable } from '../../commons/DataTable';
import Field from '../../commons/DataTable/Field';
import DateTimeField from '../../commons/DataTable/DateTimeField';
import CurrencyField from '../../commons/DataTable/CurrencyField';
import { ContPaginationPage, ContFlexRight } from '../../styled-components';
import { FormGroup, CustomInput, Row, Col, Label } from 'reactstrap';
import SearchInput from '../../commons/SearchInput';
import { ContFilterYear } from '../../styled-components';
import generateYearOptions from '../../lib/helpers/generateYearOptions';
import { useParams } from 'react-router-dom';
import { BtnOutlineOrange } from '../../styled-components';
import CSVClientsExport from './CSVClientsExport';
import IafInvoicesExport from './IafInvoicesCSVExport';
import IafModifyModal from './IafModifyModal';

function IafsPresentational({ data, query, total, onFilter, onUpdateIaf }) {
  const { t } = useTranslation();
  const { clientType } = useParams();
  const { offset, limit } = query;

  return (
    <div className="App">
      <BurgerMenu />

      <div className="corpo">
        <section>
          <Header
            title={t('PAGES:IAF10')}
            icon={<IconInvoices fill="#ffffff" style={{ height: '25px' }} />}
          />

          <Row className="search-add-cont m-t-20">
            <Col sm="6" md="6" lg="3" xl="3">
              <SearchInput
                query={{ search: query && query.admin }}
                onFilter={({ search }) => onFilter({ admin: search })}
                label={t('SEARCH:ADMINISTRATOR')}
              />
            </Col>
            <Col sm="6" md="6" lg="3" xl="3">
              <SearchInput
                query={{ search: query && query.description }}
                onFilter={({ search }) => onFilter({ description: search })}
                label={t('SEARCH:DESCRIPTION')}
              />
            </Col>

            <Col sm="6" md="6" lg="3" xl="3">
              <ContFilterYear className="no-width">
                <Row>
                  <Col xs="6">
                    <Label>{t('CONTACTS:TITLE_FILTER_YEAR')}</Label>
                  </Col>
                  <Col xs="6">
                    <FormGroup>
                      <CustomInput
                        type="select"
                        id="selectYear"
                        name="year"
                        className="year-select"
                        value={query && query.year}
                        onChange={({ target: { value: year } }) =>
                          onFilter({ year })
                        }
                      >
                        {generateYearOptions()}
                      </CustomInput>
                    </FormGroup>
                  </Col>
                </Row>
              </ContFilterYear>
            </Col>

            <Col sm="6" md="6" lg="3" xl="3">
              <ContFlexRight>
                <CSVClientsExport
                  data={data}
                  clientType={clientType}
                  year={query.year}
                >
                  <BtnOutlineOrange type="button" className="uppercase">
                    {t('COMMON:CSV_LIST_EXPORT')}
                  </BtnOutlineOrange>
                </CSVClientsExport>
              </ContFlexRight>
            </Col>
          </Row>
        </section>

        <section className="m-t-20">
          <DataTable
            data={data}
            total={total}
            offset={offset}
            limit={limit}
            onFilter={onFilter}
            emptyText={t('COMMON:NO_DATA')}
          >
            <Field
              title="Amministratore"
              source="adminName"
              className="text-left"
            />
            <Field
              title="Descrizione"
              source="description"
              className="text-left"
            />
            <Field title="Anno di riferimento" source="year" />
            <DateTimeField title="Data" source="date" format="DD/MM/YYYY" />
            <Field title="Percentuale %" source="percentage" />
            <CurrencyField title="Importo" source="amount" />
            <IafModifyModal
              title="Modifica"
              data={data}
              onUpdateIaf={onUpdateIaf}
            />
            <IafInvoicesExport title="Esporta CSV" data={data} />
          </DataTable>

          <ContPaginationPage>
            <p>{t('COMMON:PAGINATION_PER_PAGE')}</p>

            <FormGroup>
              <CustomInput
                type="select"
                className="pagination-select"
                id="perPage"
                name="perPage"
                value={query.limit}
                onChange={({ target: { value: limit } }) => {
                  onFilter({ limit });
                }}
              >
                {generatePaginationOptions()}
              </CustomInput>
            </FormGroup>
          </ContPaginationPage>
        </section>
      </div>
    </div>
  );
}

export default IafsPresentational;
