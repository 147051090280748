import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  FormGroup,
  Label,
  InputGroup,
  Input,
  InputGroupAddon,
  Form,
  FormText,
} from 'reactstrap';
import {
  BtnOutlineRed,
  BtnOutlineGreen,
  BtnRadiusRed,
  PNewItem,
} from '../../styled-components';
import './NewInvoiceModal.scss';
import DateInput from '../DateInput';
import AutoSuggest from '../Autosuggest';

const INITIAL_INVOICE_STATE = {
  accountability: '',
  customer: '',
  admin: {
    _id: '',
    businessName: '',
  },
  supplier: {
    _id: '',
    businessName: '',
  },
  number: '',
  date: '',
  supposedPaymentDate: '',
  gross: '',
  net: '',
  vatCode: '',
};

const NewInvoiceModal = ({
  administrator,
  searchSuppliers,
  searchAdministrators,
  searchCustomers,
  onConfirm,
}) => {
  const [t] = useTranslation('ROYALTY');
  const [modal, setModal] = useState(false);
  const isAdminUser = !!administrator;
  const INITIAL_ADMIN = isAdminUser
    ? {
        _id: administrator._id,
        businessName: administrator.businessName,
      }
    : { ...INITIAL_INVOICE_STATE.admin };

  const [formValues, setFormValues] = useState({
    ...INITIAL_INVOICE_STATE,
    admin: INITIAL_ADMIN,
  });

  const [formErrors, setFormErrors] = useState({
    supplier: false,
    customer: false,
    admin: false,
    number: false,
    date: false,
    supposedPaymentDate: false,
    gross: false,
    net: false,
    vatCode: false,
    accountability: false,
  });

  useEffect(() => {
    if (moment(formValues.date).isValid()) {
      setFormValues((state) => ({
        ...state,
        supposedPaymentDate: moment(formValues.date).add(60, 'days').toDate(),
      }));
    }
  }, [formValues.date]);

  const toggle = () => {
    setModal(!modal);
    setFormValues({
      ...INITIAL_INVOICE_STATE,
      admin: INITIAL_ADMIN,
      ...(isAdminUser && { vatCode: administrator.taxCode }),
    });
  };

  function onInputChange({ target }) {
    setFormValues((state) => ({ ...state, [target.name]: target.value }));
  }

  function onNumericInputChange({ target }) {
    if (/^-?[\d]*\.?[\d]{0,2}$/.test(target.value)) {
      setFormValues((state) => ({
        ...state,
        [target.name]: target.value,
      }));
    }
  }

  const renderSupplierSuggestion = ({ businessName = '', name = '' }) =>
    businessName + ' - ' + name;
  const getSupplierSuggestionValue = ({ businessName = '' }) => businessName;
  function onSupplierSuggestionSelect({ _id, businessName }) {
    setFormValues((state) => ({
      ...state,
      supplier: { _id, businessName },
    }));
  }

  const renderAdminSuggestion = ({ businessName = '' }) => businessName;
  const getAdminSuggestionValue = ({ businessName = '' }) => businessName;
  function onAdminSuggestionSelect({ _id, businessName }) {
    setFormValues((state) => ({
      ...state,
      admin: { _id, businessName },
    }));
  }

  const renderCustomerSuggestion = (rawSuggestionData) => {
    const { customer, businessName, vatCode } = rawSuggestionData || {};
    return [customer, businessName, vatCode]
      .filter((value) => value)
      .join(' - ');
  };
  function onCustomerSuggestionSelect({
    customer,
    businessName,
    vatCode,
    adminId,
  }) {
    setFormValues((state) => ({
      ...state,
      customer: customer || undefined,
      admin:
        adminId && businessName ? { _id: adminId, businessName } : undefined,
      vatCode: vatCode || undefined,
    }));
  }

  const onNewInvoiceConfirm = () => {
    const {
      supplier,
      customer,
      admin,
      number,
      date,
      supposedPaymentDate,
      gross,
      net,
      accountability,
    } = formValues;

    const errors = {};

    if (isAdminUser && (!supplier || !supplier._id)) errors.supplier = true;
    if (!customer) errors.customer = true;
    if (!admin._id) errors.admin = true;
    if (!number) errors.number = true;
    if (!date) errors.date = true;
    if (!supposedPaymentDate) errors.supposedPaymentDate = true;
    if (!gross) errors.gross = true;
    if (!net) errors.net = true;
    if (!accountability) errors.accountability = true;

    if (Object.keys(errors).length) {
      return setFormErrors(errors);
    }

    isAdminUser
      ? (formValues.supplier = formValues.supplier._id)
      : delete formValues.supplier;

    onConfirm({
      ...formValues,
      admin: formValues.admin._id,
      net: parseFloat(formValues.net),
      gross: parseFloat(formValues.gross),
    });

    toggle();
  };

  function listenForEscapeEvent() {
    document.addEventListener('keydown', ({ key }) => {
      if (key === 'Escape') toggle();
    });
  }

  const {
    supplier,
    customer,
    admin,
    number,
    date,
    supposedPaymentDate,
    gross,
    net,
    vatCode,
    accountability,
  } = formValues;

  return (
    <React.Fragment>
      <BtnRadiusRed onClick={toggle} type="button">
        <img src="/images/icn-plus.svg" alt=" plus icon" />
      </BtnRadiusRed>
      <PNewItem
        onClick={toggle}
        style={{ marginLeft: '7px', marginRight: '0', cursor: 'pointer' }}
      >
        {t('NEW_INVOICE')}
      </PNewItem>

      <Modal
        isOpen={modal}
        className="new-invoice-modal"
        onOpened={listenForEscapeEvent}
      >
        <ModalHeader toggle={toggle}>{t('NEW_INVOICE')}</ModalHeader>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            onNewInvoiceConfirm(formValues);
          }}
        >
          <ModalBody>
            <Row>
              <Col sm="12">
                <p>{t('ADD_MANUAL')}</p>
                <Row>
                  {isAdminUser && (
                    <Col xs="12" md="6" lg="4">
                      <FormGroup>
                        <Label>{t('SUPPLIER_REFERENCE')} *</Label>
                        <AutoSuggest
                          placeholder="Cerca un fornitore..."
                          onFetch={searchSuppliers}
                          onSelect={onSupplierSuggestionSelect}
                          renderSuggestion={renderSupplierSuggestion}
                          getSuggestionValue={getSupplierSuggestionValue}
                          value={supplier.businessName}
                          onChange={(businessName) =>
                            setFormValues((state) => ({
                              ...state,
                              supplier: { _id: '', businessName },
                            }))
                          }
                        />
                        {formErrors.supplier && (
                          <FormText className="error-message">
                            {t('SUPPLIER_REQUIRED')}
                          </FormText>
                        )}
                      </FormGroup>
                    </Col>
                  )}
                  <Col xs="12" md="6" lg="4">
                    <FormGroup>
                      <Label>{t('CUSTOMER_REFERENCE')} *</Label>
                      {isAdminUser ? (
                        <Input
                          type="text"
                          name="customer"
                          value={customer}
                          onChange={onInputChange}
                        />
                      ) : (
                        <AutoSuggest
                          placeholder="Cerca..."
                          onFetch={searchCustomers}
                          onSelect={onCustomerSuggestionSelect}
                          renderSuggestion={renderCustomerSuggestion}
                          getSuggestionValue={({ customer = '' }) => customer}
                          value={customer}
                          onChange={(customer) =>
                            setFormValues((state) => ({
                              ...state,
                              customer,
                            }))
                          }
                        />
                      )}
                      {formErrors.customer && (
                        <FormText className="error-message">
                          {t('COMMON:REQUIRED')}
                        </FormText>
                      )}
                    </FormGroup>
                  </Col>

                  <Col xs="12" md="6" lg="4">
                    <FormGroup>
                      <Label>{t('AMMINISTRATOR')} *</Label>
                      {isAdminUser ? (
                        <Input
                          type="text"
                          name="admin"
                          value={admin.businessName}
                          disabled
                        />
                      ) : (
                        <AutoSuggest
                          placeholder="Cerca un amministratore..."
                          onFetch={searchAdministrators}
                          onSelect={onAdminSuggestionSelect}
                          renderSuggestion={renderAdminSuggestion}
                          getSuggestionValue={getAdminSuggestionValue}
                          value={admin.businessName}
                          onChange={(businessName) =>
                            setFormValues((state) => ({
                              ...state,
                              admin: { ...admin, businessName },
                            }))
                          }
                        />
                      )}
                      {formErrors.admin && (
                        <FormText className="error-message">
                          {t('COMMON:REQUIRED')}
                        </FormText>
                      )}
                    </FormGroup>
                  </Col>
                  <Col xs="12" md="6" lg="4">
                    <FormGroup>
                      <Label>{t('INVOICE_NUMBER')} *</Label>
                      <Input
                        type="text"
                        name="number"
                        value={number}
                        onChange={onInputChange}
                      />
                      {formErrors.number && (
                        <FormText className="error-message">
                          {t('COMMON:REQUIRED')}
                        </FormText>
                      )}
                    </FormGroup>
                  </Col>
                  <Col xs="12" md="6" lg="4">
                    <FormGroup>
                      <Label>{t('CONTACTS:VAT_FISCAL')}</Label>
                      <Input
                        type="text"
                        name="vatCode"
                        value={vatCode}
                        onChange={onInputChange}
                        disabled={administrator}
                      />
                      {formErrors.vatCode && (
                        <FormText className="error-message">
                          {t('COMMON:REQUIRED')}
                        </FormText>
                      )}
                    </FormGroup>
                  </Col>
                  <Col xs="12" md="6" lg="4">
                    <FormGroup>
                      <Label>{t('INVOICE_DATE')} *</Label>
                      <DateInput
                        name="date"
                        value={date}
                        onChange={(date) =>
                          setFormValues((state) => ({ ...state, date }))
                        }
                      />
                      {formErrors.date && (
                        <FormText className="error-message">
                          {t('COMMON:REQUIRED')}
                        </FormText>
                      )}
                    </FormGroup>
                  </Col>
                  <Col xs="12" md="6" lg="4">
                    <FormGroup>
                      <Label>{t('EXPIRY_DATE')} *</Label>
                      <DateInput
                        name="supposedPaymentDate"
                        value={supposedPaymentDate}
                        onChange={(supposedPaymentDate) =>
                          setFormValues((state) => ({
                            ...state,
                            supposedPaymentDate,
                          }))
                        }
                      />
                      {formErrors.supposedPaymentDate && (
                        <FormText className="error-message">
                          {t('COMMON:REQUIRED')}
                        </FormText>
                      )}
                    </FormGroup>
                  </Col>
                  <Col xs="12" md="6" lg="4">
                    <FormGroup>
                      <Label>{t('CONTACTS:IMPORT_NET')} *</Label>
                      <InputGroup>
                        <Input
                          type="number"
                          step="0.01"
                          placeholder="0.00"
                          name="net"
                          value={net}
                          onChange={onNumericInputChange}
                        />
                        <InputGroupAddon addonType="prepend">€</InputGroupAddon>
                      </InputGroup>
                      {formErrors.net && (
                        <FormText className="error-message">
                          {t('COMMON:REQUIRED')}
                        </FormText>
                      )}
                    </FormGroup>
                  </Col>
                  <Col xs="12" md="6" lg="4">
                    <FormGroup>
                      <Label>{t('CONTACTS:IMPORT_TO_RECEIVE')} *</Label>
                      <InputGroup>
                        <Input
                          type="number"
                          step="0.01"
                          placeholder="0.00"
                          name="gross"
                          value={gross}
                          onChange={onNumericInputChange}
                        />
                        <InputGroupAddon addonType="prepend">€</InputGroupAddon>
                      </InputGroup>
                      {formErrors.gross && (
                        <FormText className="error-message">
                          {t('COMMON:REQUIRED')}
                        </FormText>
                      )}
                    </FormGroup>
                  </Col>

                  <Col xs="12" md="6" lg="4">
                    <FormGroup>
                      <Label>{t('ACC_DATE')} *</Label>
                      <DateInput
                        name="accountability"
                        value={accountability}
                        showOnlyMonth
                        setMidday
                        onChange={(accountability) => {
                          setFormValues((state) => ({
                            ...state,
                            accountability,
                          }));
                        }}
                      />
                      {formErrors.accountability && (
                        <FormText className="error-message">
                          {t('COMMON:REQUIRED')}
                        </FormText>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <BtnOutlineRed type="button" className="uppercase" onClick={toggle}>
              {t('COMMON:CANCEL')}
            </BtnOutlineRed>
            <BtnOutlineGreen type="submit" className="uppercase">
              {t('COMMON:CONFIRM')}
            </BtnOutlineGreen>
          </ModalFooter>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default NewInvoiceModal;
