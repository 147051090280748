import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import generatePaginationOptions from '../../lib/helpers/generatePaginationOptions';
import BurgerMenu from '../../commons/BurgerMenu';
import Header from '../../commons/Header';
import { ReactComponent as IconInvoices } from '../../images/icn_fatture.svg';
import { BooleanField, DataTable } from '../../commons/DataTable';
import Field from '../../commons/DataTable/Field';
import DateTimeField from '../../commons/DataTable/DateTimeField';
import CurrencyField from '../../commons/DataTable/CurrencyField';
import {
  ContPaginationPage,
  ContFlexRight,
  ContFilterCheckbox,
} from '../../styled-components';
import { FormGroup, CustomInput, Row, Col, Label } from 'reactstrap';
import SearchInput from '../../commons/SearchInput';
import { ContFilterYear } from '../../styled-components';
import generateYearOptions from '../../lib/helpers/generateYearOptions';
import { useParams } from 'react-router-dom';
import { BtnOutlineOrange } from '../../styled-components';
import CSVClientsExport from './CSVClientsExport';
import IafInvoicesExport from './IafInvoicesCSVExport';
import { Checkbox } from '../../commons/DataTable';
import DateFilter from '../../commons/DateFilter';
import ModalInvoice10 from './ModalInvoice10';

function IafsPresentational({
  data,
  query,
  total,
  onFilter,
  selectedIafs,
  onSelectChange,
  onSelectAll,
  onCreateInvoice10,
}) {
  const [t] = useTranslation('IAFS10');
  const { clientType } = useParams();
  const { offset, limit } = query;
  const [selectedAll, setSelectedAll] = React.useState(false);
  const [populatedSelected, setPopulatedSelected] = useState([]);
  const [amount, setAmount] = useState(0);

  useEffect(() => {
    setPopulatedSelected(
      selectedIafs.length
        ? data.filter(({ _id }) => selectedIafs.includes(_id))
        : data
    );
  }, [selectedIafs, data]);

  useEffect(() => {
    setAmount(populatedSelected.reduce((acc, { amount }) => acc + amount, 0));
  }, [populatedSelected]);

  const onFilterNoInvoice10 = (e) => {
    onFilter({
      noInvoice10: e.target.checked ? 'true' : 'false',
    });
  };

  const invoice10validation = () => {
    const supplierFirstValue = populatedSelected.at(0).supplier._id;
    if (
      !populatedSelected.every(
        ({ supplier: { _id } }) => _id === supplierFirstValue
      )
    ) {
      return { result: false, message: t('INVOICE_10_SUPPLIER_ERROR') };
    }

    if (populatedSelected.some(({ invoice10 }) => invoice10)) {
      return { result: false, message: t('INVOICE_10_ALREADY_EXISTS') };
    }

    return { result: true };
  };

  return (
    <div className="App">
      <BurgerMenu />

      <div className="corpo">
        <section>
          <Header
            title={t('PAGES:IAF10')}
            icon={<IconInvoices fill="#ffffff" style={{ height: '25px' }} />}
          />
          <Row className="search-add-cont">
            <Col sm="6" md="6" lg="3" xl="3">
              <SearchInput
                query={{ search: query && query.description }}
                onFilter={({ search }) => onFilter({ description: search })}
                label={t('SEARCH:DESCRIPTION')}
              />
            </Col>
            <Col sm="6" md="6" lg="3" xl="3">
              <SearchInput
                query={{ search: query && query.supplier }}
                onFilter={({ search }) => onFilter({ supplier: search })}
                label={t('SEARCH:BY_SUPPLIER')}
              />
            </Col>
            <Col sm="12" md="6" lg="3">
              <ContFilterYear className="no-width">
                <Row>
                  <Col xs="6">
                    <Label>{t('CONTACTS:TITLE_FILTER_YEAR')}</Label>
                  </Col>
                  <Col xs="6">
                    <FormGroup>
                      <CustomInput
                        type="select"
                        id="selectYear"
                        name="year"
                        className="year-select"
                        value={query && query.year}
                        onChange={({ target: { value: year } }) =>
                          onFilter({ year })
                        }
                      >
                        {generateYearOptions()}
                      </CustomInput>
                    </FormGroup>
                  </Col>
                </Row>
              </ContFilterYear>
            </Col>
            <Col sm="12" md="6" lg="3">
              <CSVClientsExport
                data={data}
                clientType={clientType}
                year={query.year}
              >
                <BtnOutlineOrange type="button" className="uppercase">
                  {t('COMMON:CSV_LIST_EXPORT')}
                </BtnOutlineOrange>
              </CSVClientsExport>
            </Col>
          </Row>
          <Row
            className="search-add-cont"
            style={{ paddingBottom: '20px', alignItems: 'baseline' }}
          >
            <Col sm="6" md="6" lg="3" xl="3">
              <SearchInput
                query={{ search: query && query.invoice10Number }}
                onFilter={({ search }) => onFilter({ invoice10Number: search })}
                label={t('SEARCH_BY_INVOICE_10_NUMBER')}
              />
            </Col>
            <Col sm="6" md="6" lg="3" xl="3">
              <DateFilter
                query={{ search: query && query.invoice10Date }}
                onFilter={({ search }) => onFilter({ invoice10Date: search })}
                label={t('SEARCH_BY_INVOICE_10_DATE')}
              />
            </Col>
            <Col sm="6" md="6" lg="3" xl="3">
              <ContFilterCheckbox>
                <CustomInput
                  type="checkbox"
                  id="emittedInvoice10"
                  label="Iaf senza fattura 10%"
                  inline
                  checked={query && query.noInvoice10 === 'true'}
                  onChange={onFilterNoInvoice10}
                />
              </ContFilterCheckbox>
            </Col>
            <Col sm="6" md="6" lg="3" xl="3"></Col>
          </Row>
          {selectedIafs.length > 0 && (
            <ModalInvoice10
              validationFn={invoice10validation}
              amount={amount}
              onCreateInvoice10={onCreateInvoice10}
            />
          )}
        </section>

        <section className="m-t-20">
          <DataTable
            data={data}
            total={total}
            offset={offset}
            limit={limit}
            onFilter={onFilter}
            emptyText={t('COMMON:NO_DATA')}
          >
            <Checkbox
              selected={selectedIafs}
              onSelectChange={onSelectChange}
              title={
                <input
                  type="checkbox"
                  value={selectedAll}
                  onChange={({ target: { checked } }) => {
                    setSelectedAll(checked);
                    onSelectAll(checked);
                  }}
                />
              }
            />
            <Field
              title="Descrizione"
              source="description"
              className="text-left"
            />
            <Field
              title="Fornitore"
              source="supplierName"
              className="text-left"
            />
            <Field title="Anno di riferimento" source="year" />
            <DateTimeField title="Data" source="date" format="DD/MM/YYYY" />
            <Field title="Percentuale %" source="percentage" />
            <CurrencyField title="Importo" source="amount" />
            <BooleanField title="Fattura 10% emessa" source="invoice10" />
            <Field title="Numero fattura 10%" source="invoice10.number" />
            <DateTimeField
              title="Data fattura 10%"
              source="invoice10.date"
              format="DD/MM/YYYY"
            />
            <IafInvoicesExport title="Esporta CSV" data={data} />
          </DataTable>

          <ContPaginationPage>
            <p>{t('COMMON:PAGINATION_PER_PAGE')}</p>

            <FormGroup>
              <CustomInput
                type="select"
                className="pagination-select"
                id="perPage"
                name="perPage"
                value={query.limit}
                onChange={({ target: { value: limit } }) => {
                  onFilter({ limit });
                }}
              >
                {generatePaginationOptions()}
              </CustomInput>
            </FormGroup>
          </ContPaginationPage>
        </section>
      </div>
    </div>
  );
}

export default IafsPresentational;
