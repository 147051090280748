import React from 'react';
import { useTranslation } from 'react-i18next';
import { DataTable } from '../../commons/DataTable';
import Field from '../../commons/DataTable/Field';
import DateTimeField from '../../commons/DataTable/DateTimeField';
import CurrencyField from '../../commons/DataTable/CurrencyField';
import ApprovedButtonField from './ApprovedButtonField';
import Invoice15Export from './Invoice15CSVExport';

function SupplierDataTable({
  data,
  total,
  offset,
  limit,
  onFilter,
  onApprovedChange,
}) {
  const [t] = useTranslation('IAF_INVOICES');

  return (
    <DataTable
      data={data}
      total={total}
      offset={offset}
      limit={limit}
      onFilter={onFilter}
      emptyText={t('COMMON:NO_DATA')}
    >
      <Field title="Numero" source="number" className="text-center" />
      <Field title="Amministratore" source="admin" className="text-left" />
      <DateTimeField title="Data" source="date" format="DD/MM/YYYY" />
      <CurrencyField title="Importo" source="amount" />
      <ApprovedButtonField
        title="Stato"
        source="approved"
        showButton
        onApprovedChange={onApprovedChange}
      />
      <Invoice15Export title="Esportazione" data={data} />
    </DataTable>
  );
}

export default SupplierDataTable;
