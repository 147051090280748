import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import generatePaginationOptions from '../../lib/helpers/generatePaginationOptions';
import BurgerMenu from '../../commons/BurgerMenu';
import Header from '../../commons/Header';
import { ReactComponent as IconInvoices } from '../../images/icn_fatture.svg';
import {
  ContFilterCheckbox,
  ContPaginationPage,
} from '../../styled-components';
import { FormGroup, CustomInput, Row, Col } from 'reactstrap';
import SearchInput from '../../commons/SearchInput';
import DateFilter from '../../commons/DateFilter';
import AdminDataTable from './AdminDataTable';
import SupplierDataTable from './SupplierDataTable';
import ModalIaf10 from './ModalIaf10';
import { BtnOutlineOrange, ContFlexRight } from '../../styled-components';
import CSVClientsExport from './CSVClientsExport';
import { applyPercentage } from '../../lib/helpers/financialMath';
import { IAF_PERCENTAGE } from '../../lib/constants';

function Invoices15Presentational({
  profile,
  data,
  query,
  total,
  onFilter,
  onPaidChange,
  onApprovedChange,
  selectedInvoices,
  onSelectChange,
  onSelectAll,
  onCreateIaf,
}) {
  const [t] = useTranslation('IAF_INVOICES');
  const { clientType } = useParams();
  const { offset, limit } = query;
  const [selectedIafTotal, setSelectedIafTotal] = useState(0);
  const [populatedSelected, setPopulatedSelected] = useState([]);

  const onFilterNotApproved = (e) => {
    onFilter({
      notApproved: e.target.checked,
    });
  };

  const onFilterNoIaf10Invoices = (e) => {
    onFilter({
      noIaf10: e.target.checked ? 'true' : 'false',
    });
  };

  const invoice15Validation = () => {
    const adminFirstValue = populatedSelected.at(0).admin;

    if (!populatedSelected.every(({ admin }) => admin === adminFirstValue)) {
      return { result: false, message: t('INVOICE_ADMIN_ERROR') };
    }

    const supplierFirstValue = populatedSelected.at(0).supplier;

    if (
      !populatedSelected.every(
        ({ supplier }) => supplier === supplierFirstValue
      )
    ) {
      return { result: false, message: t('INVOICE_SUPPLIER_ERROR') };
    }

    if (!populatedSelected.every(({ paid }) => paid)) {
      return { result: false, message: t('NOT_ALL_PAID_ERROR') };
    }

    if (!populatedSelected.every(({ approved }) => approved)) {
      return { result: false, message: t('NOT_ALL_APPROVED_ERROR') };
    }

    if (populatedSelected.some(({ iaf10Id }) => iaf10Id)) {
      return { result: false, message: t('IAF_ALREADY_EXISTS') };
    }

    return { result: true };
  };

  useEffect(() => {
    setPopulatedSelected(
      selectedInvoices.length
        ? data.filter(({ _id }) => selectedInvoices.includes(_id))
        : data
    );
  }, [selectedInvoices, data]);

  useEffect(() => {
    setSelectedIafTotal(
      populatedSelected
        .map((el) =>
          el.iafInvoices.reduce(
            (acc, { net }) => acc + applyPercentage(net, IAF_PERCENTAGE.IAF_10),
            0
          )
        )
        .reduce((acc, total) => acc + total, 0)
    );
  }, [populatedSelected]);

  return (
    <div className="App">
      <BurgerMenu />

      <div className="corpo">
        <section>
          <Header
            title={t('PAGES:INVOICES15')}
            icon={<IconInvoices fill="#ffffff" style={{ height: '25px' }} />}
          />
          <Row>
            <ContFlexRight>
              <CSVClientsExport
                data={data}
                clientType={clientType}
                year={query.year}
                isAdmin={!(profile && profile._id)}
              >
                <BtnOutlineOrange type="button" className="uppercase">
                  {t('COMMON:CSV_LIST_EXPORT')}
                </BtnOutlineOrange>
              </CSVClientsExport>
            </ContFlexRight>
          </Row>

          <Row className="search-add-cont m-t-20">
            <Col sm="6" md="6" lg="3" xl="3">
              <SearchInput
                query={{ search: query && query.admin }}
                onFilter={({ search }) => onFilter({ admin: search })}
                label={t('SEARCH:ADMINISTRATOR')}
              />
            </Col>
            {!(profile && profile._id) && (
              <Col sm="6" md="6" lg="3" xl="3">
                <SearchInput
                  query={{ search: query && query.supplier }}
                  onFilter={({ search }) => onFilter({ supplier: search })}
                  label={t('SEARCH:BY_SUPPLIER')}
                />
              </Col>
            )}
            <Col sm="6" md="6" lg="3" xl="3">
              <SearchInput
                query={{ search: query && query.number }}
                onFilter={({ search }) => onFilter({ number: search })}
                label={t('SEARCH_BY_NUMBER')}
              />
            </Col>
            <Col sm="6" md="6" lg="3" xl="3">
              <DateFilter
                query={{ search: query && query.date }}
                onFilter={({ search }) => onFilter({ date: search })}
                label={t('SEARCH_BY_DATE')}
              />
            </Col>
          </Row>
          <Row className="search-add-cont m-t-20">
            <Col sm="6" md="6" lg="3" xl="3">
              <ContFilterCheckbox>
                <CustomInput
                  type="checkbox"
                  id="notApproved"
                  label="Fatture 15% non approvate"
                  inline
                  checked={query && query.notApproved === 'true'}
                  onChange={onFilterNotApproved}
                />
              </ContFilterCheckbox>
            </Col>
            {!(profile && profile._id) && (
              <Col sm="6" md="6" lg="3" xl="3">
                <ContFilterCheckbox>
                  <CustomInput
                    type="checkbox"
                    id="noIaf10"
                    label="Fatture senza IAF 10%"
                    inline
                    checked={query && query.noIaf10 === 'true'}
                    onChange={onFilterNoIaf10Invoices}
                  />
                </ContFilterCheckbox>
              </Col>
            )}
          </Row>

          {!profile && (
            <div className="button-row m-t-20">
              <ModalIaf10
                onCreateIaf={onCreateIaf}
                validationFn={invoice15Validation}
                amount={selectedIafTotal}
                buttonText={
                  selectedInvoices.length
                    ? t('NEW_IAF_10')
                    : t('NEW_IAF_10_FOR_ALL')
                }
              />
            </div>
          )}
        </section>

        <section className="m-t-20">
          {profile && profile._id ? (
            <SupplierDataTable
              data={data}
              total={total}
              offset={offset}
              limit={limit}
              onFilter={onFilter}
              onApprovedChange={onApprovedChange}
            />
          ) : (
            <AdminDataTable
              data={data}
              total={total}
              offset={offset}
              limit={limit}
              onFilter={onFilter}
              onPaidChange={onPaidChange}
              selectedInvoices={selectedInvoices}
              onSelectChange={onSelectChange}
              onSelectAll={onSelectAll}
            />
          )}

          <ContPaginationPage>
            <p>{t('COMMON:PAGINATION_PER_PAGE')}</p>

            <FormGroup>
              <CustomInput
                type="select"
                className="pagination-select"
                id="perPage"
                name="perPage"
                value={query.limit}
                onChange={({ target: { value: limit } }) => {
                  onFilter({ limit });
                }}
              >
                {generatePaginationOptions()}
              </CustomInput>
            </FormGroup>
          </ContPaginationPage>
        </section>
      </div>
    </div>
  );
}

export default Invoices15Presentational;
