import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../commons/Auth';
import { useLoading } from '../../commons/Loading';
import { useAlert } from 'react-alert';
import {
  useQueryParams,
  NumberParam,
  withDefault,
  StringParam,
} from 'use-query-params';
import Invoices15Presentational from './Invoices15Presentational';
import { IAF_TYPES, IAF_PERCENTAGE } from '../../lib/constants';

const { REACT_APP_DEFAULT_PAGINATION_LIMIT = 50 } = process.env;

function Invoices15({ profile }) {
  const [{ token } = {}] = useAuth();
  const alert = useAlert();
  const [t] = useTranslation();
  const { showLoading, hideLoading } = useLoading();
  const [invoices15, setInvoices15] = useState([]);
  const [selectedInvoices, setSelectedInvoices] = useState([]);
  const [total, setTotal] = useState(0);

  const [query, setQuery] = useQueryParams({
    offset: NumberParam,
    limit: withDefault(NumberParam, +REACT_APP_DEFAULT_PAGINATION_LIMIT),
    admin: withDefault(StringParam, ''),
    supplier: withDefault(StringParam, ''),
    number: withDefault(StringParam, ''),
    date: withDefault(StringParam, ''),
    notApproved: withDefault(StringParam, ''),
    noIaf10: withDefault(StringParam, ''),
  });

  const {
    limit,
    offset,
    admin,
    supplier,
    number,
    date,
    notApproved,
    noIaf10,
  } = query;

  useEffect(() => {
    fetchInvoices15();
  }, [limit, offset, admin, supplier, number, date, notApproved, noIaf10]);

  function onFilter(queryDiff) {
    if (
      (queryDiff.admin && queryDiff.admin !== admin) ||
      (admin && queryDiff.admin === '') ||
      (queryDiff.supplier && queryDiff.supplier !== supplier) ||
      (supplier && queryDiff.supplier === '') ||
      (queryDiff.number && queryDiff.number !== number) ||
      (number && queryDiff.number === '') ||
      (queryDiff.date && queryDiff.date !== date) ||
      (date && queryDiff.date === '') ||
      (queryDiff.notApproved && queryDiff.notApproved !== notApproved) ||
      (notApproved && queryDiff.notApproved === '') ||
      (queryDiff.noIaf10 && queryDiff.noIaf10 !== noIaf10) ||
      (noIaf10 && queryDiff.noIaf10 === '') ||
      (queryDiff.limit && queryDiff.limit !== limit)
    ) {
      queryDiff.offset = 0; // return to first page
    }
    setQuery(queryDiff);
  }

  const fetchInvoices15 = async () => {
    showLoading();

    const response = await axios.get(
      profile && profile._id
        ? `${process.env.REACT_APP_API_URL}/invoices15/${profile._id}`
        : `${process.env.REACT_APP_API_URL}/invoices15`,
      {
        headers: { Authorization: token },
        params: {
          limit,
          offset,
          admin,
          supplier,
          number,
          date,
          notApproved,
          noIaf10,
        },
      }
    );

    hideLoading();

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    const { data = [], headers = {} } = response;
    setInvoices15(data);
    setTotal(+headers['x-total-count']);
  };

  const changePaidStatus = async (id, paid) => {
    showLoading();

    const response = await axios.patch(
      `${process.env.REACT_APP_API_URL}/invoices15/${id}`,
      {
        paid: paid,
      },
      {
        headers: { Authorization: token },
      }
    );

    hideLoading();

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    fetchInvoices15();
    return alert.success(t('COMMON:SUCCESS_UPDATE'));
  };

  const changeApprovedStatus = async (id, approved) => {
    showLoading();

    const response = await axios.patch(
      `${process.env.REACT_APP_API_URL}/invoices15/${id}`,
      {
        approved: approved,
      },
      {
        headers: { Authorization: token },
      }
    );

    hideLoading();

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    fetchInvoices15();
    return alert.success(t('COMMON:SUCCESS_UPDATE'));
  };

  const onSelectChange = (_id) => {
    if (selectedInvoices.includes(_id)) {
      return setSelectedInvoices((selectedInvoices) =>
        selectedInvoices.filter((invoiceId) => invoiceId !== _id)
      );
    }
    setSelectedInvoices((selectedInvoices) => [...selectedInvoices, _id]);
  };

  const onSelectAll = (selectAll) => {
    if (!selectAll) {
      return setSelectedInvoices([]);
    }
    setSelectedInvoices(
      invoices15.filter(({ regular }) => !regular).map(({ _id }) => _id)
    );
  };

  const createIaf = async (data) => {
    showLoading();

    const populatedSelected = selectedInvoices.length
      ? invoices15.filter(({ _id }) => selectedInvoices.includes(_id))
      : invoices15;

    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/iafs10`,
      {
        ...data,
        type: IAF_TYPES.GABETTI_TO_ADMIN,
        percentage: IAF_PERCENTAGE.IAF_10,
        invoices15: populatedSelected,
      },
      {
        headers: { Authorization: token },
      }
    );
    hideLoading();

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    alert.success(t('COMMON:SUCCESS_UPDATE'));
    fetchInvoices15();
  };

  return (
    <Invoices15Presentational
      profile={profile}
      data={invoices15}
      total={total}
      query={query}
      onFilter={onFilter}
      onPaidChange={changePaidStatus}
      onApprovedChange={changeApprovedStatus}
      selectedInvoices={selectedInvoices}
      onSelectChange={onSelectChange}
      onSelectAll={onSelectAll}
      onCreateIaf={createIaf}
    />
  );
}

export default Invoices15;
