import React from 'react';
import { Switch } from 'react-router-dom';
import { useAuth } from './AuthProvider';

const RoutesWrapper = (props) => {
  const { adminRedirect, publicRedirect, privateRedirect, children } = props;
  const [{ appLoaded, isAuthenticated, isAdmin, profile } = {}] = useAuth();

  const childrenComponents = React.Children.map(children, (child) => {
    return React.cloneElement(child, {
      ...child.props,
      appLoaded,
      isAdmin,
      isAuthenticated,
      adminRedirect,
      publicRedirect,
      privateRedirect,
      profile,
    });
  });

  return <Switch>{childrenComponents}</Switch>;
};

export default RoutesWrapper;
