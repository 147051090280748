import React from 'react';
import { formatCurrency } from '../../lib/helpers/formatters';

const TotalTr = ({ data = [] }) => {
  const total = data.reduce(
    (acc, current = {}) => {
      const {
        amount = 0,
        vat = 0,
        cashed = false,
        gabettiPerc = 0,
        adminPerc = 0,
        masterShares = 0,
      } = current;
      const gross = amount + vat;
      return {
        amount: acc.amount + amount || 0,
        vat: acc.vat + vat || 0,
        gross: acc.gross + gross || 0,
        cashed: acc.cashed + (cashed ? gross : 0) || 0,
        gabettiPercTotal: acc.gabettiPercTotal + gabettiPerc,
        adminPercTotal: acc.adminPercTotal + adminPerc,
        masterShares: acc.masterShares + masterShares,
      };
    },
    {
      amount: 0,
      vat: 0,
      gross: 0,
      cashed: 0,
      gabettiPercTotal: 0,
      adminPercTotal: 0,
      masterShares: 0,
    }
  );

  return (
    <tr className="summary-tr">
      <td>-</td>
      <td>-</td>
      <td>-</td>
      <td>{formatCurrency(total.amount)}</td>
      <td>{formatCurrency(total.gabettiPercTotal)}</td>
      <td>{formatCurrency(total.masterShares)}</td>
      <td>{formatCurrency(total.adminPercTotal)}</td>
      <td>{formatCurrency(total.vat)}</td>
      <td>{formatCurrency(total.gross)}</td>
      <td>{formatCurrency(total.cashed)}</td>
    </tr>
  );
};

export default TotalTr;
