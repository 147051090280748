import React from 'react';
import { withRouter } from 'react-router-dom';
import { Table as ReactstrapTable } from 'reactstrap';
import TopRow from './TopRow';
import TableRow from './TableRow';
import TotalsRow from './TotalTr';
import { formatCurrency } from '../../lib/helpers/formatters';
import { LAST_MASTER_YEAR } from '../../lib/constants';

function DashboardTable({ data = {}, year, history, totals }) {
  const {
    subscriptions = [],
    masters = [],
    masterShares = {},
    gabettiPerc = {},
    insuranceMasterShares = {},
    insuranceGabettiPerc = {},
  } = data;
  const hideMasterShares = year > LAST_MASTER_YEAR;

  return (
    <ReactstrapTable responsive hover className="data-table">
      <thead>
        <tr>
          <th />
          <th>Imponibile totale</th>
          <th>Dovuto</th>
          <th>EC/Banca</th>
        </tr>
      </thead>
      <tbody>
        <TopRow totals={totals} />

        {subscriptions.map(
          ({ type, title = '', total = 0, cashed = 0 }, index) => (
            <tr
              key={index}
              onClick={() => history.push(`/dashboard/${type}?year=${year}`)}
            >
              <td className="title">{title}</td>
              <td className="txt-table-right">-</td>
              <td className="txt-table-right">{formatCurrency(total)}</td>
              <td className="txt-table-right">{formatCurrency(cashed)}</td>
            </tr>
          )
        )}

        <TableRow
          data={masters}
          onClick={({ perc }) =>
            history.push(`/dashboard/masters?percentage=${perc}&year=${year}`)
          }
        />
        <tr
          onClick={() =>
            history.push(`/dashboard/compensi?accountabilityYear=${year}`)
          }
        >
          <td className="title">Compensi variabili da fatture (% Gabetti)</td>
          <td className="txt-table-right">
            {formatCurrency(gabettiPerc.net || 0)}
          </td>
          <td className="txt-table-right">
            <strong>{formatCurrency(gabettiPerc.total || 0)}</strong>
          </td>
          <td className="txt-table-right">
            <strong>{formatCurrency(gabettiPerc.cashed || 0)}</strong>
          </td>
        </tr>

        {!hideMasterShares && (
          <tr
            onClick={() =>
              history.push(`/dashboard/compensi?accountabilityYear=${year}`)
            }
          >
            <td className="title">
              Compensi variabili da fatture (Quote Master)
            </td>
            <td className="txt-table-right">-</td>
            <td className="txt-table-right">
              <strong>-{formatCurrency(masterShares.total || 0)}</strong>
            </td>
            <td className="txt-table-right">
              <strong>-{formatCurrency(masterShares.cashed || 0)}</strong>
            </td>
          </tr>
        )}

        <tr
          onClick={() =>
            history.push(`/dashboard/assicurazioni?accountabilityYear=${year}`)
          }
        >
          <td className="title">
            Compensi variabili da assicurazioni (% Gabetti)
          </td>
          <td className="txt-table-right">
            {formatCurrency(insuranceGabettiPerc.net || 0)}
          </td>
          <td className="txt-table-right">
            <strong>{formatCurrency(insuranceGabettiPerc.total || 0)}</strong>
          </td>
          <td className="txt-table-right">
            <strong>{formatCurrency(insuranceGabettiPerc.cashed || 0)}</strong>
          </td>
        </tr>

        {!hideMasterShares && (
          <tr
            onClick={() =>
              history.push(
                `/dashboard/assicurazioni?accountabilityYear=${year}`
              )
            }
          >
            <td className="title">
              Compensi variabili da assicurazioni (Quote Master)
            </td>
            <td className="txt-table-right">-</td>
            <td className="txt-table-right">
              <strong>
                -{formatCurrency(insuranceMasterShares.total || 0)}
              </strong>
            </td>
            <td className="txt-table-right">
              <strong>
                -{formatCurrency(insuranceMasterShares.cashed || 0)}
              </strong>
            </td>
          </tr>
        )}

        <TotalsRow totals={totals} />
      </tbody>
    </ReactstrapTable>
  );
}

export default withRouter(DashboardTable);
