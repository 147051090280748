import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { useAlert } from 'react-alert';
import {
  DataTable,
  Field,
  CurrencyField,
  DateTimeField,
  BooleanField,
  PaymentsDataField,
  PercentageField,
} from '../../commons/DataTable';
import InvoiceCheckbox from './InvoiceCheckbox';
import { ACCOUNTABILITY_LIMIT_DATE } from '../../lib/constants';
import { isAfter } from '../../lib/helpers/date';

function DashboardDetailTable(props) {
  const {
    data = [],
    total = 0,
    selectedInvoices = [],
    topRow,
    bottomRow,
    onSelectChange,
    onFilter,
    query = {},
    onSelectAll = () => {},
  } = props;
  const alert = useAlert();
  const [t] = useTranslation('COMMON');
  const { offset, limit } = query;
  const [selectedAll, setSelectedAll] = useState(false);
  const onRowClick = ({
    _id,
    gabettiInvoice,
    paid,
    supplier,
    accountability,
  }) => {
    if (!_id || gabettiInvoice) return;
    if (!paid) return alert.error(t('ROYALTY:NOT_PAID_ERROR'));

    if (isAfter(accountability, ACCOUNTABILITY_LIMIT_DATE)) {
      alert.error(t('ROYALTY:ACCOUNTABILITY_SELECT_ERROR'));
      return;
    }

    const { _id: supplierId } = supplier;
    const [firstSelectedInvoiceId] = selectedInvoices;
    if (!firstSelectedInvoiceId) return onSelectChange(_id);

    const firstSelectedInvoice =
      data.find(({ _id }) => {
        return _id === firstSelectedInvoiceId;
      }) || {};
    const { supplier: firstSelectedSupplier } = firstSelectedInvoice;
    const { _id: firstSelectedSupplierId } = firstSelectedSupplier || {};
    if (supplierId === firstSelectedSupplierId) return onSelectChange(_id);

    alert.error(t('ROYALTY:DIFFERENT_SUPPLIER_ERROR'));
  };

  const paidInvoices = data.filter(
    ({ paid, gabettiInvoice }) => paid && !gabettiInvoice
  );

  useEffect(() => {
    if (!selectedAll) {
      selectedInvoices.length === paidInvoices.length && onSelectAll([]);
      return;
    }
    if (!paidInvoices || !paidInvoices.length) {
      setSelectedAll(false);
      return;
    }

    const isSameSupplier = paidInvoices
      .map(({ supplier: { _id } = {} }) => _id)
      .every((val, i, arr) => val === arr[0]);

    if (!isSameSupplier) {
      alert.error(t('ROYALTY:DIFFERENT_SUPPLIER_ERROR'));
      setSelectedAll(false);
      return;
    }

    const isAccountabilityValid = paidInvoices.every(
      ({ accountability }) =>
        !isAfter(accountability, ACCOUNTABILITY_LIMIT_DATE)
    );

    if (!isAccountabilityValid) {
      alert.error(t('ROYALTY:ACCOUNTABILITY_SELECT_ERROR'));
      setSelectedAll(false);
      return;
    }

    onSelectAll(paidInvoices);
  }, [selectedAll]);

  useEffect(() => {
    if (selectedInvoices.length === paidInvoices.length) return;
    setSelectedAll(false);
  }, [selectedInvoices]);

  return (
    <DataTable
      history
      data={data}
      total={total}
      offset={offset}
      limit={limit}
      loadingText={t('LOADING')}
      emptyText={t('NO_DATA')}
      topRow={topRow}
      bottomRow={bottomRow}
      onRowClick={onRowClick}
      onFilter={onFilter}
    >
      <InvoiceCheckbox
        selected={selectedInvoices}
        onSelectChange={onRowClick}
        onClick={(e) => e.stopPropagation()}
        title={
          <input
            type="checkbox"
            checked={selectedAll}
            onChange={({ target: { checked } }) => setSelectedAll(checked)}
          />
        }
      />
      <Field
        title="Nome Fornitore"
        source="supplier.businessName"
        className="text-left"
      />
      <Field title="Riferimento Fattura" source="gabettiInvoice.invoiceRef" />
      <BooleanField title="CAP emesso" source="iaf15Id" />
      <Field title="Fattura 15%" source="invoice15Number" />
      <Field title="Numero Fattura" source="number" />
      <DateTimeField title="Data Fattura" source="date" format="DD/MM/YYYY" />
      <DateTimeField
        title="Mese Rend."
        source="accountability"
        format="MM/YYYY"
      />
      <Field title="Condominio" source="customer" />
      <Field title="Amministratore di condominio" source="admin.businessName" />
      <CurrencyField title="Imponibile" source="net" />
      <CurrencyField title="Importo da Incassare" source="gross" />
      <PaymentsDataField title="Incassi" source="payments" />
      <CurrencyField title="% Gabetti (€)" source="gabettiPerc" />
      <PercentageField title="% Gabetti" source="gabettiPercentShare" />
      <Field title="Nominativo master" source="masters[0].name" />
      <CurrencyField title="Quota master" source="masterShares[0]" />
      <PercentageField title="% Quota master" source="masterSharesPerc[0]" />
      <CurrencyField
        title="Quota master maturata"
        source="earnedMasterShares[0]"
      />
      <CurrencyField
        title="Diritto a fatturare (MASTER)"
        source="masterBillable[0]"
      />
      <BooleanField title="Pagata" source="paid" />
      <BooleanField title="Regolarizzata" source="regular" />
    </DataTable>
  );
}

export default withRouter(DashboardDetailTable);
