import React, { useState } from 'react';
import { InputGroup, InputGroupAddon, Button, Input } from 'reactstrap';
import './DateFilter.scss';
import { useAlert } from 'react-alert';

function DateFilter({ onFilter, query = {}, label, autoFocus = true }) {
  const alert = useAlert();
  const [search, setSearch] = useState(query.search || '');
  const dateRegex = /^(0[1-9]|1\d|2\d|3[01])[-/](0[1-9]|1[0-2])[-/]\d{4}$/;

  const validateDate = () => {
    if (search && !dateRegex.test(search)) {
      setSearch('');
      return alert.error(
        'Data non valida: il formato deve essere gg/mm/aaaa o gg-mm-aaaa'
      );
    }

    onFilter({ search });
  };

  return (
    <InputGroup className="search-bar">
      <Input
        defaultValue={search}
        onChange={(e) => {
          setSearch(e.target.value);
        }}
        placeholder={label}
        autoFocus={autoFocus}
      />
      <InputGroupAddon addonType="append">
        <Button onClick={validateDate}>
          <i className="fas fa-search" />
        </Button>
      </InputGroupAddon>
    </InputGroup>
  );
}

export default DateFilter;
