import React, { Children, cloneElement, useState } from 'react';
import Proptypes from 'prop-types';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import './ModalConfirm.scss';
import { BtnOutlineRed, BtnOutlineGreen } from '../../styled-components';

function ModalConfirm({
  children,
  text = '',
  title = '',
  onConfirm,
  className = '',
  style = {},
}) {
  const [t] = useTranslation();
  const [modal, setModal] = useState(false);

  const toggle = () => {
    setModal(!modal);
  };

  const onClick = () => {
    toggle();
    onConfirm();
  };

  const DEFULT_TITLE = t('COMMON:REQUEST_CONFIRM');

  const childrenComponenst = Children.map(children, (child) => {
    return cloneElement(child, { onClick: toggle });
  });

  return (
    <div className={className} style={style}>
      {childrenComponenst}
      <Modal isOpen={modal} fade={false} toggle={toggle}>
        <ModalHeader toggle={toggle}>{title || DEFULT_TITLE}</ModalHeader>
        <ModalBody>
          <p>{text || DEFULT_TITLE}</p>
        </ModalBody>
        <ModalFooter>
          <BtnOutlineRed type="button" className="uppercase" onClick={toggle}>
            {t('COMMON:CANCEL')}
          </BtnOutlineRed>
          <BtnOutlineGreen
            type="button"
            className="uppercase"
            onClick={onClick}
          >
            {t('COMMON:CONFIRM')}
          </BtnOutlineGreen>
        </ModalFooter>
      </Modal>
    </div>
  );
}

ModalConfirm.propTypes = {
  text: Proptypes.string,
  title: Proptypes.string,
  onConfirm: Proptypes.func.isRequired,
};

export default ModalConfirm;
