import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../commons/Auth';
import { useLoading } from '../../commons/Loading';
import { useAlert } from 'react-alert';
import {
  useQueryParams,
  NumberParam,
  withDefault,
  StringParam,
} from 'use-query-params';
import IafsPresentational from './IafsPresentational';

const { REACT_APP_DEFAULT_PAGINATION_LIMIT = 50 } = process.env;

function MyCap({ profile }) {
  const [{ token } = {}] = useAuth();
  const alert = useAlert();
  const [t] = useTranslation();
  const { showLoading, hideLoading } = useLoading();
  const [total, setTotal] = useState(0);
  const [iafs, setIafs] = useState([]);

  const [query, setQuery] = useQueryParams({
    offset: NumberParam,
    limit: withDefault(NumberParam, +REACT_APP_DEFAULT_PAGINATION_LIMIT),
    description: withDefault(
      StringParam,
      localStorage.getItem('myIafsDescription')
    ),
    supplier: withDefault(StringParam, ''),
    year: withDefault(NumberParam, new Date().getFullYear()),
  });

  const { limit, offset, description, supplier, year } = query;

  useEffect(() => {
    fetchIafs();
  }, [limit, offset, description, supplier, year]);

  function onFilter(queryDiff) {
    if (
      (queryDiff.description && queryDiff.description !== description) ||
      (description && queryDiff.description === '') ||
      (queryDiff.supplier && queryDiff.supplier !== supplier) || // first description
      (supplier && queryDiff.supplier === '') ||
      (queryDiff.limit && queryDiff.limit !== limit)
    ) {
      queryDiff.offset = 0; // return to first page
    }
    setQuery(queryDiff);
  }

  const fetchIafs = async () => {
    showLoading();

    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/iafs15/${profile._id}`,
      {
        headers: { Authorization: token },
        params: {
          limit,
          offset,
          description,
          supplier,
          year,
        },
      }
    );

    hideLoading();

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    const { data = [], headers = {} } = response;
    setIafs(data);
    setTotal(+headers['x-total-count']);
  };

  const updateIaf = async (iafId, data) => {
    showLoading();

    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/iafs15/${iafId}`,
      data,
      {
        headers: { Authorization: token },
      }
    );

    hideLoading();

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    alert.success(t('COMMON:SUCCESS_UPDATE'));
    fetchIafs();
  };

  return (
    <IafsPresentational
      data={iafs}
      query={query}
      onFilter={onFilter}
      onUpdateIaf={updateIaf}
    />
  );
}

export default MyCap;
