import React, { useState } from 'react';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  CustomInput,
  FormText,
} from 'reactstrap';
import { BtnOutlineRed, BtnOutlineGreen } from '../../styled-components';
import { getPastYears } from '../../lib/helpers/date';
import { IAF_PERCENTAGE, NUMBER_PAST_YEARS } from '../../lib/constants';
import DateInput from '../../commons/DateInput';
import { applyPercentage } from '../../lib/helpers/financialMath';

function ModalIaf10(props) {
  const { amount = 0, validationFn, onCreateIaf, buttonText } = props;
  const [t] = useTranslation('IAF_INVOICES');
  const alert = useAlert();
  const [modal, setModal] = useState(false);
  const [description, setDescription] = useState('');
  const [date, setDate] = useState('');
  const [year, setYear] = useState('');
  const [formErrors, setFormErrors] = useState({});

  const toggle = () => {
    setDescription('');
    setDate('');
    setYear('');
    setFormErrors({});
    setModal(!modal);
  };

  function onConfirm() {
    const errors = {};

    if (!description || description === '') errors.description = true;
    if (!year || year === '') errors.year = true;
    if (!date) errors.date = true;

    if (Object.values(errors).some(Boolean)) return setFormErrors(errors);

    onCreateIaf({ description, date, year, amount });
    toggle();
  }

  function validate() {
    const validation = validationFn();
    if (!validation.result) {
      alert.error(validation.message);
      return;
    }
    toggle();
  }

  return (
    <React.Fragment>
      <BtnOutlineGreen type="button" onClick={validate}>
        {t(buttonText)}
      </BtnOutlineGreen>

      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>{t('NEW_IAF_10')}</ModalHeader>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            onConfirm();
          }}
        >
          <ModalBody>
            <div className="max-width-form">
              <Row>
                <Col xs="12">
                  <FormGroup>
                    <Label>{t('ROYALTY:DESCRIPTION')} *</Label>
                    <Input
                      id="description"
                      name="description"
                      type="textarea"
                      onChange={(e) => setDescription(e.target.value)}
                    />
                    {formErrors.description && (
                      <FormText className="error-message">
                        {t('COMMON:REQUIRED')}
                      </FormText>
                    )}
                  </FormGroup>
                </Col>
                <Col xs="12">
                  <FormGroup>
                    <Label>{t('ROYALTY:REFERENCE_YEAR')} *</Label>
                    <CustomInput
                      type="select"
                      id="year"
                      name="customSelect"
                      onChange={(e) => setYear(e.target.value)}
                    >
                      <option value="">{t('ROYALTY:SELECT_ONE')}</option>
                      {getPastYears(NUMBER_PAST_YEARS).map((year) => (
                        <option value={year} key={year}>
                          {year}
                        </option>
                      ))}
                    </CustomInput>
                    {formErrors.year && (
                      <FormText className="error-message">
                        {t('COMMON:REQUIRED')}
                      </FormText>
                    )}
                  </FormGroup>
                </Col>
                <Col xs="12">
                  <FormGroup>
                    <Label>{t('DATE')} *</Label>
                    <DateInput value={date} onChange={setDate} />
                    {formErrors.date && (
                      <FormText className="error-message">
                        {t('COMMON:REQUIRED')}
                      </FormText>
                    )}
                  </FormGroup>
                </Col>
                <Col xs="12">
                  <FormGroup>
                    <Label>{t('ROYALTY:AMOUNT')}</Label>
                    <Input
                      type="text"
                      name="amount"
                      value={`${amount} €`}
                      disabled={true}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </div>
          </ModalBody>
          <ModalFooter>
            <BtnOutlineRed type="button" className="uppercase" onClick={toggle}>
              {t('COMMON:CANCEL')}
            </BtnOutlineRed>
            <BtnOutlineGreen type="submit" className="uppercase">
              {t('COMMON:CONFIRM')}
            </BtnOutlineGreen>
          </ModalFooter>
        </Form>
      </Modal>
    </React.Fragment>
  );
}

export default ModalIaf10;
