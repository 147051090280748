import React, { useEffect, useState } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAlert } from 'react-alert';
import { useDebouncedCallback } from 'use-debounce';
import Header from '../../commons/Header';
import BurgerMenu from '../../commons/BurgerMenu';
import { useAuth } from '../../commons/Auth';
import { ReactComponent as IconRendicontazioni } from '../../images/icn_rendicontazioni.svg';
import './MyFinancialReports.scss';
import FinancialReportsPresentational from '../ProfileDetail/FinancialReports/FinancialReportsPresentational';
import { get } from '../../lib/fetch/reports';
import { useLoading } from '../../commons/Loading';
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params';
import ProvidersDetail from '../ProfileDetail/ProvidersDetail';
import GnetAdminDetail from '../ProfileDetail/GnetAdminDetail';
import VariableRevenuesDetail from '../ProfileDetail/VariableRevenuesDetail';
import VarRevInsDetail from '../ProfileDetail/VarRevInsDetail';
import VarRevInsStudioDetail from '../ProfileDetail/VarRevInsStudioDetail';

function MyFinancialReports({ location, match = {} }) {
  const [t] = useTranslation();
  const [{ isAdmin, profile = {}, token } = {}] = useAuth();
  const {
    _id: profileId,
    master: isProfileMaster,
    type: profileType = '',
  } = profile;
  const alert = useAlert();
  const { showLoading, hideLoading } = useLoading();
  const [reports, setReports] = useState({});

  const [query, setQuery] = useQueryParams({
    year: withDefault(NumberParam, new Date().getFullYear()),
    supplier: withDefault(StringParam, ''),
    admin: withDefault(StringParam, ''),
    number: withDefault(StringParam, ''),
    accountabilityYear: NumberParam,
    accountabilityMonth: NumberParam,
    insuranceNumber: withDefault(StringParam, ''),
    insuranceAdmin: withDefault(StringParam, ''),
    insuranceSupplier: withDefault(StringParam, ''),
    studioInsuranceNumber: withDefault(StringParam, ''),
    insuranceAccountabilityYear: NumberParam,
    insuranceAccountabilityMonth: NumberParam,
    studioInsuranceAdmin: withDefault(StringParam, ''),
    studioInsuranceSupplier: withDefault(StringParam, ''),
    studioInsuranceAccountabilityYear: NumberParam,
    studioInsuranceAccountabilityMonth: NumberParam,
  });

  const {
    supplier,
    admin,
    number,
    year,
    accountabilityYear,
    accountabilityMonth,
    insuranceNumber,
    insuranceAdmin,
    insuranceSupplier,
    insuranceAccountabilityYear,
    insuranceAccountabilityMonth,
    studioInsuranceAdmin,
    studioInsuranceNumber,
    studioInsuranceSupplier,
    studioInsuranceAccountabilityYear,
    studioInsuranceAccountabilityMonth,
  } = query;

  const [fetchReports] = useDebouncedCallback(async () => {
    showLoading();
    const { error, data } = await get({
      profileId,
      year,
      token,
      supplier,
      admin,
      number,
      accountabilityYear,
      accountabilityMonth,
      insuranceNumber,
      insuranceAdmin,
      insuranceSupplier,
      insuranceAccountabilityYear,
      insuranceAccountabilityMonth,
      studioInsuranceAdmin,
      studioInsuranceNumber,
      studioInsuranceSupplier,
      studioInsuranceAccountabilityYear,
      studioInsuranceAccountabilityMonth,
    });

    if (error) {
      hideLoading();
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    setReports(data);
    hideLoading();
  }, 1000);

  useEffect(() => {
    fetchReports();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    year,
    accountabilityYear,
    accountabilityMonth,
    supplier,
    admin,
    number,
    insuranceNumber,
    insuranceAdmin,
    insuranceSupplier,
    studioInsuranceAdmin,
    studioInsuranceNumber,
    studioInsuranceSupplier,
    insuranceAccountabilityYear,
    insuranceAccountabilityMonth,
    studioInsuranceAccountabilityYear,
    studioInsuranceAccountabilityMonth,
  ]);

  if (isAdmin)
    return (
      // Super Admins do not have financial reports
      <Redirect
        push={false}
        to={{
          pathname: '/dashboard',
          state: { from: location },
        }}
      />
    );

  function onFilter(queryDiff) {
    if (
      (queryDiff.accountabilityYear &&
        queryDiff.accountabilityYear !== accountabilityYear) ||
      queryDiff.accountabilityYear === ''
    ) {
      queryDiff.accountabilityMonth = '';
    }
    if (
      (queryDiff.insuranceAccountabilityYear &&
        queryDiff.insuranceAccountabilityYear !==
          insuranceAccountabilityYear) ||
      queryDiff.insuranceAccountabilityYear === ''
    ) {
      queryDiff.insuranceAccountabilityMonth = '';
    }
    if (
      (queryDiff.studioInsuranceAccountabilityYear &&
        queryDiff.studioInsuranceAccountabilityYear !==
          studioInsuranceAccountabilityYear) ||
      queryDiff.studioInsuranceAccountabilityYear === ''
    ) {
      queryDiff.studioInsuranceAccountabilityMonth = '';
    }
    setQuery(queryDiff);
  }

  return (
    <div className="App">
      <BurgerMenu />

      <div className="corpo">
        <section>
          <Header
            title={t('PAGES:FINANCIAL_REPORTS')}
            icon={<IconRendicontazioni fill="#FFFFFF" />}
          />
          <Switch>
            <Route
              exact
              path={match.url}
              component={(props) => (
                <FinancialReportsPresentational
                  data={reports}
                  onAddBonus={() => {}}
                  onBonusDelete={() => {}}
                  onBonusUpdate={() => {}}
                  onFilter={onFilter}
                  query={query}
                  profileType={profileType}
                  isProfileMaster={isProfileMaster}
                  {...props}
                />
              )}
            />
            <Route
              path={`${match.url}/suppliers`}
              component={(props) => (
                <ProvidersDetail
                  id={profileId}
                  profile={profile}
                  reports={reports}
                  onFilter={onFilter}
                  query={query}
                  {...props}
                />
              )}
            />
            <Route
              path={`${match.url}/gnet-admins`}
              component={(props) => (
                <GnetAdminDetail
                  id={profileId}
                  profile={profile}
                  reports={reports}
                  onFilter={onFilter}
                  query={query}
                  {...props}
                />
              )}
            />
            <Route
              path={`${match.url}/variable-revenues`}
              component={(props) => (
                <VariableRevenuesDetail
                  id={profileId}
                  profile={profile}
                  reports={reports}
                  onFilter={onFilter}
                  query={query}
                  {...props}
                />
              )}
            />
            <Route
              path={`${match.url}/variable-revenues-insurances`}
              component={(props) => (
                <VarRevInsDetail
                  id={profileId}
                  profile={profile}
                  reports={reports}
                  onFilter={onFilter}
                  query={query}
                  {...props}
                />
              )}
            />
            <Route
              path={`${match.url}/insurance-revenues-studio`}
              component={(props) => (
                <VarRevInsStudioDetail
                  id={profileId}
                  profile={profile}
                  reports={reports}
                  onFilter={onFilter}
                  query={query}
                  {...props}
                />
              )}
            />
          </Switch>
        </section>
      </div>
    </div>
  );
}

export default withRouter(MyFinancialReports);
