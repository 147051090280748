import React from 'react';
import useInterval from 'react-useinterval';
import { useTranslation } from 'react-i18next';
import { Form, Row, Col } from 'reactstrap';
import BurgerMenu from '../../commons/BurgerMenu';
import Header from '../../commons/Header';
import Footer from '../../commons/Footer';
import { ReactComponent as IconFinancial } from '../../images/icn_rendicontazioni.svg';
import {
  ContFlexLeft,
  ContPaginationPage,
  ContFilterYear,
  BtnOutlineOrange,
  ContFlexRight,
} from '../../styled-components';
import Table from './Table';
import SearchInput from '../../commons/SearchInput';
import FormGroup from 'reactstrap/lib/FormGroup';
import CustomInput from 'reactstrap/lib/CustomInput';
import generatePaginationOptions from '../../lib/helpers/generatePaginationOptions';
import Label from 'reactstrap/lib/Label';
import generateYearOptions from '../../lib/helpers/generateYearOptions';
import { ProfileTypes } from '../../lib/constants';
import CSVReportProfilesExport from './CSVReportProfilesExport';
import moment from 'moment';

function ReportProfilesPresentational({
  data = [],
  total,
  query = {},
  onFilter,
  setFilterYear,
  syncReports,
  fetchReportProfiles,
  lastUpdate,
  onSort,
}) {
  const [t] = useTranslation('CONTACTS');

  useInterval(async () => {
    await fetchReportProfiles();
  }, 1000 * 60);

  return (
    <div className="App">
      <BurgerMenu />

      <div className="corpo">
        <section>
          <Header
            title={t('PAGES:REPORT')}
            icon={<IconFinancial fill="#ffffff" />}
          />
        </section>

        <section>
          <Row>
            <Col sm="12" md="6" lg="3">
              <ContFilterYear className="no-width">
                <Row>
                  <Col xs="6">
                    <Label>{t('CONTACTS:TITLE_FILTER_TYPE')}</Label>
                  </Col>
                  <Col xs="6">
                    <FormGroup>
                      <CustomInput
                        type="select"
                        id="selectYear"
                        name="year"
                        value={query.type || ''}
                        onChange={(e) => onFilter({ type: e.target.value })}
                      >
                        <option value="">Tutti</option>
                        {ProfileTypes.filter((type) =>
                          ['BUILDING_ADMIN', 'GNET_ADMIN'].includes(type)
                        ).map((type) => (
                          <option value={type} key={type}>
                            {t(type)}
                          </option>
                        ))}
                      </CustomInput>
                    </FormGroup>
                  </Col>
                </Row>
              </ContFilterYear>
            </Col>

            <Col sm="12" md="6">
              <ContFlexLeft className="show-invoices">
                <SearchInput
                  query={query}
                  onFilter={onFilter}
                  label={t('COMMON:SEARCH')}
                />
              </ContFlexLeft>
            </Col>
            <Col sm="12" md="6" lg="3">
              <ContFlexRight>
                <BtnOutlineOrange onClick={syncReports} className="uppercase">
                  {t('REPORT:FORCE_SYNC')}
                </BtnOutlineOrange>
                <CSVReportProfilesExport data={data} year={query.year}>
                  <BtnOutlineOrange type="button" className="uppercase">
                    {t('COMMON:CSV_EXPORT')}
                  </BtnOutlineOrange>
                </CSVReportProfilesExport>
              </ContFlexRight>
            </Col>
          </Row>
          <Row>
            <Col sm="12" md="6" lg="3">
              <ContFilterYear className="no-width">
                <Row>
                  <Col xs="6">
                    <Label>{t('CONTACTS:TITLE_FILTER_YEAR')}</Label>
                  </Col>
                  <Col xs="6">
                    <FormGroup>
                      <CustomInput
                        type="select"
                        id="selectYear"
                        name="year"
                        className="year-select"
                        value={query.year}
                        onChange={({ target: { value: year } }) =>
                          setFilterYear(year)
                        }
                      >
                        {generateYearOptions()}
                      </CustomInput>
                    </FormGroup>
                  </Col>
                </Row>
              </ContFilterYear>
            </Col>
            <Col>
              <ContFlexRight>
                Ultimo aggiornamento:{' '}
                {moment(lastUpdate).format('DD-MM-YYYY HH:mm:ss')}
              </ContFlexRight>
            </Col>
          </Row>

          <br />

          <Form>
            <Table
              data={data}
              total={total}
              query={query}
              onFilter={onFilter}
              onSort={onSort}
            />
          </Form>

          <ContPaginationPage>
            <p>{t('COMMON:PAGINATION_PER_PAGE')}</p>

            <FormGroup>
              <CustomInput
                type="select"
                className="pagination-select"
                id="perPage"
                name="perPage"
                value={query.limit}
                onChange={({ target: { value: limit } }) => {
                  onFilter({ limit });
                }}
              >
                {generatePaginationOptions()}
              </CustomInput>
            </FormGroup>
          </ContPaginationPage>
        </section>

        <Footer />
      </div>
    </div>
  );
}

export default ReportProfilesPresentational;
