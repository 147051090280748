import React  from 'react';
import GnetAdminDetailPresentational from './GnetAdminDetailPresentational';

const GnetAdminDetail = (props) => {
  const {
    profile = {},
    reports = {},
    query = {},
    onFilter = () => {},
  } = props;

  return (
    <GnetAdminDetailPresentational
      data={reports}
      onFilter={onFilter}
      query={query}
      profileType={profile.type}
      {...props}
    />
  );
};

export default GnetAdminDetail;
