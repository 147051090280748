import React from 'react';
import { useTranslation } from 'react-i18next';
import Header from '../../commons/Header';
import Footer from '../../commons/Footer';
import BurgerMenu from '../../commons/BurgerMenu';
import { ReactComponent as IconInvoices } from '../../images/icn_fatture.svg';
import {
  ContFlexLeft,
  ContFlexRight,
  BtnRadiusWhite,
  ContFilterYear,
} from '../../styled-components';
import SearchInput from '../../commons/SearchInput';
import InvoiceCollapse from './InvoiceCollapse';
import NewInvoiceModal from '../../commons/NewInvoiceModal';
import { Row, Col } from 'reactstrap';
import Label from 'reactstrap/lib/Label';
import FormGroup from 'reactstrap/lib/FormGroup';
import CustomInput from 'reactstrap/lib/CustomInput';
import Form from 'reactstrap/lib/Form';
import generateYearOptions from '../../lib/helpers/generateYearOptions';
import generateMonthOptions from '../../lib/helpers/generateMonthOptions';

function InvoicesPresentational({
  data = [],
  query,
  onFilter,
  searchAdministrators,
  searchCustomers,
  onNewInvoiceConfirm,
  onInvoiceUpdate,
  onAddPayment,
  onDeletePayment,
  onValidateInvoice,
  onInvoiceDelete,
}) {
  const [t] = useTranslation('INVOICES');

  return (
    <div className="App">
      <BurgerMenu />

      <div className="corpo">
        <section>
          <Header
            title={t('PAGES:INVOICES')}
            icon={<IconInvoices fill="#ffffff" style={{ height: '25px' }} />}
          />

          <Row>
            <Col sm="12" md="4">
              <ContFlexLeft>
                <NewInvoiceModal
                  searchAdministrators={searchAdministrators}
                  searchCustomers={searchCustomers}
                  onConfirm={onNewInvoiceConfirm}
                />
              </ContFlexLeft>
            </Col>
            <Col sm="12" md="8">
              <ContFlexRight className="show-invoices">
                <BtnRadiusWhite
                  className={
                    query.all === 'false' ? 'eye-toggle show-me' : 'eye-toggle'
                  }
                  onClick={() =>
                    onFilter({ all: query.all === 'true' ? 'false' : 'true' })
                  }
                >
                  <i className="far fa-eye"></i>
                </BtnRadiusWhite>
              </ContFlexRight>
            </Col>
          </Row>
          <Form className="m-t-20">
            <Row className="compensi-variabili-resp">
              <Col lg="6" xl="3">
                <ContFilterYear className="no-width">
                  <Row>
                    <Col xs="6">
                      <Label>{t('CONTACTS:TITLE_FILTER_YEAR')}</Label>
                    </Col>
                    <Col xs="6">
                      <FormGroup>
                        <CustomInput
                          type="select"
                          id="selectYear"
                          name="year"
                          value={query && query.year}
                          onChange={({ target: { value: year } }) =>
                            onFilter({ year })
                          }
                        >
                          {generateYearOptions()}
                        </CustomInput>
                      </FormGroup>
                    </Col>
                  </Row>
                </ContFilterYear>
              </Col>
              <Col lg="6" xl="3">
                <SearchInput
                  query={{ search: query && query.number }}
                  onFilter={({ search }) => onFilter({ number: search })}
                  label={t('SEARCH:INVOICE_NUMBER')}
                />
              </Col>
              <Col lg="6" xl="3">
                <SearchInput
                  query={{ search: query && query.customer }}
                  onFilter={({ search }) => onFilter({ customer: search })}
                  label={t('SEARCH:CONDOMINIUM')}
                />
              </Col>
              <Col lg="6" xl="3">
                <SearchInput
                  query={{ search: query && query.admin }}
                  onFilter={({ search }) => onFilter({ admin: search })}
                  label={t('SEARCH:ADMINISTRATOR')}
                />
              </Col>
              <Col lg="6" xl="3">
                <ContFilterYear className="no-width">
                  <Row>
                    <Col xs="6">
                      <Label>{t('COMMON:TITLE_FILTER_ACC_YEAR')}</Label>
                    </Col>
                    <Col xs="6">
                      <FormGroup>
                        <CustomInput
                          type="select"
                          id="selectAccountabilityYear"
                          name="accountabilityYear"
                          className="year-select"
                          value={query && query.accountabilityYear}
                          onChange={({
                            target: { value: accountabilityYear },
                          }) => {
                            onFilter({ accountabilityYear });
                          }}
                        >
                          {generateYearOptions(true)}
                        </CustomInput>
                      </FormGroup>
                    </Col>
                  </Row>
                </ContFilterYear>
              </Col>
              <Col lg="6" xl="3">
                <ContFilterYear className="no-width">
                  <Row>
                    <Col xs="6">
                      <Label>{t('COMMON:TITLE_FILTER_ACC_MONTH')}</Label>
                    </Col>
                    <Col xs="6">
                      <FormGroup>
                        <CustomInput
                          type="select"
                          id="accountabilityMonth"
                          name="accountabilityMonth"
                          className="year-select"
                          disabled={!query.accountabilityYear}
                          value={
                            query && query.accountabilityMonth === null
                              ? ''
                              : query.accountabilityMonth
                          }
                          onChange={({
                            target: { value: accountabilityMonth },
                          }) => onFilter({ accountabilityMonth })}
                        >
                          {generateMonthOptions()}
                        </CustomInput>
                      </FormGroup>
                    </Col>
                  </Row>
                </ContFilterYear>
              </Col>
            </Row>
          </Form>
        </section>

        <section>
          {data.map((invoice, index) => (
            <InvoiceCollapse
              key={index}
              invoice={invoice}
              searchAdministrators={searchAdministrators}
              onUpdate={onInvoiceUpdate}
              onAddPayment={onAddPayment}
              onDeletePayment={onDeletePayment}
              onValidateInvoice={onValidateInvoice}
              onInvoiceDelete={onInvoiceDelete}
            />
          ))}
        </section>
        <Footer />
      </div>
    </div>
  );
}

export default InvoicesPresentational;
