import { formatCurrency, formatDate } from '../../lib/helpers/formatters';

export default (items) =>{
  const total = formatCurrency(items.reduce((acc, invoice) => (acc += invoice.gross), 0));
  const totalRow = `<tr><td colspan="4">TOTALE DA INCASSARE</td> <td style="text-align:right"><strong>${total}</strong></td></tr>`
  const list = items.map(
    (item) => {
      return `<tr>
      <td>${item.admin.businessName}</td>
      <td>${item.number}</td>
      <td style="min-width:fit-content">${formatDate(item.date)}</td>
      <td>${item.customer}</td>
      <td style="text-align:right">${formatCurrency(item.gross)}</td>
      </tr>`;
    }
  ).join('')

  return `
  <head>
  <link rel='stylesheet' href='printable-table.css'>
  </head>
  <body>
  <div class="corpo">
  <img src='/static/media/logo-gabetti-tec.e6c3fc97.jpg'/>
  <p>${formatDate(new Date())}</p>
  <h4>I seguenti documenti non sono ancora stati pagati:</h4>
  <table class='printable-table'>
  <thead>
  <tr>
  <th>Amministratore</th>
  <th>Numero</th>
  <th>Data</th>
  <th>Cliente</th>
  <th>Importo</th>
  </tr>
  </thead>
  <tbody>
  ${list} ${totalRow}
  </tbody>
  </table>
  </div>
  </body>
  `
}