import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { TabContent, TabPane, Form, Row, Col } from 'reactstrap';
import {
  ContAffiliation,
  ContFilterYear,
  ContFilterCheckbox,
  ContPaginationPage,
} from '../../../styled-components';
import {
  DataTable,
  Field,
  DateTimeField,
  PaidBadge,
  PaymentsDataField,
  CurrencyField,
  RegulariseField,
} from '../../../commons/DataTable';
import SearchInput from '../../../commons/SearchInput';
import FormGroup from 'reactstrap/lib/FormGroup';
import CustomInput from 'reactstrap/lib/CustomInput';
import Label from 'reactstrap/lib/Label';
import TotalTr from './TotalTr';
import generateYearOptions from '../../../lib/helpers/generateYearOptions';
import generateMonthOptions from '../../../lib/helpers/generateMonthOptions';
import ModalEditInvoice from './ModalEditInvoice';
import ModalAddPayment from './ModalAddPayment';
import RegularCheckbox from '../../MyRoyalties/RegularCheckbox';
import ModalIaf15 from '../../MyRoyalties/ModalIaf15';
import {
  IAF_PERCENTAGE,
  IAF_REQUIRED_NET,
  ACCOUNTABILITY_LIMIT_DATE,
} from '../../../lib/constants';
import { isAfter } from '../../../lib/helpers/date';
import { BooleanField } from '../../../commons/DataTable';
import { applyPercentage } from '../../../lib/helpers/financialMath';
import generatePaginationOptions from '../../../lib/helpers/generatePaginationOptions';

const RoyaltiesPresentational = ({
  data,
  isAdmin,
  profile,
  query,
  total,
  onFilter,
  selectedInvoices,
  onSelectChange,
  onSelectAll,
  setFilterYear,
  searchAdministrators,
  onInvoiceUpdate,
  onRegularChange,
  onAddPayment,
  onCreateIaf,
}) => {
  const [selectedAll, setSelectedAll] = useState(false);
  const [t] = useTranslation('ENERGY');
  const { offset, limit } = query;
  const [selectedIafTotal, setSelectedIafTotal] = useState(0);
  const [selectedNet, setSelectedNet] = useState(0);
  const [populatedSelected, setPopulatedSelected] = useState([]);
  const [isSupplierProfile, setIsSupplierProfile] = useState(true);

  useEffect(() => {
    setPopulatedSelected(
      selectedInvoices.length
        ? data.filter(({ _id }) => selectedInvoices.includes(_id))
        : data
    );
  }, [selectedInvoices, data]);

  useEffect(() => {
    setSelectedIafTotal(
      +populatedSelected
        .reduce(
          (acc, { net }) => acc + applyPercentage(net, IAF_PERCENTAGE.IAF_15),
          0
        )
        .toFixed(2)
    );
    setSelectedNet(populatedSelected.reduce((acc, { net }) => acc + net, 0));
  }, [populatedSelected]);

  const getTotals = (data) => {
    const netTotals = data.reduce((prev, cur) => prev + cur.net, 0);
    const grossTotals = data.reduce((prev, cur) => prev + cur.gross, 0);
    const paymentsTotals = data.reduce((rowsAcc, { payments }) => {
      return (
        rowsAcc +
          (payments || []).reduce((paymentsAcc, { gross }) => {
            return paymentsAcc + (gross || 0);
          }, 0) || 0
      );
    }, 0);

    return {
      netTotals,
      grossTotals,
      paymentsTotals,
    };
  };

  const onFilterRegularInvoices = (e) => {
    onFilter({
      regular: e.target.checked ? 'false' : 'true',
    });
  };
  const onFilterValidatedInvoices = (e) => {
    onFilter({
      validated: e.target.checked ? 'false' : 'true',
    });
  };
  const onFilterPaidInvoices = (e) => {
    onFilter({
      paid: e.target.checked ? 'true' : 'false',
    });
  };

  const onFilterNoIaf15Invoices = (e) => {
    onFilter({
      noIaf15: e.target.checked ? 'true' : 'false',
    });
  };

  const onFilterAfterApril23 = (e) => {
    onFilter({
      afterApril23: e.target.checked ? 'true' : 'false',
    });
  };

  const onRowClick = ({ _id, regular }) => {
    !regular && onSelectChange(_id);
  };

  const iafValidation = () => {
    if (!populatedSelected.every(({ paid }) => paid)) {
      return { result: false, message: t('ROYALTY:NOT_ALL_PAID_ERROR') };
    }

    if (selectedNet < IAF_REQUIRED_NET) {
      return { result: false, message: t('ROYALTY:TOTAL_NET_ERROR') };
    }

    const firstValue = populatedSelected.at(0).supplier._id;

    if (
      !populatedSelected.every(({ supplier }) => supplier._id === firstValue)
    ) {
      return { result: false, message: t('ROYALTY:IAF_SUPPLIER_ERROR') };
    }

    if (
      !populatedSelected.every(({ accountability }) =>
        isAfter(accountability, ACCOUNTABILITY_LIMIT_DATE)
      )
    ) {
      return { result: false, message: t('ROYALTY:IAF_ACCOUNTABILITY_ERROR') };
    }

    if (populatedSelected.some(({ iaf15Id }) => iaf15Id)) {
      return { result: false, message: t('ROYALTY:IAF_ALREADY_EXISTS') };
    }

    return { result: true };
  };

  useEffect(() => {
    setIsSupplierProfile(profile?.type && profile.type.includes('SUPPLIER'));
  }, [profile]);

  return (
    <Form>
      <TabContent activeTab={'1'}>
        <TabPane tabId="1">
          <Row>
            <Col sm="12" md="6">
              <ContFilterYear className="no-width">
                <Row>
                  <Col xs="6">
                    <Label>{t('COMMON:TITLE_FILTER_ACC_YEAR')}</Label>
                  </Col>
                  <Col xs="6">
                    <FormGroup>
                      <CustomInput
                        type="select"
                        id="selectAccountabilityYear"
                        name="accountabilityYear"
                        value={query && query.accountabilityYear}
                        onChange={({
                          target: { value: accountabilityYear },
                        }) => {
                          onFilter({ accountabilityYear });
                        }}
                      >
                        {generateYearOptions()}
                      </CustomInput>
                    </FormGroup>
                  </Col>
                </Row>
              </ContFilterYear>
            </Col>
            <Col sm="12" md="6">
              <ContFilterYear className="no-width">
                <Row>
                  <Col xs="6">
                    <Label>{t('COMMON:TITLE_FILTER_ACC_MONTH')}</Label>
                  </Col>
                  <Col xs="6">
                    <FormGroup>
                      <CustomInput
                        type="select"
                        id="accountabilityMonth"
                        name="accountabilityMonth"
                        disabled={!query.accountabilityYear}
                        value={query && query.accountabilityMonth}
                        onChange={({
                          target: { value: accountabilityMonth },
                        }) => onFilter({ accountabilityMonth })}
                      >
                        {generateMonthOptions()}
                      </CustomInput>
                    </FormGroup>
                  </Col>
                </Row>
              </ContFilterYear>
            </Col>
          </Row>
          <Row className="compensi-variabili-resp">
            <Col sm="6" md="6" lg="3" xl="3">
              <SearchInput
                query={{ search: query && query.supplier }}
                onFilter={({ search }) => onFilter({ supplier: search })}
                label={t('SEARCH:BY_SUPPLIER')}
                autoFocus={false}
              />
            </Col>
            <Col sm="6" md="6" lg="3" xl="3">
              <SearchInput
                query={{ search: query && query.number }}
                onFilter={({ search }) => onFilter({ number: search })}
                label={t('SEARCH:INVOICE_NUMBER')}
                autoFocus={false}
              />
            </Col>
            <Col sm="6" md="6" lg="3" xl="3">
              <SearchInput
                query={{ search: query && query.customer }}
                onFilter={({ search }) => onFilter({ customer: search })}
                label={t('SEARCH:CONDOMINIUM')}
                autoFocus={false}
              />
            </Col>
            <Col sm="6" md="6" lg="3" xl="3">
              <SearchInput
                query={{ search: query && query.admin }}
                onFilter={({ search }) => onFilter({ admin: search })}
                label={t('SEARCH:ADMINISTRATOR')}
                autoFocus={false}
              />
            </Col>
          </Row>
          <Row style={{ paddingBottom: '20px', paddingTop: '20px' }}>
            <Col>
              <ContFilterCheckbox>
                <CustomInput
                  type="checkbox"
                  id="paid"
                  label="Fatture Pagate"
                  inline
                  checked={query && query.paid === 'true'}
                  onChange={onFilterPaidInvoices}
                />
                <CustomInput
                  type="checkbox"
                  id="regular"
                  label="Fatture da regolarizzare"
                  inline
                  checked={query && query.regular === 'false'}
                  onChange={onFilterRegularInvoices}
                />
                {isAdmin && (
                  <CustomInput
                    type="checkbox"
                    id="validated"
                    label="Fatture da convalidare"
                    inline
                    checked={query && query.validated === 'false'}
                    onChange={onFilterValidatedInvoices}
                  />
                )}
                <CustomInput
                  type="checkbox"
                  id="emittedIaf15"
                  label="Fatture senza Certificazione Avvenuto Pagamento"
                  inline
                  checked={query && query.noIaf15 === 'true'}
                  onChange={onFilterNoIaf15Invoices}
                />
                <CustomInput
                  type="checkbox"
                  id="accountabilityAfterApril23"
                  label="Fatture rendicontate dopo aprile 2023"
                  inline
                  checked={query && query.afterApril23 === 'true'}
                  onChange={onFilterAfterApril23}
                />
              </ContFilterCheckbox>
            </Col>
          </Row>

          {!isSupplierProfile && (
            <div className="button-row">
              {selectedInvoices.length ? (
                <ModalIaf15
                  onCreateIaf={onCreateIaf}
                  validationFn={iafValidation}
                  amount={selectedIafTotal}
                  buttonText={'NEW_IAF_15'}
                />
              ) : (
                <ModalIaf15
                  onCreateIaf={onCreateIaf}
                  validationFn={iafValidation}
                  amount={selectedIafTotal}
                  buttonText={'NEW_IAF_15_FOR_ALL'}
                />
              )}
            </div>
          )}

          <ContAffiliation className="m-t-20">
            <DataTable
              data={data}
              total={total}
              offset={offset}
              limit={limit}
              onFilter={onFilter}
              onRowClick={onRowClick}
              emptyText={t('COMMON:NO_DATA')}
              bottomRow={<TotalTr totals={getTotals(data)} />}
            >
              <RegularCheckbox
                selected={selectedInvoices}
                onSelectChange={onSelectChange}
                title={
                  <input
                    type="checkbox"
                    value={selectedAll}
                    onChange={({ target: { checked } }) => {
                      setSelectedAll(checked);
                      onSelectAll(checked);
                    }}
                  />
                }
              />
              <Field
                title="Fattura di riferimento"
                source="gabettiInvoice.invoiceRef"
                className="text-left"
              />
              <Field
                title="Nome Fornitore"
                source="supplier.businessName"
                className="text-left"
              />
              <Field
                title="Condominio"
                source="customer"
                className="text-left"
              />
              <Field title="Numero Fattura" source="number" />
              <Field title="Codice fiscale / partita IVA" source="vatCode" />
              <DateTimeField
                title="Data Fattura"
                source="date"
                format="DD/MM/YYYY"
              />
              <DateTimeField
                title="Mese Rend."
                source="accountability"
                format="MM/YYYY"
              />
              <DateTimeField
                title="Data prevista incassi"
                source="supposedPaymentDate"
                format="DD/MM/YYYY"
              />
              <BooleanField title="CAP emesso" source="iaf15Id" />
              <Field
                title="Amministratore di condominio"
                source="admin.businessName"
              />
              <CurrencyField
                title="Imponibile"
                source="net"
                class="txt-table-right"
              />
              <CurrencyField
                title="Importo da Incassare"
                source="gross"
                class="txt-table-right"
              />
              <PaymentsDataField
                title="Incassi"
                source="payments"
                type="list"
                field="gross"
                class="txt-table-right"
              />
              <PaidBadge title="Pagato" source="paid" />
              <RegulariseField
                title="Regolarizzata"
                source="regular"
                onRegularChange={onRegularChange}
                showButton
              />
              <ModalEditInvoice
                title="Modifica"
                onConfirm={onInvoiceUpdate}
                searchAdministrators={searchAdministrators}
              />
              <ModalAddPayment
                title="Aggiungi incasso"
                onAddPayment={onAddPayment}
              />
            </DataTable>

            <ContPaginationPage>
              <p>{t('COMMON:PAGINATION_PER_PAGE')}</p>

              <FormGroup>
                <CustomInput
                  type="select"
                  className="pagination-select"
                  id="perPage"
                  name="perPage"
                  value={query.limit}
                  onChange={({ target: { value: limit } }) => {
                    onFilter({ limit });
                  }}
                >
                  {generatePaginationOptions()}
                </CustomInput>
              </FormGroup>
            </ContPaginationPage>
          </ContAffiliation>
        </TabPane>
      </TabContent>
    </Form>
  );
};

export default RoyaltiesPresentational;
