import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAlert } from 'react-alert';
import {
  TabContent,
  TabPane,
  Form,
  Row,
  Col,
  FormGroup,
  Label,
  CustomInput,
} from 'reactstrap';
import {
  ContAffiliation,
  H5Styled,
  ContFilterYear,
  BtnOutlineOrange,
  BtnOutlineGreen,
} from '../../../styled-components';
import CSVRevenuesExport from '../FinancialReports/CSVRevenuesExport';
import SearchInput from '../../../commons/SearchInput';
import generateMonthOptions from '../../../lib/helpers/generateMonthOptions';
import RoyaltyTable from '../FinancialReports/RoyaltyTable';
import TotalRevenuesRow from '../FinancialReports/TotalTrRevenue';
import ReportingModal from '../../../commons/ReportingModal';
import generateYearOptions from '../../../lib/helpers/generateYearOptions';
import { useAuth } from '../../../commons/Auth';
import { isCheckable } from '../../../lib/helpers/isCheckboxCheckable';

const VariableRevenuesDetailPresentational = ({
  data = {},
  profile,
  query,
  onFilter,
  onAddBonus,
}) => {
  const [t] = useTranslation('CONTACTS');
  const [{ isAdmin }] = useAuth();
  const { categories = {} } = data;
  const [isOpen, setIsOpen] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const alert = useAlert();
  const [amount, setAmount] = useState(0);
  const { variableRevenues = {} } = categories;
  const { data: dataRev = [] } = variableRevenues;
  const origin = window.location.origin;
  let hash = window.location.hash.split('/');
  hash.pop();
  const prevPath =
    origin +
    '/' +
    hash.join('/') +
    `${query.year ? '?year=' + query.year : ''}`;

  const getVariableRevenuesTotals = (dataRev) => {
    const cashedTotals = dataRev.reduce((prev, cur) => {
      const { payments } = cur || {};
      return (
        prev +
        (payments || []).reduce((acc, payment) => {
          return acc + ((payment && payment.gross) || 0);
        }, 0)
      );
    }, 0);
    const netTotals = dataRev.reduce((prev, cur) => prev + cur.net, 0);
    const grossTotals = dataRev.reduce((prev, cur) => prev + cur.gross, 0);
    const masterShareTotals = dataRev.reduce(
      (prev, cur) => prev + cur.masterShare,
      0
    );
    const earnedMasterShareTotals = dataRev.reduce(
      (prev, cur) => prev + cur.earnedMasterShare,
      0
    );
    const billableMasterShareTotals = dataRev.reduce(
      (prev, cur) => prev + cur.billableMasterShare,
      0
    );
    return {
      cashedTotals,
      netTotals,
      grossTotals,
      masterShareTotals,
      earnedMasterShareTotals,
      billableMasterShareTotals,
    };
  };

  const toggle = ({ selectAll: toggleSelectAll = false } = {}) => {
    if (isOpen) {
      setSelectAll(false);
      return setIsOpen(false);
    }

    setSelectAll(toggleSelectAll);
    const checkedRows = toggleSelectAll
      ? dataRev.filter(isCheckable)
      : dataRev.filter(({ checked }) => checked);

    if (checkedRows.length) {
      const billableTotal = checkedRows.reduce(
        (acc, { billableMasterShare = 0 }) => acc + billableMasterShare,
        0
      );
      const alreadyBilled = checkedRows.reduce((acc, { iafs = [] }) => {
        return (
          acc +
            iafs.reduce((acc, iaf) => {
              const currentIafInvoice = iaf.invoices?.[0] || {};
              const currentIafInvoiceAmount =
                currentIafInvoice.billableMasterShare ||
                currentIafInvoice.earnedMasterShare ||
                0;
              return acc + currentIafInvoiceAmount || 0;
            }, 0) || 0
        );
      }, 0);
      setAmount(+(billableTotal - alreadyBilled).toFixed(2));
      setIsOpen(!isOpen);
    } else {
      const message = toggleSelectAll
        ? t('ROYALTY:NO_ROW_IAF_SELECTABLE')
        : t('ROYALTY:SELECT_AT_LEAST_ONE');
      alert.error(message);
    }
  };

  const onSubmit = (formData) => {
    const checkedRows = selectAll
      ? dataRev.filter(isCheckable)
      : dataRev.filter(({ checked }) => checked);

    const data = {
      ...formData,
      iafType: 'INVOICES',
      invoices: checkedRows.map(
        ({
          _id,
          accountability,
          admin,
          billableMasterShare,
          masterShare,
          net,
          supplier,
          customer,
          date,
          masterSharePerc,
          number,
        }) => ({
          _id,
          accountability: accountability || undefined,
          admin,
          billableMasterShare,
          masterShare,
          net,
          supplier,
          customer,
          date,
          masterSharePerc,
          number,
        })
      ),
      amount,
    };
    onAddBonus(data);
    toggle();
  };

  return (
    <Form onSubmit={(e) => e.preventDefault()}>
      <TabContent activeTab={'1'}>
        <TabPane tabId="1">
          <ContFilterYear>
            <Row>
              <Col xs="6">
                <Label>{t('COMMON:TITLE_FILTER_ACC_YEAR')}</Label>
              </Col>
              <Col xs="6">
                <FormGroup>
                  <CustomInput
                    type="select"
                    id="selectYear"
                    name="year"
                    value={query && query.year}
                    onChange={({ target: { value: year } }) =>
                      onFilter({ year })
                    }
                  >
                    {generateYearOptions()}
                  </CustomInput>
                </FormGroup>
              </Col>
            </Row>
          </ContFilterYear>
          <div
            style={{
              fontWeight: 700,
              color: '#333333',
              marginBottom: '20px',
              fontSize: '20px',
            }}
          >
            <a
              style={{
                color: '#b93544',
              }}
              href={prevPath}
            >
              Rendicontazioni
            </a>{' '}
            / <a>{t('ROYALTY:TITLE')}</a>
          </div>
          <ContAffiliation key={1}>
            <Row>
              <Col xs="6">
                <H5Styled>{t('ROYALTY:TITLE')}</H5Styled>
              </Col>
              <Col xs="6" className="text-right">
                {isAdmin && (
                  <BtnOutlineGreen
                    type="button"
                    onClick={toggle}
                    style={{ marginRight: '16px' }}
                  >
                    {t('ROYALTY:INVITE_TO_INVOICE')}
                  </BtnOutlineGreen>
                )}
                {isAdmin && (
                  <BtnOutlineGreen
                    type="button"
                    onClick={() => toggle({ selectAll: true })}
                    style={{ marginRight: '16px' }}
                  >
                    {t('ROYALTY:INVITE_TO_INVOICE_ALL')}
                  </BtnOutlineGreen>
                )}
                <CSVRevenuesExport
                  data={dataRev}
                  year={query && query.year}
                  businessName={profile ? profile.businessName : ''}
                >
                  <BtnOutlineOrange type="button" className="uppercase">
                    {t('COMMON:CSV_EXPORT')}
                  </BtnOutlineOrange>
                </CSVRevenuesExport>
              </Col>
            </Row>
            <br />
            <Row className="compensi-variabili-resp">
              <Col sm="6" md="6" lg="6" xl="4">
                <SearchInput
                  query={{ search: query && query.supplier }}
                  onFilter={({ search }) => onFilter({ supplier: search })}
                  label={t('SEARCH:BY_SUPPLIER')}
                  autoFocus={false}
                />
              </Col>
              <Col sm="6" md="6" lg="6" xl="4">
                <SearchInput
                  query={{ search: query && query.number }}
                  onFilter={({ search }) => onFilter({ number: search })}
                  label={t('SEARCH:INVOICE_NUMBER')}
                  autoFocus={false}
                />
              </Col>
              <Col sm="6" md="6" lg="6" xl="4">
                <SearchInput
                  query={{ search: query && query.admin }}
                  onFilter={({ search }) => onFilter({ admin: search })}
                  label={t('SEARCH:ADMINISTRATOR')}
                  autoFocus={false}
                />
              </Col>
              {/*<Col sm="6" md="6" lg="6" xl="4">*/}
              {/*  <ContFilterYear className="no-width">*/}
              {/*    <Row>*/}
              {/*      <Col xs="6">*/}
              {/*        <Label>{t('COMMON:TITLE_FILTER_ACC_YEAR')}</Label>*/}
              {/*      </Col>*/}
              {/*      <Col xs="6">*/}
              {/*        <FormGroup>*/}
              {/*          <CustomInput*/}
              {/*            type="select"*/}
              {/*            id="selectAccountabilityYear"*/}
              {/*            name="accountabilityYear"*/}
              {/*            value={query && query.accountabilityYear}*/}
              {/*            onChange={({*/}
              {/*              target: { value: accountabilityYear },*/}
              {/*            }) => {*/}
              {/*              onFilter({ accountabilityYear });*/}
              {/*            }}*/}
              {/*          >*/}
              {/*            {generateYearOptions(true)}*/}
              {/*          </CustomInput>*/}
              {/*        </FormGroup>*/}
              {/*      </Col>*/}
              {/*    </Row>*/}
              {/*  </ContFilterYear>*/}
              {/*</Col>*/}
              <Col sm="6" md="6" lg="6" xl="4">
                <ContFilterYear className="no-width">
                  <Row>
                    <Col xs="6">
                      <Label>{t('COMMON:TITLE_FILTER_ACC_MONTH')}</Label>
                    </Col>
                    <Col xs="6">
                      <FormGroup>
                        <CustomInput
                          type="select"
                          id="accountabilityMonth"
                          name="accountabilityMonth"
                          // disabled={!query.accountabilityYear}
                          disabled={!query.year}
                          value={
                            query && query.accountabilityMonth === null
                              ? ''
                              : query.accountabilityMonth
                          }
                          onChange={({
                            target: { value: accountabilityMonth },
                          }) => onFilter({ accountabilityMonth })}
                        >
                          {generateMonthOptions()}
                        </CustomInput>
                      </FormGroup>
                    </Col>
                  </Row>
                </ContFilterYear>
              </Col>
            </Row>
            <br />
            <RoyaltyTable
              data={dataRev || []}
              onFilter={() => {}}
              showEmptyTableMessage={true}
              bottomRow={
                <TotalRevenuesRow
                  totals={getVariableRevenuesTotals(dataRev)}
                  route="variable-revenues"
                  activeRoute={false}
                />
              }
            />
          </ContAffiliation>
        </TabPane>
      </TabContent>
      <ReportingModal
        toggle={toggle}
        isOpen={isOpen}
        onSubmit={onSubmit}
        amount={amount}
        query={query}
      />
    </Form>
  );
};

export default VariableRevenuesDetailPresentational;
