import axios from 'axios';

export const get = async (props) => {
  const {
    token,
    profileId,
    year,
    accountabilityYear,
    accountabilityMonth,
    supplier,
    admin,
    number,
    insuranceAdmin,
    insuranceNumber,
    insuranceSupplier,
    studioInsuranceAdmin,
    studioInsuranceNumber,
    studioInsuranceSupplier,
    insuranceAccountabilityYear,
    insuranceAccountabilityMonth,
    studioInsuranceAccountabilityYear,
    studioInsuranceAccountabilityMonth,
  } = props;

  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/reports/${profileId}`,
      headers: {
        Authorization: token,
      },
      params: {
        year,
        accountabilityYear,
        accountabilityMonth,
        supplier,
        admin,
        number,
        insuranceAdmin,
        insuranceNumber,
        insuranceSupplier,
        studioInsuranceAdmin,
        studioInsuranceNumber,
        studioInsuranceSupplier,
        insuranceAccountabilityMonth,
        insuranceAccountabilityYear,
        studioInsuranceAccountabilityYear,
        studioInsuranceAccountabilityMonth,
      },
    });

    if (response instanceof Error) {
      return { error: response };
    }

    return response;
  } catch (error) {
    return { error };
  }
};

export const inviteToInvoice = async ({ token, data }) => {
  try {
    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/invite-to-invoice`,
      data,
      headers: {
        Authorization: token,
      },
    });

    if (response instanceof Error) {
      return { error: response };
    }

    return response;
  } catch (error) {
    return { error };
  }
};

export const createBonus = async ({ token, bonus }) => {
  try {
    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/bonuses`,
      data: bonus,
      headers: {
        Authorization: token,
      },
    });

    if (response instanceof Error) {
      return { error: response };
    }

    return response;
  } catch (error) {
    return { error };
  }
};

export const deleteBonus = async ({ token, _id }) => {
  try {
    const response = await axios({
      method: 'delete',
      url: `${process.env.REACT_APP_API_URL}/bonuses/${_id}`,
      headers: {
        Authorization: token,
      },
    });

    if (response instanceof Error) {
      return { error: response };
    }

    return response;
  } catch (error) {
    return { error };
  }
};

export const updateBonus = async ({ token, data = {} }) => {
  const {
    _id,
    description = '',
    date = '',
    invoiceRef = '',
    accountability,
  } = data;

  try {
    const response = await axios({
      method: 'put',
      url: `${process.env.REACT_APP_API_URL}/bonuses/${_id}`,
      headers: {
        Authorization: token,
      },
      data: { description, date, invoiceRef, accountability },
    });

    if (response instanceof Error) {
      return { error: response };
    }

    return response;
  } catch (error) {
    return { error };
  }
};
