import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import styled from 'styled-components';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  FormGroup,
  Label,
  InputGroup,
  Input,
  InputGroupAddon,
  Form,
  FormText,
  CustomInput,
} from 'reactstrap';
import { BtnOutlineRed, BtnOutlineGreen } from '../../../styled-components';
import DateInput from '../../../commons/DateInput';
import generateYearOptions from '../../../lib/helpers/generateYearOptions';

const Icon = styled.i`
  color: #ff8155;
  font-size: 1.3rem;
  cursor: pointer;
`;

const ModifyBonusModal = ({ onConfirm, data }) => {
  const [t] = useTranslation('CONTACTS');
  const [modal, setModal] = useState(false);

  const [type, setType] = useState('');
  const [description, setDescription] = useState('');
  const [invoiceRef, setInvoiceRef] = useState('');
  const [date, setDate] = useState('');
  const [accountability, setAccountability] = useState('');

  const [errors, setErrors] = useState({
    description: false,
    invoiceRef: false,
    date: false,
    amount: false,
    accountability: false,
  });

  useEffect(() => {
    setType(data.type);
    setDescription(data.description);
    setInvoiceRef(data.invoiceRef);
    setDate(data.date);
    setAccountability(data.accountability);
  }, [data]);

  const toggle = () => setModal(!modal);

  function onAddConfirm() {
    if (!description || !date || !accountability) {
      const errors = {};

      errors.description = !description;
      errors.date = !date;
      errors.accountability = !accountability;

      return setErrors(errors);
    }

    onConfirm({
      ...data,
      type,
      description,
      invoiceRef,
      date,
      accountability,
    });

    toggle();
  }

  return (
    <React.Fragment>
      <Icon className="fa fa-pen" onClick={toggle} />
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          {t('MODAL_SUMMARY_TITLE_UPDATE')}
        </ModalHeader>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            onAddConfirm();
          }}
        >
          <ModalBody>
            <div className="max-width-form">
              <Row>
                <Col xs="12">
                  <FormGroup>
                    <Label>{t('LABEL_DESCRIPTION')} *</Label>
                    <InputGroup>
                      <Input
                        type="text"
                        name="description"
                        value={description}
                        onChange={({ target }) => setDescription(target.value)}
                      />
                    </InputGroup>
                    {errors.description && (
                      <FormText className="error-message">
                        {t('COMMON:REQUIRED')}
                      </FormText>
                    )}
                  </FormGroup>
                </Col>
              </Row>
            </div>

            <div className="max-width-form">
              <Row>
                <Col xs="12">
                  <FormGroup>
                    <Label>{t('LABEL_INVOICE_REF')}</Label>
                    <InputGroup>
                      <Input
                        type="textarea"
                        name="invoiceRef"
                        value={invoiceRef}
                        onChange={({ target }) => setInvoiceRef(target.value)}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
            </div>

            <div className="max-width-form">
              <Row>
                <Col xs="12">
                  <FormGroup>
                    <Label>{t('ROYALTY:IAF_ACCOUNTABILITY')}</Label>
                    <CustomInput
                      type="select"
                      id="selectAccountability"
                      name="accountability"
                      value={accountability}
                      onChange={({ target: { value } }) =>
                        setAccountability(value)
                      }
                    >
                      {generateYearOptions()}
                    </CustomInput>
                    {errors.accountability && (
                      <FormText className="error-message">
                        {t('COMMON:REQUIRED')}
                      </FormText>
                    )}
                  </FormGroup>
                </Col>
              </Row>
            </div>

            <div className="max-width-form">
              <Row>
                <Col xs="12">
                  <FormGroup>
                    <Label>{t('ROYALTY:DATE')} *</Label>
                    <DateInput
                      name="date"
                      value={date}
                      onChange={(date) => setDate(date)}
                    />
                    {errors.date && (
                      <FormText className="error-message">
                        {t('COMMON:REQUIRED')}
                      </FormText>
                    )}
                  </FormGroup>
                </Col>
              </Row>
            </div>
          </ModalBody>
          <ModalFooter>
            <BtnOutlineRed type="button" className="uppercase" onClick={toggle}>
              {t('COMMON:CANCEL')}
            </BtnOutlineRed>
            <BtnOutlineGreen type="submit" className="uppercase">
              {t('COMMON:CONFIRM')}
            </BtnOutlineGreen>
          </ModalFooter>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default ModifyBonusModal;
