import './Insurances.scss';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../commons/Auth';
import { useAlert } from 'react-alert';
import {
  useQueryParams,
  StringParam,
  withDefault,
  NumberParam,
} from 'use-query-params';
import { useLoading } from '../../commons/Loading';
import MyInsurancesPresentational from './MyInsurancesPresentational';

function MyInsurances({ profile }) {
  const [insurances, setInsurances] = useState([]);
  const [{ token } = {}] = useAuth();
  const [t] = useTranslation();
  const { showLoading, hideLoading } = useLoading();
  const alert = useAlert();

  const [query, setQuery] = useQueryParams({
    all: withDefault(StringParam, 'false'),
    insuranceNumber: withDefault(StringParam, ''),
    adminName: withDefault(StringParam, ''),
    contractor: withDefault(StringParam, ''),
    year: withDefault(NumberParam, new Date().getFullYear()),
    accountabilityYear: NumberParam,
    accountabilityMonth: NumberParam,
  });

  const {
    all,
    adminName,
    contractor,
    insuranceNumber,
    year,
    accountabilityMonth,
    accountabilityYear,
  } = query;

  useEffect(() => {
    fetchInsurances();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    all,
    adminName,
    contractor,
    insuranceNumber,
    year,
    accountabilityMonth,
    accountabilityYear,
  ]);

  const fetchInsurances = async () => {
    showLoading();

    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/insurances/${profile._id}`,
      {
        headers: { Authorization: token },
        params: query,
      }
    );

    if (response instanceof Error) {
      hideLoading();
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    const { data = [] } = response;
    setInsurances(data);
    hideLoading();
  };

  const searchAdministrators = async (value) => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/profiles/admins`,
      {
        headers: { Authorization: token },
        params: { search: value },
      }
    );

    if (response instanceof Error) {
      alert.error(t('COMMON:GENERIC_ERROR'));
      return [];
    }

    const { data = [] } = response;
    return data;
  };

  const onNewInsuranceConfirm = async (data) => {
    showLoading();

    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/insurances`,
      data,
      {
        headers: { Authorization: token },
      }
    );
    hideLoading();

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    alert.success(t('COMMON:SUCCESS_UPDATE'));
    fetchInsurances();
  };

  const onInsuranceUpdate = async (data) => {
    const { _id } = data;
    showLoading();

    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/insurances/${_id}`,
      data,
      {
        headers: { Authorization: token },
      }
    );
    hideLoading();

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    alert.success(t('COMMON:SUCCESS_UPDATE'));
    fetchInsurances();
  };

  const onAddPayment = async ({ insuranceId, data }) => {
    showLoading();

    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/insurances/${insuranceId}/payments`,
      data,
      {
        headers: { Authorization: token },
      }
    );
    hideLoading();

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    alert.success(t('COMMON:SUCCESS_UPDATE'));
    fetchInsurances();
  };

  const onDeletePayment = async ({ insuranceId, paymentIndex }) => {
    showLoading();

    const response = await axios.delete(
      `${process.env.REACT_APP_API_URL}/insurances/${insuranceId}/payments/${paymentIndex}`,
      {
        headers: { Authorization: token },
      }
    );
    hideLoading();

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    alert.success(t('COMMON:SUCCESS_UPDATE'));
    fetchInsurances();
  };

  const onValidateInsurance = async (insuranceId) => {
    showLoading();

    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/insurances/${insuranceId}/validate`,
      { validated: true },
      {
        headers: { Authorization: token },
      }
    );
    hideLoading();

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    alert.success(t('COMMON:SUCCESS_UPDATE'));
    fetchInsurances();
  };

  const onInsuranceDelete = async (insuranceId) => {
    showLoading();

    const response = await axios.delete(
      `${process.env.REACT_APP_API_URL}/insurances/${insuranceId}`,
      {
        headers: { Authorization: token },
      }
    );
    hideLoading();

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    alert.success(t('COMMON:SUCCESS_UPDATE'));
    fetchInsurances();
  };

  function onFilter(queryDiff) {
    if (queryDiff && typeof queryDiff.search === 'string') {
      localStorage.setItem('myInsurancesSearch', queryDiff.search);
    }
    if (
      (queryDiff.accountabilityYear &&
        queryDiff.accountabilityYear !== accountabilityYear) ||
      queryDiff.accountabilityYear === ''
    ) {
      queryDiff.accountabilityMonth = '';
    }
    setQuery(queryDiff);
  }

  const onInsurancesUpload = async (formData) => {
    showLoading();
    try {
      const response = await axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}/insurances/import`,
        data: formData,
        headers: {
          Authorization: token,
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response instanceof Error) {
        return { error: response };
      }

      return response;
    } catch (error) {
      return { error };
    } finally {
      hideLoading();
    }
  };

  return (
    <MyInsurancesPresentational
      data={insurances}
      query={query}
      onFilter={onFilter}
      searchAdministrators={searchAdministrators}
      onNewInsuranceConfirm={onNewInsuranceConfirm}
      onInsuranceUpdate={onInsuranceUpdate}
      onAddPayment={onAddPayment}
      onDeletePayment={onDeletePayment}
      onValidateInsurance={onValidateInsurance}
      onInsuranceDelete={onInsuranceDelete}
      onInsurancesUpload={onInsurancesUpload}
      onInsurancesUploadSuccess={fetchInsurances}
    />
  );
}

export default MyInsurances;
