import React from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import {
  DataTable,
  Field,
  CurrencyField,
  DateTimeField,
  PaymentsDataField,
  BilledField,
} from '../../../commons/DataTable';
import CustomCheckbox from '../../../commons/Checkboxes/CustomCheckboxes';
import { useAuth } from '../../../commons/Auth';

function RoyaltyTable({
  dataTable,
  data,
  total = 0,
  query = {},
  onFilter,
  isLoading,
  bottomRow,
  showEmptyTableMessage,
  truncateTable,
  onSelectChange,
  allowedRoles = [],
}) {
  const [t] = useTranslation('COMMON');
  const [{ isAdmin }] = useAuth();
  const { offset } = query;

  const onCheck = (report) => {
    if (!allowedRoles.includes('superadmin')) {
      return;
    }
    onSelectChange(report);
  };

  return (
    <DataTable
      offset={offset}
      total={total}
      dataTable={dataTable}
      data={data}
      isLoading={isLoading}
      loadingText={t('LOADING')}
      emptyText={t('NO_DATA')}
      onFilter={onFilter}
      bottomRow={bottomRow}
      showEmptyTableMessage={showEmptyTableMessage}
    >
      {!truncateTable &&
        (isAdmin ? (
          <CustomCheckbox
            onSelectChange={onCheck}
            onClick={(e) => e.stopPropagation()}
          />
        ) : (
          <></>
        ))}
      {!truncateTable && (
        <Field
          title="Nome Fornitore"
          source="supplier.businessName"
          className="text-left"
        />
      )}
      {!truncateTable && <Field title="Numero Fattura" source="number" />}
      {!truncateTable && (
        <DateTimeField title="Data Fattura" source="date" format="DD/MM/YYYY" />
      )}
      {!truncateTable && (
        <DateTimeField
          title="Mese Rend."
          source="accountability"
          format="MM/YYYY"
        />
      )}
      {!truncateTable && (
        <Field
          title="Amministratore di Condominio"
          source="admin.businessName"
        />
      )}
      <CurrencyField
        title="Imponibile"
        source="net"
        className="txt-table-right"
      />
      <CurrencyField
        title="Importo da Incassare"
        source="gross"
        className="txt-table-right"
      />
      <PaymentsDataField title="Pagato" source="payments" type="total" />
      <CurrencyField
        title="Quota master (3%)"
        source="masterShare"
        className="txt-table-right"
      />
      <CurrencyField
        title="Quota master maturata"
        source="earnedMasterShare"
        className="txt-table-right"
      />
      <CurrencyField
        title="Diritto a Fatturare"
        source="billableMasterShare"
        className="txt-table-right"
      />
      {(!truncateTable && isAdmin && (
        <BilledField title="Invitato a Fatturare" className="txt-table-right" />
      )) || <></>}
    </DataTable>
  );
}

export default withRouter(RoyaltyTable);
