import moment from 'moment';

export const formatDate = (date) =>
  date ? moment(date).format('DD-MM-YYYY') : '';

const { Intl } = window || {};
let locale;

if (Intl && typeof Intl === 'object') {
  const { language } = navigator || {};
  locale = language;
}

export const formatCurrency = (amount) => {
  if (isNaN(amount)) amount = 0;

  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: 'EUR',
  }).format(amount);
};

export const formatCurrencyForCSV = (amount) => {
  if (isNaN(amount)) amount = 0;

  return (amount || 0).toFixed(2).replace('.', ',');
};
