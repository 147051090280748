import React, { useState, useEffect } from 'react';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import AffiliationsPresentational from './AffiliationsPresentational';
import { list as listProfiles } from '../../../lib/fetch/profiles';
import {
  list as listAffiliations,
  create,
  save,
  renew,
  removeAffiliation,
  removePayment,
} from '../../../lib/fetch/affiliations';
import { useLoading } from '../../../commons/Loading';
import { useAuth } from '../../../commons/Auth';

const Affiliations = (props) => {
  const [{ token } = {}] = useAuth();
  const [affiliations, setAffiliations] = useState([]);
  const alert = useAlert();
  const [t] = useTranslation('DEALS');
  const { showLoading, hideLoading } = useLoading();

  useEffect(() => {
    fetchAffiliations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchAffiliations = async () => {
    showLoading();

    const { error, data } = await listAffiliations({
      profileId: props.id,
      token,
    });

    if (error) {
      hideLoading();
      alert.error(t('COMMON:GENERIC_ERROR'));
      return;
    }

    setAffiliations(data);
    hideLoading();
  };

  async function getProfiles(value) {
    const { error, data } = await listProfiles({
      search: value,
      master: true,
      token,
    });

    if (error) {
      alert.error(t('COMMON:GENERIC_ERROR'));
      return;
    }

    return data;
  }

  async function createAffiliation(data) {
    showLoading();

    const { error, data: affiliation } = await create({ data, token });

    if (error) {
      hideLoading();

      alert.error(
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
          t('COMMON:GENERIC_ERROR')
      );

      return;
    }

    setAffiliations([affiliation, ...affiliations]);
    hideLoading();
    alert.success(t('COMMON:SUCCESS_CREATE'));
  }

  async function updateAffiliation({ id, data }) {
    showLoading();

    const { error } = await save({ id, data, token });

    if (error) {
      hideLoading();

      alert.error(
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
          t('COMMON:GENERIC_ERROR')
      );
      throw error;
    }

    fetchAffiliations();
    alert.success(t('COMMON:SUCCESS_UPDATE'));
  }

  async function renewAffiliation({ id }) {
    showLoading();

    const { error } = await renew({ id, token });

    if (error) {
      hideLoading();

      alert.error(
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
          t('COMMON:GENERIC_ERROR')
      );

      return;
    }

    fetchAffiliations();
    alert.success(t('COMMON:SUCCESS_RENEW'));
  }

  async function deleteAffiliation(id) {
    showLoading();

    const { error } = await removeAffiliation({ id, token });

    if (error) {
      hideLoading();

      alert.error(
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
          t('COMMON:GENERIC_ERROR')
      );

      return;
    }

    fetchAffiliations();
    alert.success(t('COMMON:SUCCESS_DELETE'));
  }

  async function deletePayment(affiliationId, paymentIndex) {
    showLoading();

    const { error } = await removePayment({
      affiliationId,
      paymentIndex,
      token,
    });

    if (error) {
      hideLoading();

      alert.error(
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
          t('COMMON:GENERIC_ERROR')
      );

      return;
    }

    fetchAffiliations();
    alert.success(t('COMMON:SUCCESS_DELETE'));
  }

  return (
    <AffiliationsPresentational
      createAffiliation={createAffiliation}
      updateAffiliation={updateAffiliation}
      affiliations={affiliations}
      getProfiles={getProfiles}
      renewAffiliation={renewAffiliation}
      deleteAffiliation={deleteAffiliation}
      deletePayment={deletePayment}
      {...props}
    />
  );
};

export default Affiliations;
