import React from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { DataTable, Field } from '../../commons/DataTable';
import { BadgeField } from '../../commons/DataTableFields';

function ProfilesTable({
  history,
  data,
  total = 0,
  query = {},
  onFilter,
  isLoading,
}) {
  const [t] = useTranslation('COMMON');
  const { limit, offset } = query;

  return (
    <DataTable
      limit={limit}
      offset={offset}
      total={total}
      data={data}
      isLoading={isLoading}
      loadingText={t('LOADING')}
      emptyText={t('NO_DATA')}
      onFilter={onFilter}
      onRowClick={({ _id } = {}) =>
        history.push({
          pathname: `/profiles/${_id}`,
          state: { pageBack: 'profiles' },
        })
      }
    >
      <BadgeField title="Master" source="master" />
      <Field
        title="Ragione Sociale"
        source="businessName"
        className="text-left"
      />
      <Field title="Nome Cognome" source="name" />
      <Field title="Tipologia" source="type" className="type" />
      <Field title="P.IVA / CF." source="taxCode" />
      <BadgeField title="Affiliazione Attiva" source="activeAffiliation" />
      <BadgeField title="Utente attivo" source="isActive" />
    </DataTable>
  );
}

export default withRouter(ProfilesTable);
