import React from 'react';
import { formatCurrency } from '../../lib/helpers/formatters';
import { getSection } from '../../lib/helpers/getReportSection';

function BilledField({ data = {} }) {
  let { iafs = [] } = data;
  iafs = iafs || [];
  const section = getSection(data);
  let totalPaid = 0;

  switch (section) {
    case 'gnet_admin':
    case 'suppliers':
      totalPaid = iafs.reduce(
        (acc, iaf = {}) => acc + (iaf[section][0]?.iafRevenue || 0),
        0
      );
      break;
    case 'insurances':
    case 'insurancesStudio':
    case 'invoices':
      totalPaid = iafs.reduce(
        (acc, iaf = {}) =>
          acc +
          (iaf[section][0]?.billableMasterShare ||
            iaf[section][0]?.earnedMasterShare ||
            0),
        0
      );
      break;
    default:
      break;
  }

  if (typeof Number(totalPaid) !== 'number') return <>{formatCurrency(0)}</>;

  return <>{formatCurrency(totalPaid)}</>;
}

export default BilledField;
