import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  useQueryParams,
  StringParam,
  withDefault,
  NumberParam,
} from 'use-query-params';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../commons/Auth';
import { useAlert } from 'react-alert';
import { useLoading } from '../../../commons/Loading';
import RoyaltiesPresentational from './RoyaltiesPresentational';
import { IAF_TYPES, IAF_PERCENTAGE } from '../../../lib/constants';

const { REACT_APP_DEFAULT_PAGINATION_LIMIT } = process.env;

const Royalties = ({ profile = {} }) => {
  const [invoices, setInvoices] = useState([]);
  const [total, setTotal] = useState(0);
  const [{ isAdmin, token } = {}] = useAuth();
  const [t] = useTranslation();
  const { showLoading, hideLoading } = useLoading();
  const alert = useAlert();

  const [selectedInvoices, setSelectedInvoices] = useState([]);

  const [query, setQuery] = useQueryParams({
    admin: withDefault(StringParam, ''),
    customer: withDefault(StringParam, ''),
    number: withDefault(StringParam, ''),
    supplier: withDefault(StringParam, ''),
    offset: NumberParam,
    limit: withDefault(NumberParam, +REACT_APP_DEFAULT_PAGINATION_LIMIT || 20),
    accountabilityYear: withDefault(NumberParam, new Date().getFullYear()),
    accountabilityMonth: NumberParam,
    regular: withDefault(StringParam, ''),
    validated: withDefault(StringParam, ''),
    paid: withDefault(StringParam, ''),
    noIaf15: withDefault(StringParam, ''),
    afterApril23: withDefault(StringParam, ''),
  });

  const {
    limit,
    offset,
    search,
    accountabilityYear,
    accountabilityMonth,
    admin,
    customer,
    number,
    supplier,
    regular,
    validated,
    paid,
    noIaf15,
    afterApril23,
  } = query;

  useEffect(() => {
    if (profile._id) fetchInvoices();
  }, [
    limit,
    offset,
    search,
    accountabilityYear,
    accountabilityMonth,
    profile._id,
    admin,
    customer,
    number,
    supplier,
    regular,
    validated,
    paid,
    noIaf15,
    afterApril23,
  ]);

  const fetchInvoices = async () => {
    showLoading();

    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/invoices/${profile._id}`,
      {
        headers: { Authorization: token },
        params: {
          limit,
          offset,
          search,
          accountabilityYear,
          accountabilityMonth,
          admin,
          customer,
          number,
          supplier,
          regular,
          validated,
          paid,
          noIaf15,
          afterApril23,
        },
      }
    );

    if (response instanceof Error) {
      hideLoading();
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    const { headers, data } = response;

    setInvoices(data);
    setTotal(+headers['x-total-count']);
    hideLoading();
  };

  const onRegularChange = async (invoiceId, regular = false) => {
    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/invoices/${invoiceId}/regularize`,
      { regular },
      {
        headers: { Authorization: token },
      }
    );

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    alert.success(t('COMMON:SUCCESS_REGULARIZE'));
    fetchInvoices();
  };

  const onInvoiceUpdate = async (data) => {
    const { _id } = data;
    showLoading();

    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/invoices/${_id}`,
      data,
      {
        headers: { Authorization: token },
      }
    );
    hideLoading();

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    alert.success(t('COMMON:SUCCESS_UPDATE'));
    fetchInvoices();
  };

  const onAddPayment = async ({ invoiceId, data }) => {
    showLoading();

    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/invoices/${invoiceId}/payments`,
      data,
      {
        headers: { Authorization: token },
      }
    );
    hideLoading();

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    alert.success(t('COMMON:SUCCESS_UPDATE'));
    fetchInvoices();
  };

  const searchAdministrators = async (value) => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/profiles/admins`,
      {
        headers: { Authorization: token },
        params: { search: value },
      }
    );

    if (response instanceof Error) {
      alert.error(t('COMMON:GENERIC_ERROR'));
      return [];
    }

    const { data = [] } = response;
    return data;
  };

  function onFilter(queryDiff) {
    if (queryDiff && typeof queryDiff.search === 'string') {
      localStorage.setItem('myCompensiVariabiliSearch', queryDiff.search);
    }
    if (
      (queryDiff.search && queryDiff.search !== search) || // first search
      (search && queryDiff.search === '') || // search deleted
      (queryDiff.admin && queryDiff.admin !== admin) || // first search
      (admin && queryDiff.admin === '') || // search deleted
      (queryDiff.customer && queryDiff.customer !== customer) || // first search
      (customer && queryDiff.customer === '') || // search deleted
      (queryDiff.number && queryDiff.number !== number) || // first search
      (number && queryDiff.number === '') || // search deleted
      (queryDiff.supplier && queryDiff.supplier !== supplier) || // first search
      (supplier && queryDiff.supplier === '') || // search deleted
      (queryDiff.regular && queryDiff.regular !== regular) ||
      (queryDiff.validated && queryDiff.validated !== validated) ||
      (queryDiff.paid && queryDiff.paid !== paid) ||
      (queryDiff.accountabilityMonth &&
        queryDiff.accountabilityMonth !== accountabilityMonth) ||
      queryDiff.accountabilityMonth === ''
    ) {
      queryDiff.offset = 0; // return to first page
    }
    if (
      (queryDiff.accountabilityYear &&
        queryDiff.accountabilityYear !== accountabilityYear) ||
      queryDiff.accountabilityYear === ''
    ) {
      queryDiff.accountabilityMonth = '';
      queryDiff.offset = 0;
    }
    setQuery(queryDiff);
  }

  const onSelectChange = (_id) => {
    if (selectedInvoices.includes(_id)) {
      return setSelectedInvoices((selectedInvoices) =>
        selectedInvoices.filter((invoiceId) => invoiceId !== _id)
      );
    }
    setSelectedInvoices((selectedInvoices) => [...selectedInvoices, _id]);
  };

  const onSelectAll = (selectAll) => {
    if (!selectAll) {
      return setSelectedInvoices([]);
    }
    setSelectedInvoices(
      invoices.filter(({ regular }) => !regular).map(({ _id }) => _id)
    );
  };

  const createIaf = async (data) => {
    showLoading();

    const populatedSelected = selectedInvoices.length
      ? invoices.filter(({ _id }) => selectedInvoices.includes(_id))
      : invoices;

    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/iafs15`,
      {
        ...data,
        type: IAF_TYPES.ADMIN_TO_GABETTI,
        percentage: IAF_PERCENTAGE.IAF_15,
        invoices: populatedSelected,
      },
      {
        headers: { Authorization: token },
      }
    );
    hideLoading();

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    alert.success(t('COMMON:SUCCESS_UPDATE'));
    fetchInvoices();
  };

  return (
    <RoyaltiesPresentational
      data={invoices}
      isAdmin={isAdmin}
      profile={profile}
      query={query}
      total={total}
      onFilter={onFilter}
      onRegularChange={onRegularChange}
      selectedInvoices={selectedInvoices}
      onSelectChange={onSelectChange}
      searchAdministrators={searchAdministrators}
      onInvoiceUpdate={onInvoiceUpdate}
      onAddPayment={onAddPayment}
      onSelectAll={onSelectAll}
      onCreateIaf={createIaf}
    />
  );
};

export default Royalties;
