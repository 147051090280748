import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  FormGroup,
  Label,
  FormText,
  CustomInput,
  Input,
  Form,
} from 'reactstrap';
import { BtnOutlineRed, BtnOutlineGreen } from '../../../styled-components';
import { ReactComponent as IconPlus } from '../../../images/icn_plus_green.svg';
import DateInput from '../../../commons/DateInput';
import { isValidAmount } from '../../../commons/Utils';
const DEFAULT_VAT = 0.22;

const ModalInvoiceRef = (props) => {
  const { data = [], onConfirm, selectedDocuments = [] } = props;
  const [t] = useTranslation('ROYALTY');
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [invoiceRef, setInvoiceRef] = useState('');
  const [amount, setAmount] = useState('');
  const [gabettiPercAmount, setGabettiPercAmount] = useState(0);
  const [vat, setVat] = useState('');
  const [date, setDate] = useState('');
  const [cashed, setCashed] = useState(false);
  const [formErrors, setFormErrors] = useState({
    invoiceRef: false,
    date: false,
  });

  useEffect(() => {
    const amount = data
      .filter(({ _id }) => selectedDocuments.includes(_id))
      .reduce((invoicesAcc, invoice = {}) => {
        const { gabettiPerc = 0, masterShares = [] } = invoice;
        setGabettiPercAmount(gabettiPercAmount + gabettiPerc);
        return (
          invoicesAcc +
            gabettiPerc +
            masterShares.reduce(
              (shareAcc, share = 0) => shareAcc + share || 0,
              0
            ) || 0
        );
      }, 0);
    setAmount(amount.toFixed(2));
    setVat((amount * DEFAULT_VAT).toFixed(2));
  }, [data, selectedDocuments]);

  const masterSharesAmount = amount - gabettiPercAmount;

  function onSubmit() {
    const errors = {};

    if (!invoiceRef) errors.invoiceRef = true;
    if (!date) errors.date = true;

    if (Object.values(errors).some(Boolean)) return setFormErrors(errors);

    onConfirm({
      amount,
      vat,
      cashed,
      date,
      invoiceRef,
      gabettiPerc: gabettiPercAmount.toFixed(2),
      masterShares: masterSharesAmount.toFixed(2),
    });
  }

  return (
    <React.Fragment>
      <BtnOutlineGreen type="button" onClick={toggle}>
        <IconPlus /> {t('BTN_ADD_INVOICE_REF')}
      </BtnOutlineGreen>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>{t('MODAL_INVOICE_TITLE')}</ModalHeader>
        <Form>
          <ModalBody>
            <div className="max-width-form">
              <Row>
                <Col xs="12">
                  <FormGroup>
                    <Label>{t('INVOICE_REF')}</Label>
                    <Input
                      type="text"
                      name="invoiceRef"
                      value={invoiceRef}
                      onChange={({ target }) => setInvoiceRef(target.value)}
                    />
                    {formErrors.invoiceRef && (
                      <FormText className="error-message">
                        {t('COMMON:REQUIRED')}
                      </FormText>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs="12">
                  <FormGroup>
                    <Label>{t('INVOICE_DATE')}</Label>
                    <DateInput value={date} onChange={setDate} />
                    {formErrors.date && (
                      <FormText className="error-message">
                        {t('COMMON:REQUIRED')}
                      </FormText>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs="12">
                  <FormGroup>
                    <Label>{t('CONTACTS:IMPORT_INVOICE')}</Label>
                    <Input
                      type="input"
                      // type="number"
                      // step="0.01"
                      name="amount"
                      value={amount}
                      disabled={true}
                      // onChange={({ target: { value } }) => {
                      //   if (!isValidAmount(value)) return;
                      //   setAmount(value);
                      //   setVat((+value * DEFAULT_VAT).toFixed(2));
                      // }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs="12">
                  <FormGroup>
                    <Label>{t('CONTACTS:IMPORT_INVOICE_VAT')}</Label>
                    <Input
                      type="input"
                      // type="number"
                      // step="0.01"
                      name="vat"
                      value={vat}
                      disabled={true}
                      // onChange={({ target: { value } }) => {
                      //   isValidAmount(value) && setVat(value);
                      // }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs="12">
                  <FormGroup>
                    <Label>{t('INVOICE_CASHED')}</Label>
                    <div className="radio-modal-table">
                      <CustomInput
                        type="radio"
                        name="cashed"
                        id="cashedTrue"
                        label="Si"
                        value="1"
                        checked={cashed}
                        onChange={({ target }) => {
                          setCashed(target.value === '1');
                        }}
                      />
                      <CustomInput
                        type="radio"
                        name="cashedFalse"
                        id="cashed"
                        label="No"
                        value="0"
                        checked={!cashed}
                        onChange={({ target }) => {
                          setCashed(target.value === '1');
                        }}
                      />
                    </div>
                  </FormGroup>
                </Col>
              </Row>
            </div>
          </ModalBody>
          <ModalFooter>
            <BtnOutlineRed type="button" className="uppercase" onClick={toggle}>
              {t('COMMON:CANCEL')}
            </BtnOutlineRed>
            <BtnOutlineGreen
              type="button"
              className="uppercase"
              onClick={onSubmit}
            >
              {t('COMMON:CONFIRM')}
            </BtnOutlineGreen>
          </ModalFooter>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default ModalInvoiceRef;
