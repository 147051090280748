import './MyRoyalties.scss';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'reactstrap';
import {
  DataTable,
  Field,
  DateTimeField,
  RegulariseField,
  PaidBadge,
  CurrencyField,
  PaymentsDataField,
  BooleanField,
} from '../../commons/DataTable';
import SearchInput from '../../commons/SearchInput';
import Header from '../../commons/Header';
import Footer from '../../commons/Footer';
import BurgerMenu from '../../commons/BurgerMenu';
import { ReactComponent as IconInvoices } from '../../images/icn_fatture.svg';
import TotalRows from './TotalTr';
import {
  BtnRadiusWhite,
  ContFilterYear,
  ContFilterCheckbox,
  ContFlexLeft,
  ContFlexRight,
  ContPaginationPage,
} from '../../styled-components';
import Label from 'reactstrap/lib/Label';
import FormGroup from 'reactstrap/lib/FormGroup';
import CustomInput from 'reactstrap/lib/CustomInput';
import generateYearOptions from '../../lib/helpers/generateYearOptions';
import generateMonthOptions from '../../lib/helpers/generateMonthOptions';
import generatePaginationOptions from '../../lib/helpers/generatePaginationOptions';
import RegularCheckbox from './RegularCheckbox';
import ModalRegularizeAll from './ModalRegularizeAll';
import NewInvoiceModal from '../../commons/NewInvoiceModal';
import ModalAddPayment from '../ProfileDetail/Royalties/ModalAddPayment';
import { isAfter } from '../../lib/helpers/date';
import {
  ACCOUNTABILITY_LIMIT_DATE,
  IAF_PERCENTAGE,
  IAF_REQUIRED_NET,
} from '../../lib/constants';
import ModalIaf15 from './ModalIaf15';
import { applyPercentage } from '../../lib/helpers/financialMath';

function getTotals(data) {
  let cashed = 0,
    net = 0;
  data.forEach((res) => {
    cashed += (res.payments || []).reduce((acc, payment) => {
      return acc + ((payment && payment.gross) || 0);
    }, 0);
    net += res.net;
  });
  return { net, cashed };
}
const RoyaltiesPresentational = ({
  data,
  profile,
  query,
  total,
  onFilter,
  onRegularChange,
  selectedInvoices,
  onSelectChange,
  onRegularizeSelectedConfirm,
  onSelectAll,
  searchSuppliers,
  onNewInvoiceConfirm,
  onAddPayment,
  onCreateIaf,
}) => {
  const [selectedAll, setSelectedAll] = useState(false);
  const [t] = useTranslation('ENERGY');
  const { offset, limit } = query;
  const [selectedIafTotal, setSelectedIafTotal] = useState(0);
  const [selectedNet, setSelectedNet] = useState(0);
  const [populatedSelected, setPopulatedSelected] = useState([]);

  const onFilterPaidInvoices = (e) => {
    onFilter({
      paid: e.target.checked ? 'true' : 'false',
    });
  };

  const onFilterNoIaf15Invoices = (e) => {
    onFilter({
      noIaf15: e.target.checked ? 'true' : 'false',
    });
  };

  const onFilterAfterApril23 = (e) => {
    onFilter({
      afterApril23: e.target.checked ? 'true' : 'false',
    });
  };

  const onRowClick = ({ _id, regular }) => {
    !regular && onSelectChange(_id);
  };

  const areSelectedInvoicesValid = () => {
    return populatedSelected.every(
      ({ accountability }) =>
        !isAfter(accountability, ACCOUNTABILITY_LIMIT_DATE)
    );
  };

  const iafValidation = () => {
    if (!populatedSelected.every(({ paid }) => paid)) {
      return { result: false, message: t('ROYALTY:NOT_ALL_PAID_ERROR') };
    }

    if (selectedNet < IAF_REQUIRED_NET) {
      return { result: false, message: t('ROYALTY:TOTAL_NET_ERROR') };
    }

    const firstValue = populatedSelected.at(0).supplier._id;

    if (
      !populatedSelected.every(({ supplier }) => supplier._id === firstValue)
    ) {
      return { result: false, message: t('ROYALTY:IAF_SUPPLIER_ERROR') };
    }

    if (
      !populatedSelected.every(({ accountability }) =>
        isAfter(accountability, ACCOUNTABILITY_LIMIT_DATE)
      )
    ) {
      return { result: false, message: t('ROYALTY:IAF_ACCOUNTABILITY_ERROR') };
    }

    if (populatedSelected.some(({ iaf15Id }) => iaf15Id)) {
      return { result: false, message: t('ROYALTY:IAF_ALREADY_EXISTS') };
    }

    return { result: true };
  };

  useEffect(() => {
    setPopulatedSelected(
      selectedInvoices.length
        ? data.filter(({ _id }) => selectedInvoices.includes(_id))
        : data
    );
  }, [selectedInvoices, data]);

  useEffect(() => {
    setSelectedIafTotal(
      +populatedSelected
        .reduce(
          (acc, { net }) => acc + applyPercentage(net, IAF_PERCENTAGE.IAF_15),
          0
        )
        .toFixed(2)
    );
    setSelectedNet(populatedSelected.reduce((acc, { net }) => acc + net, 0));
  }, [populatedSelected]);

  return (
    <div className="App">
      <BurgerMenu />

      <div className="corpo">
        <section>
          <Header
            title={t('PAGES:INVOICES_AND_PAYMENTS')}
            icon={<IconInvoices fill="#ffffff" style={{ height: '25px' }} />}
          />

          <Row>
            <Col sm="12" md="4">
              <ContFlexLeft>
                <NewInvoiceModal
                  administrator={{
                    _id: profile._id,
                    businessName: profile.businessName,
                    taxCode: profile.taxCode,
                  }}
                  searchSuppliers={searchSuppliers}
                  onConfirm={onNewInvoiceConfirm}
                />
              </ContFlexLeft>
            </Col>
            <Col sm="12" md="8">
              <ContFlexRight className="show-invoices">
                <BtnRadiusWhite
                  className={
                    query.all === 'false' ? 'eye-toggle show-me' : 'eye-toggle'
                  }
                  onClick={() =>
                    onFilter({ all: query.all === 'true' ? 'false' : 'true' })
                  }
                >
                  <i className="far fa-eye"></i>
                </BtnRadiusWhite>
              </ContFlexRight>
            </Col>
          </Row>
          <Row className="m-t-20">
            <Col sm="12" md="6">
              <ContFilterYear className="no-width">
                <Row>
                  <Col xs="6">
                    <Label>{t('COMMON:TITLE_FILTER_ACC_YEAR')}</Label>
                  </Col>
                  <Col xs="6">
                    <FormGroup>
                      <CustomInput
                        type="select"
                        id="selectAccountabilityYear"
                        name="accountabilityYear"
                        className="year-select"
                        value={query && query.accountabilityYear}
                        onChange={({
                          target: { value: accountabilityYear },
                        }) => {
                          onFilter({ accountabilityYear });
                        }}
                      >
                        {generateYearOptions()}
                      </CustomInput>
                    </FormGroup>
                  </Col>
                </Row>
              </ContFilterYear>
            </Col>
            <Col sm="12" md="6">
              <ContFilterYear className="no-width">
                <Row>
                  <Col xs="6">
                    <Label>{t('COMMON:TITLE_FILTER_ACC_MONTH')}</Label>
                  </Col>
                  <Col xs="6">
                    <FormGroup>
                      <CustomInput
                        type="select"
                        id="accountabilityMonth"
                        name="accountabilityMonth"
                        className="year-select"
                        disabled={!query.accountabilityYear}
                        value={
                          query && query.accountabilityMonth === null
                            ? ''
                            : query.accountabilityMonth
                        }
                        onChange={({
                          target: { value: accountabilityMonth },
                        }) => onFilter({ accountabilityMonth })}
                      >
                        {generateMonthOptions()}
                      </CustomInput>
                    </FormGroup>
                  </Col>
                </Row>
              </ContFilterYear>
            </Col>
          </Row>
          <Row className="search-add-cont">
            <Col sm="6" md="6" lg="3" xl="3">
              <SearchInput
                query={{ search: query && query.supplier }}
                onFilter={({ search }) => onFilter({ supplier: search })}
                label={t('SEARCH:BY_SUPPLIER')}
              />
            </Col>
            <Col sm="6" md="6" lg="3" xl="3">
              <SearchInput
                query={{ search: query && query.number }}
                onFilter={({ search }) => onFilter({ number: search })}
                label={t('SEARCH:INVOICE_NUMBER')}
              />
            </Col>
            <Col sm="6" md="6" lg="3" xl="3">
              <SearchInput
                query={{ search: query && query.customer }}
                onFilter={({ search }) => onFilter({ customer: search })}
                label={t('SEARCH:CONDOMINIUM')}
              />
            </Col>
            <Col sm="6" md="6" lg="3" xl="3">
              <SearchInput
                query={{ search: query && query.admin }}
                onFilter={({ search }) => onFilter({ admin: search })}
                label={t('SEARCH:ADMINISTRATOR')}
              />
            </Col>
          </Row>
          <Row style={{ paddingBottom: '20px', paddingTop: '20px' }}>
            <Col sm="6" md="6" lg="3" xl="3">
              <ContFilterCheckbox>
                <CustomInput
                  type="checkbox"
                  id="paid"
                  label="Fatture Pagate"
                  inline
                  checked={query && query.paid === 'true'}
                  onChange={onFilterPaidInvoices}
                />
              </ContFilterCheckbox>
            </Col>
            <Col sm="6" md="6" lg="3" xl="3">
              <ContFilterCheckbox>
                <CustomInput
                  type="checkbox"
                  id="emittedIaf15"
                  label="Fatture senza Certificazione Avvenuto Pagamento"
                  inline
                  checked={query && query.noIaf15 === 'true'}
                  onChange={onFilterNoIaf15Invoices}
                />
              </ContFilterCheckbox>
            </Col>
            <Col sm="6" md="6" lg="3" xl="3">
              <ContFilterCheckbox>
                <CustomInput
                  type="checkbox"
                  id="accountabilityAfterApril23"
                  label="Fatture rendicontate dopo aprile 2023"
                  inline
                  checked={query && query.afterApril23 === 'true'}
                  onChange={onFilterAfterApril23}
                />
              </ContFilterCheckbox>
            </Col>
          </Row>
          {selectedInvoices.length ? (
            <div className="button-row">
              <ModalRegularizeAll
                onConfirm={onRegularizeSelectedConfirm}
                areSeletedInvoicesValid={areSelectedInvoicesValid}
              />
              <ModalIaf15
                onCreateIaf={onCreateIaf}
                validationFn={iafValidation}
                amount={selectedIafTotal}
                buttonText={'NEW_IAF_15'}
              />
            </div>
          ) : (
            <ModalIaf15
              onCreateIaf={onCreateIaf}
              validationFn={iafValidation}
              amount={selectedIafTotal}
              buttonText={'NEW_IAF_15_FOR_ALL'}
            />
          )}
        </section>

        <section className="m-t-20">
          <DataTable
            data={data}
            total={total}
            offset={offset}
            limit={limit}
            onFilter={onFilter}
            onRowClick={onRowClick}
            emptyText={t('COMMON:NO_DATA')}
            bottomRow={<TotalRows totals={getTotals(data)} />}
          >
            <RegularCheckbox
              selected={selectedInvoices}
              onSelectChange={onSelectChange}
              title={
                <input
                  type="checkbox"
                  value={selectedAll}
                  onChange={({ target: { checked } }) => {
                    setSelectedAll(checked);
                    onSelectAll(checked);
                  }}
                />
              }
            />
            <Field
              title="Nome Fornitore"
              source="supplier.businessName"
              className="text-left"
            />
            <Field title="Condominio" source="customer" className="text-left" />
            <Field title="Numero Fattura" source="number" />
            <Field title="Codice fiscale / partita IVA" source="vatCode" />
            <DateTimeField
              title="Data Fattura"
              source="date"
              format="DD/MM/YYYY"
            />
            <DateTimeField
              title="Mese Rend."
              source="accountability"
              format="MM/YYYY"
            />
            <BooleanField title="CAP emesso" source="iaf15Id" />
            <DateTimeField
              title="Data prevista incassi"
              source="supposedPaymentDate"
              format="DD/MM/YYYY"
            />
            <Field
              title="Amministratore di condominio"
              source="admin.businessName"
            />
            <CurrencyField title="Importo NETTO" source="net" />
            <PaymentsDataField title="Pagamenti" source="payments" />
            <PaidBadge title="Pagata" source="paid" />
            <RegulariseField
              title="Regolarizzata"
              source="regular"
              profile={profile}
              onRegularChange={onRegularChange}
            />
            <ModalAddPayment
              title="Aggiungi incasso"
              onAddPayment={onAddPayment}
            />
          </DataTable>

          <ContPaginationPage>
            <p>{t('COMMON:PAGINATION_PER_PAGE')}</p>

            <FormGroup>
              <CustomInput
                type="select"
                className="pagination-select"
                id="perPage"
                name="perPage"
                value={query.limit}
                onChange={({ target: { value: limit } }) => {
                  onFilter({ limit });
                }}
              >
                {generatePaginationOptions()}
              </CustomInput>
            </FormGroup>
          </ContPaginationPage>
        </section>
        <Footer />
      </div>
    </div>
  );
};

export default RoyaltiesPresentational;
