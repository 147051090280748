export const ProfileTypes = [
  'BUILDING_ADMIN',
  'ESTATE_AGENCY',
  'GNET_ADMIN',
  'SUPPLIER_ALBO',
  'SUPPLIER_TEC',
  'SUPPLIER_INS',
  'COMMERCIAL',
];

export const ProfileStatus = ['ACTIVATED', 'NOT_ACTIVATED'];

export const LAST_MASTER_YEAR = 2022;

export const ACCOUNTABILITY_LIMIT_DATE = '2023-04-01';
export const NUMBER_PAST_YEARS = 5;

export const IAF_PERCENTAGE = {
  IAF_15: 15,
  IAF_10: 10,
};

export const IAF_TYPES = {
  ADMIN_TO_GABETTI: 'ADMIN_TO_GABETTI',
  GABETTI_TO_ADMIN: 'GABETTI_TO_ADMIN',
};

// TODO: restore the required amount when we are asked to do so
// export const IAF_REQUIRED_NET = 500;
export const IAF_REQUIRED_NET = 0;
