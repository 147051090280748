import React from 'react';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import { formatCurrencyForCSV as formatCurrency } from '../../lib/helpers/formatters';

export default ({ data: insurances = [], year, children }) => {
  const csvData = [
    [
      'AMMINISTRATORE',
      'NUMERO ASSICURAZIONE',
      'RIFERIMENTO FATTURA',
      'NOME FORNITORE',
      'CONTRAENTE',
      'TIPO',
      'DATA INIZIO',
      'DATA FINE',
      'MESE RENDICONTAZIONE',
      'IMPONIBILE',
      'IMPORTO DA INCASSARE',
      'INCASSI',
      '% GABETTI (€)',
      '% GABETTI',
      'NOMINATIVO MASTER',
      'QUOTA MASTER',
      '% QUOTA MASTER',
      'QUOTA MASTER MATURATA',
      'PAGATA',
    ],
  ];

  insurances.forEach((compenso = {}) => {
    const {
      admin: { businessName: adminName = '' } = {},
      insuranceNumber,
      supplier: { businessName: supplierName = '' } = {},
      contractor,
      insuranceType,
      startDate,
      endDate,
      accountability,
      gross,
      net,
      gabettiPerc,
      payments = [],
      masters = [],
      masterShares = [],
      masterSharesPerc = [],
      earnedMasterShares = [],
      paid,
      gabettiInvoice = {},
      // masterBillable = [],
    } = compenso;
    const [firstMaster = {}] = masters;

    csvData.push([
      //'AMMINISTRATORE',
      adminName,
      // 'NUMERO ASSICURAZIONE',
      insuranceNumber,
      // 'RIFERIMENTO FATTURA',
      gabettiInvoice.invoiceRef || '',
      // 'NOME FORNITORE',
      supplierName,
      // 'CONTRAENTE',
      contractor,
      // 'TIPO',
      insuranceType,
      // 'DATA INIZIO',
      moment(startDate).format('DD/MM/YYYY'),
      // 'DATA FINE',
      moment(endDate).format('DD/MM/YYYY'),
      // 'MESE RENDICONTAZIONE',
      accountability ? moment(accountability).format('MM/YYYY') : '',
      // 'IMPONIBILE',
      formatCurrency(net),
      // 'IMPORTO DA INCASSARE',
      formatCurrency(gross),
      // 'INCASSI',
      formatCurrency(payments.reduce((acc, val) => acc + val.gross, 0)),
      // '% GABETTI (€)',
      formatCurrency(gabettiPerc),
      // '% GABETTI',
      '5',
      // 'NOMINATIVO MASTER',
      firstMaster.name || '',
      // 'QUOTA MASTER',
      formatCurrency(masterShares[0] || 0),
      // '% QUOTA MASTER',
      masterSharesPerc[0] || 0,
      // 'QUOTA MASTER MATURATA',
      formatCurrency(earnedMasterShares[0] || 0),
      // 'DIRITTO A FATTURARE (MASTER)
      // formatCurrency(masterBillable[0] || 0),
      // 'PAGATA',
      paid ? 'Si' : 'No',
    ]);
  });

  return (
    <CSVLink
      data={csvData}
      filename={`COMPENSI_VARIABILI_ASSICURAZIONI_${year}.csv`}
      separator={';'}
    >
      {children}
    </CSVLink>
  );
};
