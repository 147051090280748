import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Row, Col } from 'reactstrap';
// import Menu from '../../commons/Menu';
import BurgerMenu from '../../commons/BurgerMenu';
import Header from '../../commons/Header';
import Footer from '../../commons/Footer';
import { ReactComponent as IconDashboard } from '../../images/icn_dashboard.svg';
import {
  H4Styled,
  VerticalSpacing,
  BtnRadiusWhite,
  ContFlexLeft,
  ContFlexRight,
  BtnOutlineOrange,
} from '../../styled-components';
import Table from './Table';
import { ReactComponent as IconBack } from '../../images/icn-back.svg';
import CSVMastersExport from './CSVMastersExport';

function DashboardDetailCompensiPresentational({
  data = [],
  percentage,
  year,
  match,
  history,
}) {
  const [t] = useTranslation('DASHBOARD');

  return (
    <div className="App">
      {/* <Menu /> */}
      <BurgerMenu />

      <div className="corpo">
        <section>
          <Header
            title={t('PAGES:DASHBOARD')}
            icon={<IconDashboard fill="#ffffff" />}
          />
        </section>

        <section>
          <Row>
            <Col sm="12" md="6">
              <ContFlexLeft>
                <BtnRadiusWhite
                  onClick={() => history.replace(`/dashboard?year=${year}`)}
                >
                  <IconBack />
                </BtnRadiusWhite>
                <H4Styled>
                  Master {percentage}% - Anno {year}
                </H4Styled>
              </ContFlexLeft>
            </Col>
            <Col>
              <ContFlexRight>
                <CSVMastersExport
                  data={data}
                  year={year}
                  percentage={percentage}
                >
                  <BtnOutlineOrange type="button" className="uppercase">
                    {t('COMMON:CSV_EXPORT')}
                  </BtnOutlineOrange>
                </CSVMastersExport>
              </ContFlexRight>
            </Col>
          </Row>

          <VerticalSpacing>
            <Form>
              <Table data={data} />
            </Form>
          </VerticalSpacing>
        </section>

        <Footer />
      </div>
    </div>
  );
}

export default DashboardDetailCompensiPresentational;
