import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../commons/Auth';
import { useLoading } from '../../commons/Loading';
import { useAlert } from 'react-alert';
import {
  useQueryParams,
  NumberParam,
  withDefault,
  StringParam,
} from 'use-query-params';
import IafsPresentational from './IafsPresentational';

const { REACT_APP_DEFAULT_PAGINATION_LIMIT = 50 } = process.env;

function Iafs15(profile) {
  const [{ token } = {}] = useAuth();
  const alert = useAlert();
  const [t] = useTranslation();
  const { showLoading, hideLoading } = useLoading();
  const [total, setTotal] = useState(0);
  const [iafs, setIafs] = useState([]);
  const [selectedIafs, setSelectedIafs] = useState([]);

  const [query, setQuery] = useQueryParams({
    offset: NumberParam,
    limit: withDefault(NumberParam, +REACT_APP_DEFAULT_PAGINATION_LIMIT),
    admin: withDefault(StringParam, ''),
    description: withDefault(
      StringParam,
      localStorage.getItem('myIafsDescription')
    ),
    supplier: withDefault(StringParam, ''),
    year: withDefault(NumberParam, new Date().getFullYear()),
    invoice15Number: withDefault(StringParam, ''),
    invoice15Date: withDefault(StringParam, ''),
    noInvoice15: withDefault(StringParam, ''),
  });

  const {
    limit,
    offset,
    admin,
    description,
    supplier,
    year,
    invoice15Number,
    invoice15Date,
    noInvoice15,
  } = query;

  useEffect(() => {
    fetchIafs();
  }, [
    limit,
    offset,
    admin,
    description,
    supplier,
    year,
    invoice15Number,
    invoice15Date,
    noInvoice15,
  ]);

  function onFilter(queryDiff) {
    setSelectedIafs([]);
    if (
      (queryDiff.admin && queryDiff.admin !== admin) ||
      (admin && queryDiff.admin === '') ||
      (queryDiff.description && queryDiff.description !== description) ||
      (description && queryDiff.description === '') ||
      (queryDiff.supplier && queryDiff.supplier !== supplier) ||
      (supplier && queryDiff.supplier === '') ||
      (queryDiff.invoice15Number &&
        queryDiff.invoice15Number !== invoice15Number) ||
      (invoice15Number && queryDiff.invoice15Number === '') ||
      (queryDiff.invoice15Date && queryDiff.invoice15Date !== invoice15Date) ||
      (invoice15Date && queryDiff.invoice15Date === '') ||
      (queryDiff.noInvoice15 && queryDiff.noInvoice15 !== noInvoice15) ||
      (noInvoice15 && queryDiff.noInvoice15 === '') ||
      (queryDiff.limit && queryDiff.limit !== limit)
    ) {
      queryDiff.offset = 0; // return to first page
    }
    setQuery(queryDiff);
  }

  const fetchIafs = async () => {
    showLoading();

    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/iafs15`,
      {
        headers: { Authorization: token },
        params: {
          limit,
          offset,
          admin,
          description,
          supplier,
          year,
          invoice15Number,
          invoice15Date,
          noInvoice15,
        },
      }
    );

    hideLoading();

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    const { data = [], headers = {} } = response;
    setIafs(data);
    setTotal(+headers['x-total-count']);
  };

  const onSelectChange = (_id) => {
    if (selectedIafs.includes(_id)) {
      return setSelectedIafs((selectedIafs) =>
        selectedIafs.filter((iafId) => iafId !== _id)
      );
    }
    setSelectedIafs((selectedIafs) => [...selectedIafs, _id]);
  };

  const onSelectAll = (selectAll) => {
    if (!selectAll) {
      return setSelectedIafs([]);
    }
    setSelectedIafs(
      iafs.filter(({ regular }) => !regular).map(({ _id }) => _id)
    );
  };

  const createInvoice15 = async (data) => {
    showLoading();

    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/invoices15/create`,
      { iafsId: selectedIafs, ...data },
      {
        headers: { Authorization: token },
      }
    );

    hideLoading();

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    setSelectedIafs([]);
    alert.success(t('COMMON:SUCCESS_CREATE'));
    fetchIafs();
  };

  return (
    <IafsPresentational
      data={iafs}
      total={total}
      query={query}
      onFilter={onFilter}
      selectedIafs={selectedIafs}
      onSelectChange={onSelectChange}
      onSelectAll={onSelectAll}
      onCreateInvoice15={createInvoice15}
    />
  );
}

export default Iafs15;
