import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Row, Col } from 'reactstrap';
import BurgerMenu from '../../commons/BurgerMenu';
import Header from '../../commons/Header';
import Footer from '../../commons/Footer';
import { ReactComponent as IconFinancial } from '../../images/icn_rendicontazioni.svg';
import {
  ContFlexLeft,
  BtnRadiusWhite,
  ContPaginationPage,
  ContFilterYear,
} from '../../styled-components';
import Table from './Table';
import SearchInput from '../../commons/SearchInput';
import FormGroup from 'reactstrap/lib/FormGroup';
import CustomInput from 'reactstrap/lib/CustomInput';
import generatePaginationOptions from '../../lib/helpers/generatePaginationOptions';
import Label from 'reactstrap/lib/Label';
import generateYearOptions from '../../lib/helpers/generateYearOptions';
import TotalTr from './TotalTr';

function InvoicesPresentational({
  data = [],
  total,
  query = {},
  onFilter,
  onUpdate,
}) {
  const [t] = useTranslation('DASHBOARD');

  return (
    <div className="App">
      <BurgerMenu />

      <div className="corpo">
        <section>
          <Header
            title={t('PAGES:INVOICES_REF')}
            icon={<IconFinancial fill="#ffffff" />}
          />
        </section>

        <section>
          <Row>
            <Col sm="12" md="6" lg="3">
              <ContFilterYear className="no-width">
                <Row>
                  <Col xs="6">
                    <Label>{t('CONTACTS:TITLE_FILTER_YEAR')}</Label>
                  </Col>
                  <Col xs="6">
                    <FormGroup>
                      <CustomInput
                        type="select"
                        id="selectYear"
                        name="year"
                        className="year-select"
                        value={query.year}
                        onChange={({ target: { value: year } }) =>
                          onFilter({ year })
                        }
                      >
                        {generateYearOptions(true)}
                      </CustomInput>
                    </FormGroup>
                  </Col>
                </Row>
              </ContFilterYear>
            </Col>
            <Col sm="12" md="6">
              <ContFlexLeft className="show-invoices">
                <SearchInput
                  query={query}
                  onFilter={onFilter}
                  label={t('COMMON:SEARCH')}
                />
                <BtnRadiusWhite
                  className={
                    query.all === 'false' ? 'eye-toggle show-me' : 'eye-toggle'
                  }
                  onClick={() =>
                    onFilter({ all: query.all === 'true' ? 'false' : 'true' })
                  }
                >
                  <i className="far fa-eye"></i>
                </BtnRadiusWhite>
              </ContFlexLeft>
            </Col>
          </Row>

          <br />

          <Form>
            <Table
              showEmptyTable={true}
              data={data}
              total={total}
              query={query}
              onFilter={onFilter}
              onUpdate={onUpdate}
              bottomRow={<TotalTr data={data} />}
            />
          </Form>

          <ContPaginationPage>
            <p>{t('COMMON:PAGINATION_PER_PAGE')}</p>

            <FormGroup>
              <CustomInput
                type="select"
                className="pagination-select"
                id="perPage"
                name="perPage"
                value={query.limit}
                onChange={({ target: { value: limit } }) => {
                  onFilter({ limit });
                }}
              >
                {generatePaginationOptions()}
              </CustomInput>
            </FormGroup>
          </ContPaginationPage>
        </section>

        <Footer />
      </div>
    </div>
  );
}

export default InvoicesPresentational;
