import React from 'react';
import { useTranslation } from 'react-i18next';
import { TabContent, TabPane, Form, Row, Col } from 'reactstrap';
import {
  ContAffiliation,
  ContFilterYear,
  ContFilterCheckbox,
} from '../../../styled-components';
import {
  DataTable,
  Field,
  DateTimeField,
  CurrencyField,
  PaymentsDataField,
  BooleanField,
} from '../../../commons/DataTable';
import SearchInput from '../../../commons/SearchInput';
import FormGroup from 'reactstrap/lib/FormGroup';
import CustomInput from 'reactstrap/lib/CustomInput';
import Label from 'reactstrap/lib/Label';
import TotalTr from './TotalTr';
import generateYearOptions from '../../../lib/helpers/generateYearOptions';
import generateMonthOptions from '../../../lib/helpers/generateMonthOptions';

const InsurancesPresentational = ({
  data,
  query,
  total,
  onFilter,
  onSelectChange,
  enableCheckbox = false,
  setFilterYear,
}) => {
  const [t] = useTranslation('ENERGY');
  const { offset, limit, year, regular } = query;

  const onRowClick = enableCheckbox
    ? ({ _id, gabettiInvoice }) => {
        _id && !gabettiInvoice && onSelectChange(_id);
      }
    : undefined;

  const onFilterPaidInvoices = (e) => {
    onFilter({
      paid: e.target.checked ? 'true' : '',
    });
  };

  return (
    <Form>
      <TabContent activeTab={'1'}>
        <TabPane tabId="1">
          <Row>
            <Col sm="12" md="6" lg="4">
              <ContFilterYear className="no-width">
                <Row>
                  <Col xs="6">
                    <Label>{t('CONTACTS:TITLE_FILTER_YEAR')}</Label>
                  </Col>
                  <Col xs="6">
                    <FormGroup>
                      <CustomInput
                        type="select"
                        id="selectYear"
                        name="year"
                        value={year}
                        onChange={(e) => setFilterYear(e.target.value)}
                      >
                        {generateYearOptions()}
                      </CustomInput>
                    </FormGroup>
                  </Col>
                </Row>
              </ContFilterYear>
            </Col>
            <Col sm="12" md="6" lg="4">
              <ContFilterYear className="no-width">
                <Row>
                  <Col xs="6">
                    <Label>{t('COMMON:TITLE_FILTER_ACC_YEAR')}</Label>
                  </Col>
                  <Col xs="6">
                    <FormGroup>
                      <CustomInput
                        type="select"
                        id="selectAccountabilityYear"
                        name="accountabilityYear"
                        value={query && query.accountabilityYear}
                        onChange={({
                          target: { value: accountabilityYear },
                        }) => {
                          onFilter({ accountabilityYear });
                        }}
                      >
                        {generateYearOptions(true)}
                      </CustomInput>
                    </FormGroup>
                  </Col>
                </Row>
              </ContFilterYear>
            </Col>
            <Col sm="12" md="6" lg="4">
              <ContFilterYear className="no-width">
                <Row>
                  <Col xs="6">
                    <Label>{t('COMMON:TITLE_FILTER_ACC_MONTH')}</Label>
                  </Col>
                  <Col xs="6">
                    <FormGroup>
                      <CustomInput
                        type="select"
                        id="accountabilityMonth"
                        name="accountabilityMonth"
                        disabled={!query.accountabilityYear}
                        value={query && query.accountabilityMonth}
                        onChange={({
                          target: { value: accountabilityMonth },
                        }) => onFilter({ accountabilityMonth })}
                      >
                        {generateMonthOptions()}
                      </CustomInput>
                    </FormGroup>
                  </Col>
                </Row>
              </ContFilterYear>
            </Col>
          </Row>
          <Row className="compensi-variabili-resp">
            <Col sm="6" md="6" lg="3" xl="3">
              <SearchInput
                query={{ search: query && query.supplier }}
                onFilter={({ search }) => onFilter({ supplier: search })}
                label={t('SEARCH:BY_SUPPLIER')}
              />
            </Col>
            <Col sm="6" md="6" lg="3" xl="3">
              <SearchInput
                query={{ search: query && query.insuranceNumber }}
                onFilter={({ search }) => onFilter({ insuranceNumber: search })}
                label={t('SEARCH:INSURANCE_NUMBER')}
              />
            </Col>
            <Col sm="6" md="6" lg="3" xl="3">
              <SearchInput
                query={{ search: query && query.adminName }}
                onFilter={({ search }) => onFilter({ adminName: search })}
                label={t('SEARCH:ADMINISTRATOR')}
              />
            </Col>

            <Col sm="12" md="12" lg="8" xl="6">
              <FormGroup>
                <ContFilterCheckbox>
                  <CustomInput
                    type="checkbox"
                    id="paid"
                    label="Assicurazioni Pagate"
                    inline
                    checked={query && query.paid === 'true'}
                    onChange={onFilterPaidInvoices}
                  />
                </ContFilterCheckbox>
              </FormGroup>
            </Col>
          </Row>

          <ContAffiliation className="m-t-20">
            <DataTable
              data={data}
              total={total}
              offset={offset}
              limit={limit}
              onFilter={onFilter}
              emptyText={t('COMMON:NO_DATA')}
              onRowClick={onRowClick}
              bottomRow={<TotalTr data={data} />}
            >
              <Field
                title="Nome Fornitore"
                source="supplier.businessName"
                className="text-left"
              />
              <Field title="Contraente" source="contractor" />
              <Field title="Assicurazione" source="insuranceNumber" />
              <Field title="Tipo" source="insuranceType" />
              <DateTimeField
                title="Data inizio"
                format="DD/MM/YYYY"
                source="startDate"
              />
              <DateTimeField
                title="Data fine"
                format="DD/MM/YYYY"
                source="endDate"
              />
              <DateTimeField
                title="Mese Rend."
                source="accountability"
                format="MM/YYYY"
              />
              <DateTimeField
                title="Data prevista incassi"
                format="DD/MM/YYYY"
                source="supposedPaymentDate"
              />
              <Field
                title="Amministratore di condominio"
                source="admin.businessName"
              />
              <CurrencyField
                title="Imponibile"
                className="txt-table-right"
                source="net"
              />
              <CurrencyField
                title="Importo da incassare"
                className="txt-table-right"
                source="gross"
              />
              <PaymentsDataField
                title="Incassi"
                source="payments"
                type="list"
                field="gross"
                class="txt-table-right"
              />
              <BooleanField title="Pagato" source="paid" />
            </DataTable>
          </ContAffiliation>
        </TabPane>
      </TabContent>
    </Form>
  );
};

export default InsurancesPresentational;
