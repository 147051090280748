import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  FormGroup,
  Label,
  CustomInput,
  Form,
} from 'reactstrap';
import {
  BtnOutlineRed,
  BtnOutlineGreen,
  BtnModalTable,
} from '../../styled-components';

const ModalInvoiceRef = ({ onConfirm, children, ...props }) => {
  const [t] = useTranslation('ROYALTY');
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const [cashed, setCashed] = useState(
    typeof props.value === 'string' ? false : props.value
  );

  function onSubmit() {
    onConfirm({ cashed, _id: props.data._id });

    toggle();
  }

  const childrenComponents = React.Children.map(children, (child) => {
    return React.cloneElement(child, {
      ...props,
    });
  });

  return (
    <React.Fragment>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {childrenComponents}
        <div style={{ marginLeft: '10px' }}>
          <BtnModalTable type="button" onClick={toggle}>
            MODIFICA
          </BtnModalTable>
        </div>
      </div>

      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>{t('MODIFY_CASHED')}</ModalHeader>
        <Form>
          <ModalBody>
            <div className="max-width-form">
              <Row>
                <Col xs="12">
                  <FormGroup>
                    <Label className="u-center-horizontal">
                      {t('INVOICE_CASHED')}
                    </Label>
                    <div className="radio-modal-table">
                      <CustomInput
                        type="radio"
                        name="cashed"
                        id="cashedTrue"
                        label="Si"
                        value="1"
                        checked={cashed}
                        onChange={({ target }) => {
                          setCashed(target.value === '1');
                        }}
                      />
                      <CustomInput
                        type="radio"
                        name="cashedFalse"
                        id="cashed"
                        label="No"
                        value="0"
                        checked={!cashed}
                        onChange={({ target }) => {
                          setCashed(target.value === '1');
                        }}
                      />
                    </div>
                  </FormGroup>
                </Col>
              </Row>
            </div>
          </ModalBody>
          <ModalFooter>
            <BtnOutlineRed type="button" className="uppercase" onClick={toggle}>
              {t('COMMON:CANCEL')}
            </BtnOutlineRed>
            <BtnOutlineGreen
              type="button"
              className="uppercase"
              onClick={onSubmit}
            >
              {t('COMMON:CONFIRM')}
            </BtnOutlineGreen>
          </ModalFooter>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default ModalInvoiceRef;
