import './Invoices.scss';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAlert } from 'react-alert';
import InvoicesPresentational from './InvoicesPresentational';
import { useLoading } from '../../commons/Loading';
import { useAuth } from '../../commons/Auth';
import axios from 'axios';
import {
  useQueryParams,
  withDefault,
  StringParam,
  NumberParam,
} from 'use-query-params';

const { REACT_APP_DEFAULT_PAGINATION_LIMIT = 50 } = process.env;

function Invoices(props) {
  const { showLoading, hideLoading } = useLoading();
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [{ token } = {}] = useAuth();
  const { t } = useTranslation('DASHBOARD');
  const alert = useAlert();

  const [query, setQuery] = useQueryParams({
    search: withDefault(StringParam, ''),
    offset: withDefault(NumberParam, 0),
    limit: withDefault(NumberParam, +REACT_APP_DEFAULT_PAGINATION_LIMIT),
    all: withDefault(StringParam, 'false'),
    year: withDefault(NumberParam, undefined),
  });

  const { search, all, offset, limit, year } = query;

  const fetchInvoicesRefs = async () => {
    showLoading();
    setData([]);

    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/gabetti-invoices`,
      {
        headers: { Authorization: token },
        params: { search, all, offset, limit, year },
      }
    );

    hideLoading();

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    setData(response.data || []);
    setTotal(Number(response.headers['x-total-count']));
  };

  useEffect(() => {
    fetchInvoicesRefs();
  }, [search, all, offset, limit, year]);

  const onUpdate = async (update = {}) => {
    showLoading();
    const { _id } = update;

    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/gabetti-invoices/${_id}`,
      {
        ...update,
        _id: undefined,
      },
      {
        headers: { Authorization: token },
      }
    );

    hideLoading();

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    alert.success(t('COMMON:SUCCESS_UPDATE'));
    fetchInvoicesRefs();
  };

  function onFilter(queryDiff) {
    if (
      (queryDiff.search && queryDiff.search !== search) ||
      (search && queryDiff.search === '') ||
      (queryDiff.year && queryDiff.year !== year) ||
      (year && queryDiff.year === '') ||
      (queryDiff.limit && queryDiff.limit !== limit)
    ) {
      queryDiff.offset = 0; // return to first page
    }
    setQuery(queryDiff);
  }

  return (
    <InvoicesPresentational
      data={data}
      total={total}
      query={query}
      onFilter={onFilter}
      onUpdate={onUpdate}
      {...props}
    />
  );
}

export default Invoices;
