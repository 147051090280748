import React from 'react';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import { formatCurrencyForCSV as formatCurrency } from '../../../lib/helpers/formatters';
import { useAuth } from '../../../commons/Auth';

export default (props) => {
  const [{ isAdmin }] = useAuth();
  const { children, data = [], year, businessName, studio = false } = props;

  let csvData, insurances;

  csvData = [
    [
      'NOME FORNITORE',
      'NUMERO ASSICURAZIONE',
      'DATA INIZIO',
      'DATA FINE',
      'MESE RENDICONTAZIONE',
      'AMMINISTRATORE DI CONDOMINIO',
      'IMPONIBILE',
      'IMPORTO DA INCASSARE',
      'INCASSI',
      'QUOTA MASTER',
      '% QUOTA MASTER',
      'QUOTA MASTER MATURATA',
      'DIRITTO A FATTURARE (MASTER)',
    ],
  ];

  if (isAdmin) csvData[0].push('INVITATO A FATTURARE');

  insurances = data;

  insurances.forEach((compenso = {}) => {
    const {
      supplier: { businessName: supplierName = '' } = {},
      insuranceNumber,
      startDate,
      endDate,
      accountability = null,
      admin: { businessName: adminName = '', type: adminType = '' } = {},
      gross,
      net,
      payments = [],
      earnedMasterShare,
      billableMasterShare,
      masterShare,
      iafs = [],
    } = compenso;

    const row = [
      // 'NOME FORNITORE',
      supplierName,
      // 'NUMERO ASSICURAZIONE',
      insuranceNumber,
      // 'DATA INIZIO',
      moment(startDate).format('DD/MM/YYYY'),
      // 'DATA FINE
      moment(endDate).format('DD/MM/YYYY'),
      // 'MESE RENDICONTAZIONE',
      !accountability ? '-' : moment(accountability).format('MM/YYYY'),
      // 'AMMINISTRATORE DI CONDOMINIO',
      adminName,
      // 'IMPONIBILE',
      formatCurrency(net),
      // 'IMPORTO DA INCASSARE',
      formatCurrency(gross),
      // 'INCASSI',
      formatCurrency(payments.reduce((acc, val) => acc + val.gross, 0)),
      // 'QUOTA MASTER',
      formatCurrency(masterShare || 0),
      // '% QUOTA MASTER',
      '3',
      // 'QUOTA MASTER MATURATA',
      formatCurrency(earnedMasterShare || 0),
      // 'DIRITTO A FATTURARE (MASTER)
      formatCurrency(billableMasterShare || 0),
    ];

    if (isAdmin) {
      const iafAmount = (iafs || []).reduce((acc, iaf = {}) => {
        const iafDetail = iaf.insurances || iaf.insurancesStudio || [];
        const [firstIAFDetail = {}] = iafDetail;
        return (
          acc + ((firstIAFDetail && firstIAFDetail.earnedMasterShare) || 0)
        );
      }, 0);
      row.push(formatCurrency(iafAmount));
    }

    csvData.push(row);
  });

  const filename = studio
    ? `COMPENSI_ASSICURAZIONI_STUDIO_${businessName}_${year}.csv`
    : `COMPENSI_ASSICURAZIONI_${businessName}_${year}.csv`;

  return (
    <CSVLink data={csvData} filename={filename} separator={';'}>
      {children}
    </CSVLink>
  );
};
