import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAlert } from 'react-alert';
import { dashboard } from '../../lib/fetch/dashboard';
import DashboardPresentational from './DashboardPresentational';
import { useLoading } from '../../commons/Loading';
import { useAuth } from '../../commons/Auth';
import { useQueryParams, withDefault, NumberParam } from 'use-query-params';

function Dashboard(props) {
  const [{ token } = {}] = useAuth();
  const [data, setData] = useState({});
  const { t } = useTranslation('DASHBOARD');
  const alert = useAlert();
  const { showLoading, hideLoading } = useLoading();

  const [query, setQuery] = useQueryParams({
    year: withDefault(NumberParam, new Date().getFullYear()),
  });

  useEffect(() => {
    const fetchDashboard = async ({ token, year }) => {
      showLoading();

      const { error, data } = await dashboard({ token, year });

      if (error) {
        hideLoading();
        alert.error(t('COMMON:GENERIC_ERROR'));
        return;
      }

      setData(data);
      hideLoading();
    };

    fetchDashboard({ token, year: query.year });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.year]);

  function setFilterYear(value) {
    setQuery({ year: value });
  }

  return (
    <DashboardPresentational
      data={data}
      setFilterYear={setFilterYear}
      year={query.year}
      {...props}
    />
  );
}

export default Dashboard;
