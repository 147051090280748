import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAlert } from 'react-alert';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Form,
  FormText,
} from 'reactstrap';
import {
  BtnOutlineRed,
  BtnOutlineGreen,
  BtnModalTable,
} from '../../styled-components';
import DateInput from '../../commons/DateInput';

function ModalNoInvoice(props) {
  const { data = {}, onConfirm = () => {} } = props;
  const [t] = useTranslation('CONTACTS');
  const alert = useAlert();
  const [modal, setModal] = useState(false);
  const [accountability, setAccountability] = useState(undefined);
  const [formError, setFormError] = useState(false);
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();
  const toggle = () => {
    setModal(!modal);
    setAccountability(undefined);
    setFormError(false);
  };
  const onSubmit = (e) => {
    e.preventDefault();
    if (!accountability) return setFormError(true);
    if (accountability.getFullYear() > currentYear) {
      toggle();
      return alert.error(t('INVOICES:NO_INVOICE_DATE_ERR'));
    }
    if (
      accountability.getFullYear() === currentYear &&
      accountability.getMonth() > currentMonth
    ) {
      toggle();
      return alert.error(t('INVOICES:NO_INVOICE_DATE_ERR'));
    }
    onConfirm({
      supplier: data._id,
      month: accountability.getMonth() + 1,
      year: accountability.getFullYear(),
    });
    toggle();
  };
  return (
    <React.Fragment>
      <div className="flex-check">
        <BtnModalTable type="button" onClick={toggle}>
          {t('INVOICES:NO_INVOICE_BUTTON')}
        </BtnModalTable>
      </div>

      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          {t('INVOICES:NO_INVOICE_THIS_MONTH')}
        </ModalHeader>
        <Form onSubmit={onSubmit}>
          <ModalBody>
            <div className="max-width-form">
              <FormGroup>
                <DateInput
                  className="text-center"
                  name="accountability"
                  value={accountability && accountability.toISOString()}
                  showOnlyMonth
                  setMidday
                  returnDateObject
                  onChange={(accountability) => {
                    setAccountability(accountability);
                  }}
                />
                {formError && (
                  <FormText className="error-message">
                    {t('COMMON:REQUIRED')}
                  </FormText>
                )}
              </FormGroup>
            </div>
            <p>{t('COMMON:CONFIRM_WARNING')}</p>
            <p>{t('INVOICES:NO_INVOICE_THIS_MONTH_WARNING')}</p>
          </ModalBody>
          <ModalFooter>
            <BtnOutlineRed type="button" className="uppercase" onClick={toggle}>
              {t('COMMON:CANCEL')}
            </BtnOutlineRed>
            <BtnOutlineGreen type="submit" className="uppercase">
              {t('COMMON:CONFIRM')}
            </BtnOutlineGreen>
          </ModalFooter>
        </Form>
      </Modal>
    </React.Fragment>
  );
}

export default ModalNoInvoice;
