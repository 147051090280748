import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  FormGroup,
  Label,
  InputGroup,
  Input,
  InputGroupAddon,
  Form,
  FormText,
} from 'reactstrap';
import {
  BtnOutlineRed,
  BtnOutlineGreen,
  BtnRadiusRed,
  PNewItem,
} from '../../styled-components';
import './Insurances.scss';
import DateInput from '../../commons/DateInput';
import AutoSuggest from '../../commons/Autosuggest';
import CustomInput from 'reactstrap/lib/CustomInput';

const INITIAL_INSURANCE_STATE = {
  contractor: '',
  admin: {
    _id: '',
    businessName: '',
  },
  insuranceNumber: '',
  startDate: '',
  endDate: '',
  supposedPaymentDate: '',
  gross: '',
  net: '',
  insuranceType: '',
};

const ModalNewInsurance = ({ searchAdministrators, onConfirm }) => {
  const [t] = useTranslation('ROYALTY');
  const [modal, setModal] = useState(false);

  const [formValues, setFormValues] = useState({
    ...INITIAL_INSURANCE_STATE,
    admin: { ...INITIAL_INSURANCE_STATE.admin },
  });
  const [formErrors, setFormErrors] = useState({
    contractor: false,
    admin: false,
    insuranceNumber: false,
    startDate: false,
    endDate: false,
    supposedPaymentDate: false,
    gross: false,
    net: false,
    insuranceType: false,
    accountability: false,
  });

  useEffect(() => {
    if (moment(formValues.startDate).isValid()) {
      setFormValues((state) => ({
        ...state,
        endDate: moment(formValues.startDate).add({ year: 1 }).toISOString(),
        supposedPaymentDate: moment(formValues.startDate)
          .add(60, 'days')
          .toISOString(),
      }));
    }
  }, [formValues.startDate]);

  const toggle = () => {
    setModal(!modal);
    setFormValues({
      ...INITIAL_INSURANCE_STATE,
      admin: { ...INITIAL_INSURANCE_STATE.admin },
    });
  };

  function onInputChange({ target }) {
    setFormValues((state) => ({ ...state, [target.name]: target.value }));
  }

  function onNumericInputChange({ target }) {
    if (/^-?[\d]*\.?[\d]{0,2}$/.test(target.value)) {
      setFormValues((state) => ({
        ...state,
        [target.name]: target.value,
      }));
    }
  }

  const renderSuggestion = ({ businessName = '' }) => businessName;
  const getSuggestionValue = ({ businessName = '' }) => businessName;
  function onSuggestionSelect({ _id, businessName }) {
    setFormValues((state) => ({
      ...state,
      admin: { _id, businessName },
    }));
  }

  const onNewInsuranceConfirm = () => {
    const {
      contractor,
      admin,
      insuranceNumber,
      startDate,
      endDate,
      supposedPaymentDate,
      gross,
      net,
      insuranceType,
    } = formValues;

    const errors = {};

    if (!contractor) errors.contractor = true;
    if (!admin._id) errors.admin = true;
    if (!insuranceNumber) errors.insuranceNumber = true;
    if (!startDate) errors.startDate = true;
    if (!endDate) errors.endDate = true;
    if (!supposedPaymentDate) errors.supposedPaymentDate = true;
    if (!gross) errors.gross = true;
    if (!net) errors.net = true;
    if (!insuranceType) errors.insuranceType = true;

    if (Object.keys(errors).length > 0) {
      return setFormErrors(errors);
    }

    onConfirm({
      ...formValues,
      admin: formValues.admin._id,
      net: parseFloat(formValues.net),
      gross: parseFloat(formValues.gross),
    });

    toggle();
  };

  function listenForEscapeEvent() {
    document.addEventListener('keydown', ({ key }) => {
      if (key === 'Escape') toggle();
    });
  }

  const {
    contractor,
    admin,
    insuranceNumber,
    startDate,
    endDate,
    supposedPaymentDate,
    gross,
    net,
    insuranceType,
    accountability,
  } = formValues;

  return (
    <React.Fragment>
      <BtnRadiusRed onClick={toggle} type="button">
        <img src="/images/icn-plus.svg" alt=" plus icon" />
      </BtnRadiusRed>
      <PNewItem
        onClick={toggle}
        style={{ marginLeft: '7px', marginRight: '0', cursor: 'pointer' }}
      >
        {t('INSURANCES:NEW_INSURANCE')}
      </PNewItem>

      <Modal
        isOpen={modal}
        className="new-insurance-modal"
        onOpened={listenForEscapeEvent}
      >
        <ModalHeader toggle={toggle}>
          {t('INSURANCES:NEW_INSURANCE')}
        </ModalHeader>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            onNewInsuranceConfirm(formValues);
          }}
        >
          <ModalBody>
            <Row>
              <Col sm="12">
                <p>{t('ADD_MANUAL')}</p>
                <Row>
                  <Col xs="12" md="6" lg="4">
                    <FormGroup>
                      <Label>{t('AMMINISTRATOR')} *</Label>
                      <AutoSuggest
                        placeholder="Cerca un amministratore..."
                        onFetch={searchAdministrators}
                        onSelect={onSuggestionSelect}
                        renderSuggestion={renderSuggestion}
                        getSuggestionValue={getSuggestionValue}
                        value={admin.businessName}
                        onChange={(businessName) =>
                          setFormValues((state) => ({
                            ...state,
                            admin: { ...admin, businessName },
                          }))
                        }
                      />
                      {formErrors.admin && (
                        <FormText className="error-message">
                          {t('COMMON:REQUIRED')}
                        </FormText>
                      )}
                    </FormGroup>
                  </Col>
                  <Col xs="12" md="6" lg="4">
                    <FormGroup>
                      <Label>{t('INSURANCES:LABEL')} *</Label>
                      <Input
                        type="text"
                        name="insuranceNumber"
                        value={insuranceNumber}
                        onChange={onInputChange}
                      />
                      {formErrors.insuranceNumber && (
                        <FormText className="error-message">
                          {t('COMMON:REQUIRED')}
                        </FormText>
                      )}
                    </FormGroup>
                  </Col>
                  <Col xs="12" md="6" lg="4">
                    <FormGroup>
                      <Label>{t('INSURANCES:CONTRAENTE')} *</Label>
                      <Input
                        type="text"
                        name="contractor"
                        value={contractor}
                        onChange={onInputChange}
                      />
                      {formErrors.contractor && (
                        <FormText className="error-message">
                          {t('COMMON:REQUIRED')}
                        </FormText>
                      )}
                    </FormGroup>
                  </Col>
                  <Col xs="12" md="6" lg="4">
                    <FormGroup>
                      <Label>{t('INSURANCES:TYPE')} *</Label>
                      <CustomInput
                        type="select"
                        id="insuranceType"
                        name="insuranceType"
                        value={insuranceType}
                        onChange={onInputChange}
                      >
                        <option value="">
                          {t('INSURANCES:TYPES_PLACEHOLDER')}
                        </option>
                        <option value="Globale Fabbricati">
                          {t('INSURANCES:TYPES_1')}
                        </option>
                      </CustomInput>
                      {formErrors.type && (
                        <FormText className="error-message">
                          {t('COMMON:REQUIRED')}
                        </FormText>
                      )}
                    </FormGroup>
                  </Col>
                  <Col xs="12" md="6" lg="4">
                    <FormGroup>
                      <Label>{t('INSURANCES:START_DATE')} *</Label>
                      <DateInput
                        name="startDate"
                        value={startDate}
                        onChange={(date) =>
                          setFormValues((state) => ({
                            ...state,
                            startDate: moment(date).toISOString(),
                          }))
                        }
                      />
                      {formErrors.startDate && (
                        <FormText className="error-message">
                          {t('COMMON:REQUIRED')}
                        </FormText>
                      )}
                    </FormGroup>
                  </Col>
                  <Col xs="12" md="6" lg="4">
                    <FormGroup>
                      <Label>{t('INSURANCES:END_DATE')} *</Label>
                      <DateInput
                        name="endDate"
                        value={endDate}
                        onChange={(date) =>
                          setFormValues((state) => ({
                            ...state,
                            endDate: moment(date).toISOString(),
                          }))
                        }
                      />
                      {formErrors.endDate && (
                        <FormText className="error-message">
                          {t('COMMON:REQUIRED')}
                        </FormText>
                      )}
                    </FormGroup>
                  </Col>
                  <Col xs="12" md="6" lg="4">
                    <FormGroup>
                      <Label>{t('INSURANCES:COLLECTION_DATE')} *</Label>
                      <DateInput
                        name="supposedPaymentDate"
                        value={supposedPaymentDate}
                        onChange={(date) =>
                          setFormValues((state) => ({
                            ...state,
                            supposedPaymentDate: moment(date).toISOString(),
                          }))
                        }
                      />
                      {formErrors.supposedPaymentDate && (
                        <FormText className="error-message">
                          {t('COMMON:REQUIRED')}
                        </FormText>
                      )}
                    </FormGroup>
                  </Col>
                  <Col xs="12" md="6" lg="4">
                    <FormGroup>
                      <Label>{t('INSURANCES:AWARD_IMPONIBILE')} *</Label>
                      <InputGroup>
                        <Input
                          type="number"
                          step="0.01"
                          placeholder="0.00"
                          name="net"
                          value={net}
                          onChange={onNumericInputChange}
                        />
                        <InputGroupAddon addonType="prepend">€</InputGroupAddon>
                      </InputGroup>
                      {formErrors.net && (
                        <FormText className="error-message">
                          {t('COMMON:REQUIRED')}
                        </FormText>
                      )}
                    </FormGroup>
                  </Col>
                  <Col xs="12" md="6" lg="4">
                    <FormGroup>
                      <Label>{t('INSURANCES:AWARD')} *</Label>
                      <InputGroup>
                        <Input
                          type="number"
                          step="0.01"
                          placeholder="0.00"
                          name="gross"
                          value={gross}
                          onChange={onNumericInputChange}
                        />
                        <InputGroupAddon addonType="prepend">€</InputGroupAddon>
                      </InputGroup>
                      {formErrors.gross && (
                        <FormText className="error-message">
                          {t('COMMON:REQUIRED')}
                        </FormText>
                      )}
                    </FormGroup>
                  </Col>
                  <Col xs="12" md="6" lg="4">
                    <FormGroup>
                      <Label>{t('ACC_DATE')}</Label>
                      <DateInput
                        name="accountability"
                        value={accountability}
                        showOnlyMonth
                        setMidday
                        onChange={(accountability) => {
                          setFormValues((state) => ({
                            ...state,
                            accountability: moment(
                              accountability
                            ).toISOString(),
                          }));
                        }}
                      />
                      {formErrors.accountability && (
                        <FormText className="error-message">
                          {t('COMMON:REQUIRED')}
                        </FormText>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <BtnOutlineRed type="button" className="uppercase" onClick={toggle}>
              {t('COMMON:CANCEL')}
            </BtnOutlineRed>
            <BtnOutlineGreen type="submit" className="uppercase">
              {t('COMMON:CONFIRM')}
            </BtnOutlineGreen>
          </ModalFooter>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default ModalNewInsurance;
