import React from 'react';
import { CSVLink } from 'react-csv';
import { formatCurrencyForCSV as formatCurrency } from '../../lib/helpers/formatters';

export default ({
  data: { affiliations = [], totals = {} },
  clientType = '',
  year,
  children,
}) => {
  const csvData = [
    [
      'RAGIONE SOCIALE / NOME',
      'PERIODO DI AFFILIAZIONE',
      'DOVUTO',
      'QUOTA MASTER I',
      'QUOTA MASTER II',
    ],
  ];

  affiliations.forEach((affiliation = {}) => {
    const {
      businessName,
      affiliationPeriod,
      contractAmount,
      master = [],
    } = affiliation;

    // Dati principali
    csvData.push([
      businessName,
      affiliationPeriod,
      formatCurrency(contractAmount),
      formatCurrency((master[0] && master[0].quotaMaster) || 0),
      formatCurrency((master[1] && master[1].quotaMaster) || 0),
    ]);
  });

  // Totali

  csvData.push([
    '',
    '',
    formatCurrency(totals.contractAmount),
    '',
    formatCurrency(totals.quotaMaster),
  ]);

  return (
    <CSVLink
      data={csvData}
      filename={`ISCRIZIONI_${clientType.toUpperCase()}_${year}.csv`}
      separator={';'}
    >
      {children}
    </CSVLink>
  );
};
