import React from 'react';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import { formatCurrencyForCSV as formatCurrency } from '../../../lib/helpers/formatters';
import { useAuth } from '../../../commons/Auth';

export default (props) => {
  const [{ isAdmin }] = useAuth();
  const { children, data = [], year, businessName } = props;

  let csvData, invoices;

  csvData = [
    [
      'NOME FORNITORE',
      'NUMERO FATTURA',
      'DATA FATTURA',
      'MESE RENDICONTAZIONE',
      'AMMINISTRATORE DI CONDOMINIO',
      'IMPONIBILE',
      'IMPORTO DA INCASSARE',
      'INCASSI',
      'QUOTA MASTER',
      '% QUOTA MASTER',
      'QUOTA MASTER MATURATA',
      'DIRITTO A FATTURARE (MASTER)',
    ],
  ];

  if (isAdmin) csvData[0].push('INVITATO A FATTURARE');

  invoices = data;

  invoices.forEach((compenso = {}) => {
    const {
      supplier: { businessName: supplierName = '' } = {},
      number,
      date,
      accountability,
      admin: { businessName: adminName = '', type: adminType = '' } = {},
      gross,
      net,
      payments = [],
      earnedMasterShare,
      billableMasterShare,
      masterShare,
      masters = [],
      iafs = [],
    } = compenso;

    const row = [
      // 'NOME FORNITORE',
      supplierName,
      // 'NUMERO FATTURA',
      number,
      // 'DATA FATTURA',
      moment(date).format('DD/MM/YYYY'),
      // 'MESE RENDICONTAZIONE',
      accountability ? moment(accountability).format('MM/YYYY') : '',
      // 'AMMINISTRATORE DI CONDOMINIO',
      adminName,
      // 'IMPONIBILE',
      formatCurrency(net),
      // 'IMPORTO DA INCASSARE',
      formatCurrency(gross),
      // 'INCASSI',
      formatCurrency(payments.reduce((acc, val) => acc + val.gross, 0)),
      // 'QUOTA MASTER',
      formatCurrency(masterShare || 0),
      // '% QUOTA MASTER',
      '3',
      // 'QUOTA MASTER MATURATA',
      formatCurrency(earnedMasterShare || 0),
      // 'DIRITTO A FATTURARE (MASTER)'
      formatCurrency(billableMasterShare || 0),
    ];

    if (isAdmin) {
      const iafAmount = (iafs || []).reduce((acc, iaf = {}) => {
        const iafDetail = iaf.invoices || [];
        const [firstIAFDetail = {}] = iafDetail;
        return (
          acc + ((firstIAFDetail && firstIAFDetail.earnedMasterShare) || 0)
        );
      }, 0);
      row.push(formatCurrency(iafAmount));
    }

    csvData.push(row);
  });

  return (
    <CSVLink
      data={csvData}
      filename={`COMPENSI_VARIABILI_${businessName}_${year}.csv`}
      separator={';'}
    >
      {children}
    </CSVLink>
  );
};
