import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAlert } from 'react-alert';
import { compensi } from '../../lib/fetch/dashboard';
import DashboardDetailCompensiPresentational from './DashboardDetailCompensiPresentational';
import { useLoading } from '../../commons/Loading';
import { useAuth } from '../../commons/Auth';
import {
  useQueryParams,
  withDefault,
  StringParam,
  NumberParam,
} from 'use-query-params';
import axios from 'axios';
const { REACT_APP_DEFAULT_PAGINATION_LIMIT } = process.env;

function DashboardDetailCompensi(props) {
  const { showLoading, hideLoading } = useLoading();
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [{ token } = {}] = useAuth();
  const { t } = useTranslation('DASHBOARD');
  const alert = useAlert();

  const [selectedInvoices, setSelectedInvoices] = useState([]);

  const [query, setQuery] = useQueryParams({
    search: withDefault(StringParam, ''),
    admin: withDefault(StringParam, ''),
    customer: withDefault(StringParam, ''),
    number: withDefault(StringParam, ''),
    supplier: withDefault(StringParam, ''),
    offset: NumberParam,
    limit: withDefault(NumberParam, +REACT_APP_DEFAULT_PAGINATION_LIMIT || 50),
    paid: withDefault(StringParam, ''),
    gabettiInvoice: withDefault(StringParam, ''),
    year: NumberParam,
    accountabilityYear: NumberParam,
    accountabilityMonth: NumberParam,
    invoiceRef: StringParam,
    noIaf15: withDefault(StringParam, ''),
    invoice15Number: StringParam,
    noInvoice15: withDefault(StringParam, ''),
  });

  const {
    limit,
    offset,
    search,
    gabettiInvoice,
    paid,
    admin,
    customer,
    number,
    supplier,
    year,
    accountabilityYear,
    accountabilityMonth,
    invoiceRef,
    noIaf15,
    invoice15Number,
    noInvoice15,
  } = query;

  const fetchCompensi = async () => {
    showLoading();

    const { error, headers, data } = await compensi({
      token,
      year,
      search,
      limit,
      offset,
      gabettiInvoice,
      paid,
      admin,
      customer,
      number,
      supplier,
      accountabilityYear,
      accountabilityMonth,
      invoiceRef,
      noIaf15,
      invoice15Number,
      noInvoice15,
    });

    if (error) {
      hideLoading();
      alert.error(t('COMMON:GENERIC_ERROR'));
      return;
    }

    setData(data);
    setSelectedInvoices([]);
    setTotal(Number(headers['x-total-count']));
    hideLoading();
  };

  useEffect(() => {
    fetchCompensi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    year,
    search,
    offset,
    limit,
    gabettiInvoice,
    paid,
    admin,
    customer,
    number,
    supplier,
    accountabilityYear,
    accountabilityMonth,
    invoiceRef,
    noIaf15,
    invoice15Number,
    noInvoice15,
  ]);

  function onFilter(queryDiff) {
    if (
      (queryDiff.search && queryDiff.search !== search) || // first search
      (search && queryDiff.search === '') || // search deleted
      (queryDiff.admin && queryDiff.admin !== admin) || // first search
      (admin && queryDiff.admin === '') || // search deleted
      (queryDiff.customer && queryDiff.customer !== customer) || // first search
      (customer && queryDiff.customer === '') || // search deleted
      (queryDiff.number && queryDiff.number !== number) || // first search
      (number && queryDiff.number === '') || // search deleted
      (queryDiff.supplier && queryDiff.supplier !== supplier) || // first search
      (supplier && queryDiff.supplier === '') ||
      (queryDiff.invoiceRef && queryDiff.invoiceRef !== invoiceRef) ||
      (invoiceRef && queryDiff.invoiceRef === '') ||
      (queryDiff.accountabilityMonth &&
        queryDiff.accountabilityMonth !== accountabilityMonth) ||
      queryDiff.accountabilityMonth === '' ||
      (queryDiff.paid && queryDiff.paid !== paid) ||
      (paid && queryDiff.paid === '') ||
      (queryDiff.gabettiInvoice &&
        queryDiff.gabettiInvoice !== gabettiInvoice) ||
      (gabettiInvoice && queryDiff.gabettiInvoice === '') ||
      (queryDiff.noIaf15 && queryDiff.noIaf15 !== noIaf15) ||
      (noIaf15 && queryDiff.noIaf15 === '') ||
      (queryDiff.invoice15Number &&
        queryDiff.invoice15Number !== invoice15Number) ||
      (invoice15Number && queryDiff.invoice15Number === '') ||
      (queryDiff.noInvoice15 && queryDiff.noInvoice15 !== noInvoice15) ||
      (noInvoice15 && queryDiff.noInvoice15 === '') ||
      (queryDiff.limit && queryDiff.limit !== limit) ||
      (queryDiff.year && queryDiff.year !== year) ||
      (search && queryDiff.year === '')
    ) {
      queryDiff.offset = 0; // return to first page
    }
    if (
      (queryDiff.accountabilityYear &&
        queryDiff.accountabilityYear !== accountabilityYear) ||
      queryDiff.accountabilityYear === ''
    ) {
      queryDiff.accountabilityMonth = ''; // reset month
      queryDiff.offset = 0; // return to first page
    }
    setQuery(queryDiff);
  }

  const createGabettiInvoice = async (invoice) => {
    setSelectedInvoices([]);

    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/gabetti-invoices`,
      {
        ...invoice,
        invoices: selectedInvoices,
      },
      {
        headers: { Authorization: token },
      }
    );

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    alert.success(t('COMMON:SUCCESS_CREATE'));

    fetchCompensi();
  };

  function onSelectChange(_id) {
    if (selectedInvoices.includes(_id)) {
      return setSelectedInvoices((selectedInvoices) =>
        selectedInvoices.filter((invoiceId) => invoiceId !== _id)
      );
    }
    setSelectedInvoices((selectedInvoices) => [...selectedInvoices, _id]);
  }

  const onSelectAll = (data) => {
    setSelectedInvoices(data.map(({ _id }) => _id));
  };

  return (
    <DashboardDetailCompensiPresentational
      data={data}
      year={accountabilityYear}
      query={query}
      total={total}
      onFilter={onFilter}
      createGabettiInvoice={createGabettiInvoice}
      selectedInvoices={selectedInvoices}
      onSelectChange={onSelectChange}
      onSelectAll={onSelectAll}
      {...props}
    />
  );
}

export default DashboardDetailCompensi;
