import React from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import {
  CurrencyField,
  DataTable,
  Field,
  DateTimeField,
  BooleanField,
} from '../../commons/DataTable';
import ModalCashed from './ModalCashed';
import InvoicesRef from './InvoicesRef';
import ModalAmount from './ModalAmount';

function InvoicesDetailTable({
  data = [],
  total = 0,
  query = {},
  onFilter = () => {},
  onUpdate,
  bottomRow,
  showEmptyTable,
}) {
  const [t] = useTranslation('COMMON');

  return (
    <DataTable
      history
      data={data}
      loadingText={t('LOADING')}
      emptyText={t('NO_DATA')}
      total={total}
      offset={query.offset}
      limit={query.limit}
      onFilter={onFilter}
      bottomRow={bottomRow}
      showEmptyTable={showEmptyTable}
    >
      <Field title="Fornitore" source="businessName" className="text-left" />
      <InvoicesRef
        title="Fattura Di Riferimento"
        source="invoiceRef"
        onConfirm={onUpdate}
      >
        <Field source="invoiceRef" />
      </InvoicesRef>
      <DateTimeField
        title="Data Rif. Fattura"
        source="date"
        format="DD/MM/YYYY"
      />
      <CurrencyField source="amount" title={t('CONTACTS:IMPORT_INVOICE')} />
      <CurrencyField source="gabettiPerc" title={t('CONTACTS:GABETTI_PERC')} />
      <CurrencyField
        source="masterShares"
        title={t('CONTACTS:MASTER_SHARES')}
      />
      <CurrencyField source="adminPerc" title={t('CONTACTS:ADMIN_PERC')} />
      <CurrencyField source="vat" title={t('CONTACTS:IMPORT_INVOICE_VAT')} />
      <CurrencyField
        source="gross"
        title={t('CONTACTS:IMPORT_INVOICE_GROSS')}
      />
      <ModalCashed title="Incassata" source="cashed" onConfirm={onUpdate}>
        <BooleanField />
      </ModalCashed>
    </DataTable>
  );
}

export default withRouter(InvoicesDetailTable);
