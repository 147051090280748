import axios from 'axios';

export const getSignedUrl = async (key) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/signed-url`,
      {
        params: { key },
      }
    );

    if (response instanceof Error) {
      return { error: response };
    }

    return response;
  } catch (error) {
    return { error };
  }
};

export const deleteFile = async ({ id, key, token }) => {
  try {
    const response = await axios({
      method: 'delete',
      url: `${process.env.REACT_APP_API_URL}/profiles/${id}/files`,
      data: { key },
      headers: {
        Authorization: token,
      },
    });

    if (response instanceof Error) {
      return { error: response };
    }

    return response;
  } catch (error) {
    // Sentry TODO
    return { error };
  }
};
