import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  TabContent,
  TabPane,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  InputGroup,
  Input,
  InputGroupAddon,
} from 'reactstrap';
import {
  ContAffiliation,
  H4Styled,
  H5Styled,
  ContTitleAction,
  ContFlexRight,
  SummaryAmountCont,
  BtnOutlineGreen,
  BtnOutlineOrange,
  BtnOutlineRed,
} from '../../../styled-components';
import DateInput from '../../../commons/DateInput';
import ReceiptsTable from './ReceiptsTable';
import ModalReceipt from './ModalReceipt';
import { ReactComponent as IconPlus } from '../../../images/icn_plus_green.svg';
import moment from 'moment';
import AutoSuggest from '../../../commons/Autosuggest';
import Affiliation from './Affiliation';
import { ReactComponent as IconRenewed } from '../../../images/icn_rinnova.svg';
import ModalConfirm from '../../../commons/ModalConfirm';

const { REACT_APP_TAX_PERCENTAGE = '22' } = process.env;

const INITIAL_STATE = {
  _id: '',
  startDate: '',
  endDate: '',
  totalGross: '',
  totalNet: '',
  totalTax: Number(REACT_APP_TAX_PERCENTAGE),
  masters: [],
  payments: [],
};

const AffiliationsPresentational = ({
  getProfiles,
  affiliations,
  profiles,
  createAffiliation,
  updateAffiliation,
  renewAffiliation,
  deleteAffiliation,
  deletePayment,
  ...props
}) => {
  const [t] = useTranslation('CONTACTS');
  const formRef = useRef(null);

  const [masterOne, setMasterOne] = useState('');
  const [masterTwo, setMasterTwo] = useState('');

  const [activeAffiliationId, setActiveAffiliationId] = useState(
    INITIAL_STATE._id
  );
  const [startDate, setStartDate] = useState(INITIAL_STATE.startDate);
  const [endDate, setEndDate] = useState(INITIAL_STATE.endDate);
  const [totalGross, setTotalGross] = useState(INITIAL_STATE.totalGross);
  const [totalNet, setTotalNet] = useState(INITIAL_STATE.totalNet);
  const [totalTax, setTotalTax] = useState(INITIAL_STATE.totalTax);
  const [masters, setMasters] = useState(INITIAL_STATE.masters);
  const [payments, setPayments] = useState(INITIAL_STATE.payments);

  useEffect(() => {
    const activeAffiliation = affiliations[0] || {};

    const {
      _id,
      startDate,
      endDate,
      totalGross,
      totalTax,
      totalNet,
      masters,
      payments,
    } = activeAffiliation;

    setActiveAffiliationId(_id || INITIAL_STATE._id);
    setStartDate(startDate || INITIAL_STATE.startDate);
    setEndDate(endDate || INITIAL_STATE.endDate);
    setTotalGross(
      typeof totalGross === 'number'
        ? totalGross.toFixed(2)
        : INITIAL_STATE.totalGross
    );
    setTotalTax(
      typeof totalTax === 'number'
        ? totalTax.toFixed(2)
        : INITIAL_STATE.totalTax
    );
    setTotalNet(
      typeof totalNet === 'number'
        ? totalNet.toFixed(2)
        : INITIAL_STATE.totalNet
    );
    setMasters(masters || INITIAL_STATE.masters);
    setPayments(payments || INITIAL_STATE.payments);
  }, [affiliations]);

  useEffect(() => {
    if (startDate && !endDate) {
      setEndDate(
        moment(startDate).add(1, 'year').subtract(1, 'day').toISOString()
      );
    }
  }, [endDate, startDate]);

  useEffect(() => {
    setTotalGross(
      Number(
        (
          Number(totalNet) +
          (Number(totalNet) / 100) * Number(totalTax)
        ).toFixed(2)
      )
    );
  }, [totalNet, totalTax]);

  useEffect(() => {
    if (masters.length) {
      const { name: masterOne = '' } = masters[0] || {};
      const { name: masterTwo = '' } = masters[1] || {};

      setMasterOne(masterOne);
      setMasterTwo(masterTwo);
    }
  }, [masters]);

  const onAddPayment = async (payment) => {
    const amountNet = Number(
      ((payment.amountGross * 100) / (100 + +totalTax) || 0).toFixed(2)
    );
    const newPayments = [...payments, { ...payment, amountNet }];

    setPayments(newPayments);

    const data = {
      profileId: props.id,
      startDate,
      endDate,
      totalGross,
      totalTax,
      totalNet,
      masters,
      payments: newPayments,
    };

    updateAffiliation({
      id: activeAffiliationId,
      data: { ...data, payments: newPayments },
    });
  };

  function handleAffiliationSubmit() {
    const data = {
      profileId: props.id,
      startDate,
      endDate,
      totalGross,
      totalTax,
      totalNet,
      masters,
      payments,
    };

    data.masters = data.masters.map((master) => {
      return {
        ...master,
        totalContract: (totalNet * master.percentage) / 100,
      };
    });

    activeAffiliationId
      ? updateAffiliation({ id: activeAffiliationId, data })
      : createAffiliation(data);
  }

  function calculatePercentage(masterIndex) {
    return masters[masterIndex]
      ? Number(
          (Number(totalNet) * masters[masterIndex].percentage) / 100
        ).toFixed(2)
      : 0;
  }

  function calculateToReceive() {
    const receivedPaymentsTotal = payments.reduce(
      (acc, val) => acc + val.amountGross,
      0
    );

    return (totalGross - receivedPaymentsTotal).toFixed(2);
  }

  function handleRenew() {
    renewAffiliation({ id: activeAffiliationId });
  }

  function addMaster(suggestion, masterIndex) {
    const { _id, businessName, type } = suggestion;

    const allMasters = [...masters];
    allMasters[masterIndex] = { _id, name: businessName, type, percentage: 0 };

    setMasters(allMasters);
  }

  function clearMaster(masterIndex) {
    const newMasters = [...masters];
    newMasters.splice(masterIndex, 1);

    if (masterIndex === 0) {
      setMasterOne(masterTwo);
      setMasterTwo('');
    }

    setMasters(newMasters);
  }

  function handleDeletePayment(paymentIndex) {
    deletePayment(activeAffiliationId, paymentIndex);
  }

  const handleDeleteAffiliation = () => {
    deleteAffiliation(activeAffiliationId);
  };

  return (
    <Form onSubmit={(e) => e.preventDefault()}>
      <TabContent activeTab={'1'}>
        <TabPane tabId="1">
          <ContAffiliation ref={formRef}>
            <Row>
              <Col sm="12" md="6">
                <H5Styled>{t('DATA_AFFILIATION_TITLE')}</H5Styled>
              </Col>
              {activeAffiliationId && (
                <React.Fragment>
                  <Col sm="12" md="6" className="text-right">
                    <ContFlexRight>
                      <ModalConfirm
                        onConfirm={handleRenew}
                        text={t('CONFIRM_RENEW')}
                      >
                        <BtnOutlineOrange type="button" className="uppercase">
                          <IconRenewed fill="#ff8155" />
                          {t('RENEWED_AFFILIATION')}
                        </BtnOutlineOrange>
                      </ModalConfirm>
                      <ModalConfirm
                        onConfirm={handleDeleteAffiliation}
                        text={t('CONFIRM_DELETE')}
                      >
                        <BtnOutlineRed type="button" className="uppercase">
                          {t('DELETE_AFFILIATION')}
                        </BtnOutlineRed>
                      </ModalConfirm>
                    </ContFlexRight>
                  </Col>
                </React.Fragment>
              )}
            </Row>
            <br />
            <Row>
              <Col sm="12" md="6" lg="3">
                <FormGroup>
                  <Label>{t('AFFILIATION_START')}</Label>
                  <DateInput
                    value={startDate}
                    setMidday
                    onChange={setStartDate}
                  />
                </FormGroup>
              </Col>
              <Col sm="12" md="6" lg="3">
                <FormGroup>
                  <Label>{t('AFFILIATION_END')}</Label>
                  <DateInput value={endDate} setMidday onChange={setEndDate} />
                </FormGroup>
              </Col>
              <Col sm="12" md="6" lg="2">
                <FormGroup>
                  <Label>{t('IMPORT_NET')}</Label>
                  <InputGroup>
                    <Input
                      type="number"
                      step="0.01"
                      name="totalNet"
                      placeholder="0.00"
                      value={totalNet}
                      onChange={({ target }) => {
                        if (/^[\d]*\.?[\d]{0,2}$/.test(target.value)) {
                          setTotalNet(target.value);
                        }
                      }}
                    />
                    <InputGroupAddon addonType="prepend">€</InputGroupAddon>
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col sm="12" md="6" lg="2">
                <FormGroup>
                  <Label>{t('TAX_PERCENTAGE')}</Label>
                  <InputGroup>
                    <Input
                      type="number"
                      name="totalTax"
                      placeholder="0.00"
                      value={totalTax}
                      onChange={({ target }) => {
                        if (/^[\d]*\.?[\d]{0,2}$/.test(target.value)) {
                          setTotalTax(target.value);
                        }
                      }}
                    />
                    <InputGroupAddon addonType="prepend">%</InputGroupAddon>
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col sm="12" md="6" lg="2">
                <FormGroup>
                  <Label>{t('IMPORT_GROSS')}</Label>
                  <InputGroup>
                    <Input
                      type="number"
                      name="totalGross"
                      value={totalGross}
                      readOnly
                    />
                    <InputGroupAddon addonType="prepend">€</InputGroupAddon>
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
            <Row className="master-row">
              <Col sm="12" md="6" lg="4" className="master-col">
                <H4Styled>1</H4Styled>
                <FormGroup>
                  <Label>{t('MASTER')}</Label>
                  <AutoSuggest
                    onFetch={getProfiles}
                    placeholder="Cerca un utente..."
                    renderSuggestion={({ businessName }) => businessName}
                    getSuggestionValue={(suggestion) => suggestion.name}
                    onSelect={(suggestion) => addMaster(suggestion, 0)}
                    clearMaster={clearMaster}
                    masterIndex={0}
                    value={masterOne}
                    onChange={setMasterOne}
                  />
                </FormGroup>
              </Col>
              <Col sm="12" md="6" lg="4">
                <FormGroup>
                  <Label>{t('MASTER_TYPE')}</Label>
                  <Input
                    type="text"
                    name="masterOneType"
                    value={masters[0] ? t(masters[0].type) : ''}
                    readOnly
                  />
                </FormGroup>
              </Col>
              <Col sm="12" md="6" lg="2">
                <FormGroup>
                  <Label>{t('PERCENTAGE')}</Label>
                  <InputGroup>
                    <Input
                      type="number"
                      name="masterOnePercentage"
                      value={masters[0] ? masters[0].percentage : ''}
                      disabled={!masters[0]}
                      onChange={(e) => {
                        const newMasters = [...masters];
                        const master = newMasters[0];

                        master.percentage = Number(e.target.value);

                        setMasters(newMasters);
                      }}
                    />
                    <InputGroupAddon addonType="prepend">%</InputGroupAddon>
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col sm="12" md="6" lg="2">
                <FormGroup>
                  <Label>{t('MONEY_CORRESPONDING')}</Label>
                  <InputGroup>
                    <Input
                      type="number"
                      name="masterOneCommission"
                      value={calculatePercentage(0)}
                      readOnly
                    />
                    <InputGroupAddon addonType="prepend">€</InputGroupAddon>
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>

            <Row className="master-row">
              <Col sm="12" md="6" lg="4" className="master-col">
                <H4Styled>2</H4Styled>
                <FormGroup>
                  <Label>{t('MASTER')}</Label>
                  <AutoSuggest
                    onFetch={getProfiles}
                    placeholder="Cerca un utente..."
                    renderSuggestion={({ businessName }) => businessName}
                    getSuggestionValue={(suggestion) => suggestion.businessName}
                    onSelect={(suggestion) => addMaster(suggestion, 1)}
                    clearMaster={clearMaster}
                    masterIndex={1}
                    value={masterTwo}
                    onChange={setMasterTwo}
                  />
                </FormGroup>
              </Col>
              <Col sm="12" md="6" lg="4">
                <FormGroup>
                  <Label>{t('MASTER_TYPE')}</Label>
                  <Input
                    type="text"
                    name="masterTwoType"
                    value={masters[1] ? t(masters[1].type) : ''}
                    readOnly
                  />
                </FormGroup>
              </Col>
              <Col sm="12" md="6" lg="2">
                <FormGroup>
                  <Label>{t('PERCENTAGE')}</Label>
                  <InputGroup>
                    <Input
                      type="number"
                      name="masterTwoPercentage"
                      value={masters[1] ? masters[1].percentage : ''}
                      disabled={!masters[1]}
                      onChange={(e) => {
                        const newMasters = [...masters];
                        const master = newMasters[1];

                        master.percentage = Number(e.target.value);

                        setMasters(newMasters);
                      }}
                    />
                    <InputGroupAddon addonType="prepend">%</InputGroupAddon>
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col sm="12" md="6" lg="2">
                <FormGroup>
                  <Label>{t('MONEY_CORRESPONDING')}</Label>
                  <InputGroup>
                    <Input
                      type="number"
                      name="masterTwoCommission"
                      value={calculatePercentage(1)}
                      readOnly
                    />
                    <InputGroupAddon addonType="prepend">€</InputGroupAddon>
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
            <br />
            <ContFlexRight>
              <BtnOutlineGreen
                type="button"
                className="uppercase"
                onClick={handleAffiliationSubmit}
              >
                <IconPlus />
                {t('ADD_AFFILIATION')}
              </BtnOutlineGreen>
            </ContFlexRight>

            {activeAffiliationId && (
              <React.Fragment>
                <ContTitleAction>
                  <H5Styled>{t('RECEIPT_LIST_TITLE')}</H5Styled>
                  <ModalReceipt onAddPayment={onAddPayment} />
                </ContTitleAction>
                <ReceiptsTable
                  data={payments}
                  handleDeletePayment={handleDeletePayment}
                />
                <ContFlexRight>
                  <SummaryAmountCont>
                    <Row>
                      <Col xs="6">
                        <p>{t('TOTAL_TO_COLLECT')}</p>
                      </Col>
                      <Col xs="6" className="text-right">
                        <p>{(Number(totalGross) || 0).toFixed(2)} €</p>
                      </Col>
                    </Row>
                    <hr />
                    <Row>
                      <Col xs="6">
                        <p>{t('TOTAL_TO_RECEIVED')}</p>
                      </Col>
                      <Col xs="6" className="text-right">
                        <p>{calculateToReceive()} €</p>
                      </Col>
                    </Row>
                  </SummaryAmountCont>
                </ContFlexRight>
              </React.Fragment>
            )}
          </ContAffiliation>

          {affiliations.map((affiliation, index) => {
            if (index !== 0) {
              return (
                <Affiliation
                  key={affiliation._id}
                  affiliation={affiliation}
                  updateAffiliation={updateAffiliation}
                  deleteAffiliation={deleteAffiliation}
                  deletePayment={deletePayment}
                />
              );
            }

            return null;
          })}
        </TabPane>
      </TabContent>
    </Form>
  );
};

export default AffiliationsPresentational;
