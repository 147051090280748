import React from 'react';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { formatCurrencyForCSV as formatCurrency } from '../../lib/helpers/formatters';
import { BtnOutlineOrange } from '../../styled-components';

const ExportIAFCSVField = ({ data }) => {
  const [t] = useTranslation();

  if (!data.iafType) return '';

  return (
    <CSVExport data={{ data }}>
      <BtnOutlineOrange type="button" className="uppercase">
        {t('COMMON:CSV_EXPORT')}
      </BtnOutlineOrange>
    </CSVExport>
  );
};

const getCSVData = ({ data = {} }) => {
  const { amount } = data;
  const section = data.hasOwnProperty('suppliers')
    ? 'suppliers'
    : data.hasOwnProperty('gnet_admin')
    ? 'gnet_admin'
    : data.hasOwnProperty('invoices')
    ? 'invoices'
    : data.hasOwnProperty('insurances')
    ? 'insurances'
    : data.hasOwnProperty('insurancesStudio')
    ? 'insurancesStudio'
    : '';

  if (section === 'suppliers' || section === 'gnet_admin') {
    const CSVData = [
      [
        'RAGIONE SOCIALE',
        'PERIODO AFFILIAZIONE',
        'DATA INIZIO',
        'DATA FINE',
        'IMPORTO A CONTRATTO',
        'QUOTA MASTER',
        'INVITATO A FATTURARE',
      ],
    ];
    data[section].forEach(
      ({
        businessName,
        affiliationPeriod,
        startDate,
        endDate,
        contractAmount,
        masterContractAmount,
        iafRevenue,
      }) => {
        CSVData.push([
          businessName,
          affiliationPeriod,
          moment(startDate).format('DD/MM/yyyy'),
          moment(endDate).format('DD/MM/yyyy'),
          formatCurrency(+contractAmount),
          formatCurrency(+masterContractAmount),
          formatCurrency(+iafRevenue),
        ]);
      }
    );
    CSVData.push(['', '', '', '', '', '', formatCurrency(+amount)]);
    return CSVData || [];
  }

  if (section === 'invoices') {
    const CSVData = [
      [
        'NOME FORNITORE',
        'NUMERO FATTURA',
        'DATA',
        'MESE RENDICONTAZIONE',
        'NOME AMMINISTRATORE CONDOMINIO',
        'CONDOMINIO',
        'IMPONIBILE',
        'QUOTA MASTER (%)',
        'QUOTA MASTER',
        'INVITATO A FATTURARE',
      ],
    ];
    data.invoices.forEach(
      ({
        supplier: { businessName: supplierName } = {},
        number,
        date,
        accountability,
        admin: { businessName: adminName } = {},
        net,
        masterSharePerc,
        masterShare,
        earnedMasterShare,
        billableMasterShare,
        customer,
      }) => {
        CSVData.push([
          supplierName,
          number,
          moment(date).format('DD/MM/yyyy'),
          accountability ? moment(accountability).format('MM/yyyy') : '',
          adminName,
          customer,
          formatCurrency(+net),
          formatCurrency(+masterSharePerc),
          formatCurrency(+masterShare),
          formatCurrency(+billableMasterShare || +earnedMasterShare),
        ]);
      }
    );
    CSVData.push(['', '', '', '', '', '', '', '', '', formatCurrency(+amount)]);

    return CSVData || [];
  }

  if (section === 'insurances' || section === 'insurancesStudio') {
    const CSVData = [
      [
        'NOME FORNITORE',
        'NUMERO ASSICURAZIONE',
        'DATA',
        'MESE RENDICONTAZIONE',
        'DATA INIZIO',
        'DATA FINE',
        'NOME AMMINISTRATORE CONDOMINIO',
        'IMPONIBILE',
        'QUOTA MASTER',
        'INVITATO A FATTURARE',
      ],
    ];
    data[section].forEach(
      ({
        supplier: { businessName: supplierName } = {},
        insuranceNumber,
        date,
        accountability,
        startDate,
        endDate,
        admin: { businessName: adminName } = {},
        net,
        masterShare,
        earnedMasterShare,
        billableMasterShare,
      }) => {
        CSVData.push([
          supplierName,
          insuranceNumber,
          moment(date).format('DD/MM/yyyy'),
          accountability ? moment(accountability).format('MM/YYYY') : '',
          moment(startDate).format('DD/MM/yyyy'),
          moment(endDate).format('DD/MM/yyyy'),
          adminName,
          formatCurrency(+net),
          formatCurrency(+masterShare),
          formatCurrency(+billableMasterShare || +earnedMasterShare),
        ]);
      }
    );
    CSVData.push(['', '', '', '', '', '', '', '', '', formatCurrency(+amount)]);
    return CSVData || [];
  }

  return [['NO DATA']];
};

const CSVExport = ({ data = {}, children }) => {
  const csvData = getCSVData(data) || [];
  return (
    <CSVLink data={csvData} filename={`invito_a_fatturare.csv`} separator={';'}>
      {children}
    </CSVLink>
  );
};

export default ExportIAFCSVField;
