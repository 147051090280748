import React from 'react';
import { useTranslation } from 'react-i18next';
import Header from '../../commons/Header';
import Footer from '../../commons/Footer';
import BurgerMenu from '../../commons/BurgerMenu';
import { ReactComponent as IconPolizza } from '../../images/icn_polizza.svg';
import {
  ContFlexLeft,
  BtnRadiusWhite,
  ContFilterYear,
} from '../../styled-components';
import SearchInput from '../../commons/SearchInput';
import { Row, Col } from 'reactstrap';
import Label from 'reactstrap/lib/Label';
import FormGroup from 'reactstrap/lib/FormGroup';
import CustomInput from 'reactstrap/lib/CustomInput';
import Form from 'reactstrap/lib/Form';
import generateYearOptions from '../../lib/helpers/generateYearOptions';
import generateMonthOptions from '../../lib/helpers/generateMonthOptions';
import InsurancesTable from './InsurancesTable';

function InsurancesPresentational({ data = [], query, total, onFilter }) {
  const [t] = useTranslation('INVOICES');

  return (
    <div className="App">
      <BurgerMenu />

      <div className="corpo">
        <section>
          <Header
            title={t('INSURANCES:TITLE')}
            icon={<IconPolizza fill="#ffffff" />}
          />
        </section>

        <section>
          <Form className="m-t-20">
            <Row className="compensi-variabili-resp">
              <Col sm="12" md="6">
                <ContFlexLeft className="show-invoices">
                  <BtnRadiusWhite
                    className={
                      query.all === 'false'
                        ? 'eye-toggle show-me'
                        : 'eye-toggle'
                    }
                    onClick={() =>
                      onFilter({
                        all: query.all === 'true' ? 'false' : 'true',
                      })
                    }
                  >
                    <i className="far fa-eye"></i>
                  </BtnRadiusWhite>
                </ContFlexLeft>
              </Col>
              <Col sm="12" lg="6" xl="3">
                <ContFilterYear className="no-width">
                  <Row>
                    <Col xs="6">
                      <Label>{t('CONTACTS:TITLE_FILTER_YEAR')}</Label>
                    </Col>
                    <Col xs="6">
                      <FormGroup>
                        <CustomInput
                          type="select"
                          id="selectYear"
                          name="year"
                          value={query && query.year}
                          onChange={({ target: { value: year } }) =>
                            onFilter({ year })
                          }
                        >
                          {generateYearOptions()}
                        </CustomInput>
                      </FormGroup>
                    </Col>
                  </Row>
                </ContFilterYear>
              </Col>

              <Col lg="6" xl="3">
                <SearchInput
                  query={{ search: query && query.insuranceNumber }}
                  onFilter={({ search }) =>
                    onFilter({ insuranceNumber: search })
                  }
                  label={t('SEARCH:INSURANCE_NUMBER')}
                />
              </Col>
              <Col lg="6" xl="3">
                <SearchInput
                  query={{ search: query && query.supplier }}
                  onFilter={({ search }) => onFilter({ supplier: search })}
                  label={t('SEARCH:BY_SUPPLIER')}
                />
              </Col>
              <Col lg="6" xl="3">
                <SearchInput
                  query={{ search: query && query.adminName }}
                  onFilter={({ search }) => onFilter({ adminName: search })}
                  label={t('SEARCH:ADMINISTRATOR')}
                />
              </Col>
              <Col lg="6" xl="3">
                <ContFilterYear className="no-width">
                  <Row>
                    <Col xs="6">
                      <Label>{t('COMMON:TITLE_FILTER_ACC_YEAR')}</Label>
                    </Col>
                    <Col xs="6">
                      <FormGroup>
                        <CustomInput
                          type="select"
                          id="selectAccountabilityYear"
                          name="accountabilityYear"
                          className="year-select"
                          value={query && query.accountabilityYear}
                          onChange={({
                            target: { value: accountabilityYear },
                          }) => {
                            onFilter({ accountabilityYear });
                          }}
                        >
                          {generateYearOptions(true)}
                        </CustomInput>
                      </FormGroup>
                    </Col>
                  </Row>
                </ContFilterYear>
              </Col>
              <Col lg="6" xl="3">
                <ContFilterYear className="no-width">
                  <Row>
                    <Col xs="6">
                      <Label>{t('COMMON:TITLE_FILTER_ACC_MONTH')}</Label>
                    </Col>
                    <Col xs="6">
                      <FormGroup>
                        <CustomInput
                          type="select"
                          id="accountabilityMonth"
                          name="accountabilityMonth"
                          className="year-select"
                          disabled={!query.accountabilityYear}
                          value={
                            query && query.accountabilityMonth === null
                              ? ''
                              : query.accountabilityMonth
                          }
                          onChange={({
                            target: { value: accountabilityMonth },
                          }) => onFilter({ accountabilityMonth })}
                        >
                          {generateMonthOptions()}
                        </CustomInput>
                      </FormGroup>
                    </Col>
                  </Row>
                </ContFilterYear>
              </Col>
            </Row>
          </Form>
        </section>

        <section>
          <InsurancesTable
            data={data}
            total={total}
            query={query}
            onFilter={onFilter}
          />
        </section>
        <Footer />
      </div>
    </div>
  );
}

export default InsurancesPresentational;
