import React, { useState } from 'react';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  FormText,
} from 'reactstrap';
import { BtnOutlineRed, BtnOutlineGreen } from '../../styled-components';
import DateInput from '../../commons/DateInput';

function ModalInvoice10(props) {
  const { amount = 0, validationFn, onCreateInvoice10 } = props;
  const [t] = useTranslation('IAFS10');
  const alert = useAlert();
  const [modal, setModal] = useState(false);
  const [number, setNumber] = useState('');
  const [date, setDate] = useState('');
  const [formErrors, setFormErrors] = useState({});

  const toggle = () => {
    setNumber('');
    setDate('');
    setFormErrors({});
    setModal(!modal);
  };

  const onDateChange = (value) => {
    const date = new Date(value);
    setDate(
      new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
    );
  };

  function onConfirm() {
    const errors = {};

    if (!number || number === '') errors.number = true;
    if (!date) errors.date = true;

    if (Object.values(errors).some(Boolean)) return setFormErrors(errors);

    onCreateInvoice10({ number, date, amount: amount });
    toggle();
  }

  function validate() {
    const validation = validationFn();
    if (!validation.result) {
      alert.error(validation.message);
      return;
    }
    toggle();
  }

  return (
    <React.Fragment>
      <BtnOutlineGreen type="button" onClick={validate}>
        {t('NEW_INVOICE_10')}
      </BtnOutlineGreen>

      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>{t('NEW_INVOICE_10')}</ModalHeader>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            onConfirm();
          }}
        >
          <ModalBody>
            <div className="max-width-form">
              <Row>
                <Col xs="12">
                  <FormGroup>
                    <Label>{t('NUMBER')} *</Label>
                    <Input
                      id="number"
                      name="number"
                      type="text"
                      onChange={(e) => setNumber(e.target.value)}
                    />
                    {formErrors.number && (
                      <FormText className="error-message">
                        {t('COMMON:REQUIRED')}
                      </FormText>
                    )}
                  </FormGroup>
                </Col>
                <Col xs="12">
                  <FormGroup>
                    <Label>{t('DATE')} *</Label>
                    <DateInput value={date} onChange={onDateChange} />
                    {formErrors.date && (
                      <FormText className="error-message">
                        {t('COMMON:REQUIRED')}
                      </FormText>
                    )}
                  </FormGroup>
                </Col>
                <Col xs="12">
                  <FormGroup>
                    <Label>{t('AMOUNT')}</Label>
                    <Input
                      type="text"
                      name="amount"
                      value={`${amount} €`}
                      disabled={true}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </div>
          </ModalBody>
          <ModalFooter>
            <BtnOutlineRed type="button" className="uppercase" onClick={toggle}>
              {t('COMMON:CANCEL')}
            </BtnOutlineRed>
            <BtnOutlineGreen type="submit" className="uppercase">
              {t('COMMON:CONFIRM')}
            </BtnOutlineGreen>
          </ModalFooter>
        </Form>
      </Modal>
    </React.Fragment>
  );
}

export default ModalInvoice10;
