import React from 'react';
import styled from 'styled-components';

const Icon = styled.i`
  color: #b93544;
  font-size: 1.3rem;
`;

export default (props) => {
  return <Icon className={props.icon} />;
};
