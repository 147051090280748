import React from 'react';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import { Row, Col, FormGroup, Label, Button } from 'reactstrap';
import { PDocument } from '../../../styled-components';
import { getSignedUrl } from '../../../lib/fetch/files';

function FilesList({ files, handleDelete }) {
  const [t] = useTranslation('CONTACTS');
  const alert = useAlert();

  async function handleClick(key) {
    const { data, error } = await getSignedUrl(key);

    if (error) {
      alert.error(t('COMMON:GENERIC_ERROR'));
      return;
    }

    window.open(data.url, '_blank');
  }

  return (
    <Row>
      <Col sm="12" md="6" lg="6">
        <FormGroup>
          <Label for="selectFile">{t('FILE')}</Label>
          {Array.isArray(files) && files.length ? (
            files.map((file, index) => {
              return (
                <div key={index} onClick={() => handleClick(file.key)}>
                  <PDocument>
                    <Button
                      onClick={(e) => {
                        e.stopPropagation();

                        handleDelete(file.key);
                      }}
                    >
                      <i class="fas fa-times-circle"></i>
                    </Button>
                    {file.name}
                  </PDocument>
                </div>
              );
            })
          ) : (
            <PDocument>Nessun file allegato</PDocument>
          )}
        </FormGroup>
      </Col>
    </Row>
  );
}

export default FilesList;
