import React from 'react';
import { HashRouter as Router, Route } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import { I18nextProvider } from 'react-i18next';
import { QueryParamProvider } from 'use-query-params';
import { Provider as AlertProvider, positions } from 'react-alert';
import i18next from 'i18next';
import moment from 'moment';
import 'moment/locale/it';
import {
  AuthProvider,
  AdminRoute,
  PrivateRoute,
  PublicRoute,
  RoutesWrapper,
} from './commons/Auth';
import AlertTemplate from './commons/AlertTemplate';
import { LoadingProvider } from './commons/Loading';
import Login from './pages/Login';
import Forgot from './pages/Forgot';
import Reset from './pages/Reset';
import Signup from './pages/Signup';
import Verification from './pages/Verification';
import Dashboard from './pages/Dashboard';
import Profiles from './pages/Profiles';
import ProfileDetail from './pages/ProfileDetail';
import Info from './pages/Info';
import PageNotFound from './pages/PageNotFound';
import translations from './translations';
import './App.scss';
import DashboardDetail from './pages/DashboardDetail';
import DashboardDetailCompensi from './pages/DashboardDetailCompensi';
import MyProfile from './pages/MyProfile';
import MyFinancialReports from './pages/MyFinancialReports';
import MyAffiliations from './pages/MyAffiliations';
import MyInvoices from './pages/MyInvoices';
import MyRoyalties from './pages/MyRoyalties';
import MyInsurances from './pages/MyInsurances';
import DashboardListMasters from './pages/DashboardListMasters';
import Invoices from './pages/Invoices';
import DashboardDetailAssicurazioni from './pages/DashboardDetailAssicurazioni';
import Logs from './pages/Logs';
import InvoicesByMonth from './pages/InvoicesByMonth';
import Insurances from './pages/Insurances';
import ReportProfiles from './pages/ReportProfiles';
import MyCap from './pages/MyCap';
import Iafs15 from './pages/Iafs15';
import Invoices15 from './pages/Invoices15';
import Iafs10 from './pages/Iafs10';
import MyIafs from './pages/MyIafs';
import Invoices10 from './pages/Invoices10';

i18next.init({
  resources: translations,
  lng: 'it',
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
});

moment.locale('it');

export const FINANCIAL_REPORTS_ALLOWED_TYPES = Object.freeze([
  'BUILDING_ADMIN',
  'GNET_ADMIN',
  'ADMIN_AGENT',
  'COMMERCIAL',
]);

export const AFFILIATIONS_ALLOWED_TYPES = Object.freeze([
  'BUILDING_ADMIN',
  'GNET_ADMIN',
  'ADMIN_AGENT',
  'SUPPLIER_TEC',
  'SUPPLIER_ALBO',
  'SUPPLIER_INS',
  'COMMERCIAL',
  'SUPPLIER_INS',
]);

export const INVOICES_ALLOWED_TYPES = Object.freeze([
  'SUPPLIER_TEC',
  'SUPPLIER_ALBO',
]);

export const ROYALTIES_ALLOWED_TYPES = Object.freeze([
  'BUILDING_ADMIN',
  'GNET_ADMIN',
  'ADMIN_AGENT',
  'COMMERCIAL',
]);

export const AGENT_TYPES = Object.freeze(['BUILDING_ADMIN', 'GNET_ADMIN']);

export const INSURANCES_ALLOWED_TYPES = Object.freeze(['SUPPLIER_INS']);

function App() {
  return (
    <div className="App">
      <I18nextProvider i18n={i18next}>
        <AlertProvider
          template={AlertTemplate}
          position={positions.TOP_RIGHT}
          timeout={5000}
        >
          <CookiesProvider>
            <AuthProvider>
              <LoadingProvider>
                <Router>
                  <QueryParamProvider ReactRouterRoute={Route}>
                    <RoutesWrapper
                      adminRedirect="/dashboard"
                      publicRedirect="/"
                      privateRedirect="/my-profile"
                    >
                      <PublicRoute exact path="/" component={Login} />
                      <PublicRoute path="/signup" component={Signup} />
                      <PublicRoute path="/forgot" component={Forgot} />
                      <PublicRoute path="/reset" component={Reset} />
                      <PublicRoute
                        path="/verification"
                        component={Verification}
                      />
                      <AdminRoute
                        exact
                        path="/dashboard"
                        component={Dashboard}
                      />
                      <AdminRoute
                        exact
                        path="/dashboard/compensi"
                        component={DashboardDetailCompensi}
                      />
                      <AdminRoute
                        exact
                        path="/dashboard/masters"
                        component={DashboardListMasters}
                      />
                      <AdminRoute
                        exact
                        path="/dashboard/assicurazioni"
                        component={DashboardDetailAssicurazioni}
                      />
                      <AdminRoute
                        exact
                        path="/dashboard/:clientType"
                        component={DashboardDetail}
                      />

                      <AdminRoute exact path="/profiles" component={Profiles} />
                      <AdminRoute
                        path="/profiles/:id"
                        component={ProfileDetail}
                      />
                      <AdminRoute path="/ref-invoices" component={Invoices} />
                      <AdminRoute path="/insurances" component={Insurances} />
                      <AdminRoute
                        path="/by-month"
                        component={InvoicesByMonth}
                      />
                      <AdminRoute path="/iaf-15" component={Iafs15} />
                      <AdminRoute path="/invoices-15" component={Invoices15} />
                      <AdminRoute path="/iaf-10" component={Iafs10} />
                      <AdminRoute path="/invoices-10" component={Invoices10} />
                      <AdminRoute
                        exact
                        path="/report-profiles"
                        component={ReportProfiles}
                      />
                      <AdminRoute path="/logs" component={Logs} />
                      <PrivateRoute path="/my-profile" component={MyProfile} />
                      <PrivateRoute
                        path="/my-financial-reports"
                        component={MyFinancialReports}
                        allow={FINANCIAL_REPORTS_ALLOWED_TYPES}
                      />
                      <PrivateRoute
                        path="/my-affiliations"
                        component={MyAffiliations}
                        allow={AFFILIATIONS_ALLOWED_TYPES}
                      />
                      <PrivateRoute
                        path="/my-invoices"
                        component={MyInvoices}
                        allow={INVOICES_ALLOWED_TYPES}
                      />
                      <PrivateRoute
                        path="/my-by-month"
                        component={InvoicesByMonth}
                        allow={INVOICES_ALLOWED_TYPES}
                      />
                      <PrivateRoute
                        path="/my-royalties"
                        component={MyRoyalties}
                        allow={ROYALTIES_ALLOWED_TYPES}
                      />
                      <PrivateRoute
                        path="/my-cap"
                        component={MyCap}
                        allow={ROYALTIES_ALLOWED_TYPES}
                      />
                      <PrivateRoute
                        path="/my-invs-15"
                        component={Invoices15}
                        allow={INVOICES_ALLOWED_TYPES}
                      />
                      <PrivateRoute
                        path="/my-iafs"
                        component={MyIafs}
                        allow={ROYALTIES_ALLOWED_TYPES}
                      />
                      <PrivateRoute
                        path="/my-invs-10"
                        component={Invoices10}
                        allow={ROYALTIES_ALLOWED_TYPES}
                      />
                      <PrivateRoute
                        path="/my-insurance-royalties"
                        component={Insurances}
                        allow={ROYALTIES_ALLOWED_TYPES}
                      />
                      <PrivateRoute
                        path="/my-insurances"
                        component={MyInsurances}
                        allow={INSURANCES_ALLOWED_TYPES}
                      />
                      <PrivateRoute path="/info" component={Info} />
                      <Route component={PageNotFound} />
                    </RoutesWrapper>
                  </QueryParamProvider>
                </Router>
              </LoadingProvider>
            </AuthProvider>
          </CookiesProvider>
        </AlertProvider>
      </I18nextProvider>
    </div>
  );
}

export default App;
