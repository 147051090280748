import { getSection } from './getReportSection';

export const formatElement = (data = {}) => {
  const { iafs = [] } = data;
  //se l'elemento non ha alcun iaf
  if (iafs.length === 0) {
    return data;
  }

  // se ha iafs bisogna tenere conto dei pagamenti precedenti
  const section = getSection(data);
  let totalPaid = 0;

  switch (section) {
    case 'suppliers':
    case 'gnet_admin':
      const { revenue = 0 } = data;
      totalPaid = iafs.reduce(
        (acc, iaf = {}) => acc + (iaf[section][0]?.revenue || 0),
        0
      );
      if (revenue > totalPaid) {
        return { ...data, revenue: revenue - totalPaid };
      }
      return {};
    case 'invoices':
    case 'insurances':
    case 'insurancesStudio':
      const { billableMasterShare = 0 } = data;
      totalPaid = iafs.reduce(
        (acc, iaf = {}) => acc + (iaf[section][0]?.billableMasterShare || 0),
        0
      );

      if (billableMasterShare > totalPaid) {
        return {
          ...data,
          billableMasterShare: billableMasterShare - totalPaid,
        };
      }
      return {};
    default:
      return data;
  }
};
