import React from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import BurgerMenu from '../../commons/BurgerMenu';
import Header from '../../commons/Header';
import Footer from '../../commons/Footer';
import { ReactComponent as IconRegistries } from '../../images/icn_anagrafiche.svg';
import SearchAddCont from '../../commons/SearchAddCont';
import './Profiles.scss';
import {
  ContFilterYear,
  ContPaginationPage,
  VerticalSpacing20,
  ContFlexBetween,
} from '../../styled-components';
import { Row, Col, Label, FormGroup, CustomInput, Form } from 'reactstrap';
import Table from './Table';
import { ProfileStatus, ProfileTypes } from '../../lib/constants';
import generatePaginationOptions from '../../lib/helpers/generatePaginationOptions';

function ProfilesPresentational(props) {
  const [t] = useTranslation('CONTACTS');

  const { data, total = 0, query = {}, onFilter, isLoading = false } = props;

  return (
    <div className="App">
      <BurgerMenu />

      <div className="corpo">
        <section>
          <Header
            title={t('PAGES:REGISTRIES')}
            icon={<IconRegistries fill="#ffffff" />}
          />
        </section>
        <section>
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6">
              <Form>
                <ContFlexBetween className="user-filter">
                  <ContFilterYear>
                    <Row>
                      <Col xs="6">
                        <Label>{t('CONTACTS:TITLE_FILTER_TYPE')}</Label>
                      </Col>
                      <Col xs="6">
                        <FormGroup>
                          <CustomInput
                            type="select"
                            id="selectYear"
                            name="year"
                            value={query.type || ''}
                            onChange={(e) => onFilter({ type: e.target.value })}
                          >
                            <option value="">Tutti</option>
                            {ProfileTypes.map((type) => (
                              <option value={type} key={type}>
                                {t(type)}
                              </option>
                            ))}
                          </CustomInput>
                        </FormGroup>
                      </Col>
                    </Row>
                  </ContFilterYear>

                  <ContFilterYear>
                    <Row>
                      <Col xs="6">
                        <Label>{t('CONTACTS:ACTIVE_USER_FILTER_TYPE')}</Label>
                      </Col>
                      <Col xs="6">
                        <FormGroup>
                          <CustomInput
                            type="select"
                            id="selectProfileStatus"
                            name="profileStatus"
                            value={query.profileStatus || ''}
                            onChange={(e) =>
                              onFilter({ profileStatus: e.target.value })
                            }
                          >
                            <option value="">Tutti</option>
                            {ProfileStatus.map((type) => (
                              <option value={type} key={type}>
                                {t(type)}
                              </option>
                            ))}
                          </CustomInput>
                        </FormGroup>
                      </Col>
                    </Row>
                  </ContFilterYear>
                </ContFlexBetween>
              </Form>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6">
              <SearchAddCont
                isLoading={isLoading}
                onAdd={() => props.history.push('/profiles/create')}
                addLabel={t('COMMON:ADD')}
                onFilter={onFilter}
                query={query}
              />
            </div>
          </div>

          <VerticalSpacing20>
            <Table
              data={data}
              total={total}
              query={query}
              onFilter={onFilter}
              isLoading={isLoading}
            />
          </VerticalSpacing20>

          <ContPaginationPage>
            <p>{t('COMMON:PAGINATION_PER_PAGE')}</p>

            <FormGroup>
              <CustomInput
                type="select"
                className="pagination-select"
                id="perPage"
                name="perPage"
                value={query.limit}
                onChange={({ target: { value: limit } }) => {
                  onFilter({ limit });
                }}
              >
                {generatePaginationOptions()}
              </CustomInput>
            </FormGroup>
          </ContPaginationPage>
        </section>
        <Footer />
      </div>
    </div>
  );
}

export default withRouter(ProfilesPresentational);
