import './Variables.scss';
import variables from './variables';
import styled from 'styled-components';

export const ButtonGabetti = styled.button`
  border-radius: 0;
  background-color: ${variables.primary};
  border: 2px solid ${variables.primary};
  color: ${variables.white};
  font-weight: 600;
  padding: 8px 30px;
  font-family: ${variables.RobotoSlab};
  font-weight: 700;
  &:focus {
    box-shadow: none;
    outline: 0;
  }
`;

export const ButtonLogin = styled(ButtonGabetti)`
  background-color: ${variables.white};
  color: ${variables.primary};
  text-transform: uppercase;
  border-color: ${variables.white};
`;

export const ButtonSave = styled(ButtonLogin)`
  background-color: ${variables.blue};
  color: ${variables.white};
  border-color: ${variables.blue};
`;

export const ButtonDelete = styled(ButtonLogin)`
  background-color: transparent;
  color: ${variables.primary};
  border-color: ${variables.primary};
`;

export const BackButton = styled.button`
  padding: 0;
  border: 0;
  border-radius: 0;
  font-family: ${variables.RobotoSlab};
  color: ${variables.primary};
  font-weight: 700;
  background-color: transparent;
  font-size: 13px;
  svg {
    margin-right: 7px;
    width: 20px;
  }

  &:focus {
    outline: 0;
    box-shadow: none;
  }
`;

export const BtnRadiusWhite = styled.button`
  background-color: ${variables.white};
  border-radius: 50%;
  border: 0;
  margin: 0;
  padding: 5px !important;
  height: 40px;
  width: 40px;
  min-width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 3px 3px 8px 0px rgba(0, 0, 0, 0.12);
  img {
    width: 20px;
    height: 20px;
  }
  &:focus {
    outline: none;
  }
`;

export const BtnRadiusRed = styled(BtnRadiusWhite)`
  background-color: ${variables.primary};
`;

export const BtnOutlineRed = styled.button`
  background-color: transparent;
  border: 2px solid ${variables.primary};
  color: ${variables.primary};
  font-weight: 600;
  padding: 5px 30px;
  font-family: ${variables.RobotoSlab};
  font-weight: 700;
  font-size: 14px;
  svg {
    margin-right: 10px;
    width: 15px;
    height: auto;
  }
  &:focus {
    box-shadow: none;
    outline: 0;
  }
`;

export const BtnOutlineGreen = styled(BtnOutlineRed)`
  border: 2px solid ${variables.success};
  color: ${variables.success};
`;

export const BtnOutlineOrange = styled(BtnOutlineRed)`
  border: 2px solid ${variables.warning};
  color: ${variables.warning};
`;

export const BtnModalTable = styled(BtnOutlineOrange)`
  border: 0;
  background-color: ${variables.warning};
  color: ${variables.white};
  font-size: 12px;
  padding: 3px 15px;
  text-transform: uppercase;
`;

export const H1Login = styled.h1`
  text-align: center;
  color: ${variables.darkGrey};
  font-weight: 800;
  font-family: ${variables.RobotoSlab};
  font-size: 25px;
  margin-bottom: 20px;
  span {
    font-weight: 400;
    font-size: 18px;
  }
`;

export const H4Styled = styled.h4`
  font-family: ${variables.RobotoSlab};
  font-weight: 800;
  color: ${variables.primary};
  margin-bottom: 20px;
  font-size: 25px;
  span {
    display: flex;
    align-items: center;
    background-color: ${variables.darkGrey};
    color: ${variables.white};
    font-size: 20px;
    padding: 5px 15px;
    font-weight: 600;
    small {
      font-size: 15px;
      font-weight: 400;
      margin-left: 5px;
    }
  }
`;

export const H5Styled = styled.h5`
  font-weight: 700;
  color: ${variables.black};
  margin-bottom: 20px;
  font-size: 20px;
`;

export const H6Styled = styled.h6`
  font-family: ${variables.RobotoSlab};
  color: ${variables.primary};
  font-size: 16px;
`;

export const PNewItem = styled.p`
  font-weight: 800;
  color: ${variables.darkGrey};
  margin-bottom: 0px;
  margin-right: 7px;
  font-size: 16px;
`;

export const PDocument = styled.p`
  color: ${variables.black};
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: 700;
  display: flex;
  align-items: center;
  button {
    padding: 0 !important;
    margin: 0;
    line-height: 0;
    margin-right: 7px;
    background-color: transparent;
    border: 0;
    box-shadow: none;
    i {
      color: ${variables.lightPrimary};
      font-size: 18px;
    }
    &:hover {
      background-color: transparent;
      i {
        color: ${variables.primary};
      }
    }
  }
  &:hover {
    border: 0;
    background-color: transparent;
    cursor: pointer;
  }
`;

export const PNoActiveAffiliation = styled.p`
  text-align: center;
  font-family: ${variables.RobotoSlab};
  color: ${variables.primary};
  font-weight: 500;
  font-size: 20px;
  padding: 16px;
  background-color: ${variables.white};
  border: 2px dotted ${variables.lightPrimary};
`;

export const ContFlexRight = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  button {
    &:last-child {
      margin-left: 10px;
    }
  }
  @media only screen and (max-width: 767px) {
    &.responsive-alert {
      flex-direction: column-reverse;
      justify-content: flex-end;
      align-items: flex-end;
    }
  }
  @media only screen and (max-width: 991px) {
    &.actions-m-resp {
      margin-top: 16px;
      justify-content: center;
    }
  }
`;

export const ContFlexLeft = styled.div`
  width: 100%;
  display: flex;
  flex-dirextion: row;
  justify-content: flex-start;
  align-items: center;
  h4 {
    margin-bottom: 0;
    margin-left: 20px;
  }
`;

export const ContFlexBetween = styled(ContFlexLeft)`
  justify-content: space-between;
  h4 {
    margin-left: inherit;
  }
  &.user-filter {
    label {
      white-space: nowrap;
    }
    @media only screen and (max-width: 767px) {
      flex-direction: column;
    }
  }
`;

export const ContAffiliation = styled.div`
  width: 100%;
  padding: 30px 16px;
  background-color: #fafafa;
  margin-bottom: 20px;
  -webkit-box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.1);
`;

export const ContTitleAction = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 40px;
  h5 {
    margin-bottom: 0px;
    margin-right: 20px;
  }
`;

export const VerticalSpacing = styled.div`
  margin-top: 40px;
  margin-bottom: 40px;
`;

export const VerticalSpacing20 = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const SummaryAmountCont = styled.div`
  background-color: ${variables.white};
  border: 2px dotted ${variables.grey};
  min-width: 400px;
  padding: 18px 6px;
  margin-top: 20px;
  margin-bottom: 40px;
  .row {
    p {
      margin-bottom: 0;
      padding: 0 10px;
      font-weight: 600;
    }
    &:first-child {
      p {
        color: ${variables.darkGrey};
        font-size: 13px;
      }
    }
    &:last-child {
      p {
        color: ${variables.primary};
        font-size: 16px;
      }
    }
  }
`;

export const HeaderCollapse = styled.div`
  background-color: ${variables.darkGrey};
  width: 100%;
  padding: 16px;
  color: ${variables.white};
  margin-top: 20px;
  h6 {
    color: ${variables.white};
    margin-bottom: 0;
    font-family: ${variables.RobotoSlab};
  }
  &:hover {
    cursor: pointer;
  }
`;

export const ContFilterYear = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  width: 350px;
  margin-bottom: 20px;
  &.no-width {
    width: auto;
  }
  .col-6 {
    &:first-child {
      background-color: ${variables.primary};
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      label {
        color: ${variables.white};
        margin-bottom: 0;
        font-weight: 700;
      }
    }
    &:last-child {
      padding: 0;
      .form-group {
        margin-bottom: 0;
        select {
          border-bottom-color: ${variables.primary};
          border-left: 0;
          font-family: ${variables.RobotoSlab};
          font-weight: 700;
        }
      }
    }
  }
  @media only screen and (max-width: 767px) {
    width: 100%;
  }
`;

export const ContFilterCheckbox = styled.div`
  .custom-checkbox {
    padding-left: 1.8rem;
    .custom-control-input:checked ~ .custom-control-label::before {
      background-color: ${variables.primary};
    }
    .custom-control-label {
      font-style: initial;
      font-size: 15px;
      color: ${variables.black};
      font-family: ${variables.RobotoSlab};
      font-weight: 500;
      &:before {
        border-radius: 0;
        width: 1.3rem;
        height: 1.3rem;
        border: 2px solid ${variables.primary};
        left: -1.8rem;
        top: 0.1rem;
      }
      &:after {
        width: 1.3rem;
        height: 1.3rem;
        left: -1.75rem;
        top: 0.1rem;
      }
    }
  }
`;

export const InputLink = styled.div`
  display: flex;
  align-items: center;
  .form-group {
    width: calc(100% - 50px);
  }
  button {
    margin-left: 10px;
  }
`;

export const YellowAlert = styled.div`
  position: relative;
  background-color: ${variables.alert};
  max-width: 400px;
  padding: 10px;
  color: ${variables.black};
  margin-right: 40px;
  p {
    font-size: 12px;
    margin-bottom: 5px;
    span {
      font-family: ${variables.RobotoSlab};
      font-weight: 700;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  &::after {
    content: '';
    position: absolute;
    left: 100%;
    top: 37%;
    width: 0;
    height: 0;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-left: 15px solid ${variables.alert};
    clear: both;
  }
  @media only screen and (max-width: 767px) {
    margin-right: 0;
    margin-top: 20px;
    &::after {
      top: -15px;
      left: 45%;
      border-top: inherit;
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      border-bottom: 15px solid ${variables.alert};
    }
  }
`;

export const ContPaginationPage = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  margin-bottom: 20px;
  p {
    margin-bottom: 6px;
    font-size: 13px;
    font-family: ${variables.RobotoSlab};
    color: ${variables.darkGrey};
  }
  .form-group {
    margin-bottom: 0;
  }
`;
