import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Row, Col } from 'reactstrap';
import BurgerMenu from '../../commons/BurgerMenu';
import Header from '../../commons/Header';
import Footer from '../../commons/Footer';
import { ReactComponent as IconDashboard } from '../../images/icn_dashboard.svg';
import {
  H4Styled,
  VerticalSpacing,
  BtnRadiusWhite,
  ContFlexLeft,
  ContFlexRight,
  BtnOutlineOrange,
  ContFilterCheckbox,
  ContFilterYear,
  ContPaginationPage,
} from '../../styled-components';
import Table from './Table';
import { ReactComponent as IconBack } from '../../images/icn-back.svg';
import CSVAssicurazioniExport from './CSVAssicurazioniExport';
import SearchInput from '../../commons/SearchInput';
import ModalInvoiceRef from '../ProfileDetail/Insurances/ModalInvoiceRef';
import { FormGroup } from 'reactstrap';
import CustomInput from 'reactstrap/lib/CustomInput';
import TotalTr from './TotalTr';
import Label from 'reactstrap/lib/Label';
import generateYearOptions from '../../lib/helpers/generateYearOptions';
import generateMonthOptions from '../../lib/helpers/generateMonthOptions';
import generatePaginationOptions from '../../lib/helpers/generatePaginationOptions';
import createPrintablePage from './createPrintablePage';

function DashboardDetailAssicurazioniPresentational({
  data = [],
  total = 0,
  year,
  query,
  onFilter,
  history,
  selectedInsurances = [],
  onSelectChange,
  onSelectAll,
  createGabettiInvoice,
}) {
  const [t] = useTranslation('DASHBOARD');

  const solicitateNotPaidInsurances = () => {
    const notPaidInsurances = data.filter((insurance) => !insurance.paid);

    const myWindow = window.open('', '_blank');
    const windowText = createPrintablePage(notPaidInsurances);

    myWindow.document.write(windowText);
    myWindow.document.close();
  };

  return (
    <div className="App">
      <BurgerMenu />

      <div className="corpo">
        <section>
          <Header
            title={t('PAGES:DASHBOARD')}
            icon={<IconDashboard fill="#ffffff" />}
          />
        </section>

        <section>
          <Row>
            <Col md="12" lg="6">
              <ContFlexLeft>
                <BtnRadiusWhite
                  onClick={() => history.replace(`/dashboard?year=${year}`)}
                >
                  <IconBack />
                </BtnRadiusWhite>

                <H4Styled>{t('INSURANCES:TITLE')}</H4Styled>
              </ContFlexLeft>
            </Col>
            <Col md="12" lg="6">
              <ContFlexRight className="actions-m-resp">
                <BtnOutlineOrange
                  onClick={solicitateNotPaidInsurances}
                  className="uppercase"
                >
                  {t('COMMON:SOLICITATE_DOCUMENTS')}
                </BtnOutlineOrange>
                <CSVAssicurazioniExport data={data} year={year}>
                  <BtnOutlineOrange type="button" className="uppercase">
                    {t('COMMON:CSV_EXPORT')}
                  </BtnOutlineOrange>
                </CSVAssicurazioniExport>
                {selectedInsurances.length ? (
                  <ModalInvoiceRef
                    data={data}
                    onConfirm={createGabettiInvoice}
                    selectedDocuments={selectedInsurances}
                  />
                ) : null}
              </ContFlexRight>
            </Col>
          </Row>

          <VerticalSpacing>
            <Form>
              <Row>
                <Col sm="6" md="6" lg="4" xl="4">
                  <ContFilterYear className="no-width">
                    <Row>
                      <Col xs="6">
                        <Label>{t('COMMON:TITLE_FILTER_ACC_YEAR')}</Label>
                      </Col>
                      <Col xs="6">
                        <FormGroup>
                          <CustomInput
                            type="select"
                            id="selectAccountabilityYear"
                            name="accountabilityYear"
                            value={query && query.accountabilityYear}
                            onChange={({
                              target: { value: accountabilityYear },
                            }) => {
                              onFilter({ accountabilityYear });
                            }}
                          >
                            {generateYearOptions()}
                          </CustomInput>
                        </FormGroup>
                      </Col>
                    </Row>
                  </ContFilterYear>
                </Col>
                <Col sm="6" md="6" lg="4" xl="4">
                  <ContFilterYear className="no-width">
                    <Row>
                      <Col xs="6">
                        <Label>{t('COMMON:TITLE_FILTER_ACC_MONTH')}</Label>
                      </Col>
                      <Col xs="6">
                        <FormGroup>
                          <CustomInput
                            type="select"
                            id="accountabilityMonth"
                            name="accountabilityMonth"
                            disabled={!query.accountabilityYear}
                            value={
                              query && query.accountabilityMonth === null
                                ? ''
                                : query.accountabilityMonth
                            }
                            onChange={({
                              target: { value: accountabilityMonth },
                            }) => onFilter({ accountabilityMonth })}
                          >
                            {generateMonthOptions()}
                          </CustomInput>
                        </FormGroup>
                      </Col>
                    </Row>
                  </ContFilterYear>
                </Col>
              </Row>
              <Row className="compensi-variabili-resp">
                <Col sm="6" md="6" lg="4" xl="4">
                  <SearchInput
                    query={{ search: query && query.contractor }}
                    onFilter={({ search }) => onFilter({ contractor: search })}
                    label={t('SEARCH:CONTRACTOR')}
                  />
                </Col>
                <Col sm="6" md="6" lg="4" xl="4">
                  <SearchInput
                    query={{ search: query && query.supplier }}
                    onFilter={({ search }) => onFilter({ supplier: search })}
                    label={t('SEARCH:BY_SUPPLIER')}
                  />
                </Col>
                <Col sm="6" md="6" lg="4" xl="4">
                  <SearchInput
                    query={{ search: query && query.adminName }}
                    onFilter={({ search }) => onFilter({ adminName: search })}
                    label={t('SEARCH:ADMINISTRATOR')}
                  />
                </Col>
              </Row>
              <Row className="compensi-variabili-resp">
                <Col sm="6" md="6" lg="4" xl="4">
                  <SearchInput
                    query={{ search: query && query.insuranceNumber }}
                    onFilter={({ search }) =>
                      onFilter({ insuranceNumber: search })
                    }
                    label={t('SEARCH:INSURANCE_NUMBER')}
                  />
                </Col>
                <Col sm="6" md="6" lg="4" xl="4">
                  <SearchInput
                    query={{ search: query && query.invoiceRef }}
                    onFilter={({ search }) => onFilter({ invoiceRef: search })}
                    label={t('SEARCH:INVOICEREF')}
                  />
                </Col>
                <Col sm="12" md="12" lg="4" xl="4">
                  <FormGroup>
                    <ContFilterCheckbox>
                      <CustomInput
                        type="checkbox"
                        id="paidCheckbox"
                        label="Assicurazioni Pagate"
                        inline
                        checked={query && query.paid === 'true'}
                        onChange={({ target: { checked } }) => {
                          onFilter({ paid: checked ? 'true' : '' });
                        }}
                      />
                      <CustomInput
                        type="checkbox"
                        id="gabettiInvoiceCheckbox"
                        label="Senza fattura di riferimento"
                        inline
                        checked={query && query.gabettiInvoice === 'false'}
                        onChange={({ target: { checked } }) => {
                          onFilter({ gabettiInvoice: checked ? 'false' : '' });
                        }}
                      />
                    </ContFilterCheckbox>
                  </FormGroup>
                </Col>
              </Row>
            </Form>

            <br />

            <Form>
              <Table
                data={data}
                total={total}
                onFilter={onFilter}
                onSelectChange={onSelectChange}
                selectedInsurances={selectedInsurances}
                query={query}
                onSelectAll={onSelectAll}
                bottomRow={<TotalTr data={data} />}
              />
            </Form>
          </VerticalSpacing>

          <ContPaginationPage>
            <p>{t('COMMON:PAGINATION_PER_PAGE')}</p>

            <FormGroup>
              <CustomInput
                type="select"
                className="pagination-select"
                id="perPage"
                name="perPage"
                value={query.limit}
                onChange={({ target: { value: limit } }) => {
                  onFilter({ limit });
                }}
              >
                {generatePaginationOptions()}
              </CustomInput>
            </FormGroup>
          </ContPaginationPage>
        </section>

        <Footer />
      </div>
    </div>
  );
}

export default DashboardDetailAssicurazioniPresentational;
