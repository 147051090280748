import React from 'react';
import { formatCurrency } from '../../../lib/helpers/formatters';

const TotalTr = ({ data = [] }) => {
  if (!Array.isArray(data)) return null;

  const totals = data.reduce(
    (acc, row = {}) => {
      const {
        gross = 0,
        net = 0,
        gabettiPerc = 0,
        payments = [],
        masterShares = [],
        masterBillable = [],
        earnedMasterShares = [],
      } = row;
      const reducedPayments = payments.reduce(
        (acc, { gross } = {}) => acc + (gross || 0),
        0
      );
      const reducedMasterShares = masterShares.reduce(
        (acc, share) => acc + (share || 0),
        0
      );
      const reducedMasterBillable = masterBillable.reduce(
        (acc, billable) => acc + (billable || 0),
        0
      );
      const reducedEarnedMasterShares = earnedMasterShares.reduce(
        (acc, share) => acc + (share || 0),
        0
      );
      return {
        gross: acc.gross + gross,
        net: acc.net + net,
        gabettiPerc: acc.gabettiPerc + gabettiPerc,
        payments: acc.payments + reducedPayments,
        masterShares: acc.masterShares + reducedMasterShares,
        masterBillable: acc.masterBillable + reducedMasterBillable,
        earnedMasterShares: acc.earnedMasterShares + reducedEarnedMasterShares,
      };
    },
    {
      gross: 0,
      net: 0,
      gabettiPerc: 0,
      payments: 0,
      masterShares: 0,
      masterBillable: 0,
      earnedMasterShares: 0,
    }
  );

  return (
    <tr className="summary-tr">
      <td>-</td>
      <td>-</td>
      <td>-</td>
      <td>-</td>
      <td>-</td>
      <td>-</td>
      <td>-</td>
      <td>-</td>
      <td>-</td>
      <td className="txt-table-right">{formatCurrency(totals.net)}</td>
      <td className="txt-table-right">{formatCurrency(totals.gross)}</td>
      <td>{formatCurrency(totals.payments)}</td>
      <td>-</td>
    </tr>
  );
};

export default TotalTr;
