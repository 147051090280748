const initialState = {
  isAuthenticated: false,
  isAdmin: false,
  appLoaded: false,
  profile: {},
  token: null,
  email: '',
};

function authReducer(state, action = {}) {
  const { type, token, email, isAdmin, profile = {} } = action;

  switch (type) {
    case 'logout':
      return { ...initialState };
    case 'login':
      return {
        ...state,
        isAuthenticated: true,
        token,
        email,
        isAdmin,
      };
    case 'setProfile':
      return {
        ...state,
        appLoaded: true,
        profile,
      };
    default:
      return { ...state };
  }
}

export { initialState, authReducer };
