import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DataTable,
  Field,
  DateTimeField,
  CurrencyField,
  PaidButtonField,
} from '../../commons/DataTable';
import Invoice10CSVExport from './Invoice10CSVExport';

function AdminDataTable({
  data,
  total,
  offset,
  limit,
  onFilter,
  onPaidChange,
}) {
  const [t] = useTranslation('IAF_INVOICES');
  const [selectedAll, setSelectedAll] = useState(false);

  return (
    <DataTable
      data={data}
      total={total}
      offset={offset}
      limit={limit}
      onFilter={onFilter}
      emptyText={t('COMMON:NO_DATA')}
    >
      <Field title="Numero" source="number" className="text-center" />
      <Field title="Amministratore" source="admin" className="text-left" />
      <Field title="Fornitore" source="supplier" className="text-left" />
      <DateTimeField title="Data" source="date" format="DD/MM/YYYY" />
      <CurrencyField title="Importo" source="amount" />
      <PaidButtonField
        title="Stato"
        source="paid"
        onPaidChange={onPaidChange}
        showButton
      />
      <Invoice10CSVExport title="Esportazione" data={data} />
    </DataTable>
  );
}

export default AdminDataTable;
