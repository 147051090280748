import React from 'react';
import { CSVLink } from 'react-csv';
import { formatCurrencyForCSV as formatCurrency } from '../../lib/helpers/formatters';
import moment from 'moment';

export default ({ data, clientType = '', children, isAdmin }) => {
  const csvData = isAdmin
    ? [['NUMERO', 'AMMINISTRATORE', 'FORNITORE', 'DATA', 'IMPORTO']]
    : [['NUMERO', 'FORNITORE', 'DATA', 'IMPORTO']];

  data.forEach((invoice15) => {
    // Dati principali
    if (isAdmin)
      csvData.push([
        invoice15.number,
        invoice15.admin,
        invoice15.supplier,
        moment(invoice15.date).format('DD/MM/YYYY'),
        formatCurrency(invoice15.amount),
      ]);
    else
      csvData.push([
        invoice15.number,
        invoice15.supplier,
        moment(invoice15.date).format('DD/MM/YYYY'),
        formatCurrency(invoice15.amount),
      ]);
  });

  return (
    <CSVLink
      data={csvData}
      filename={`INVOICES_10${clientType.toUpperCase()}.csv`}
      separator={';'}
    >
      {children}
    </CSVLink>
  );
};
