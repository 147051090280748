import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DataTable,
  Field,
  DateTimeField,
  CurrencyField,
  BooleanField,
  PaidButtonField,
} from '../../commons/DataTable';
import RegularCheckbox from '../MyRoyalties/RegularCheckbox';
import Invoice15Export from './Invoice15CSVExport';

function AdminDataTable({
  data,
  total,
  offset,
  limit,
  onFilter,
  onPaidChange,
  selectedInvoices,
  onSelectChange,
  onSelectAll,
}) {
  const [t] = useTranslation('IAF_INVOICES');
  const [selectedAll, setSelectedAll] = useState(false);

  return (
    <DataTable
      data={data}
      total={total}
      offset={offset}
      limit={limit}
      onFilter={onFilter}
      emptyText={t('COMMON:NO_DATA')}
    >
      <RegularCheckbox
        selected={selectedInvoices}
        onSelectChange={onSelectChange}
        title={
          <input
            type="checkbox"
            value={selectedAll}
            onChange={({ target: { checked } }) => {
              setSelectedAll(checked);
              onSelectAll(checked);
            }}
          />
        }
      />
      <Field title="Numero" source="number" className="text-center" />
      <Field title="Amministratore" source="admin" className="text-left" />
      <BooleanField title="IAF 10% emesso" source="iaf10Id" />
      <Field title="Fornitore" source="supplier" className="text-left" />
      <DateTimeField title="Data" source="date" format="DD/MM/YYYY" />
      <CurrencyField title="Importo" source="amount" />
      <PaidButtonField
        title="Stato"
        source="paid"
        onPaidChange={onPaidChange}
        showButton
      />
      <BooleanField title="Approvata" source="approved" />
      <Invoice15Export title="Esportazione" data={data} />
    </DataTable>
  );
}

export default AdminDataTable;
