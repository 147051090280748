import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAlert } from 'react-alert';
import DashboardDetailPresentational from './DashboardDetailPresentational';
import { useLoading } from '../../commons/Loading';
import { useAuth } from '../../commons/Auth';
import { useParams } from 'react-router-dom';
import { getClientData } from '../../lib/fetch/dashboard';
import { useQueryParams, NumberParam } from 'use-query-params';

function DashboardDetail(props) {
  const { clientType } = useParams();
  const [{ token } = {}] = useAuth();
  const [data, setData] = useState({});

  const { t } = useTranslation('DASHBOARD');
  const alert = useAlert();
  const { showLoading, hideLoading } = useLoading();

  const [query] = useQueryParams({
    year: NumberParam,
  });

  const { year } = query;

  const fetchDashboard = async (clientType, filterYear, token) => {
    showLoading();

    const { data, error } = await getClientData({
      clientType,
      filterYear,
      token,
    });

    if (error) {
      hideLoading();
      alert.error(t('COMMON:GENERIC_ERROR'));
      return;
    }

    setData(data);
    hideLoading();
  };

  useEffect(() => {
    fetchDashboard(clientType, year, token);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DashboardDetailPresentational data={data} filterYear={year} {...props} />
  );
}

export default DashboardDetail;
