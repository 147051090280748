import React from 'react';
import { CSVLink } from 'react-csv';
import { BtnOutlineOrange } from '../../styled-components';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { formatCurrencyForCSV as formatCurrency } from '../../lib/helpers/formatters';

function IafInvoicesCSVExport(data) {
  const {
    data: { invoices, _id },
  } = data;
  const [t] = useTranslation();

  const csvData = [
    [
      'NOME FORNITORE',
      'NUMERO FATTURA',
      'DATA FATTURA',
      'MESE RENDICONTAZIONE',
      'CONDOMINIO',
      'AMMINISTRATORE DI CONDOMINIO',
      'IMPONIBILE',
    ],
  ];

  invoices.forEach((invoice) => {
    // Dati principali
    csvData.push([
      invoice.supplier.businessName,
      invoice.number,
      moment(invoice.date).format('DD/MM/YYYY'),
      moment(invoice.accountability).format('MM/YYYY'),
      invoice.customer,
      invoice.admin.businessName,
      formatCurrency(invoice.net),
    ]);
  });

  return (
    <React.Fragment>
      <CSVLink
        data={csvData}
        filename={`FATTURE_CAP_${_id.toUpperCase()}.csv`}
        separator={';'}
      >
        <BtnOutlineOrange type="button" className="uppercase">
          {t('COMMON:CSV_EXPORT')}
        </BtnOutlineOrange>
      </CSVLink>
    </React.Fragment>
  );
}

export default IafInvoicesCSVExport;
