import React from 'react';
import VariableRevenuesDetailPresentational from './VariableRevenuesDetailPresentational';

const VariableRevenuesDetail = (props) => {
  const {
    profile = {},
    reports = {},
    query = {},
    onAddBonus = () => {},
    onFilter = () => {},
  } = props;

  return (
    <VariableRevenuesDetailPresentational
      data={reports}
      onAddBonus={onAddBonus}
      onFilter={onFilter}
      query={query}
      profileType={profile.type}
      {...props}
    />
  );
};

export default VariableRevenuesDetail;
