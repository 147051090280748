import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  DataTable,
  Field,
  DateTimeField,
  CurrencyField,
  BooleanField,
} from '../../commons/DataTable';
import Invoice10CSVExport from './Invoice10CSVExport';

function CondAdminDataTable({ data, total, offset, limit, onFilter }) {
  const [t] = useTranslation('IAF_INVOICES');

  return (
    <DataTable
      data={data}
      total={total}
      offset={offset}
      limit={limit}
      onFilter={onFilter}
      emptyText={t('COMMON:NO_DATA')}
    >
      <Field title="Numero" source="number" className="text-center" />
      <Field title="Fornitore" source="supplier" className="text-left" />
      <DateTimeField title="Data" source="date" format="DD/MM/YYYY" />
      <CurrencyField title="Importo" source="amount" />
      <BooleanField title="Pagata" source="paid"></BooleanField>
      <Invoice10CSVExport title="Esportazione" data={data} />
    </DataTable>
  );
}

export default CondAdminDataTable;
