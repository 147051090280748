import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  useQueryParams,
  StringParam,
  withDefault,
  NumberParam,
} from 'use-query-params';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../commons/Auth';
import { useAlert } from 'react-alert';
import { useLoading } from '../../commons/Loading';
import RoyaltiesPresentational from './RoyaltiesPresentational';
import { IAF_TYPES, IAF_PERCENTAGE } from '../../lib/constants';
const { REACT_APP_DEFAULT_PAGINATION_LIMIT = 50 } = process.env;

const Royalties = ({ profile }) => {
  const [invoices, setInvoices] = useState([]);
  const [total, setTotal] = useState(0);
  const [{ token } = {}] = useAuth();
  const [t] = useTranslation();
  const { showLoading, hideLoading } = useLoading();
  const alert = useAlert();
  const [selectedInvoices, setSelectedInvoices] = useState([]);

  const [query, setQuery] = useQueryParams({
    all: withDefault(StringParam, 'false'),
    search: withDefault(
      StringParam,
      localStorage.getItem('myCompensiVariabiliSearch')
    ),
    offset: NumberParam,
    limit: withDefault(NumberParam, +REACT_APP_DEFAULT_PAGINATION_LIMIT),
    admin: withDefault(StringParam, ''),
    customer: withDefault(StringParam, ''),
    number: withDefault(StringParam, ''),
    supplier: withDefault(StringParam, ''),
    regular: withDefault(StringParam, ''),
    paid: withDefault(StringParam, ''),
    noIaf15: withDefault(StringParam, ''),
    afterApril23: withDefault(StringParam, ''),
    accountabilityYear: withDefault(NumberParam, new Date().getFullYear()),
    accountabilityMonth: NumberParam,
  });

  const {
    all,
    limit,
    offset,
    search,
    admin,
    customer,
    number,
    supplier,
    paid,
    noIaf15,
    afterApril23,
    regular,
    accountabilityMonth,
    accountabilityYear,
  } = query;

  useEffect(() => {
    fetchInvoices();
  }, [
    all,
    limit,
    offset,
    search,
    admin,
    customer,
    number,
    supplier,
    paid,
    noIaf15,
    afterApril23,
    regular,
    accountabilityMonth,
    accountabilityYear,
  ]);

  const fetchInvoices = async () => {
    showLoading();

    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/invoices/${profile._id}`,
      {
        headers: { Authorization: token },
        params: {
          all,
          limit,
          offset,
          search,
          admin,
          customer,
          number,
          supplier,
          paid,
          noIaf15,
          afterApril23,
          regular,
          accountabilityMonth,
          accountabilityYear,
        },
      }
    );

    hideLoading();

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    const { data = [], headers = {} } = response;
    setInvoices(data);
    setTotal(+headers['x-total-count']);
  };

  const onSingleInvoiceRegularChange = (invoiceId, regular = false) => {
    return axios.put(
      `${process.env.REACT_APP_API_URL}/invoices/${invoiceId}/regularize`,
      { regular },
      {
        headers: { Authorization: token },
      }
    );
  };

  const onRegularChange = async (invoiceId, regular = false) => {
    const response = await onSingleInvoiceRegularChange(invoiceId, regular);

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    await fetchInvoices();
  };

  const regularizeSelected = async () => {
    showLoading();
    const results = await Promise.all(
      selectedInvoices.map((id) => onSingleInvoiceRegularChange(id, true))
    );

    if (results.some((result) => result instanceof Error)) {
      alert.error(t('COMMON:GENERIC_ERROR'));
    }

    setSelectedInvoices([]);
    await fetchInvoices();
  };

  function onFilter(queryDiff) {
    setSelectedInvoices([]);

    if (queryDiff && typeof queryDiff.search === 'string') {
      localStorage.setItem('myCompensiVariabiliSearch', queryDiff.search);
    }

    if (
      (queryDiff.search && queryDiff.search !== search) || // first search
      (search && queryDiff.search === '') || // search deleted
      (queryDiff.admin && queryDiff.admin !== admin) || // first search
      (admin && queryDiff.admin === '') || // search deleted
      (queryDiff.regular && queryDiff.regular !== regular) || // first search
      (regular && queryDiff.regular === '') || // search deleted
      (queryDiff.paid && queryDiff.paid !== paid) || // first search
      (paid && queryDiff.paid === '') || // search deleted
      (queryDiff.noIaf15 && queryDiff.noIaf15 !== noIaf15) || // first search
      (noIaf15 && queryDiff.noIaf15 === '') || // search deleted
      (queryDiff.afterApril23 && queryDiff.afterApril23 !== afterApril23) || // first search
      (afterApril23 && queryDiff.afterApril23 === '') || // search deleted
      (queryDiff.customer && queryDiff.customer !== customer) || // first search
      (customer && queryDiff.customer === '') || // search deleted
      (queryDiff.number && queryDiff.number !== number) || // first search
      (number && queryDiff.number === '') || // search deleted
      (queryDiff.supplier && queryDiff.supplier !== supplier) || // first search
      (supplier && queryDiff.supplier === '') ||
      (queryDiff.accountabilityMonth &&
        queryDiff.accountabilityMonth !== accountabilityMonth) ||
      queryDiff.accountabilityMonth === '' ||
      (queryDiff.limit && queryDiff.limit !== limit)
    ) {
      queryDiff.offset = 0; // return to first page
    }
    if (
      (queryDiff.accountabilityYear &&
        queryDiff.accountabilityYear !== accountabilityYear) ||
      queryDiff.accountabilityYear === ''
    ) {
      queryDiff.accountabilityMonth = '';
      queryDiff.offset = 0; // return to first page
    }
    setQuery(queryDiff);
  }

  const onSelectChange = (_id) => {
    if (selectedInvoices.includes(_id)) {
      return setSelectedInvoices((selectedInvoices) =>
        selectedInvoices.filter((invoiceId) => invoiceId !== _id)
      );
    }
    setSelectedInvoices((selectedInvoices) => [...selectedInvoices, _id]);
  };

  const onSelectAll = (selectAll) => {
    if (!selectAll) {
      return setSelectedInvoices([]);
    }
    setSelectedInvoices(
      invoices.filter(({ regular }) => !regular).map(({ _id }) => _id)
    );
  };

  const searchSuppliers = async (value) => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/profiles/suppliers`,
      {
        headers: { Authorization: token },
        params: { search: value },
      }
    );

    if (response instanceof Error) {
      alert.error(t('COMMON:GENERIC_ERROR'));
      return [];
    }

    const { data = [] } = response;
    return data;
  };

  const onNewInvoiceConfirm = async (data) => {
    showLoading();

    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/invoices`,
      data,
      {
        headers: { Authorization: token },
      }
    );
    hideLoading();

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    alert.success(t('COMMON:SUCCESS_UPDATE'));
    fetchInvoices();
  };

  const onAddPayment = async ({ invoiceId, data }) => {
    showLoading();

    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/invoices/${invoiceId}/payments`,
      data,
      {
        headers: { Authorization: token },
      }
    );
    hideLoading();

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    alert.success(t('COMMON:SUCCESS_UPDATE'));
    fetchInvoices();
  };

  const createIaf = async (data) => {
    showLoading();

    const populatedSelected = selectedInvoices.length
      ? invoices.filter(({ _id }) => selectedInvoices.includes(_id))
      : invoices;

    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/iafs15`,
      {
        ...data,
        type: IAF_TYPES.ADMIN_TO_GABETTI,
        percentage: IAF_PERCENTAGE.IAF_15,
        invoices: populatedSelected,
      },
      {
        headers: { Authorization: token },
      }
    );
    hideLoading();

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    alert.success(t('COMMON:SUCCESS_UPDATE'));
    fetchInvoices();
  };

  return (
    <RoyaltiesPresentational
      data={invoices}
      profile={profile}
      query={query}
      total={total}
      onFilter={onFilter}
      onRegularChange={onRegularChange}
      selectedInvoices={selectedInvoices}
      onSelectChange={onSelectChange}
      searchSuppliers={searchSuppliers}
      onRegularizeSelectedConfirm={regularizeSelected}
      onSelectAll={onSelectAll}
      onNewInvoiceConfirm={onNewInvoiceConfirm}
      onAddPayment={onAddPayment}
      onCreateIaf={createIaf}
    />
  );
};

export default Royalties;
