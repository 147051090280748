import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAlert } from 'react-alert';
import { save, remove, create } from '../../../lib/fetch/profiles';
import EnergyPresentational from './EnergyPresentational';
import { useAuth } from '../../../commons/Auth';

const Energy = (props) => {
  const [{ token } = {}] = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [t] = useTranslation();
  const alert = useAlert();

  const { history, id, profile, setProfile } = props;

  const isUpdating = id !== 'create';

  async function updateProfile(updates) {
    setIsLoading(true);
    const { error } = await save({ id, data: updates, token });

    if (error) {
      setIsLoading(false);

      alert.error(t('COMMON:GENERIC_ERROR'));
      return;
    }

    setIsLoading(false);
    alert.success(t('COMMON:SUCCESS_UPDATE'));
  }

  async function deleteProfile() {
    setIsLoading(true);
    const { error } = await remove({ id, token });

    if (error) {
      setIsLoading(false);

      alert.error(t('COMMON:GENERIC_ERROR'));
      return;
    }

    setIsLoading(false);
    alert.success(t('COMMON:SUCCESS_DELETE'));
    history.push('/profiles');
  }

  async function createProfile(profile) {
    setIsLoading(true);
    const { error, data } = await create({ id, data: profile, token });

    if (error) {
      setIsLoading(false);
      setProfile({});

      alert.error(t('COMMON:GENERIC_ERROR'));
      return;
    }

    setIsLoading(false);
    alert.success(t('COMMON:SUCCESS_CREATE'));
    history.push(`/profiles/${data._id}/info`);
  }

  return (
    <EnergyPresentational
      data={profile}
      isLoading={isLoading}
      isUpdating={isUpdating}
      updateProfile={updateProfile}
      deleteProfile={deleteProfile}
      createProfile={createProfile}
      {...props}
    />
  );
};

export default Energy;
