import React from 'react';
import { CSVLink } from 'react-csv';
import { formatCurrencyForCSV as formatCurrency } from '../../lib/helpers/formatters';
import moment from 'moment';

export default ({ data, clientType = '', children, year }) => {
  const csvData = [
    [
      'DESCRIZIONE',
      'FORNITORE',
      'ANNO DI RIFERIMENTO',
      'DATA',
      'PERCENTUALE',
      'IMPORTO',
    ],
  ];

  data.forEach((iaf) => {
    // Dati principali
    csvData.push([
      iaf.description,
      iaf.supplierName,
      iaf.year,
      moment(iaf.date).format('DD/MM/YYYY'),
      `${iaf.percentage}%`,
      formatCurrency(iaf.amount),
    ]);
  });

  return (
    <CSVLink
      data={csvData}
      filename={`CAP${clientType.toUpperCase()}_${year}.csv`}
      separator={';'}
    >
      {children}
    </CSVLink>
  );
};
