import React from 'react';
import plusIcon from '../../images/icn-plus.svg';
import Spinner from './../Spinner';
import './SearchAddCont.scss';
import { ContFlexRight, PNewItem, BtnRadiusRed } from '../../styled-components';

function AddButton({ isLoading, onAdd, addLabel }) {
  return isLoading ? (
    <Spinner />
  ) : (
    <ContFlexRight>
      <PNewItem>{addLabel}</PNewItem>
      <BtnRadiusRed onClick={onAdd}>
        <img src={plusIcon} alt="Aggiungi" />
      </BtnRadiusRed>
    </ContFlexRight>
  );
}

export default AddButton;
