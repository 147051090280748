import React from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../commons/Auth';
import LogoGabetti from '../../images/logo-gabetti-tec.jpg';
import { ReactComponent as IconDashboard } from '../../images/icn_dashboard.svg';
import { ReactComponent as IconRegistries } from '../../images/icn_anagrafiche.svg';
import { ReactComponent as IconInvoices } from '../../images/icn_fatture.svg';
import { ReactComponent as IconPolizza } from '../../images/icn_polizza.svg';
import { ReactComponent as IconFinancial } from '../../images/icn_rendicontazioni.svg';
import { ReactComponent as IconProfile } from '../../images/icn_profilo.svg';
import { ReactComponent as IconAffiliations } from '../../images/icn-incontro.svg';
import { ReactComponent as IconLogs } from '../../images/icn-edit.svg';
import { ReactComponent as IconCalendar } from '../../images/icn-calendar.svg';
import {
  FINANCIAL_REPORTS_ALLOWED_TYPES,
  AFFILIATIONS_ALLOWED_TYPES,
  INVOICES_ALLOWED_TYPES,
  ROYALTIES_ALLOWED_TYPES,
  INSURANCES_ALLOWED_TYPES,
} from '../../App';
import './LinkMenu.scss';

function LinkMenu({ match }) {
  const [t] = useTranslation('PAGES');

  const { url } = match || {};
  const [{ isAdmin = false, profile = {} } = {}] = useAuth();
  const { type: profileType = '', master = false } = profile;

  const getActive = (url = '', paths = []) => {
    return paths.some((path) => url.startsWith(path)) ? 'active' : '';
  };

  return (
    <>
      <div className="cont-logo-menu">
        <img src={LogoGabetti} alt="Gabetti Tec" />
      </div>
      <div className="cont-link-menu">
        {isAdmin && (
          <Link to={'/dashboard'} className={getActive(url, ['/dashboard'])}>
            <IconDashboard className="hover-color" /> {t('DASHBOARD')}
          </Link>
        )}

        {isAdmin && (
          <Link
            to={'/profiles'}
            className={getActive(url, ['/profiles', '/profile-detail'])}
          >
            <IconRegistries className="hover-color" /> {t('REGISTRIES')}
          </Link>
        )}

        {isAdmin && (
          <Link
            to={'/ref-invoices'}
            className={getActive(url, ['/ref-invoices'])}
          >
            <IconFinancial className="hover-color" />
            {t('PAGES:INVOICES_REF')}
          </Link>
        )}

        {isAdmin && (
          <Link to={'/by-month'} className={getActive(url, ['/by-month'])}>
            <IconCalendar className="hover-color" />
            {t('PAGES:INVOICES_BY_MONTH')}
          </Link>
        )}

        {isAdmin && (
          <Link to={'/iaf-15'} className={getActive(url, ['/iaf-15'])}>
            <IconInvoices className="hover-color" />
            {t('PAGES:CAP15')}
          </Link>
        )}

        {isAdmin && (
          <Link
            to={'/invoices-15'}
            className={getActive(url, ['/invoices-15'])}
          >
            <IconInvoices className="hover-color" />
            {t('PAGES:INVOICES15')}
          </Link>
        )}

        {isAdmin && (
          <Link to={'/iaf-10'} className={getActive(url, ['/iaf-10'])}>
            <IconInvoices className="hover-color" />
            {t('PAGES:IAF10')}
          </Link>
        )}

        {isAdmin && (
          <Link
            to={'/invoices-10'}
            className={getActive(url, ['/invoices-10'])}
          >
            <IconInvoices className="hover-color" />
            {t('PAGES:INVOICES10')}
          </Link>
        )}

        {isAdmin && (
          <Link
            to={'/report-profiles'}
            className={getActive(url, ['/report-profiles'])}
          >
            <IconCalendar className="hover-color" /> {/*TODO CHANGE ICON*/}
            {t('PAGES:REPORT')}
          </Link>
        )}

        {isAdmin && (
          <Link to={'/logs'} className={getActive(url, ['/logs'])}>
            <IconLogs className="hover-color" />
            {t('PAGES:LOGS')}
          </Link>
        )}

        {!isAdmin && (
          <Link to={'/my-profile'} className={getActive(url, ['/my-profile'])}>
            <IconRegistries className="hover-color" /> {t('MY_PROFILE')}
          </Link>
        )}

        {!isAdmin && FINANCIAL_REPORTS_ALLOWED_TYPES.includes(profileType) && (
          <Link
            to={'/my-financial-reports'}
            className={getActive(url, ['/my-financial-reports'])}
          >
            <IconFinancial className="hover-color" />
            {t('PAGES:FINANCIAL_REPORTS')}
          </Link>
        )}

        {!isAdmin && AFFILIATIONS_ALLOWED_TYPES.includes(profileType) && (
          <Link
            to={'/my-affiliations'}
            className={getActive(url, ['/my-affiliations'])}
          >
            <IconAffiliations
              className="hover-color"
              style={{ height: '23px' }}
            />
            {t('CONTACTS:AFFILIATIONS')}
          </Link>
        )}

        {!isAdmin && INVOICES_ALLOWED_TYPES.includes(profileType) && (
          <Link
            to={'/my-invoices'}
            className={getActive(url, ['/my-invoices'])}
          >
            <IconInvoices className="hover-color" style={{ height: '23px' }} />
            {t('INVOICES')}
          </Link>
        )}

        {!isAdmin && INVOICES_ALLOWED_TYPES.includes(profileType) && (
          <Link to={'/my-invs-15'} className={getActive(url, ['/my-invs-15'])}>
            <IconInvoices className="hover-color" style={{ height: '23px' }} />
            {t('INVOICES15')}
          </Link>
        )}

        {!isAdmin && INVOICES_ALLOWED_TYPES.includes(profileType) && (
          <Link
            to={'/my-by-month'}
            className={getActive(url, ['/my-by-month'])}
          >
            <IconCalendar className="hover-color" />
            {t('PAGES:INVOICES_BY_MONTH')}
          </Link>
        )}

        {!isAdmin && ROYALTIES_ALLOWED_TYPES.includes(profileType) && (
          <Link
            to={'/my-royalties'}
            className={getActive(url, ['/my-royalties'])}
          >
            <IconInvoices className="hover-color" style={{ height: '23px' }} />
            {t('PAGES:INVOICES_AND_PAYMENTS')}
          </Link>
        )}

        {!isAdmin && ROYALTIES_ALLOWED_TYPES.includes(profileType) && (
          <Link to={'/my-cap'} className={getActive(url, ['/my-cap'])}>
            <IconInvoices className="hover-color" style={{ height: '23px' }} />
            {t('PAGES:CAP15')}
          </Link>
        )}

        {!isAdmin && ROYALTIES_ALLOWED_TYPES.includes(profileType) && (
          <Link to={'/my-iafs'} className={getActive(url, ['/my-iafs'])}>
            <IconInvoices className="hover-color" style={{ height: '23px' }} />
            {t('PAGES:IAF10')}
          </Link>
        )}

        {!isAdmin && ROYALTIES_ALLOWED_TYPES.includes(profileType) && (
          <Link to={'/my-invs-10'} className={getActive(url, ['/my-invs-10'])}>
            <IconInvoices className="hover-color" style={{ height: '23px' }} />
            {t('PAGES:INVOICES10')}
          </Link>
        )}

        {!isAdmin && ROYALTIES_ALLOWED_TYPES.includes(profileType) && (
          <Link
            to={'/my-insurance-royalties'}
            className={getActive(url, ['/my-insurance-royalties'])}
          >
            <IconPolizza className="hover-color" style={{ height: '23px' }} />
            {t('PAGES:INSURANCES')}
          </Link>
        )}

        {!isAdmin && INSURANCES_ALLOWED_TYPES.includes(profileType) && (
          <Link
            to={'/my-insurances'}
            className={getActive(url, ['/my-insurances'])}
          >
            <IconPolizza className="hover-color" style={{ height: '23px' }} />
            {t('PAGES:INSURANCES')}
          </Link>
        )}

        <Link to={'/info'} className={getActive(url, ['/info'])}>
          <IconProfile className="hover-color" /> {t('COMMON:INFO')}
        </Link>
      </div>
    </>
  );
}

export default withRouter(LinkMenu);
