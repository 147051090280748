import React from 'react';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import { formatCurrencyForCSV as formatCurrency } from '../../lib/helpers/formatters';

export default (props) => {
  const { children, data = [], year } = props;
  let csvData;

  csvData = [
    [
      'RAGIONE SOCIALE',
      'MASTER',
      'TIPOLOGIA',
      'TOTALE AFFILIAZIONE ALBO FORNITORI',
      'TOTALE AMMINISTRATORI RETE-G',
      'COMPENSI VARIABILI',
      'COMPENSI ASSICURAZIONI DA MASTER',
      'COMPENSI ASSICURAZIONI STUDIO',
      'COMPENSI VARIABILI ENERGIA',
      'TOTALE IMPONIBILE NETTO',
      'SALDO ANNO PRECEDENTE',
      'FATTURA/BONUS',
      'TOTALE',
    ],
  ];

  data.forEach((profile = {}) => {
    const {
      businessName,
      master,
      type,
      totals: {
        supplier,
        gnet_admin,
        billableVariableRevenues,
        billableVariableRevenuesInsurances,
        billableVariableRevenuesInsurancesStudio,
        billableVariableRevenuesEnergy,
        billableTotalNet,
        lastYearRevenue,
        bonuses,
        billableTotal,
      },
    } = profile;

    csvData.push([
      // 'RAGIONE SOCIALE',
      businessName,
      // 'MASTER',
      master ? 'SI' : 'NO',
      // 'TIPOLOGIA',
      type,
      // 'TOTALE AFFILIAZIONE ALBO FORNITORI',
      formatCurrency(supplier.revenue),
      // 'TOTALE AMMINISTRATORI RETE-G',
      formatCurrency(gnet_admin.revenue),
      // 'COMPENSI VARIABILI',
      formatCurrency(billableVariableRevenues),
      // 'COMPENSI ASSICURAZIONI DA MASTER',
      formatCurrency(billableVariableRevenuesInsurances),
      // 'COMPENSI ASSICURAZIONI STUDIO',
      formatCurrency(billableVariableRevenuesInsurancesStudio),
      // 'COMPENSI VARIABILI ENERGIA',
      formatCurrency(billableVariableRevenuesEnergy),
      // 'TOTALE IMPONIBILE NETTO',
      formatCurrency(billableTotalNet),
      // 'SALDO ANNO PRECEDENTE',
      formatCurrency(lastYearRevenue),
      // 'FATTURA/BONUS',
      formatCurrency(bonuses),
      // 'TOTALE',
      formatCurrency(billableTotal),
    ]);
  });

  return (
    <CSVLink
      data={csvData}
      filename={`REPORT_PROFILI_${year}.csv`}
      separator={';'}
    >
      {children}
    </CSVLink>
  );
};
