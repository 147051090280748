import React from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import {
  DataTable,
  Field,
  CurrencyField,
  DateTimeField,
} from '../../../commons/DataTable';
import ExportIAFCSVField from '../../../commons/DataTable/ExportIAFCSVField';
import ModifyBonusModal from './ModifyBonusModal';
import DeleteBonusModal from './DeleteBonusModal';

function InvoiceBonusTable({
  data,
  handleUpdateBonus,
  handleDeleteBonus,
  isAdmin,
  onFilter,
}) {
  const [t] = useTranslation('COMMON');

  function generateFields() {
    const fields = [
      <Field
        key={1}
        title="Descrizione"
        source="description"
        className="text-left"
      />,
      <Field key={2} title="Rif. Fattura" source="invoiceRef" />,
      <Field
        key={3}
        title={t('ROYALTY:IAF_ACCOUNTABILITY')}
        source="accountability"
      />,
      <DateTimeField key={4} title="Data" source="date" format="DD/MM/YYYY" />,
      <CurrencyField key={5} title="Importo" source="amount" />,
    ];

    if (isAdmin) {
      fields.push(
        <ModifyBonusModal
          key={5}
          title={t('COMMON:EDIT')}
          onConfirm={handleUpdateBonus}
        />,
        <DeleteBonusModal
          key={6}
          title={t('COMMON:DELETE')}
          onConfirm={handleDeleteBonus}
        />,
        <ExportIAFCSVField key={7} title={t('COMMON:CSV_EXPORT')} />
      );
    }

    return fields;
  }

  return (
    <DataTable
      onFilter={onFilter}
      data={data}
      loadingText={t('LOADING')}
      emptyText={t('NO_DATA')}
    >
      {generateFields()}
    </DataTable>
  );
}

export default withRouter(InvoiceBonusTable);
