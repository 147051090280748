import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
  Collapse,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  Badge,
  FormText,
} from 'reactstrap';
import {
  HeaderCollapse,
  ContAffiliation,
  H5Styled,
  ContFlexRight,
  ButtonSave,
  ContTitleAction,
  SummaryAmountCont,
  YellowAlert,
  BtnOutlineGreen,
  BtnOutlineRed,
} from '../../styled-components';
import DateInput from '../../commons/DateInput';
import './Invoices.scss';
import ModalReceipt from './ModalReceipt';
import ReceiptsTable from './ReceiptsTable';
import { formatCurrency } from '../../lib/helpers/formatters';
import AutoSuggest from '../../commons/Autosuggest';
import ModalConfirm from '../../commons/ModalConfirm';

const InvoiceCollapse = ({
  invoice = {},
  searchAdministrators,
  onUpdate,
  onAddPayment,
  onDeletePayment,
  onValidateInvoice,
  onInvoiceDelete,
}) => {
  const [t] = useTranslation('ROYALTY');
  const [isOpen, setIsOpen] = useState(false);

  const [formValues, setFormValues] = useState(
    JSON.parse(JSON.stringify(invoice))
  );
  const [formErrors, setFormErrors] = useState({
    customer: false,
    admin: false,
    number: false,
    date: false,
    supposedPaymentDate: false,
    gross: false,
    net: false,
    vatCode: false,
    accountability: false,
  });

  useEffect(() => {
    setFormValues(JSON.parse(JSON.stringify(invoice)));
  }, [invoice]);

  const toggle = () => setIsOpen(!isOpen);

  const {
    customer,
    admin,
    number,
    date,
    supposedPaymentDate,
    gross,
    net,
    vatCode,
    payments = [],
    paid = false,
    validated = false,
    regular = false,
    accountability,
  } = formValues;

  function onInputChange({ target }) {
    setFormValues((state) => ({ ...state, [target.name]: target.value }));
  }

  function onNumericInputChange({ target }) {
    if (/^[\d]*\.?[\d]{0,2}$/.test(target.value)) {
      setFormValues((state) => ({
        ...state,
        [target.name]: target.value,
      }));
    }
  }

  const renderSuggestion = ({ businessName = '' }) => businessName;
  const getSuggestionValue = ({ businessName = '' }) => businessName;
  function onSuggestionSelect({ _id, businessName }) {
    setFormValues((state) => ({
      ...state,
      admin: { _id, businessName },
    }));
  }

  function onInvoiceUpdate() {
    const errors = {};

    if (!customer) errors.customer = true;
    if (!admin._id) errors.admin = true;
    if (!number) errors.number = true;
    if (!date) errors.date = true;
    if (!supposedPaymentDate) errors.supposedPaymentDate = true;
    if (!gross) errors.gross = true;
    if (!net) errors.net = true;

    if (Object.keys(errors).length) {
      return setFormErrors(errors);
    }

    onUpdate({
      ...formValues,
      admin: formValues.admin._id,
      net: parseFloat(formValues.net),
      gross: parseFloat(formValues.gross),
    });
  }

  function handleInvoiceDelete() {
    onInvoiceDelete(invoice._id);
  }

  const totalPayments = payments.reduce((acc, val) => acc + val.gross, 0);
  const toPay = gross - totalPayments || 0;

  return (
    <React.Fragment>
      <HeaderCollapse onClick={toggle}>
        <Row className="align-center">
          <Col xs="8">
            <h6>
              Condominio: <span className="text-bold">{customer}</span>
              <br />
              Amministratore:{' '}
              <span className="text-bold">{admin.businessName}</span>
              <br />
              Fattura n. <span className="text-bold">{number}</span> del{' '}
              <span className="text-bold">
                {moment(date).format('DD-MM-YYYY')}
              </span>
              <br />
              Da incassare:{' '}
              <span className="text-bold">{formatCurrency(toPay)}</span> di{' '}
              <span className="text-bold">{formatCurrency(gross)}</span>
            </h6>
          </Col>
          <Col xs="4" className="text-right">
            {validated ? (
              <Badge className="pay">CONVALIDATA</Badge>
            ) : (
              <Badge className="not-pay">NON CONVALIDATA</Badge>
            )}
            {regular ? (
              <Badge className="pay">REGOLARIZZATA</Badge>
            ) : (
              <Badge className="not-pay">NON REGOLARIZZATA</Badge>
            )}
            {paid ? (
              <Badge className="pay">PAGATA</Badge>
            ) : (
              <Badge className="not-pay">NON PAGATA</Badge>
            )}

            <i className="fas fa-chevron-down"></i>
          </Col>
        </Row>
      </HeaderCollapse>
      <Collapse isOpen={isOpen}>
        <ContAffiliation>
          <Form>
            <Row>
              <Col sm="4" md="4">
                <H5Styled>Dati fattura</H5Styled>
              </Col>
              {!validated && (
                <Col sm="8" md="8" className="text-right">
                  <BtnOutlineGreen
                    type="button"
                    className="uppercase"
                    onClick={onInvoiceUpdate}
                  >
                    Salva
                  </BtnOutlineGreen>{' '}
                  <ModalConfirm
                    text={t('INVOICES:CONFIRM_DELETE')}
                    style={{ display: 'inline-block' }}
                    onConfirm={handleInvoiceDelete}
                  >
                    <BtnOutlineRed type="button" className="uppercase">
                      Elimina
                    </BtnOutlineRed>
                  </ModalConfirm>
                </Col>
              )}
            </Row>

            <Row>
              <Col xs="12" md="6" lg="4">
                <FormGroup>
                  <Label>{t('CUSTOMER_REFERENCE')} *</Label>
                  <Input
                    type="text"
                    name="customer"
                    value={customer}
                    onChange={onInputChange}
                    readOnly={validated}
                  />
                  {formErrors.customer && (
                    <FormText className="error-message">
                      {t('COMMON:REQUIRED')}
                    </FormText>
                  )}
                </FormGroup>
              </Col>

              <Col xs="12" md="6" lg="4">
                <FormGroup>
                  <Label>{t('AMMINISTRATOR')} *</Label>
                  <AutoSuggest
                    placeholder="Cerca un amministratore..."
                    onFetch={!validated ? searchAdministrators : () => []}
                    onSelect={onSuggestionSelect}
                    renderSuggestion={renderSuggestion}
                    getSuggestionValue={getSuggestionValue}
                    value={admin.businessName}
                    onChange={(businessName) => {
                      if (validated) return;

                      setFormValues((state) => ({
                        ...state,
                        admin: { ...admin, businessName },
                      }));
                    }}
                  />
                  {formErrors.admin._id && (
                    <FormText className="error-message">
                      {t('COMMON:REQUIRED')}
                    </FormText>
                  )}
                </FormGroup>
              </Col>

              <Col xs="12" md="6" lg="4">
                <FormGroup>
                  <Label>{t('INVOICE_NUMBER')} *</Label>
                  <Input
                    type="text"
                    name="number"
                    value={number}
                    onChange={onInputChange}
                    readOnly={validated}
                  />
                  {formErrors.number && (
                    <FormText className="error-message">
                      {t('COMMON:REQUIRED')}
                    </FormText>
                  )}
                </FormGroup>
              </Col>
              <Col xs="12" md="6" lg="4">
                <FormGroup>
                  <Label>{t('CONTACTS:VAT_FISCAL')}</Label>
                  <Input
                    type="text"
                    name="vatCode"
                    value={vatCode}
                    onChange={onInputChange}
                    readOnly={validated}
                  />
                  {formErrors.vatCode && (
                    <FormText className="error-message">
                      {t('COMMON:REQUIRED')}
                    </FormText>
                  )}
                </FormGroup>
              </Col>
              <Col xs="12" md="6" lg="4">
                <FormGroup>
                  <Label>{t('INVOICE_DATE')} *</Label>
                  <DateInput
                    name="date"
                    value={date}
                    onChange={(date) => {
                      if (validated) return;
                      setFormValues((state) => ({ ...state, date }));
                    }}
                    readOnly={validated}
                  />
                  {formErrors.date && (
                    <FormText className="error-message">
                      {t('COMMON:REQUIRED')}
                    </FormText>
                  )}
                </FormGroup>
              </Col>

              <Col xs="12" md="6" lg="4">
                <FormGroup>
                  <Label>{t('EXPIRY_DATE')} *</Label>
                  <DateInput
                    name="supposedPaymentDate"
                    value={supposedPaymentDate}
                    onChange={(supposedPaymentDate) => {
                      if (validated) return;
                      setFormValues((state) => ({
                        ...state,
                        supposedPaymentDate,
                      }));
                    }}
                    readOnly={validated}
                  />
                  {formErrors.supposedPaymentDate && (
                    <FormText className="error-message">
                      {t('COMMON:REQUIRED')}
                    </FormText>
                  )}
                </FormGroup>
              </Col>

              <Col xs="12" md="6" lg="4">
                <FormGroup>
                  <Label>{t('CONTACTS:IMPORT_NET')} *</Label>
                  <InputGroup>
                    <Input
                      type="number"
                      step="0.01"
                      placeholder="0.00"
                      name="net"
                      value={net}
                      onChange={onNumericInputChange}
                      readOnly={validated}
                    />
                    <InputGroupAddon addonType="prepend">€</InputGroupAddon>
                  </InputGroup>
                  {formErrors.net && (
                    <FormText className="error-message">
                      {t('COMMON:REQUIRED')}
                    </FormText>
                  )}
                </FormGroup>
              </Col>

              <Col xs="12" md="6" lg="4">
                <FormGroup>
                  <Label>{t('CONTACTS:IMPORT_TO_RECEIVE')} *</Label>
                  <InputGroup>
                    <Input
                      type="number"
                      step="0.01"
                      placeholder="0.00"
                      name="gross"
                      value={gross}
                      onChange={onNumericInputChange}
                      readOnly={validated}
                    />
                    <InputGroupAddon addonType="prepend">€</InputGroupAddon>
                  </InputGroup>
                  {formErrors.gross && (
                    <FormText className="error-message">
                      {t('COMMON:REQUIRED')}
                    </FormText>
                  )}
                </FormGroup>
              </Col>

              <Col xs="12" md="6" lg="4">
                <FormGroup>
                  <Label>{t('ACC_DATE')}</Label>
                  <DateInput
                    name="accountability"
                    value={accountability}
                    readOnly={validated}
                    showOnlyMonth
                    setMidday
                    onChange={(accountability) => {
                      setFormValues((state) => ({
                        ...state,
                        accountability,
                      }));
                    }}
                  />
                  {formErrors.accountability && (
                    <FormText className="error-message">
                      {t('COMMON:REQUIRED')}
                    </FormText>
                  )}
                </FormGroup>
              </Col>
            </Row>

            {!validated && (
              <ContFlexRight className="responsive-alert">
                <YellowAlert>
                  <p className="title">
                    <span>ATTENZIONE!</span>
                  </p>
                  <p>
                    I dati non sono ancora stati convalidati.
                    <br />
                    Una volta trasmessi, non saranno più modificabili
                  </p>
                </YellowAlert>
                <ModalConfirm
                  text={t('CONTACTS:CONFRIM_VALIDATE_INVOICE')}
                  onConfirm={() => {
                    onValidateInvoice(invoice._id);
                  }}
                >
                  <ButtonSave type="button">{t('VALIDATION_BNT')}</ButtonSave>
                </ModalConfirm>
              </ContFlexRight>
            )}

            <ContTitleAction>
              <H5Styled>{t('CONTACTS:RECEIPT_LIST_TITLE')}</H5Styled>
              <ModalReceipt invoice={invoice} onAddPayment={onAddPayment} />
            </ContTitleAction>
            <ReceiptsTable data={invoice.payments} readOnly={validated} />
            <ContFlexRight>
              <SummaryAmountCont style={{ marginBottom: '0' }}>
                <Row>
                  <Col xs="6">
                    <p>{t('CONTACTS:TOTAL_TO_COLLECT')}</p>
                  </Col>
                  <Col xs="6" className="text-right">
                    <p>{formatCurrency(gross)}</p>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col xs="6">
                    <p>{t('CONTACTS:TOTAL_TO_RECEIVED')}</p>
                  </Col>
                  <Col xs="6" className="text-right">
                    <p>{formatCurrency(gross - totalPayments)}</p>
                  </Col>
                </Row>
              </SummaryAmountCont>
            </ContFlexRight>
          </Form>
        </ContAffiliation>
      </Collapse>
    </React.Fragment>
  );
};

export default InvoiceCollapse;
