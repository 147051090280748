import React from 'react';
import { formatCurrency } from '../../../lib/helpers/formatters';

const TotalTr = ({ totals = {} }) => {
  const { netTotals = 0, grossTotals = 0, paymentsTotals = 0 } = totals;

  return (
    <tr className="summary-tr">
      <td>-</td>
      <td>-</td>
      <td>-</td>
      <td>-</td>
      <td>-</td>
      <td>-</td>
      <td>-</td>
      <td>-</td>
      <td>-</td>
      <td>-</td>
      <td>-</td>
      <td className="txt-table-right">{formatCurrency(netTotals)}</td>
      <td className="txt-table-right">{formatCurrency(grossTotals)}</td>
      <td className="txt-table-right">{formatCurrency(paymentsTotals)}</td>
      <td>-</td>
      <td>-</td>
      <td>-</td>
      <td>-</td>
    </tr>
  );
};

export default TotalTr;
