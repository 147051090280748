import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { BtnOutlineRed, BtnOutlineGreen } from '../../../styled-components';

const Icon = styled.i`
  color: #b93544;
  font-size: 1.3rem;
  cursor: pointer;
`;

export default ({ onConfirm, data }) => {
  const [t] = useTranslation();
  const [modal, setModal] = useState(false);

  const toggle = () => {
    setModal(!modal);
  };

  function onDeleteConfirm() {
    onConfirm(data._id);
    toggle();
  }

  return (
    <React.Fragment>
      <Icon className="far fa-trash-alt" onClick={toggle} />
      <Modal isOpen={modal} fade={false} toggle={toggle}>
        <ModalHeader toggle={toggle}>Conferma Eliminazione</ModalHeader>
        <ModalBody>
          <p>Sei sicuro di voler eliminare lo IAF?</p>
        </ModalBody>
        <ModalFooter>
          <BtnOutlineRed type="button" className="uppercase" onClick={toggle}>
            {t('COMMON:CANCEL')}
          </BtnOutlineRed>
          <BtnOutlineGreen
            type="button"
            className="uppercase"
            onClick={onDeleteConfirm}
          >
            {t('COMMON:CONFIRM')}
          </BtnOutlineGreen>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};
