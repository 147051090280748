import React from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import {
  DataTable,
  Field,
  DateTimeField,
  CurrencyField,
} from '../../commons/DataTable';

function Insurances({ data, onFilter, query = {}, total = 0 }) {
  const [t] = useTranslation('COMMON');
  const { offset, limit } = query;

  return (
    <DataTable
      data={data}
      offset={offset}
      total={total}
      limit={limit}
      loadingText={t('LOADING')}
      emptyText={t('NO_DATA')}
      onFilter={onFilter}
    >
      <Field title="Amministratore" source="admin.businessName" />
      <Field title="Fornitore" source="supplier.businessName" />
      <Field title="Assicurazione" source="insuranceNumber" />
      <Field title="Contraente" source="contractor" />
      <Field title="Tipo" source="insuranceType" />
      <DateTimeField
        title="Data inizio"
        source="startDate"
        format="DD/MM/YYYY"
      />
      <DateTimeField title="Data fine" source="endDate" format="DD/MM/YYYY" />
      <DateTimeField
        title="Data incasso prevista"
        source="supposedPaymentDate"
        format="DD/MM/YYYY"
      />
      <DateTimeField
        title="Data rendicontazione"
        source="accountability"
        format="MM/YYYY"
      />
      <CurrencyField
        title="Premio"
        source="gross"
        className="txt-table-right"
      />
      <CurrencyField
        title="Premio imponibile"
        source="net"
        className="txt-table-right"
      />
    </DataTable>
  );
}

export default withRouter(Insurances);
