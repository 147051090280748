import axios from 'axios';

export const dashboard = async ({ year, token } = {}) => {
  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/dashboard?year=${year}`,
      headers: {
        Authorization: token,
      },
    });

    if (response instanceof Error) {
      return { error: response };
    }

    return response;
  } catch (error) {
    // Sentry TODO
    return { error };
  }
};

export const getClientData = async ({ clientType, filterYear, token } = {}) => {
  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/dashboard/clients?year=${filterYear}&type=${clientType}`,
      headers: {
        Authorization: token,
      },
    });

    if (response instanceof Error) {
      return { error: response };
    }

    return response;
  } catch (error) {
    // Sentry TODO
    return { error };
  }
};

export const compensi = async (params = {}) => {
  const {
    year,
    search,
    token,
    limit,
    offset,
    gabettiInvoice,
    paid,
    admin,
    customer,
    number,
    supplier,
    accountabilityYear,
    accountabilityMonth,
    invoiceRef,
    noIaf15,
    invoice15Number,
    noInvoice15,
  } = params;
  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/invoices`,
      headers: {
        Authorization: token,
      },
      params: {
        year,
        search,
        limit,
        offset,
        gabettiInvoice,
        paid,
        admin,
        customer,
        number,
        supplier,
        accountabilityYear,
        accountabilityMonth,
        invoiceRef,
        noIaf15,
        invoice15Number,
        noInvoice15,
      },
    });

    if (response instanceof Error) {
      return { error: response };
    }

    return response;
  } catch (error) {
    // Sentry TODO
    return { error };
  }
};

export const insurances = async (params = {}) => {
  const {
    year,
    search,
    token,
    limit,
    offset,
    paid,
    gabettiInvoice,
    adminName,
    contractor,
    insuranceNumber,
    supplier,
    accountabilityYear,
    accountabilityMonth,
    invoiceRef,
  } = params;
  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/insurances`,
      headers: {
        Authorization: token,
      },
      params: {
        year,
        search,
        limit,
        offset,
        paid,
        gabettiInvoice,
        adminName,
        contractor,
        insuranceNumber,
        supplier,
        accountabilityYear,
        accountabilityMonth,
        invoiceRef,
      },
    });

    if (response instanceof Error) {
      return { error: response };
    }

    return response;
  } catch (error) {
    // Sentry TODO
    return { error };
  }
};
