import React from 'react';

function RegularCheckbox({ data, selected = [], onSelectChange = () => {} }) {
  if (data.regular) {
    return null;
  }

  return (
    <input
      type="checkbox"
      checked={selected.includes(data._id)}
      onChange={() => onSelectChange(data._id)}
    />
  );
}

export default RegularCheckbox;
