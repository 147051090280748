import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'reactstrap';
import BurgerMenu from '../../commons/BurgerMenu';
import Header from '../../commons/Header';
import Footer from '../../commons/Footer';
import { ReactComponent as IconEdit } from '../../images/icn-edit.svg';
import { ContPaginationPage } from '../../styled-components';
import Table from './Table';
import FormGroup from 'reactstrap/lib/FormGroup';
import CustomInput from 'reactstrap/lib/CustomInput';
import generatePaginationOptions from '../../lib/helpers/generatePaginationOptions';

function LogsPresentational({
  data = [],
  total,
  query = {},
  onFilter,
  onUpdate,
}) {
  const [t] = useTranslation();

  return (
    <div className="App">
      <BurgerMenu />

      <div className="corpo">
        <section>
          <Header title={t('PAGES:LOGS')} icon={<IconEdit fill="#ffffff" />} />
        </section>

        <section>
          <Form>
            <Table
              data={data}
              total={total}
              query={query}
              onFilter={onFilter}
              onUpdate={onUpdate}
            />
          </Form>

          <ContPaginationPage>
            <p>{t('COMMON:PAGINATION_PER_PAGE')}</p>

            <FormGroup>
              <CustomInput
                type="select"
                className="pagination-select"
                id="perPage"
                name="perPage"
                value={query.limit}
                onChange={({ target: { value: limit } }) => {
                  onFilter({ limit });
                }}
              >
                {generatePaginationOptions()}
              </CustomInput>
            </FormGroup>
          </ContPaginationPage>
        </section>

        <Footer />
      </div>
    </div>
  );
}

export default LogsPresentational;
