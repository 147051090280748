import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Form,
  CustomInput,
} from 'reactstrap';
import {
  BtnOutlineRed,
  BtnOutlineGreen,
  BtnModalTable,
} from '../../styled-components';
import { isAfter } from '../../lib/helpers/date';
import { ACCOUNTABILITY_LIMIT_DATE } from '../../lib/constants';

function ButtonModalField(props) {
  const [t] = useTranslation('CONTACTS');
  const { data, profile = {}, onRegularChange, showButton } = props;
  const { admin = {}, regular, accountability } = data;
  const canRegularize = !isAfter(accountability, ACCOUNTABILITY_LIMIT_DATE);

  const [selectedRadio, setSelectedRadio] = useState(
    canRegularize ? regular : false
  );

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  function onRegularConfirm() {
    onRegularChange(data._id, selectedRadio);
    toggle();
  }

  return (
    <React.Fragment>
      {(showButton || profile._id === admin._id) && (
        <div className="flex-check">
          <BtnModalTable
            type="button"
            onClick={(event) => {
              event.stopPropagation();
              toggle();
            }}
          >
            {t('REGOLARIZZA')}
          </BtnModalTable>
        </div>
      )}

      {canRegularize ? (
        regular ? (
          <p className="regolarizzata">{t('REGOLARIZZATA')}</p>
        ) : (
          <p className="non-regolarizzata">{t('NON_REGOLARIZZATA')}</p>
        )
      ) : (
        ''
      )}

      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          {t('MODAL_REGOLARIZZA_TITLE')}
        </ModalHeader>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            onRegularConfirm();
          }}
        >
          <ModalBody>
            <div className="max-width-form">
              <FormGroup>
                <div
                  className="radio-modal-table"
                  onChange={({ target }) =>
                    canRegularize
                      ? setSelectedRadio(target.value === '1')
                      : setSelectedRadio(false)
                  }
                >
                  <CustomInput
                    type="radio"
                    id="regularRadio"
                    name="regularRadio"
                    label="Regolarizza"
                    value="1"
                    defaultChecked={selectedRadio}
                    disabled={!canRegularize}
                  />
                  <CustomInput
                    type="radio"
                    id="notRegularRadio"
                    name="regularRadio"
                    label="Non regolarizzare"
                    value="0"
                    defaultChecked={!selectedRadio}
                    disabled={!canRegularize}
                  />
                </div>
              </FormGroup>
            </div>
          </ModalBody>
          <ModalFooter>
            <BtnOutlineRed type="button" className="uppercase" onClick={toggle}>
              {t('COMMON:CANCEL')}
            </BtnOutlineRed>
            <BtnOutlineGreen type="submit" className="uppercase">
              {t('COMMON:CONFIRM')}
            </BtnOutlineGreen>
          </ModalFooter>
        </Form>
      </Modal>
    </React.Fragment>
  );
}

export default ButtonModalField;
