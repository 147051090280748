import React from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { Form, Row, Col, Label, FormGroup, CustomInput } from 'reactstrap';
import BurgerMenu from '../../commons/BurgerMenu';
import Header from '../../commons/Header';
import Footer from '../../commons/Footer';
import { ReactComponent as IconDashboard } from '../../images/icn_dashboard.svg';
import './Dashboard.scss';
import {
  H4Styled,
  ContFilterYear,
  ContFlexBetween,
} from '../../styled-components';
import Table from './Table';
import TotalsRow from './TotalTr';
import TopRow from './TopRow';
import generateYearOptions from '../../lib/helpers/generateYearOptions';

function DashboardPresentational({ data = {}, year, setFilterYear, match }) {
  const [t] = useTranslation('DASHBOARD');

  const {
    subscriptions = {},
    masters = {},
    totals = {},
    variableRevenues = {},
    insuranceVariableRevenues = {},
  } = data;
  const { masterShares = {}, gabettiPerc = {} } = variableRevenues;
  const {
    masterShares: insuranceMasterShares = {},
    gabettiPerc: insuranceGabettiPerc = {},
  } = insuranceVariableRevenues;
  const { data: subscriptionData = {} } = subscriptions;
  const { data: mastersData = {} } = masters;

  const tableData = {
    subscriptions: [],
    masters: [],
    masterShares,
    gabettiPerc,
    insuranceMasterShares,
    insuranceGabettiPerc,
  };

  Object.keys(subscriptionData).forEach((subscriptionType) => {
    tableData.subscriptions.push({
      type: subscriptionType.toLowerCase(),
      title: `Iscrizione ${subscriptionType}`,
      total: subscriptions.data[subscriptionType].total,
      cashed: subscriptions.data[subscriptionType].cashed,
    });
  });

  Object.keys(mastersData).forEach((masterPercentage) => {
    tableData.masters.push({
      title: `Master ${masterPercentage}%`,
      total: -masters.data[masterPercentage].total,
      cashed: -masters.data[masterPercentage].cashed,
      perc: masterPercentage,
    });
  });

  return (
    <div className="App">
      <BurgerMenu />

      <div className="corpo">
        <section>
          <Header
            title={t('PAGES:DASHBOARD')}
            icon={<IconDashboard fill="#ffffff" />}
          />
        </section>

        <section>
          <Form>
            <ContFlexBetween>
              <H4Styled>{t('TABLE_TITLE')}</H4Styled>

              <ContFilterYear>
                <Row>
                  <Col xs="6">
                    <Label>{t('CONTACTS:TITLE_FILTER_YEAR')}</Label>
                  </Col>
                  <Col xs="6">
                    <FormGroup>
                      <CustomInput
                        type="select"
                        id="selectYear"
                        name="year"
                        value={year}
                        onChange={(e) => setFilterYear(e.target.value)}
                      >
                        {generateYearOptions()}
                      </CustomInput>
                    </FormGroup>
                  </Col>
                </Row>
              </ContFilterYear>
            </ContFlexBetween>

            <br />

            <Table
              data={tableData}
              year={year}
              totals={totals}
              topRow={<TopRow totals={totals} />}
              bottomRow={<TotalsRow totals={totals} />}
            />
          </Form>
        </section>

        <Footer />
      </div>
    </div>
  );
}

export default withRouter(DashboardPresentational);
