import React from 'react';
import VarRevInsStudioDetailPresentational from './VarRevInsStudioDetailPresentational';

const VarRevInsStudioDetail = (props) => {
  const {
    profile = {},
    reports = {},
    query = {},
    onFilter = () => {},
  } = props;

  return (
    <VarRevInsStudioDetailPresentational
      data={reports}
      onFilter={onFilter}
      query={query}
      profileType={profile.type}
      {...props}
    />
  );
};

export default VarRevInsStudioDetail;
