export default {
  background: '#F3F3F3',
  black: '#333333',
  white: '#ffffff',
  primary: '#B93544',
  lightPrimary: '#DCB0B5',
  grey: '#E0E0E0',
  darkGrey: '#666666',
  success: '#00C884',
  warning: '#FF8155',
  blue: '#6BA8B2',
  alert: '#F0C755',

  Roboto: 'Roboto, sans-serif',
  RobotoSlab: 'Roboto Slab, serif',
};
