import React from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { formatCurrency } from '../../../lib/helpers/formatters';
import { ROYALTIES_ALLOWED_TYPES } from '../../../App';

function Summary({
  totals = {},
  supplierTotals = {},
  gnetAdminTotals = {},
  variableRevenuesData = [],
  profileType,
  isProfileMaster,
}) {
  const [t] = useTranslation('CONTACTS');

  const {
    revenue: supplierRevenue = 0,
    masterContractAmount: supplierMasterAmount = 0,
  } = supplierTotals;
  const {
    revenue: gnetAdminRevenue = 0,
    masterContractAmount: gnetMasterAmount = 0,
  } = gnetAdminTotals;

  const {
    billableTotal = 0,
    billableTotalNet = 0,
    billableVariableRevenues = 0,
    billableVariableRevenuesInsurances = 0,
    billableVariableRevenuesInsurancesStudio = 0,
    bonuses = 0,
    // masterContractAmount = 0,
    // revenue = 0,
    total = 0,
    totalNet = 0,
    variableRevenues = 0,
    variableRevenuesInsurances = 0,
    variableRevenuesInsurancesStudio = 0,
    lastYearMasterContractAmount = 0,
    lastYearRevenue = 0,
  } = totals;

  return (
    <div className="summary-table">
      <Row>
        <Col xs="4"></Col>
        <Col xs="4">
          <p>{t('CONTACT_MASTER_FEE')}</p>
        </Col>
        <Col xs="4">
          <p>{t('FINANCIAL_REPORT_REVENUE')}</p>
        </Col>
      </Row>

      {ROYALTIES_ALLOWED_TYPES.includes(profileType) &&
        isProfileMaster && [
          <Row key={0}>
            <Col xs="4">
              <p>{t('FINANCIAL_REPORT_TOTAL1')}</p>
            </Col>
            <Col xs="4">
              <p>{formatCurrency(supplierMasterAmount)}</p>
            </Col>
            <Col xs="4">
              <p>{formatCurrency(supplierRevenue)}</p>
            </Col>
          </Row>,
          <Row key={1}>
            <Col xs="4">
              <p>{t('FINANCIAL_REPORT_TOTAL2')}</p>
            </Col>
            <Col xs="4">
              <p>{formatCurrency(gnetMasterAmount)}</p>
            </Col>
            <Col xs="4">
              <p>{formatCurrency(gnetAdminRevenue)}</p>
            </Col>
          </Row>,
          <Row key={2}>
            <Col xs="4">
              <p>{t('FINANCIAL_REPORT_TOTAL6')}</p>
            </Col>
            <Col xs="4">
              <p>{formatCurrency(variableRevenues)}</p>
            </Col>
            <Col xs="4">
              <p>{formatCurrency(billableVariableRevenues)}</p>
            </Col>
          </Row>,
          <Row key={3}>
            <Col xs="4">
              <p>{t('FINANCIAL_REPORT_TOTAL7')}</p>
            </Col>
            <Col xs="4">
              <p>{formatCurrency(variableRevenuesInsurances)}</p>
            </Col>
            <Col xs="4">
              <p>{formatCurrency(billableVariableRevenuesInsurances)}</p>
            </Col>
          </Row>,
        ]}

      {ROYALTIES_ALLOWED_TYPES.includes(profileType) && (
        <Row>
          <Col xs="4">
            <p>{t('FINANCIAL_REPORT_TOTAL8')}</p>
          </Col>
          <Col xs="4">
            <p>{formatCurrency(variableRevenuesInsurancesStudio)}</p>
          </Col>
          <Col xs="4">
            <p>{formatCurrency(billableVariableRevenuesInsurancesStudio)}</p>
          </Col>
        </Row>
      )}

      <Row>
        <Col xs="4">
          <p>{t('FINANCIAL_REPORT_TOTAL3')}</p>
        </Col>
        <Col xs="4">
          <p>{formatCurrency(totalNet)}</p>
        </Col>
        <Col xs="4">
          <p>{formatCurrency(billableTotalNet)}</p>
        </Col>
      </Row>

      <Row>
        <Col xs="4">
          <p>{t('FINANCIAL_REPORT_TOTAL4')}</p>
        </Col>
        <Col xs="4">
          <p>{formatCurrency(lastYearMasterContractAmount)}</p>
        </Col>
        <Col xs="4">
          <p>{formatCurrency(lastYearRevenue)}</p>
        </Col>
      </Row>

      <Row>
        <Col xs="4" className="flex-between">
          <p>{t('CONTACTS:LABEL_INVOICE_BONUS').toUpperCase()}</p>
        </Col>
        <Col xs="4">
          <p>{formatCurrency(bonuses)}</p>
        </Col>
        <Col xs="4">
          <p>{formatCurrency(bonuses)}</p>
        </Col>
      </Row>

      <Row>
        <Col xs="4">
          <p>{t('FINANCIAL_REPORT_TOTAL5')}</p>
        </Col>
        <Col xs="4">
          <p>{formatCurrency(total)}</p>
        </Col>
        <Col xs="4">
          <p>{formatCurrency(billableTotal)}</p>
        </Col>
      </Row>
    </div>
  );
}

export default withRouter(Summary);
