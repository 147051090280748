import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardBody } from 'reactstrap';
import Header from '../../commons/Header';
// import Menu from '../../commons/Menu';
import BurgerMenu from '../../commons/BurgerMenu';
import { useAuth } from '../../commons/Auth';
import { ReactComponent as IconProfile } from '../../images/icn_profilo.svg';
import './MyProfile.scss';
import { Redirect } from 'react-router-dom';
import MyProfilePresentational from './MyProfilePresentational';

function MyProfile({ location }) {
  const [t] = useTranslation();
  const [{ isAdmin, profile = {} } = {}] = useAuth();

  if (isAdmin)
    return (
      // Admins do not have a profile
      <Redirect
        push={false}
        to={{
          pathname: '/dashboard',
          state: { from: location },
        }}
      />
    );

  return (
    <div className="App">
      {/* <Menu /> */}
      <BurgerMenu />

      <div className="corpo">
        <section>
          <Header
            title={t('PAGES:MY_PROFILE')}
            icon={<IconProfile fill="#FFFFFF" />}
          />
          <Card>
            <CardBody>
              <MyProfilePresentational data={profile}></MyProfilePresentational>
            </CardBody>
          </Card>
        </section>
      </div>
    </div>
  );
}

export default MyProfile;
