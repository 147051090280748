import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  FormGroup,
  Label,
  InputGroup,
  Input,
  InputGroupAddon,
  Form,
  CustomInput,
  FormText,
} from 'reactstrap';
import { BtnOutlineRed, BtnOutlineGreen } from '../../../styled-components';
import { ReactComponent as IconPlus } from '../../../images/icn_plus_red.svg';
import DateInput from '../../../commons/DateInput';

const ModalReceipt = (props) => {
  const [t] = useTranslation('CONTACTS');
  const { className, onAddPayment } = props;

  const [date, setDate] = useState('');
  const [type, setType] = useState('');
  const [amountGross, setAmountGross] = useState('');
  const [errors, setErrors] = useState({
    date: false,
    type: false,
    amountGross: false,
  });

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const onAddPaymentSubmit = async () => {
    if (!date || !type || !amountGross) {
      const errors = {};

      errors.date = !date;
      errors.type = !type;
      errors.amountGross = !amountGross;

      setErrors(errors);
      return;
    }

    onAddPayment({ date, type, amountGross: Number(amountGross) }).then(() => {
      setDate('');
      setType('');
      setAmountGross('');
      setErrors({});

      toggle();
    });
  };

  return (
    <React.Fragment>
      <BtnOutlineRed type="button" onClick={toggle}>
        <IconPlus /> {t('BTN_NEW_RECEIPT')}
      </BtnOutlineRed>
      <Modal isOpen={modal} toggle={toggle} className={className}>
        <ModalHeader toggle={toggle}>{t('MODAL_RECEIPT_TITLE')}</ModalHeader>
        <Form>
          <ModalBody>
            <div className="max-width-form">
              <Row>
                <Col xs="12">
                  <FormGroup>
                    <Label>{t('RECEIPT_DATE')} *</Label>
                    <DateInput value={date} onChange={setDate} />
                    {errors.date && (
                      <FormText className="error-message">
                        {t('COMMON:REQUIRED')}
                      </FormText>
                    )}
                  </FormGroup>
                </Col>
                <Col xs="12">
                  <FormGroup>
                    <Label>{t('IMPORT_TYPE')} *</Label>
                    <CustomInput
                      type="select"
                      id="type"
                      name="customSelect"
                      onChange={(e) => setType(e.target.value)}
                    >
                      <option value="">{t('IMPORT_TYPE_PLACEHOLDER')}</option>
                      <option value="TRANSFER">{t('TRANSFER')}</option>
                      <option value="CARD">{t('CARD')}</option>
                      <option value="RIBA">{t('RIBA')}</option>
                      <option value="CASH">{t('CASH')}</option>
                      <option value="OTHER">{t('OTHER')}</option>
                    </CustomInput>
                    {errors.type && (
                      <FormText className="error-message">
                        {t('COMMON:REQUIRED')}
                      </FormText>
                    )}
                  </FormGroup>
                </Col>
                <Col xs="12">
                  <FormGroup>
                    <Label>{t('IMPORT_PAID')} *</Label>
                    <InputGroup>
                      <Input
                        type="number"
                        name="amountGross"
                        placeholder="0.00"
                        value={amountGross}
                        onChange={({ target }) => {
                          if (/^[\d]*\.?[\d]{0,2}$/.test(target.value)) {
                            setAmountGross(target.value);
                          }
                        }}
                      />
                      <InputGroupAddon addonType="prepend">€</InputGroupAddon>
                    </InputGroup>
                    {errors.amountGross && (
                      <FormText className="error-message">
                        {t('COMMON:REQUIRED')}
                      </FormText>
                    )}
                  </FormGroup>
                </Col>
              </Row>
            </div>
          </ModalBody>
          <ModalFooter>
            <BtnOutlineRed type="button" className="uppercase" onClick={toggle}>
              {t('COMMON:CANCEL')}
            </BtnOutlineRed>
            <BtnOutlineGreen
              type="button"
              className="uppercase"
              onClick={onAddPaymentSubmit}
            >
              {t('COMMON:CONFIRM')}
            </BtnOutlineGreen>
          </ModalFooter>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default ModalReceipt;
