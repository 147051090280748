import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAlert } from 'react-alert';
import { save, remove, create, upload } from '../../../lib/fetch/profiles';
import { deleteFile } from '../../../lib/fetch/files';
import ProfileInfoPresentational from './ProfileInfoPresentational';
import { useLoading } from '../../../commons/Loading';
import { useAuth } from '../../../commons/Auth';

const ProfileInfo = (props) => {
  const [{ token } = {}] = useAuth();
  const [t] = useTranslation();
  const alert = useAlert();
  const { showLoading, hideLoading } = useLoading();

  const { history, id, profile, setProfile } = props;

  const isUpdating = id !== 'create';

  async function updateProfile(updates) {
    showLoading();

    const { error, data } = await save({ id, data: updates, token });

    if (error) {
      hideLoading();

      alert.error(t('COMMON:GENERIC_ERROR'));
      return;
    }

    setProfile(data);
    hideLoading();
    alert.success(t('COMMON:SUCCESS_UPDATE'));
  }

  async function deleteProfile() {
    showLoading();
    const { error } = await remove({ id, token });

    if (error) {
      hideLoading();
      const { response = {} } = error;
      const { data = {} } = response;
      const { message = '' } = data;
      alert.error(message || t('COMMON:GENERIC_ERROR'));
      return;
    }

    setProfile({});
    hideLoading();
    alert.success(t('COMMON:SUCCESS_DELETE'));
    history.push('/profiles');
  }

  async function createProfile(profile) {
    showLoading();
    const { error, data } = await create({ id, data: profile, token });

    if (error) {
      hideLoading();

      alert.error(t('COMMON:GENERIC_ERROR'));
      return;
    }

    hideLoading();

    alert.success(t('COMMON:SUCCESS_CREATE'));
    history.replace(`/profiles/${data._id}/info`);
  }

  async function uploadFile(file) {
    showLoading();

    const { error, data } = await upload({ id, file, token });

    if (error) {
      hideLoading();

      alert.error(t('COMMON:GENERIC_ERROR'));
      return;
    }

    hideLoading();
    setProfile({ ...profile, files: data });
    alert.success(t('COMMON:SUCCESS_UPLOAD'));
  }

  async function handleDelete(key) {
    showLoading();

    const { error, data } = await deleteFile({ id, key, token });

    if (error) {
      hideLoading();

      alert.error(t('COMMON:GENERIC_ERROR'));
      return;
    }

    hideLoading();
    setProfile({ ...profile, files: data });
    alert.success(t('COMMON:SUCCESS_DELETE'));
  }

  return (
    <ProfileInfoPresentational
      data={profile}
      isUpdating={isUpdating}
      updateProfile={updateProfile}
      deleteProfile={deleteProfile}
      createProfile={createProfile}
      uploadFile={uploadFile}
      handleDelete={handleDelete}
      {...props}
    />
  );
};

export default ProfileInfo;
