import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Proptypes from 'prop-types';

function PrivateRoute(props) {
  const {
    component: Component,
    appLoaded,
    isAuthenticated = false,
    profile = {},
    publicRedirect = '/',
    privateRedirect = '/my-profile',
    allow,
    ...rest
  } = props;

  const getAllowedComponent = () => {
    const component = <Component {...props} />;
    if (!allow || !allow.length) {
      // Se allowType è vuoto, consenti l'accesso all'utente autenticato, qualunque sia il tipo
      return component;
    }
    if (Array.isArray(allow) && allow.includes(profile.type)) {
      // Se allowType non è vuoto, consenti l'accesso all'utente autenticato il cui tipo coincide con i tipi permessi
      return component;
    }

    return (
      <Redirect
        push={false}
        to={{
          pathname: privateRedirect,
          state: { from: props.location },
        }}
      />
    );
  };

  return (
    <Route
      {...rest}
      render={(props) => {
        return isAuthenticated ? (
          appLoaded ? (
            getAllowedComponent()
          ) : (
            ''
          )
        ) : (
          <Redirect
            push={false}
            to={{
              pathname: publicRedirect,
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  );
}

PrivateRoute.propTypes = {
  component: Proptypes.func.isRequired,
  isAuthenticated: Proptypes.bool,
  allow: Proptypes.arrayOf(Proptypes.string),
};

export default PrivateRoute;
