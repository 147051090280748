import './Invoices.scss';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../commons/Auth';
import { useAlert } from 'react-alert';
import {
  useQueryParams,
  StringParam,
  withDefault,
  NumberParam,
} from 'use-query-params';
import { useLoading } from '../../commons/Loading';
import InvoicesPresentational from './InvoicesPresentational';

function Invoices({ profile }) {
  const [invoices, setInvoices] = useState([]);
  const [{ token } = {}] = useAuth();
  const [t] = useTranslation();
  const { showLoading, hideLoading } = useLoading();
  const alert = useAlert();

  const [query, setQuery] = useQueryParams({
    all: withDefault(StringParam, 'false'),
    admin: withDefault(StringParam, ''),
    customer: withDefault(StringParam, ''),
    number: withDefault(StringParam, ''),
    supplier: withDefault(StringParam, ''),
    year: withDefault(NumberParam, new Date().getFullYear()),
    accountabilityYear: NumberParam,
    accountabilityMonth: NumberParam,
  });

  const {
    all,
    admin,
    customer,
    number,
    supplier,
    year,
    accountabilityMonth,
    accountabilityYear,
  } = query;

  useEffect(() => {
    fetchInvoices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    all,
    admin,
    customer,
    number,
    supplier,
    year,
    accountabilityMonth,
    accountabilityYear,
  ]);

  const fetchInvoices = async () => {
    showLoading();

    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/invoices/${profile._id}`,
      {
        headers: { Authorization: token },
        params: query,
      }
    );

    if (response instanceof Error) {
      hideLoading();
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    setInvoices(response.data);
    hideLoading();
  };

  const searchAdministrators = async (value) => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/profiles/admins`,
      {
        headers: { Authorization: token },
        params: { search: value },
      }
    );

    if (response instanceof Error) {
      alert.error(t('COMMON:GENERIC_ERROR'));
      return [];
    }

    const { data = [] } = response;
    return data;
  };

  const searchCustomers = async (value) => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/invoices/${profile._id}/selfcompilation`,
      {
        headers: { Authorization: token },
        params: { search: value },
      }
    );

    if (response instanceof Error) {
      alert.error(t('COMMON:GENERIC_ERROR'));
      return [];
    }

    const { data = [] } = response;

    return data;
  };

  const onNewInvoiceConfirm = async (data) => {
    showLoading();

    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/invoices`,
      data,
      {
        headers: { Authorization: token },
      }
    );
    hideLoading();

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    alert.success(t('COMMON:SUCCESS_UPDATE'));
    fetchInvoices();
  };

  const onInvoiceUpdate = async (data) => {
    const { _id } = data;
    showLoading();

    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/invoices/${_id}`,
      data,
      {
        headers: { Authorization: token },
      }
    );
    hideLoading();

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    alert.success(t('COMMON:SUCCESS_UPDATE'));
    fetchInvoices();
  };

  const onAddPayment = async ({ invoiceId, data }) => {
    showLoading();

    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/invoices/${invoiceId}/payments`,
      data,
      {
        headers: { Authorization: token },
      }
    );
    hideLoading();

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    alert.success(t('COMMON:SUCCESS_UPDATE'));
    fetchInvoices();
  };

  const onDeletePayment = async ({ invoiceId, paymentIndex }) => {
    showLoading();

    const response = await axios.delete(
      `${process.env.REACT_APP_API_URL}/invoices/${invoiceId}/payments/${paymentIndex}`,
      {
        headers: { Authorization: token },
      }
    );
    hideLoading();

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    alert.success(t('COMMON:SUCCESS_UPDATE'));
    fetchInvoices();
  };

  const onValidateInvoice = async (invoiceId) => {
    showLoading();

    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/invoices/${invoiceId}/validate`,
      { validated: true },
      {
        headers: { Authorization: token },
      }
    );
    hideLoading();

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    alert.success(t('COMMON:SUCCESS_UPDATE'));
    fetchInvoices();
  };

  const onInvoiceDelete = async (invoiceId) => {
    showLoading();

    const response = await axios.delete(
      `${process.env.REACT_APP_API_URL}/invoices/${invoiceId}`,
      {
        headers: { Authorization: token },
      }
    );
    hideLoading();

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    alert.success(t('COMMON:SUCCESS_UPDATE'));
    fetchInvoices();
  };

  function onFilter(queryDiff) {
    if (queryDiff && typeof queryDiff.search === 'string') {
      localStorage.setItem('myInvoiceSearch', queryDiff.search);
    }
    if (
      (queryDiff.accountabilityYear &&
        queryDiff.accountabilityYear !== accountabilityYear) ||
      queryDiff.accountabilityYear === ''
    ) {
      queryDiff.accountabilityMonth = '';
    }
    setQuery(queryDiff);
  }

  return (
    <InvoicesPresentational
      data={invoices}
      query={query}
      onFilter={onFilter}
      searchAdministrators={searchAdministrators}
      searchCustomers={searchCustomers}
      onNewInvoiceConfirm={onNewInvoiceConfirm}
      onInvoiceUpdate={onInvoiceUpdate}
      onAddPayment={onAddPayment}
      onDeletePayment={onDeletePayment}
      onValidateInvoice={onValidateInvoice}
      onInvoiceDelete={onInvoiceDelete}
    />
  );
}

export default Invoices;
