import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  FormGroup,
  CustomInput,
  Form,
} from 'reactstrap';
import {
  BtnOutlineRed,
  BtnOutlineGreen,
  BtnModalTable,
} from '../../styled-components';

const InvoicesRef = ({ onConfirm, children, ...props }) => {
  const [t] = useTranslation('ROYALTY');
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const [invoiceRef, setInvoiceRef] = useState(props.value);

  function onSubmit() {
    onConfirm({ invoiceRef, _id: props.data._id });
    toggle();
  }

  const childrenComponents = React.Children.map(children, (child) => {
    return React.cloneElement(child, {
      ...props,
    });
  });

  return (
    <React.Fragment>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {childrenComponents}
        <div style={{ marginLeft: '10px' }}>
          <BtnModalTable type="button" onClick={toggle}>
            MODIFICA
          </BtnModalTable>
        </div>
      </div>

      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          Modifica fattura di riferimento
        </ModalHeader>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            onSubmit();
          }}
        >
          <ModalBody>
            <div className="max-width-form">
              <Row>
                <Col xs="12">
                  <FormGroup>
                    <div className="radio-modal-table">
                      <CustomInput
                        type="text"
                        name="invoiceRef"
                        value={invoiceRef}
                        onChange={({ target }) => {
                          setInvoiceRef(target.value);
                        }}
                        style={{ textAlign: 'center', width: '100%' }}
                      />
                    </div>
                  </FormGroup>
                </Col>
              </Row>
            </div>
          </ModalBody>
          <ModalFooter>
            <BtnOutlineRed
              type="button"
              className="uppercase"
              onClick={() => {
                setInvoiceRef(props.value);
                toggle();
              }}
            >
              {t('COMMON:CANCEL')}
            </BtnOutlineRed>
            <BtnOutlineGreen
              type="button"
              className="uppercase"
              onClick={onSubmit}
            >
              {t('COMMON:CONFIRM')}
            </BtnOutlineGreen>
          </ModalFooter>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default InvoicesRef;
